/* eslint-disable no-unused-expressions */
import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { createGlobalStyle } from "styled-components";
import { Provider } from "react-redux";
import { setTag } from "./sentry/sentry";
import store from "./store";
import register from "./registerServiceWorker";
import App from "./App";
import BrowserNotSupported from "./components/Error/BrowserNotSupported";
import { CSSResets } from "./styles/reset";
import config from "./config";
import { RELEASE_VERSION } from "./utils/constants";
import { isBrowserSupported } from "./utils";
import apiClient from "./utils/apiClients";
import trueEccApiClient from "./true-ecc/utils/apiClients";
import jobsPageApiClient from "./views/JobsPage/utils/apiClients";
import { handleError, handleTrueEccError } from "./utils/apiHandlers";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

apiClient.interceptors.response.use(
	response => {
		handleError(response);
		return response;
	},
	error => {
		handleError(error.response);
		return error.response;
	}
);
jobsPageApiClient.interceptors.response.use(
	response => {
		handleError(response);
		return response;
	},
	error => {
		handleError(error.response);
		return error.response;
	}
);

trueEccApiClient.interceptors.response.use(
	res => res,
	err => {
		handleTrueEccError(err.response);
		return Promise.reject(err);
	}
);

process.env.NODE_ENV !== "development" &&
	Sentry.init({
		dsn: config.sentry,
		environment: config.env,
		release: `employer-dashboard-web@${RELEASE_VERSION}`,
		ignoreErrors: ["TypeError Function.<anonymous>(TAYVCY680JDL)", "TAYVCY680JDL"],
		denyUrls: ["https://cdn.nmgassets.com"],
	});

process.env.NODE_ENV !== "development" &&
	Sentry.configureScope(scope => {
		setTag(scope);
	});

const GlobalStyle = createGlobalStyle`
  ${CSSResets};
`;

if (!isBrowserSupported()) {
	ReactDOM.render(<BrowserNotSupported />, document.getElementById("app"));
} else {
	ReactDOM.render(
		<ErrorBoundary>
			<Provider store={store}>
				<GlobalStyle />
				<BrowserRouter
					getUserConfirmation={() => {
						/* Empty callback to block the default browser prompt */
					}}
				>
					<App />
				</BrowserRouter>
			</Provider>
		</ErrorBoundary>,
		document.getElementById("app")
	);

	register(); // Runs register() as default function
}
