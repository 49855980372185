import { modalActionTypes } from "../constants/types";

const initialState = {
	confirmationModal: {
		show: false,
	},
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case modalActionTypes.showConfirmationModal: {
			return { ...state, confirmationModal: { show: true, ...payload } };
		}
		case modalActionTypes.hideConfirmationModal: {
			return { ...state, confirmationModal: { show: false } };
		}
		default:
			return state;
	}
};
