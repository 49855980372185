export const openRespondChat = () => {
	try {
		if (typeof window !== "undefined") {
			const respondButton = document.getElementById(
				"faq-bot-bottom-livechat-container"
			);

			if (respondButton) {
				respondButton.click();
			}
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log(error);
	}
};
