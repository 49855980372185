/* eslint-disable import/prefer-default-export */
import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const secondsToTime = secs => {
	const divisorForMinutes = secs % (60 * 60);
	const minutes = Math.floor(divisorForMinutes / 60);

	const divisorForSeconds = divisorForMinutes % 60;
	const seconds = Math.ceil(divisorForSeconds);

	return {
		m: minutes <= 9 ? `0${minutes}` : `${minutes}`,
		s: seconds <= 9 ? `0${seconds}` : `${seconds}`,
	};
};

export const useCounter = ({
	duration = 0,
	onCounterEnd = () => null,
	onCounterStart = () => null,
	onCounterCleanup = () => null,
	onCounterChange = () => null,
}) => {
	const timer = useRef(null);
	const [remaningTimeInSeconds, setRemaningTimeInSeconds] = useState(duration);

	const clearCounter = () => {
		clearTimeout(timer.current);
		onCounterCleanup();
	};

	const startCounter = () => {
		setRemaningTimeInSeconds(duration);
		onCounterStart();
		(function countRecursion() {
			timer.current = setTimeout(() => {
				setRemaningTimeInSeconds(c => {
					const newC = c - 1;
					onCounterChange(newC);
					if (newC === 0) {
						onCounterEnd();
						clearTimeout(timer.current);
						return 0;
					}
					countRecursion();
					return newC;
				});
			}, 1000);
		})();

		return clearCounter;
	};

	return [secondsToTime(remaningTimeInSeconds), startCounter, clearCounter];
};
