import {
	INIT_JOB_APPLICATIONS_TAB_COUNT,
	INCREASE_TAB_COUNT,
	DECREASE_TAB_COUNT,
	UPDATE_TAB_COUNT,
	RESET_JOB_APPLICATIONS_TAB_COUNT,
} from "../constants/types";

const initialState = {
	jobId: null,
	not_connected: 0,
	connected: 0,
	rejected: 0,
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case INIT_JOB_APPLICATIONS_TAB_COUNT:
			return {
				...state,
				...payload,
			};
		case INCREASE_TAB_COUNT:
			return {
				...state,
				[payload.tab]: state[payload.tab] + 1 || 1,
			};
		case DECREASE_TAB_COUNT:
			return {
				...state,
				[payload.tab]: state[payload.tab] - 1 >= 0 ? state[payload.tab] - 1 : 0,
			};
		case UPDATE_TAB_COUNT:
			return {
				...state,
				[payload.tab]: payload.count,
			};
		case RESET_JOB_APPLICATIONS_TAB_COUNT:
			return {
				...state,
				...initialState,
			};
		default:
			return state;
	}
};
