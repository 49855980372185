import {
	UPDATE_RECENT_CANDIDATE_QUEUE,
	RESET_RECENT_CANDIDATE_QUEUE,
} from "../constants/types";

const initialState = {};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case UPDATE_RECENT_CANDIDATE_QUEUE: {
			return { ...state, ...payload };
		}
		case RESET_RECENT_CANDIDATE_QUEUE: {
			return initialState;
		}
		default:
			return state;
	}
};
