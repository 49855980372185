import {
	OPEN_COMMENT_MODAL,
	COMMENT_DATA,
	SNACKBAR_SHOW,
	JOB_SEARCH_BY,
	IS_QC_DASHBOARD,
	ATS_SNACKBAR_SHOW,
	SET_INTERNAL_DASHBOARD_TYPE,
} from "../constants/InternalUserTypes";
import { DASHBOARD_TYPES } from "../utils/constants";

const initialState = {
	commentModalData: {
		show: false,
	},
	commentDataByInternalUser: null,
	snackBarStatus: {
		show: false,
		message: "",
	},
	jobSearchByName: "companyName",
	isQcDashboard: false,
	internalDashboardType: DASHBOARD_TYPES.UR,
};

const internalUser = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_COMMENT_MODAL:
			return {
				...state,
				commentModalData: action.value,
			};
		case COMMENT_DATA:
			return {
				...state,
				commentDataByInternalUser: action.value,
			};
		case SNACKBAR_SHOW:
			return {
				...state,
				snackBarStatus: action.value,
			};
		case ATS_SNACKBAR_SHOW:
			return {
				...state,
				ATSSnackBarData: action.value,
			};
		case JOB_SEARCH_BY:
			return {
				...state,
				jobSearchByName: action.value,
			};
		case IS_QC_DASHBOARD:
			return {
				...state,
				isQcDashboard: action.value,
			};
		case SET_INTERNAL_DASHBOARD_TYPE:
			return {
				...state,
				internalDashboardType: action.value,
			};
		default:
			return state;
	}
};

export default internalUser;
