/* eslint-disable no-empty-character-class */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import mixpanel from "mixpanel-browser";
import config from "../config";
import {
	DEVICE_IDENTIFIER,
	DEVICE_INFO,
	GENDER_DATA,
	SHIELD_ID,
	URL_PARAMS,
	URL_PARAM_KEYS,
	UTM_CONSTANTS_VALUES,
	UTM_PARAMS,
} from "./constants";

window.mixpanel = mixpanel;
mixpanel.init(config.mixpanelKey, {
	api_host: config.mixpanelProxyURL,
});

const getQueryParam = (url, param) => {
	// Expects a raw URL
	const newParam = param.replace(/[[]/, "[").replace(/[]]/, "]");
	const regexS = `[?&]${newParam}=([^&#]*)`;
	const regex = new RegExp(regexS);
	const results = regex.exec(url);
	if (
		results === null ||
		(results && typeof results[1] !== "string" && results[1].length)
	) {
		return "";
	}
	return decodeURIComponent(results[1]).replace(/\W/gi, " ");
};

const campaignParams = () => {
	const campaign_keywords = "utm_source utm_medium utm_campaign utm_content utm_term".split(
		" "
	);
	let kw = "";
	const params = {};
	let index;
	for (index = 0; index < campaign_keywords.length; ++index) {
		kw = getQueryParam(document.URL, campaign_keywords[index]);
		if (kw.length) {
			params[`${campaign_keywords[index]} [last touch]`] = kw;
		}
	}
	mixpanel.people.set(params);
	mixpanel.register(params);
};

campaignParams();
// const envCheck = process.env.NODE_ENV === "development";

const actions = {
	identify: id => {
		mixpanel.identify(id);
	},
	alias: id => {
		mixpanel.alias(id);
	},
	track: (name, props, jobData) => {
		const extraJobData = {
			Openings: jobData && jobData.no_of_openings,
			Gender: jobData && jobData.gender && GENDER_DATA[jobData.gender],
			Education: jobData && jobData.education_level,
			"English Requirements": jobData?.job_data?.english,
			Experience: jobData?.experience_in_years,
			Shift: jobData?.shift,
			Deposit:
				jobData?.job_data?.json_data?.deposit?.amount ||
				jobData?.job_data?.deposit?.amount,
			"Application From": jobData?.applicant_location,
			Area: jobData?.address?.area?.name,
			City: jobData?.address?.area?.city?.name,
			"Job ID": jobData?.id,
			"Jobs Category": jobData?.category,
			"Expiry Date": jobData?.expiry,
			"Is Paid": !!jobData?.coins_spent,
			"Category ID": jobData?.type?.job_type,
			"Coins Spent": jobData?.coins_spent,
		};

		const shieldData = {};

		const shieldId = localStorage.getItem(SHIELD_ID);
		if (shieldId) {
			shieldData[SHIELD_ID] = shieldId;
		}

		let fpData = {};

		const deviceIdentifier = localStorage.getItem(DEVICE_IDENTIFIER);
		const deviceInfo = JSON.parse(localStorage.getItem(DEVICE_INFO)) || {};

		if (deviceIdentifier) {
			fpData[DEVICE_IDENTIFIER] = deviceIdentifier;
			fpData = { ...fpData, ...deviceInfo };
		}

		// Get Params from URL
		const { search } = window.location;
		const params = new URLSearchParams(search);
		const query = {};
		// eslint-disable-next-line no-restricted-syntax
		for (const [key, value] of params.entries()) {
			if (URL_PARAM_KEYS.includes(key)) {
				// incase of whatsapp campign
				if (
					key === UTM_PARAMS.utm_custom_2 &&
					value?.includes(
						UTM_CONSTANTS_VALUES[UTM_PARAMS.utm_custom_2]?.toLowerCase()
					)
				) {
					query[key] = UTM_CONSTANTS_VALUES[UTM_PARAMS.utm_custom_2];
				} else {
					query[key] = value;
				}
			}
		}
		// If URL params are not stored in Local Storage, capture them from URL and store them.
		if (!localStorage.getItem(URL_PARAMS)) {
			if (Object.keys(query).length > 0) {
				localStorage.setItem(URL_PARAMS, JSON.stringify(query));
			}
		} else {
			localStorage.setItem(
				URL_PARAMS,
				JSON.stringify({
					...JSON.parse(localStorage.getItem(URL_PARAMS)),
					...query,
				})
			);
		}

		const utmData = JSON.parse(localStorage.getItem(URL_PARAMS));

		mixpanel.track(
			name,
			jobData
				? {
						...extraJobData,
						...utmData,
						...props,
						...shieldData,
				  }
				: { ...props, ...utmData, ...shieldData, ...fpData }
		);
	},
	getGroup: (name, value) => {
		return mixpanel.get_group(name, value);
	},
	trackWithoutGenricProps: (name, props) => {
		mixpanel.track(name, props);
	},
	people: {
		set: props => {
			mixpanel.people.set(props);
		},
		set_once: props => {
			mixpanel.people.set_once(props);
		},
		union: (key, val) => {
			mixpanel.people.union(key, val);
		},
	},
};

const Mixpanel = actions;

export default Mixpanel;
