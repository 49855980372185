import { lazy } from "react";
import withSuspense from "../hocs/withSuspense";
import { retry } from "./commonUtils";

/* eslint-disable no-unused-vars */
export const isMobileOrTablet = () => {
	return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
};

/**
 * @description converts an objects key-values into get url params using encodeURIComponent
 */
export const objectToGetParams = (data = {}) => {
	const params = Object.entries(data)
		.filter(([, value]) => value !== undefined && value !== null)
		.map(
			([key, value]) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
		);
};

/**
 * @description generate a facebook share url
 * @param {String} url
 * @param {String} quote
 */
export const createFacebookShareUrl = (url, quote) => {
	return `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
		u: url,
		quote,
	})}`;
};

export const createLinkedInShareUrl = (url, title, summary, source) => {
	return `https://linkedin.com/shareArticle${objectToGetParams({
		url,
		mini: "true",
		title,
		summary,
		source,
	})}`;
};

export const createWhatsAppShareUrl = (url, title, separator) => {
	return `https://${
		isMobileOrTablet() ? "api" : "web"
	}.whatsapp.com/send?${objectToGetParams({
		text: title ? title + separator + url : url,
	})}`;
};

export const lazyLoader = fn => withSuspense(lazy(() => retry(fn)));
