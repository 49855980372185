export default {
	JOB_APPLICATIONS_URL: "api/employer/v2/jobs/",
	CANDIDATE_WHATSAPP_URL: "api/employer/v1/get_candidate_whatsapp/",
	UPDATE_CONNECTION_STATUS_V2_URL: "api/employer/v2/applications/connection_update/",
	REJECT_CANDIDATE_V2_URL: "/api/employer/v2/true_ecc/reject",
	RESTORE_CANDIDATE_V2_URL: "api/employer/v2/true_ecc/restore",
	getUpdateEccPrefenceAPI: jobId =>
		`api/employer/v1/jobs/${jobId}/tecc/employer/preference/`,
	BULK_REJECT_CANDIDATES: "api/employer/v1/jobs/%job_id%/bulk/reject/",
	BULK_REJECT_CANDIDATES_STATUS: "api/employer/v1/jobs/%job_id%/bulk/reject/poll/",
	csvDownloadFileAPI: jobId =>
		`api/employer/v2/jobs/${jobId}/csv-download-applications/`,
	applicantDetailURL: applicantId => `api/employer/v1/applications/${applicantId}/`,
	eccStallingInfoURL: jobId => `/api/employer/v1/job/${jobId}/ecc_info`,
	TAB_AWARENESS_STATUS: "/api/jobfeed/v1/jobs/user/flags",
};
