import {
	INIT_JOB_APPLICATIONS_TAB_COUNT,
	INCREASE_TAB_COUNT,
	DECREASE_TAB_COUNT,
	RESET_JOB_APPLICATIONS_TAB_COUNT,
} from "../constants/types";

export const initJobApplicationsCount = (jobData = {}) => {
	return {
		type: INIT_JOB_APPLICATIONS_TAB_COUNT,
		payload: {
			jobId: jobData?.id,
			not_connected: jobData?.not_connected_count,
			connected: jobData?.connected_count,
			rejected: jobData?.rejected_count,
		},
	};
};

export const resetJobApplicationsCount = () => {
	return {
		type: RESET_JOB_APPLICATIONS_TAB_COUNT,
	};
};

export const increaseApplicationCount = tab => {
	return {
		type: INCREASE_TAB_COUNT,
		payload: {
			tab,
		},
	};
};

export const decreaseApplicationCount = tab => {
	return {
		type: DECREASE_TAB_COUNT,
		payload: {
			tab,
		},
	};
};
