/* eslint-disable import/prefer-default-export */
import config from "../../config";

export const RAVEN_BASE_URL = `${config.baseUrl}/raven/api/v4/`;
export const RAVEN_MIDDLEWARE_URL = `${config.baseUrl}/mid-raven/api/v1/`;

// Key for storing channels opened till now by employer
export const LOCAL_OPENED_CHANNEL_LIST = "__channels_opened__";
export const LOCAL_CHANNELS = "local_channels";
export const CALL_DETAIL = "CALL_DETAIL";

/**
 * Message type string values
 * @enum {string}
 */
export const MESSAGE_TYPES = {
	sent: "sent",
	recieved: "recieved",
	system: "system",
	invalid: "401",
};

export const REPORT_CANDIDATE_OPTIONS = {
	1: "not_relevant",
	2: "misbehavior",
	3: "fake_candidate",
	4: "others",
};

export const REPORT_OPTION_TEXT = {
	not_relevant: "Not Relevant",
	misbehavior: "Misbehavior",
	fake_candidate: "Fake Candidate",
	others: "Others",
};

export const ATS_FUNNELS = {
	rejected: "rejected",
	newCandidate: "new_candidate",
	offerJob: "offer_job",
};

export const FILE_SIZE_LIMITS = {
	imageMax: { value: 5242880, text: "5MB" }, // 5MB
	otherMax: { value: 10485760, text: "10MB" }, // 10MB
};

export const FILE_TYPE_STRINGS = {
	image: "image",
	video: "video",
	audio: "audio",
	pdf: "pdf",
	text: "text",
	other: "other",
};

export const LANGUAGE_PARSER = {
	kn: "Kannada",
	te: "Telugu",
	ta: "Tamil",
	bn: "Bengali",
	ang: "Hindi",
	en: "English",
};

export const RESPONSE_STATUS_RANGES = [[400, 404], [500, 599]];

export const CALL_STATUS = {
	callInitiated: "call initiated",
	callFailed: "call failed",
	callEnded: "call ended",
};

export const CALL_INTERVAL = {
	pollingInterval: 5000,
	showCallFailBannerInterval: 4000,
};

export const ONGOING_CALL_UPDATE_STATUS = {
	answered: "ANSWER",
	ongoing: "INITIATED",
};
