import { atsFunnelTypes } from "../constants/types";

const initialState = {};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case atsFunnelTypes.createFunnelHash: {
			const { funnelHash, jobId } = payload;
			return { ...state, [jobId]: funnelHash };
		}
		default:
			return state;
	}
};
