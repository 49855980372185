import styled from "styled-components";

export const Spacing = styled.div`
	margin-top: ${({ mt, my, isPixel }) => {
		const margin = mt || my || 0;
		return margin === "auto" ? margin : `${margin}${isPixel ? "px" : "rem"};`;
	}};
	margin-bottom: ${({ mb, my, isPixel }) => {
		const margin = mb || my || 0;
		return margin === "auto" ? margin : `${margin}${isPixel ? "px" : "rem"};`;
	}};
	margin-left: ${({ ml, mx, isPixel }) => {
		const margin = ml || mx || 0;
		return margin === "auto" ? margin : `${margin}${isPixel ? "px" : "rem"};`;
	}};
	margin-right: ${({ mr, mx, isPixel }) => {
		const margin = mr || mx || 0;
		return margin === "auto" ? margin : `${margin}${isPixel ? "px" : "rem"};`;
	}};

	padding-top: ${({ pt, py, isPixel }) => {
		const padding = pt || py || 0;
		return padding === "auto" ? padding : `${padding}${isPixel ? "px" : "rem"};`;
	}};
	padding-bottom: ${({ pb, py, isPixel }) => {
		const padding = pb || py || 0;
		return padding === "auto" ? padding : `${padding}${isPixel ? "px" : "rem"};`;
	}};
	padding-left: ${({ pl, px, isPixel }) => {
		const padding = pl || px || 0;
		return padding === "auto" ? padding : `${padding}${isPixel ? "px" : "rem"};`;
	}};
	padding-right: ${({ pr, px, isPixel }) => {
		const padding = pr || px || 0;
		return padding === "auto" ? padding : `${padding}${isPixel ? "px" : "rem"};`;
	}};
`;

export default {};
