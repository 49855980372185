import {
	CANDIDATE_CALL_INITIATED,
	CANDIDATE_CALL_FAILED,
	CANDIDATE_CALL_ENDED,
	RESET_CANDIDATE_CALL,
	CANDIDATE_CALL_ID_UPDATED,
} from "../constants/types";
import { updateCallDetailInLS } from "../utils/helpers";

const initialState = {
	onGoing: false,
	callStatus: null,
	activeCallJobId: null,
	activeCallCandidateId: null,
	callId: null,
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case CANDIDATE_CALL_INITIATED: {
			updateCallDetailInLS({
				...state,
				onGoing: true,
				callStatus: payload.callStatus,
				activeCallJobId: payload.jobId,
				activeCallCandidateId: payload.userId,
			});
			return {
				...state,
				onGoing: true,
				callStatus: payload.callStatus,
				activeCallJobId: payload.jobId,
				activeCallCandidateId: payload.userId,
			};
		}
		case CANDIDATE_CALL_ID_UPDATED: {
			updateCallDetailInLS({
				...state,
				callId: payload.callId,
			});
			return {
				...state,
				callId: payload.callId,
			};
		}
		case CANDIDATE_CALL_FAILED: {
			updateCallDetailInLS({
				...state,
				onGoing: false,
				callStatus: payload.callStatus,
				activeCallJobId: payload.jobId,
				activeCallCandidateId: payload.userId,
			});
			return {
				...state,
				onGoing: false,
				callStatus: payload.callStatus,
				activeCallJobId: payload.jobId,
				activeCallCandidateId: payload.userId,
			};
		}
		case CANDIDATE_CALL_ENDED: {
			updateCallDetailInLS({
				...state,
				onGoing: false,
				callStatus: payload.callStatus,
				activeCallJobId: payload.jobId,
				activeCallCandidateId: payload.userId,
			});
			return {
				...state,
				onGoing: false,
				callStatus: payload.callStatus,
				activeCallJobId: payload.jobId,
				activeCallCandidateId: payload.userId,
			};
		}
		case RESET_CANDIDATE_CALL: {
			updateCallDetailInLS({
				onGoing: false,
				callStatus: null,
				activeCallJobId: null,
				activeCallCandidateId: null,
			});
			return {
				onGoing: false,
				callStatus: null,
				activeCallJobId: null,
				activeCallCandidateId: null,
			};
		}
		default:
			return state;
	}
};
