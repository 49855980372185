import {
	TOTAL_COIN_BALANCE,
	TOGGLE_BUY_COINS_SIDE_DRAW,
	ACCOUNT_ID,
	BUY_COINS,
	UPDATE_APNA_COIN_PRODUCT,
	EXTRA_COINS_REQUIRED,
	RENEWAL_SUCCESS_MODAL,
	DEBIT_COINS_DETAILS,
	PAYMENT_STATUS_SUCCESS,
	IS_ACCOUNT_DETAILS_FETCHED,
	SHOW_ACTIVATION_PLANS_POPUP,
	PAID_JOBS_COUNT,
	SHOW_COMPLIMENTARY_COINS_MESSAGE,
	REFETCH_ACTIVATION_PLANS,
	UPDATE_SELECTED_ACTIVATION_PLAN,
	SHOW_APNA_PROMISE_MODAL,
	FREE_BANNER_JOBS,
	UPDATE_REFUND_DETAILS,
	INVALID_RETRY_PAYMENT_MODAL,
	IS_FIRST_PURCHASE,
	EXPIRED_FREE_JOBS_LIST,
	FETCH_TRANSACTIONS,
	IS_FREE_JOB_DETAILS_FETCHED,
	MIN_BALANCE_FOR_TRANSFER,
	JOB_POSTING_MODAL,
} from "../constants/MonetisationTypes";
import {
	RETRY_PAYMENT_MODAL_CODES,
	ACTIVATION_PLANS_TYPE,
	JOB_POSTING_MODAL_TYPE,
} from "../utils/constants";

const initialState = {
	totalCoinBalance: null,
	buyCoinsSideDraw: {
		show: false,
		coins: null,
		moneyAmount: null,
		packType: null,
		imageURL: "",
		packageId: "",
	},
	accountId: null,
	apnaCoinProduct: {},
	coinsRequiredToPostJob: 0,
	showRenewalSuccessModal: false,
	showActivationPlansPopup: false,
	debitCoinsApiDetails: {
		jobId: "",
		service: {},
		status: 1,
		jobData: {},
	},
	paymentStatusSuccess: false,
	isAccountDetailsFetched: false,
	paidJobsCount: 0,
	showComplimentaryCoinsMessage: false,
	isRefetchActivationPlans: false,
	selectedJobActivationPlan: {},
	showApnaPromiseModal: false,
	freeBannerJobs: 0,
	refundDetails: {
		coins: 0,
		jobId: "",
	},
	selectPlanWarningDetails: {
		show: false,
		jobData: {},
		modalType: ACTIVATION_PLANS_TYPE.SUFFICIENT_FOR_SOME_PLANS,
		redirectionSource: "",
	},
	invalidRetryPaymentModal: {
		show: false,
		coins: 0,
		modalType: RETRY_PAYMENT_MODAL_CODES[3],
	},
	isFirstPurchase: false,
	expiredFreeJobDetails: {
		expiredFreeJobsList: [],
		totalJobs: 0,
		yLimitBreachedJobs: 0,
	},
	fetchTransactions: false,
	isFreeJobDetailsFetched: false,
	jobPostingModal: {
		show: false,
		modalType: JOB_POSTING_MODAL_TYPE.LOADER,
	},
};

const monetisation = (state = initialState, action) => {
	switch (action.type) {
		case TOTAL_COIN_BALANCE:
			return {
				...state,
				totalCoinBalance: action.payLoad,
			};
		case TOGGLE_BUY_COINS_SIDE_DRAW:
			return {
				...state,
				buyCoinsSideDraw: {
					...state.buyCoinsSideDraw,
					show: action.show,
					coins: action.coins,
				},
			};
		case ACCOUNT_ID:
			return {
				...state,
				accountId: action.payLoad,
			};
		case BUY_COINS:
			return {
				...state,
				buyCoinsSideDraw: {
					...action.payLoad,
				},
			};
		case UPDATE_APNA_COIN_PRODUCT:
			return {
				...state,
				apnaCoinProduct: action.payload,
			};
		case EXTRA_COINS_REQUIRED:
			return {
				...state,
				coinsRequiredToPostJob: action.payLoad,
			};
		case RENEWAL_SUCCESS_MODAL:
			return {
				...state,
				showRenewalSuccessModal: action.payLoad,
			};
		case DEBIT_COINS_DETAILS:
			return {
				...state,
				debitCoinsApiDetails: {
					...action.payLoad,
				},
			};
		case PAYMENT_STATUS_SUCCESS:
			return {
				...state,
				paymentStatusSuccess: action.payLoad,
			};
		case IS_ACCOUNT_DETAILS_FETCHED:
			return {
				...state,
				isAccountDetailsFetched: action.payload,
			};
		case SHOW_ACTIVATION_PLANS_POPUP:
			return {
				...state,
				showActivationPlansPopup: action.payload,
			};
		case PAID_JOBS_COUNT:
			return {
				...state,
				paidJobsCount: action.payload,
			};
		case SHOW_COMPLIMENTARY_COINS_MESSAGE:
			return {
				...state,
				showComplimentaryCoinsMessage: action.payload,
			};
		case REFETCH_ACTIVATION_PLANS:
			return {
				...state,
				isRefetchActivationPlans: action.payload,
			};
		case UPDATE_SELECTED_ACTIVATION_PLAN:
			return {
				...state,
				selectedJobActivationPlan: action.payload,
			};

		case SHOW_APNA_PROMISE_MODAL: {
			return {
				...state,
				showApnaPromiseModal: action.payload,
			};
		}
		case FREE_BANNER_JOBS: {
			return {
				...state,
				freeBannerJobs: action.payload,
			};
		}
		case UPDATE_REFUND_DETAILS: {
			return {
				...state,
				refundDetails: action.payload,
			};
		}

		case INVALID_RETRY_PAYMENT_MODAL: {
			return {
				...state,
				invalidRetryPaymentModal: action.payload,
			};
		}
		case IS_FIRST_PURCHASE: {
			return {
				...state,
				isFirstPurchase: action.payload,
			};
		}

		case EXPIRED_FREE_JOBS_LIST: {
			return {
				...state,
				expiredFreeJobDetails: action.payload,
			};
		}

		case FETCH_TRANSACTIONS: {
			return {
				...state,
				fetchTransactions: action.payload,
			};
		}

		case IS_FREE_JOB_DETAILS_FETCHED: {
			return {
				...state,
				isFreeJobDetailsFetched: action.payload,
			};
		}
		case MIN_BALANCE_FOR_TRANSFER: {
			return {
				...state,
				minBalanceForTransfer: action.payload,
			};
		}
		case JOB_POSTING_MODAL: {
			return {
				...state,

				jobPostingModal: {
					...action.payload,
				},
			};
		}
		default:
			return state;
	}
};

export default monetisation;
