import { channelActionTypes } from "../constants/types";
import { getIndexChatInActiveChats } from "../utils/helpers";

const MAX_ALLOWED_CHANNELS = 4;

const initialState = [];

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case channelActionTypes.selectChannel: {
			const { userId, jobId } = payload;
			const activeChatLength = state.length;
			if (!getIndexChatInActiveChats(userId, jobId, state)) {
				if (activeChatLength >= MAX_ALLOWED_CHANNELS) {
					// use concat to get new array and remove first element
					const newState = state.concat([{ userId, jobId }]);
					newState.splice(0, 1);
					return newState;
				}
				return [...state, { userId, jobId }];
			}
			// TODO: open channel if already in active channels
			return state;
		}
		case channelActionTypes.clearActiveChannel: {
			const { userId } = payload;
			return state.filter(item => item.userId !== userId);
		}
		case channelActionTypes.clearAllSelectedChannels:
			return initialState;
		default:
			return state;
	}
};
