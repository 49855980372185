import React from "react";
import { TrueEccPreferenceOptionIcons } from "../../assets/svgs/TrueECCIcons";
import { trackClickEvents } from "../utils/trackers";

export const NEW_CANDIDATE = "New Candidate";

export const CONNECTION_TABS = {
	NEW_CANDIDATES: "not_connected",
	CONNECTED: "connected",
	REJECTED: "rejected",
	SUGGESTED: "suggested",
};

export const CONNECTION_STATUS = {
	[CONNECTION_TABS.NEW_CANDIDATES]: "Review Pending",
	[CONNECTION_TABS.CONNECTED]: "Reviewed",
	[CONNECTION_TABS.REJECTED]: "Not Relevant",
};

export const JOB_APPLICATIONS_PAGE_SIZE = 10;

export const NUMBER_SHARED_STATUS = {
	REQUESTED: "requested",
	NOT_SHARED: "not_shared",
	SHARED: "shared",
};

export const JOB_APPLICATION_STATES = [
	{ label: "Select a status", value: "new_candidate" },
	{ label: "Shortlisted", value: "shortlisted" },
	{ label: "Document Pending", value: "document_pending" },
	{ label: "Interview Fixed", value: "interview_fixed" },
	{ label: "Absent for Interview", value: "absent_for_interview" },
	{ label: "Hired", value: "hired" },
];

export const HIRING_STATUS = {
	NEW_CANDIDATE: "new_candidate",
	HIRED: "hired",
	SHORTLISTED: "shortlisted",
	INTERVIEW_FIXED: "interview_fixed",
	DOCUMENT_PENDING: "document_pending",
	ABSENT_FOR_INTERVIEW: "absent_for_interview",
};

export const TRUE_ECC_CALL_DETAIL = "TRUE_ECC_CALL_DETAIL";
export const TRUE_ECC_NUM_MASK_EDU = "TRUE_ECC_NUM_MASK_EDU";

export const TECC_PREF_POPUP_SHOWN_FOR = "TECC_PREF_POPUP_SHOWN_FOR";

export const CALL_STATUS = {
	callInitiated: "call initiated",
	callEnded: "call ended",
	callDisconnected: "call disconnected",
	callFailed: "call failed",
};

export const CALL_INTERVAL = {
	pollingInterval: 5000,
	showCallFailBannerInterval: 4000,
};

export const C2C_CALL_STATUS = {
	ongoing: "INITIATED",
	answered: "ANSWER",
	disconnected: ["FAILED_LEG1", "BUSY_LEG1", "NOANSWER_LEG1"],
	failed: ["FAILED_LEG2", "BUSY_LEG2", "NOANSWER_LEG2", "CANCEL"],
};

export const CONNECTION_REMARK = {
	CALL: "call",
	WHATSAPP: "wa",
	CSV: "csv",
	REJECT: "reject",
};

export const CONNECT_REMARK_FULL = {
	DESKTOP_CALL: "desktop_call",
	DESKTOP_WHATSAPP: "desktop_wa",
	MOBILE_WEB_WHATSAPP: "mobile_web_wa",
	MOBILE_WEB_CALL: "mobile_web_call",
	CANDIDATE_CALL: "candidate_call",
	DESKTOP_CSV: "desktop_csv",
	MOBILE_CSV: "mobile_csv",
	WHATSAPP_MINI: "wa_mini",
};

export const MODAL = {
	showCallConnectStepOne: "showCallConnectStepOne",
	showConnectedOnCall: "showConnectedOnCall",
	rejectedCandidate: "rejectedCandidate",
	showCallFailedStepOne: "showCallFailedStepOne",
	connectedOnWhatsapp: "connectedOnWhatsapp",
	showDidConnectOptions: "showDidConnectOptions",
};

export const TOAST_STATUS = {
	DISCONNECTED: { value: "disconnected", label: "disconnected" },
	FAILED: { value: "failed", label: "failed" },
	REJECTED: { value: "rejected", label: "Rejected" },
	CONNECTED: { value: "connected", label: "Connected" },
	CALL_SUCCESS: { value: "call_success", label: "call_success" },
	NOT_CONNECTED: { value: "not_connected", label: "Not connected" },
	HIRING_STATUS_SUCCESS: { value: "status_updated", label: "status_updated" },
	HIRING_STATUS_FAIL: { value: "status_fail", label: "status_fail" },
	NETWORK_ERROR: { value: "network_error", label: "Something went wrong." },
	DANGER: { value: "danger" },
	SUCCESS: { value: "success" },
};

export const AUTO_HIDE_TIME = 5;

export const JOB_TABS = {
	INFO: "INFO",
	STATS: "STATS",
};
export const PREFERENCE_OPTIONS = [
	{
		key: "call+whatsapp",
		headItems: [
			{ icon: <TrueEccPreferenceOptionIcons.Call />, text: "Calls" },
			{ icon: <TrueEccPreferenceOptionIcons.Whatsapp />, text: "Whatsapp" },
		],
		items: [
			{
				available: true,
				label: "Call",
				description: "Candidates can call you (9am- 6pm)",
			},
			{
				available: true,
				label: "Whatsapp",
				description: "Apna will send all applications in a single WhatsApp chat",
			},
			{
				available: true,
				label: "Website",
				description: "Manage applications on website",
			},
		],
		data: { call: true, whatsapp: true },
		briefInfo: "Stay updated on all platforms for faster hiring",
	},
	{
		key: "whatsapp",
		headItems: [
			{ icon: <TrueEccPreferenceOptionIcons.Whatsapp />, text: "Whatsapp" },
		],
		items: [
			{
				available: false,
				label: "Call",
				description: "Candidates cannot call you directly",
			},
			{
				available: true,
				label: "Whatsapp",
				description: "Apna will send all applications in a single WhatsApp chat",
			},
			{
				available: true,
				label: "Website",
				description: "Manage applications on website",
			},
		],
		data: { call: false, whatsapp: true },
		briefInfo: "Receive updates on WhatsApp from Apna without candidate calls",
	},
	{
		key: "website",
		headItems: [
			{ icon: <TrueEccPreferenceOptionIcons.Website />, text: "Website" },
			{ icon: <TrueEccPreferenceOptionIcons.CSV />, text: "Excel Download" },
		],
		items: [
			{
				available: false,
				label: "Call",
				description: "Candidates cannot call you directly",
			},
			{
				available: false,
				label: "Whatsapp",
				description:
					"Apna or candidates will not be able to send you WhatsApp directly",
			},
			{
				available: true,
				label: "Website",
				description:
					"Manage applications on the website along with Excel download option",
			},
		],
		data: { call: false, whatsapp: false },
		briefInfo:
			"Manage applications on the website without candidate calls & WhatsApp from Apna",
	},
];

export const TABS = [
	{
		label: "Review Pending",
		value: CONNECTION_TABS.NEW_CANDIDATES,
		trackerEvent: trackClickEvents.notConnectedTab,
	},
	{
		label: "Reviewed",
		value: CONNECTION_TABS.CONNECTED,
		trackerEvent: trackClickEvents.connectedTab,
	},
	{
		label: "Not Relevant",
		value: CONNECTION_TABS.REJECTED,
		trackerEvent: trackClickEvents.rejectedTab,
	},
	{
		label: "Suggested",
		value: CONNECTION_TABS.SUGGESTED,
		trackerEvent: trackClickEvents.suggestedTab,
	},
];

export const POPUP_DELAY = 3000;

export const DEFER_CONNECTION_UPDATE_DELAY = 1000;

export const STALE_JOBS = "STALE_JOBS";

export const NUMBER_MASKING_DISABLED = true;

export const RECENTLY_UPDATED_QUEUE_SIZE = 2;

export const CITIES_DICT = {
	SURAT: {
		id: 16,
		name: "surat",
	},
};

export const LANGUAGE_NAME = {
	speak: "Speaking",
	read: "Reading",
	write: "Writing",
};

export const LOW_RESPONSE_RATE = 15;
export const LOW_RESPONSE_SCORE = 2;
export const DIRECT_CALL_ACTION = "DIRECT_CALL_ACTION";

export const TAB_AWARENESS_STAGE = {
	REVIEW_PENDING: "Review Pending",
	REVIEWED: "Reviewed",
	NOT_RELEVANT: "Not Relevant",
	NOT_RELEVANT_CTA: "Not Relevant CTA",
};

export const TAB_AWARENESS_TEXT = {
	REVIEW_PENDING:
		"Candidates who have applied to your job but have not been reviewed yet.",
	REVIEWED: {
		FOR_CSV:
			"Please reach out to these candidates actively as they can not contact you from apna app.",
		FOR_OTHERS:
			"Once a candidate is reviewed, apna shares your contact detail with candidate for further communication. These candidate may reach out to you via WhatsApp or Call",
	},
	NOT_RELEVANT:
		"Candidates marked as 'Not Relevant' can be found here. These candidates can not reach out to you anymore from apna app. You can click on 'Restore' to connect with these candidates again.",
	NOT_RELEVANT_CTA:
		"Mark candidate as 'Not Relevant' if you don't find the candidate suitable for job. This will help apna find better suited candidates for your job posting.",
};

export const TAB_AWARENESS_HEADING = {
	REVIEW_PENDING: "Applications waiting to be reviewed.",
	REVIEWED: "Reviewed candidates can be found here.",
	NOT_RELEVANT: "Not relevant candidates for this job.",
	NOT_RELEVANT_CTA: "Don't like your candidate?",
};

export const TRUE_ECC_PREFERENCE = {
	CALL_AND_WHATSAPP: "Call + Whatsapp",
	WHATSAPP: "Whatsapp",
	CSV: "Website + CSV Download",
};
