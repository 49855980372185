import { combineReducers } from "redux";
import forms from "./forms";
import formData from "./formData";
import apiData from "./apiData";
import jobData from "./jobData";
import internalUser from "./internalUser";
import modal from "./modal";
import notification from "./notification";
import loader from "./loader";
import jobs from "../ecc/reducers/jobsReducer";
import leadsList from "../ecc/reducers/leadsListReducer";
import activeChannels from "../ecc/reducers/activeChannelReducer";
import suggestedLeads from "../ecc/reducers/suggestedLeadsReducer";
import selectedJob from "../ecc/reducers/selectedJobReducer";
import jobUserChannelMapping from "../ecc/reducers/jobUserChannelReducer";
import userProfiles from "../ecc/reducers/userProfilesReducer";
import channels from "../ecc/reducers/channelsReducer";
import channelPosts from "../ecc/reducers/channelPostsReducer";
import jobApplicantsStatus from "../ecc/reducers/jobApplicantsStatusReducer";
import searchbar from "./searchbar";
import globalModal from "../ecc/reducers/globalModalReducer";
import atsFunnelHash from "../ecc/reducers/atsFunnelReducer";
import retryStatus from "../ecc/reducers/retryStateReducer";
import eccPreference from "../ecc/reducers/eccPreferenceReducer";
import candidateCallStatus from "../ecc/reducers/candidateCallReducer";
import jobApplicationCount from "../true-ecc/reducers/applicationCountReducer";
import jobApplications from "../true-ecc/reducers/jobApplicationsReducer";
import trueEccCallStatus from "../true-ecc/reducers/callStatusReducer";
import connectionUpdateDetails from "../true-ecc/reducers/connectionUpdateReducer";
import teccDeferedApplications from "../true-ecc/reducers/deferedJobApplicationReducer";
import teccDeferedToasts from "../true-ecc/reducers/deferedStatusToastReducer";
import teccPreference from "../true-ecc/reducers/trueEccPreferenceReducer";
import staleJobStatus from "../true-ecc/reducers/staleJobStatusReducer";
import teccRecentlyUpdatedQueue from "../true-ecc/reducers/recentlyUpdatedQueueReducer";
import extendedProfile from "../true-ecc/reducers/extendedProfileReducer";
import monetisation from "./monetisation";
import tabAwarenessMode from "../true-ecc/reducers/tabAwarenessReducer";

const rootReducer = combineReducers({
	forms,
	formData,
	apiData,
	jobData,
	internalUser,
	modal,
	notification,
	loader,
	jobs,
	leadsList,
	activeChannels,
	suggestedLeads,
	selectedJob,
	userProfiles,
	channels,
	channelPosts,
	jobUserChannelMapping,
	jobApplicantsStatus,
	searchbar,
	globalModal,
	atsFunnelHash,
	retryStatus,
	eccPreference,
	candidateCallStatus,
	jobApplicationCount,
	jobApplications,
	trueEccCallStatus,
	connectionUpdateDetails,
	teccDeferedApplications,
	teccDeferedToasts,
	teccPreference,
	staleJobStatus,
	teccRecentlyUpdatedQueue,
	monetisation,
	extendedProfile,
	tabAwarenessMode,
});

export default rootReducer;
