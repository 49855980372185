import { FETCHED_SUGGESTED_LEADS } from "../constants/types";

const initialState = { suggestedCandidates: [], loadingSuggestions: false };

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case FETCHED_SUGGESTED_LEADS: {
			const { loadingSuggestions, suggestions } = payload;
			return {
				...state,
				suggestedCandidates: suggestions,
				loadingSuggestions,
			};
		}
		default:
			return state;
	}
};
