import {
	ADD_JOB_TITLE,
	ADD_ENG_AND_EDU,
	ADD_DEFAULT_ENG_AND_EDU,
	ADD_MUMBAI_AREAS,
	ADD_CITIES,
	ADD_LANGUAGE_CONFIG,
	ADD_ECC_PREFERENCE_CONFIG,
	FETCH_COMPANY_DOCS_SUCCESS,
	FETCH_COMPANY_DOCS_FAILED,
	ADD_CREDIT_EXPIRY_OPTIONS,
	SET_ALL_STATES,
	ADD_ADMIN_MEMBERS_CONFIG,
	ADD_ADMIN_MEMBERS_TABLE_DATA,
} from "../constants/ApiTypes";
import { CLEAR_FORM, SET_EMPLOYER_FEEDBACK_OPTIONS } from "../constants/FormTypes";
import { sortEducationOptions } from "../utils";

const initialState = {
	jobCategories: [],
	educationLevels: [],
	defaultEducationLevels: [],
	englishOptions: null,
	defaultEnglishOptions: null,
	mumbaiAreas: [],
	citiesList: {},
	languageConfig: {},
	eccPreferenceConfig: {},
	creditExpiryOptions: [],
	allStatesList: [],
	adminMembersConfig: {},
	adminMembersTableData: {},
};

const apiData = (state = initialState, action) => {
	switch (action.type) {
		case ADD_JOB_TITLE:
			return {
				...state,
				jobCategories: [...action.jobCategories],
			};
		case ADD_ENG_AND_EDU:
			return {
				...state,
				educationLevels: sortEducationOptions(action.options.educationOptions),
				englishOptions: action.options.englishOptions,
			};
		case ADD_DEFAULT_ENG_AND_EDU:
			return {
				...state,
				defaultEducationLevels: sortEducationOptions(
					action.options.educationOptions
				),
				defaultEnglishOptions: action.options.englishOptions,
			};
		case CLEAR_FORM:
			// clears education and english options along with formOne, formTwo and formThree once showForm is false.
			return {
				...state,
				educationLevels: [],
				englishOptions: null,
			};
		case ADD_MUMBAI_AREAS:
			return {
				...state,
				mumbaiAreas: [...action.mumbaiAreas],
			};
		case ADD_CITIES:
			return {
				...state,
				citiesList: { ...action.citiesList },
			};
		case ADD_LANGUAGE_CONFIG:
			return {
				...state,
				languageConfig: { ...action.languageConfig },
			};
		case ADD_ECC_PREFERENCE_CONFIG:
			return {
				...state,
				eccPreferenceConfig: action.eccPreferenceConfig,
			};
		case FETCH_COMPANY_DOCS_SUCCESS:
			return {
				...state,
				companyDocs: action.companyDocs,
			};
		case FETCH_COMPANY_DOCS_FAILED:
			return {
				...state,
				companyDocs: null,
			};
		case ADD_CREDIT_EXPIRY_OPTIONS:
			return {
				...state,
				creditExpiryOptions: action.creditExpiryOptions,
			};
		case SET_EMPLOYER_FEEDBACK_OPTIONS:
			return {
				...state,
				employerFeedbackModalOptions: action.data,
			};
		case SET_ALL_STATES:
			return {
				...state,
				allStatesList: action.data,
			};
		case ADD_ADMIN_MEMBERS_CONFIG:
			return {
				...state,
				adminMembersConfig: action.data,
			};
		case ADD_ADMIN_MEMBERS_TABLE_DATA:
			return {
				...state,
				adminMembersTableData: {
					...state?.adminMembersTableData,
					...action?.data,
				},
			};
		default:
			return state;
	}
};

export default apiData;
