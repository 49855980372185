import httpClient from "axios";
import CommonUtils from "../../utils/commonUtils";
import url from "./apiURLs";
import config from "../../config";
import { updateUrl } from "../../utils/apiClients";

const axios = httpClient.create({
	baseURL: config.baseUrl,
});

axios.interceptors.request.use(conf => {
	const token = CommonUtils.getItemFromLocalStorage("__token__");
	// eslint-disable-next-line no-param-reassign
	conf.headers.Authorization = `Token ${token}`;

	return conf;
});

export const fetchCandidates = async (jobId, tab, page = 1, pageSize = 10) => {
	const URL = `${url.JOB_APPLICATIONS_URL}${jobId}/applications/`;

	return axios.get(URL, {
		params: {
			tab,
			page_size: pageSize,
			page,
		},
	});
};

export const fetchCandidateWhatsApp = userId => {
	const URL = `${url.CANDIDATE_WHATSAPP_URL}`;
	const data = {
		user_id: parseInt(userId, 10),
	};
	return axios.get(URL, data);
};

export const updateConnectionStatus = (
	applicationId,
	connectionStatus,
	connectionRemark = "",
	connectionData = {}
) => {
	const URL = `${url.UPDATE_CONNECTION_STATUS_V2_URL}`;
	const data = {
		application_id: applicationId,
		connection_status: connectionStatus,
		connection_remark: connectionRemark || "",
		connection_metadata: connectionData,
	};
	return axios.put(URL, data);
};

export const rejectCandidate = (applicationId, connectionRemark) => {
	const URL = url.REJECT_CANDIDATE_V2_URL;
	const data = {
		application_id: applicationId,
		connection_remark: connectionRemark,
	};
	return axios.post(URL, data);
};

export const restoreCandidate = applicationId => {
	const URL = url.RESTORE_CANDIDATE_V2_URL;
	const data = {
		application_id: applicationId,
	};
	return axios.post(URL, data);
};

export const updateTrueEccPreference = (jobId, payload) =>
	axios.put(url.getUpdateEccPrefenceAPI(jobId), payload);

export const bulkRejectCandidates = jobId =>
	axios.post(updateUrl(url.BULK_REJECT_CANDIDATES, { job_id: jobId }), {});

export const getBulkRejectStatus = jobId =>
	axios.get(updateUrl(url.BULK_REJECT_CANDIDATES_STATUS, { job_id: jobId }), {});

export const downloadCsvFile = (jobId, source) => {
	const URL = url.csvDownloadFileAPI(jobId);
	return axios.get(URL, {
		params: {
			source,
		},
	});
};

export const fetchApplicantDetail = applicantId => {
	const URL = url.applicantDetailURL(applicantId);
	return axios.get(URL);
};

export const getJobStaleDetail = jobId => {
	const URL = url.eccStallingInfoURL(jobId);
	return axios.get(URL);
};

export const getShowTabAwarenessFlowStatus = () => {
	const URL = url.TAB_AWARENESS_STATUS;
	const response = axios.get(URL);
	return response;
};
export const postTabAwarenessFlowShown = () => {
	const URL = url.TAB_AWARENESS_STATUS;
	const response = axios.post(URL);
	return response;
};

export default axios;
