/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from "react";

const withSuspense = Component => props => (
	<Suspense fallback="">
		<Component {...props} />
	</Suspense>
);

export default withSuspense;
