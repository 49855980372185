/* eslint-disable import/no-named-default */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { default as MaterialUiModal } from "@material-ui/core/Modal";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	paper: {
		position: "absolute",
		width: "100%",
		maxWidth: ({ maxWidth }) => maxWidth,
		maxHeight: ({ maxHeight }) => maxHeight,
		overflowY: "auto",
		overflowX: "hidden",
		backgroundColor: theme.palette.background.paper,
		padding: ({ paddingSpacing }) => theme.spacing(...paddingSpacing),
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: ({ noBorderRadius, borderRadius }) =>
			noBorderRadius ? 0 : `${borderRadius}px`,
		boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.04)",
		border: "solid 1px #ededed",

		"&:focus": {
			outline: "none",
		},
	},
	drawerViewStyle: {
		"@media (max-width:786px)": {
			borderRadius: "8px 8px 0 0 !important",
			top: "auto",
			bottom: 0,
			transform: "translate(-50%, 0)",
		},
	},
}));

export default function Modal({
	children,
	open,
	handleClose = () => null,
	maxWidth,
	maxHeight,
	ariaLabel,
	ariaDescribe,
	disableAutoFocus,
	disableBackdropClick,
	disableEscapeKeyDown,
	disableEnforceFocus,
	paddingSpacing = [2, 4, 3],
	noBorderRadius,
	borderRadius = 8,
	drawerViewForMobile = false,
	smallDeviceProps = {},
	className = "",
	styles = {},
}) {
	const isDesktopDevice = useMediaQuery("(min-width:768px)");

	const mobileStyles = useStyles({
		maxWidth,
		maxHeight,
		paddingSpacing,
		noBorderRadius,
		borderRadius,
		drawerViewForMobile,
		...smallDeviceProps,
	});

	const desktopStyles = useStyles({
		maxWidth,
		maxHeight,
		paddingSpacing,
		noBorderRadius,
		borderRadius,
		drawerViewForMobile,
	});

	const { paper, drawerViewStyle } = isDesktopDevice ? desktopStyles : mobileStyles;

	// eslint-disable-next-line consistent-return
	const handleCloseWrapper = (event, reason) => {
		if (disableBackdropClick && reason === "backdropClick") {
			return false;
		}

		if (disableEscapeKeyDown && reason === "escapeKeyDown") {
			return false;
		}

		if (typeof handleClose === "function") {
			handleClose();
		}
	};

	return (
		<>
			<MaterialUiModal
				aria-labelledby={ariaLabel}
				aria-describedby={ariaDescribe}
				open={open}
				onClose={handleCloseWrapper}
				disableAutoFocus={disableAutoFocus}
				disableEnforceFocus={disableEnforceFocus}
				style={styles}
			>
				<div
					className={`${paper} ${
						drawerViewForMobile ? drawerViewStyle : ""
					} ${className || ""}`}
				>
					{children}
				</div>
			</MaterialUiModal>
		</>
	);
}

Modal.propTypes = {
	open: PropTypes.bool.isRequired,
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	ariaLabel: PropTypes.string,
	ariaDescribe: PropTypes.string,
	handleClose: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
	disableAutoFocus: PropTypes.bool,
	disableBackdropClick: PropTypes.bool,
	disableEscapeKeyDown: PropTypes.bool,
	disableEnforceFocus: PropTypes.bool,
	drawerViewForMobile: PropTypes.bool,
};

Modal.defaultProps = {
	maxWidth: 400,
	maxHeight: "100vh",
	ariaLabel: "modal-title",
	ariaDescribe: "modal-description",
	disableAutoFocus: false,
	disableBackdropClick: false,
	disableEscapeKeyDown: false,
	disableEnforceFocus: true,
	drawerViewForMobile: false,
};
