import React from "react";
import styled, { css } from "styled-components";

const getFontSize = ({ size = "md" }) => {
	switch (size) {
		case "xxs":
			return "10px";
		case "xs":
			return "12px";
		case "sm":
			return "14px";
		case "md":
			return "16px";
		case "lg":
			return "18px";
		case "xl":
			return "20px";
		case "xxl":
			return "24px";
		default:
			return Number.isNaN(size) ? size : `${size}px`;
	}
};

const getLineHeight = ({ size = "md", lineHeight }) => {
	switch (size) {
		case "xxs":
			return "14px";
		case "xs":
			return "16px";
		case "sm":
			return "20px";
		case "md":
			return "24px";
		case "lg":
			return "26px";
		case "xl":
			return "30px";
		case "xxl":
			return "36px";
		default:
			return lineHeight;
	}
};

const getColor = ({ color = "dark" }) => {
	switch (color) {
		case "dark":
			return "#172b4d";
		case "light":
			return "#ffffff";
		case "muted":
			return "#5e6c84";
		case "grey":
			return "#6B7C8F";
		case "red":
			return "#D93F4C";
		case "green":
			return "#2BB792";
		default:
			return color;
	}
};

const getFontWeight = ({ weight = "normal" }) => {
	switch (weight) {
		case "normal":
			return 400;
		case "bold":
			return 500;
		case "bolder":
			return 600;
		case "boldest":
			return 700;
		default:
			return weight;
	}
};

export const StyledText = styled.div`
	font-size: ${getFontSize};
	line-height: ${getLineHeight};
	font-weight: ${getFontWeight};
	color: ${getColor};

	${({ withSpacing }) =>
		withSpacing &&
		css`
			letter-spacing: 0.0025em;
		`}

	${({ center }) =>
		center &&
		css`
			text-align: center;
		`}
`;

/**
 *
 * @param {{
 *   size: "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | number
 *   color: "dark" | "light" | "muted" | "grey" | "red" | string
 *   weight: "normal" | "bold" | "bolder" | "boldest" | number
 *   withSpacing: boolean
 *   center: boolean
 *   span: boolean
 * }} props
 */
export default function Text({ inline = false, ...props }) {
	return <StyledText {...props} {...(inline && { as: "span" })} />;
	// TODO: check why span prop has been added @ rajat gupta / saurav prakash
}
