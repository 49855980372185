/* eslint-disable consistent-return */

export const checkCompanyFormError = formStates => {
	if (formStates.companyUrl && (formStates.fileObject || formStates.fileName)) {
		return {
			result: !(
				formStates.document_type &&
				formStates.document_type.trim(" ") &&
				formStates.document_type !== "None" &&
				formStates.document_number &&
				formStates.document_type.trim(" ") &&
				(formStates.fileObject || formStates.fileName) &&
				formStates.companyUrl
			),
			errors: {
				document_type: !(
					formStates.document_type &&
					formStates.document_type.trim(" ") &&
					formStates.document_type !== "None"
				),
				document_number: !(
					formStates.document_number && formStates.document_type.trim(" ")
				),
				file: !(formStates.fileObject || formStates.fileName),
			},
		};
	}
	if (formStates.companyUrl) {
		return {
			result: !formStates.companyUrl.trim(" "),
			errors: {
				document_type: false,
				document_number: false,
				file: false,
			},
		};
	}
	if (!formStates.companyUrl) {
		return {
			result: !(
				formStates.document_type &&
				formStates.document_type.trim(" ") &&
				formStates.document_type !== "None" &&
				formStates.document_number &&
				formStates.document_type.trim(" ") &&
				(formStates.fileObject || formStates.fileName)
			),
			errors: {
				document_type: !(
					formStates.document_type &&
					formStates.document_type.trim(" ") &&
					formStates.document_type !== "None"
				),
				document_number: !(
					formStates.document_number && formStates.document_type.trim(" ")
				),
				file: !(formStates.fileObject || formStates.fileName),
			},
		};
	}
};

export default null;
