/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import JobModalCommonContainer from "./components/JobModalCommonContainer";
import Button from "../../components/Buttons/Button";
import { POC_DOC_TYPE_OPTIONS } from "../../utils/constants";
import { ChevronDownIcon } from "../../assets/svg";
import DocUploader from "../../components/FileUploader/DocUploader";
import { Spacing } from "../../styles/SpacingStyles";
import { getJobDetail, uploadPocDoc } from "../../utils/apiClients";
import {
	selectedJob,
	sendNotification,
	updateFilteredJobs,
	updateUserDetails,
} from "../../actions";
import { updateJob } from "../../ecc/actions/jobsActions";
import Mixpanel from "../../utils/Mixpanel";

const FILE_SIZE_ERROR = "File size should not be greater than 5MB.";

const PocDocUploadModal = ({
	handleClose,
	selectedJobDetails,
	updateSelectedJob,
	userDetails,
	updateProfileName,
	notify,
}) => {
	const {
		id: jobId,
		created_by: {
			id: creatorId,
			phone_number: creatorNumber,
			lead_type: leadType,
		} = {},
		// organization: { id: orgId, name: orgName } = {},
		creator_organization: creatorOrganization,
	} = selectedJobDetails || {};

	const { full_name: userName, id: userId, phone_number: userNumber } =
		userDetails || {};

	const [state, setState] = useState({
		isNameEditable: !userName,
		name: userName,
		docType: "",
		doc1: null,
		doc2: null,
		doc1Error: false,
		doc2Error: false,
		submitInProgress: false,
	});

	useEffect(() => {
		setState(s => ({ ...s, isNameEditable: !userName, name: userName }));
	}, [userName]);

	const onDoc1Upload = file => {
		setState(s => ({ ...s, doc1: file, doc1Error: false }));
	};

	const onDoc2Upload = file => {
		setState(s => ({ ...s, doc2: file, doc2Error: false }));
	};

	const onSubmit = async () => {
		setState(s => ({ ...s, submitInProgress: true }));

		const payload = new FormData();
		payload.append("organization_id", creatorOrganization?.id);
		if (state.isNameEditable) {
			payload.append("full_name", state.name);
		}
		payload.append("type", state.docType);
		// payload.append("poc_detail", "");		// Might need later.
		payload.append("poc_file", state.doc1);
		if (state.doc2) {
			payload.append("poc_file2", state.doc2);
		}

		try {
			const res = await uploadPocDoc(userId, creatorOrganization?.id, payload);
			if (res.status !== 201) {
				throw res;
			}
			notify("success", "POC doc uploaded successfully.");
			handleClose();
			const { status, data: updateJobData } = await getJobDetail(jobId);
			if (status === 200 && updateJobData) {
				updateSelectedJob(updateJobData);
			}
			if (state.isNameEditable) {
				updateProfileName(state.name);
			}
			Mixpanel.track(
				"Upload POC Submitted",
				{
					"Job ID": jobId,
					"Company Name": creatorOrganization?.name,
					"Employer ID": creatorId,
					"Uploaded by ID": userId,
					"Employer Number": creatorNumber,
					"Org Id": creatorOrganization?.id,
					"Lead Type": leadType,
					"Doc Type": state.docType,
					"Number of Docs": state.doc2 ? 2 : 1,
					Source: "popup",
				},
				selectedJobDetails
			);
		} catch (e) {
			notify("error", "Something went wrong.");
		}

		setState(s => ({ ...s, submitInProgress: false }));
	};

	const isCtaDisabled =
		!state.name || !state.docType || !state.doc1 || state.submitInProgress;

	return (
		<JobModalCommonContainer
			onCloseClick={handleClose}
			headerText="Employer KYC"
			iconColor="#2BB793"
			iconSize={80}
		>
			<SubHeading>Please verify your employment/ association details</SubHeading>
			<MainContainer>
				<Label>Name</Label>
				<StyledTextField
					placeholder="Please enter your name..."
					autoComplete="none"
					variant="outlined"
					color="secondary"
					fullWidth
					value={state.name}
					disabled={!state.isNameEditable}
					onChange={event => {
						const { value } = event.target;
						setState(s => ({ ...s, name: value }));
					}}
				/>
				<Label>Phone number</Label>
				<StyledTextField
					autoComplete="none"
					variant="outlined"
					color="secondary"
					fullWidth
					disabled
					value={userNumber}
				/>
				<Label>Select Document type</Label>
				<Autocomplete
					options={POC_DOC_TYPE_OPTIONS}
					getOptionLabel={option => option.label || ""}
					blurOnSelect
					value={POC_DOC_TYPE_OPTIONS.find(
						({ value }) => value === state.docType
					)}
					onChange={(e, selectedItem) => {
						const { value: docType = "" } = selectedItem || {};
						if (docType) setState(s => ({ ...s, docType }));
					}}
					closeIcon={null}
					popupIcon={<ChevronDownIcon className="MuiSvgIcon-root" />}
					renderInput={({ inputProps, ...params }) => (
						<StyledTextField
							{...params}
							inputProps={{
								...inputProps,
								readOnly: true,
							}}
							placeholder="Choose a document type here"
							variant="outlined"
							fullWidth
							color="secondary"
						/>
					)}
				/>
				<Label>Upload Document here</Label>
				<DocUploader
					fileName={state.doc1?.name}
					uploadCtaText="Upload"
					infoText={
						state.doc1?.name
							? "front side Uploaded Sucessfully"
							: "Upload Company Document for Verification PDF, PNG, JPEG supported"
					}
					supportedDocTypes={["pdf", "png", "jpeg"]}
					onChange={onDoc1Upload}
					error={state.doc1Error}
					maxFileSizeInMB={5}
					onFileSizeError={() => {
						setState(s => ({
							...s,
							doc1: null,
							doc1Error: FILE_SIZE_ERROR,
						}));
					}}
					onDelete={() => {
						setState(s => ({ ...s, doc1: null, doc1Error: false }));
					}}
				/>
				{!!state.doc1?.name && (
					<Spacing mt={0.75}>
						<DocUploader
							fileName={state.doc2?.name}
							uploadCtaText="Upload back"
							infoText={
								state.doc2?.name
									? "back side Uploaded Sucessfully"
									: "PDF, PNG, JPEG supported"
							}
							supportedDocTypes={["pdf", "png", "jpeg"]}
							onChange={onDoc2Upload}
							error={state.doc2Error}
							maxFileSizeInMB={5}
							onFileSizeError={() => {
								setState(s => ({
									...s,
									doc2: null,
									doc2Error: FILE_SIZE_ERROR,
								}));
							}}
							onDelete={() => {
								setState(s => ({ ...s, doc2: null, doc2Error: false }));
							}}
						/>
					</Spacing>
				)}
			</MainContainer>
			<CompleteVerificationCta onClick={onSubmit} disabled={isCtaDisabled}>
				Complete verification
			</CompleteVerificationCta>
		</JobModalCommonContainer>
	);
};

const SubHeading = styled.div`
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	text-align: center;
	color: #a5a5a5;
	margin-top: 2px;
`;

const MainContainer = styled.div`
	padding: 0 16px 40px;
`;

const CompleteVerificationCta = styled(Button)`
	padding: 8px 32px;
	height: auto;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 2px;
`;

const Label = styled.div`
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: #444444;
	margin-bottom: 8px;
	margin-top: 24px;
`;

const StyledTextField = styled(TextField)`
	.MuiInputBase-input {
		font-size: 16px;
		line-height: 17px;
	}
`;

const mapStateToProps = ({ jobData, forms: { userDetails = {}, workspace } }) => ({
	selectedJobDetails: jobData?.selectedJobDetails,
	userDetails,
	workspace,
});

const mapDispatchToProps = dispatch => ({
	notify: (type, message) => {
		dispatch(
			sendNotification({
				message_type: type,
				message,
			})
		);
	},
	updateSelectedJob: data => {
		dispatch(selectedJob(data));
		dispatch(updateJob(data));
		dispatch(updateFilteredJobs(data));
	},
	updateProfileName: (name = "") => {
		dispatch(
			updateUserDetails({
				full_name: name,
			})
		);
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PocDocUploadModal);
