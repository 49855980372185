import { SHOW_SEARCHBAR, HIDE_SEARCHBAR } from "../constants/SearchbarTypes";

const initialState = {
	isOpen: false,
	data: "",
};

const searchbar = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_SEARCHBAR:
			return {
				...state,
				isOpen: true,
				data: action.data,
			};
		case HIDE_SEARCHBAR:
			return {
				...state,
				isOpen: false,
			};
		default:
			return state;
	}
};

export default searchbar;
