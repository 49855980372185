import * as Sentry from "@sentry/browser";
import CommonUtils from "./commonUtils";
import store from "../store";
import { sendNotification } from "../actions";
import ErrorStatuses from "./ErrorStatuses.json";
import { error4xxRegex } from "./constants";
import { handleTrueEccNetworkError } from "../true-ecc/actions/update-connection-status-action";

export const ErrorHandlers = {
	401: error => {
		if (!error?.request?.responseURL.includes("/logout")) {
			CommonUtils.logout();
		}
	},
};

export const LogErrorsOnSentry = err => {
	if (error4xxRegex.test(err?.status) && process.env.NODE_ENV === "production") {
		let message = `${err?.status} - ${ErrorStatuses[(err?.status)]}`;

		if (err?.status === 401) {
			message = "Authorization Failed";
		}

		const params = {
			data: err?.data,
			url: err?.config?.url,
		};

		const sentryLogger = {
			message: {
				message,
				params,
			},
		};
		Sentry.captureEvent(sentryLogger);
	}
};

export function handleError(err) {
	if (ErrorStatuses[(err?.status)]) {
		store.dispatch(
			sendNotification({
				message_type: "error",
				message: ErrorStatuses[(err?.status)],
			})
		);
	}

	LogErrorsOnSentry(err);

	const errorHandler = ErrorHandlers[(err?.status)];
	if (errorHandler) {
		errorHandler(err);
	}
}

export function handleTrueEccError(err) {
	const errorCodesForTrueEcc = [400, 403, 404, 500, 502, 503];
	if (errorCodesForTrueEcc.includes(err?.status)) {
		store.dispatch(handleTrueEccNetworkError());
	}

	LogErrorsOnSentry(err);
}

export default {};
