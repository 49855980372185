/* eslint-disable import/prefer-default-export */

export const ADD_JOB_TITLE = "ADD_JOB_TITLE";
export const ADD_ENG_AND_EDU = "ADD_ENG_AND_EDU";
export const ADD_DEFAULT_ENG_AND_EDU = "ADD_DEFAULT_ENG_AND_EDU";
export const ADD_MUMBAI_AREAS = "ADD_MUMBAI_AREAS";
export const ADD_CITIES = "ADD_CITIES";
export const ADD_LANGUAGE_CONFIG = "ADD_LANGUAGE_CONFIG";
export const ADD_ECC_PREFERENCE_CONFIG = "ADD_ECC_PREFERENCE_CONFIG";
export const FETCH_COMPANY_DOCS_SUCCESS = "FETCH_COMPANY_DOCS_SUCCESS";
export const FETCH_COMPANY_DOCS_FAILED = "FETCH_COMPANY_DOCS_FAILED";
export const ADD_CREDIT_EXPIRY_OPTIONS = "ADD_CREDIT_EXPIRY_OPTIONS";
export const SET_ALL_STATES = "SET_ALL_STATES";
export const ADD_ADMIN_MEMBERS_CONFIG = "ADD_ADMIN_MEMBERS_CONFIG";
export const ADD_ADMIN_MEMBERS_TABLE_DATA = "ADD_ADMIN_MEMBERS_TABLE_DATA";
