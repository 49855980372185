import React from "react";
import FieldJobIcon from "../../assets/JobPostFormIcons/FieldJobIcon";
import SingleJobLocationIcon from "../../assets/JobPostFormIcons/SingleJobLocationIcon";
import WorkFromHomeIcon from "../../assets/JobPostFormIcons/WorkFromHomeIcon";
import {
	APPLICATION_LOCATION_VALUES_FOR_NON_WFH,
	APPLICATION_LOCATION_VALUES_FOR_WFH,
} from "../../utils/constants";

export const BASIC_DETAILS_OBJECT = {
	companyName: "companyName",
	jobTitle: "jobTitle",
	jobCategory: "jobCategory",
	minimumEducation: "minimumEducation",
	jobType: "jobType",
	shiftType: "shiftType",
	englishLevel: "englishLevel",
	minSalary: "minSalary",
	maxSalary: "maxSalary",
	incentive: "incentive",
	numberOfOpenings: "numberOfOpenings",
	isWfh: "isWfh",
	includesIncetivesOrNot: "includesIncetivesOrNot",
	jobCity: "job_city",
	toggleChangeCompanyModal: "toggleChangeCompanyModal",
	depositStatus: "depositStatus",
	amount: "amount",
	depositReason: "depositReason",
	purpose: "purpose",
	whenToPay: "whenToPay",
	assetsOrInventory: "assetsOrInventory",
	jobLocationType: "jobLocationType",
	interviewCity: "interviewCity",
	interviewArea: "interviewArea",
	interviewCompleteAddress: "interviewCompleteAddress",
	interviewGoogleAddress: "interviewGoogleAddress",
	jobLocationCity: "jobLocationCity",
	jobLocationArea: "jobLocationArea",
	jobLocationCompleteAddress: "jobLocationCompleteAddress",
	jobLocationGoogleAddress: "jobLocationGoogleAddress",
	singleJobApplicantLocation: "singleJobApplicantLocation",
	interviewMethod: "interviewMethod",
	wfhJobApplicationLocation: "wfhJobApplicationLocation",
	fieldJobLocationType: "fieldJobLocationType",
	salaryType: "salaryType",
	remoteApplicantEligible: "remoteApplicantEligible",
};
export const BASIC_ERROR_MESSAGES = {
	jobTitleRequired: "Please select a job role",
	jobCategoryRequired: "Please select a job category",
	jobTypeRequired: "Please select a job type",
	salaryTypeRequired: "Please select a category",
	incentiveRequired: "Please enter the average incentive",
	minSalaryRequired: "Please enter the minimum salary",
	minSalaryError: "Min salary must be less than Max salary",
	maxSalaryRequired: "Please enter the maximum salary",
	maxSalaryGreater: "Max salary should be at least ₹ 1000",
	maxSalaryLess: "Max salary cannot exceed ₹ 99999",
	maxSalaryError: "Max salary must be greater than Min salary",
	numberOpeningsRequired: "Please enter a value",
	lessOpenings: "Please enter a value greater than 0",
	maxOpenings:
		"We allow only 250 openings per job, for more than 250 openings please post more than 1 job",
	englishLevelRequired: "Please select an education level",

	jobCityRequired: "Please enter a value",
	companyNameInvalid: "Please enter a valid company name",
	amountRequired: "Please enter a value",
	amountMin: "Please enter a value greater than 0",
	purposeRequired: "Please enter purpose",
	depositReasonRequired: "Please select a reason",
	depositStatusRequired: "Please choose one option",
	whenToPayRequired: "Please choose one option",
	companyNameRequired: "Please enter company name",
	jobLocationTypeRequired: "Please select a job location",
	interviewCity: "Please select city",
	interviewArea: "Please select area",
	interviewCompleteAddress: "Please enter a value",
	jobLocationCity: "Please select city",
	jobLocationArea: "Please select area",
	completeAddressIsRequired: "Please provide your complete google address",
	singleJobApplicantLocation: "Please select one option",
	interviewMethod: "Please select one option",
	wfhJobApplicationLocation: "Please select one option",
	fieldJobLocationType: "Please select one option",
	incentiveSalaryGreater: "Incentive should be at least ₹ 1000",
	incentiveSalaryLess: "Incentive cannot exceed ₹ 99999",
	totalEarningPotentialCannotExceed: "Total salary cannot exceed ₹ 99,999",
};
export const JOB_DETAILS_ERROR = {
	amountRequired: "Please enter a value",
	jobDescRequired: "Please enter a value",
	jobDescInvalid: "Please enter a valid job description",
	purposeRequired: "Please enter purpose",
	amountMin: "Please enter a value greater than 0",
	depositStatusRequired: "Please choose one option",
	depositReasonRequired: "Please select a reason",
	englishLevelRequired: "Please select an option",
	minAgeRequired: "Please enter a value",
	maxAgeRequired: "Please enter a value",
	minAgeError: "Min. age must be less than Max. age",
	maxAgeError: "Max. age must be greater than Min. age",
	minAgeLowerBoundError: "Min. age should be more than 18",
	maxAgeUpperBoundError: "Max. age should be less than 60",
	minimumEducationRequired: "Please select minimum education",
	minYearsRequired: "Please enter a value",
	minYearsMax: "Please enter a value less than 20",
	minYearsError: "Min years must be less than max years",
	maxYearsError: "Max years must be greater than min years",
	minYearLess: "Please enter a value greater than 0",
	maxYearsRequired: "Please enter a value",
	maxYearsBucketRequired: "Please select one option",
	maxYearsRequiredNonZero: "Max experience required cannot be 0",
	maxYearsMin: "Please enter a value less than 30",
	genderRequired: "Please choose one option",
	experienceType: "Please select one option",
	minExpBucketRequired: "Please select minimum experience",
};
export const INTERVIEW_INFO_ERROR = {
	companyNameRequired: "Please enter company name",
	companyNameInvalid: "Please enter a valid company name",
	contactNameRequired: "Please enter recruiter's name",
	contactNameInvalid: "only alphabets are allowed",
	contactNumberRequired: "Please enter a value",
	contactNumberInvalid: "Please enter a valid contact number",
	contactEmailRequired: "Please enter a value",
	contactEmailInavlid: "Please enter a valid email",
	landmarkRequired: "Please enter a value",
	buildingNameRequired: "Please enter a building name",
	streetNameRequired: "Please enter a value",
	areaRequired: "Please enter a value",
	cityRequired: "Please enter a value",
	jobLandmarkRequired: "Please enter a value",
	jobBuildingNameRequired: "Please enter a value",
	jobStreetNameRequired: "Please enter a value",
	jobAreaRequired: "Please enter a value",
	jobCityRequired: "Please enter a value",
	numberNameRequired: "Please enter a value",
	applicantLocationRequired: "Please enter a value",
	eccPreferenceRequired: "Please select a preference",
	selectedEccQuestionOptionsRequired: "Please select atleast 1 option",
	customEccQuestionRequired: "Please enter a value",
	applyForOldJobsRequired: "Please select an option",
	trueEccPreferenceRequired: "Please select an option",
	remoteApplicantEligibleRequired: "Please choose one option",
	companyCity: "Please select city",
	companyArea: "Please select area",
	companyLocationCompleteAddress: "Please provide your complete google address",
	interviewCity: "Please select city",
	interviewArea: "Please select area",
	interviewMethod: "Please select one option",
	interviewCompleteAddress: "Please provide your complete google address",
};
export const JOB_DETAILS_OBJECT = {
	englishLevel: "englishLevel",
	engDesc: "engDesc",
	depositStatus: "depositStatus",
	amount: "amount",
	depositReason: "depositReason",
	purpose: "purpose",
	ageStatus: "ageStatus",
	minAge: "minAge",
	maxAge: "maxAge",
	minimumEducation: "minimumEducation",
	gender: "gender",
	experienceType: "experienceType",
	minYears: "minYears",
	maxYears: "maxYears",
	experienceBucket: "experienceBucket",
};
export const INTERVIEW_INFO_DATA = {
	companyName: "companyName",
	contactName: "contactName",
	contactNumber: "contactNumber",
	contactEmail: "contactEmail",
	buildingName: "buildingName",
	city: "city",
	area: "area",
	jobAddressCheckbox: "jobAddressCheckbox",
	jobBuildingName: "jobBuildingName",
	jobCity: "jobCity",
	jobArea: "jobArea",
	applicantLocation: "applicantLocation",
	googleJobBuilding: "googleJobBuilding",
	googleBuilding: "googleBuilding",
	eccPreferencePreview: "eccPreferencePreview",
	eccPreference: "eccPreference",
	applyForOldJobs: "applyForOldJobs",
	selectedEccQuestionOptions: "selectedEccQuestionOptions",
	customEccQuestion: "customEccQuestion",
	trueEccPreference: "trueEccPreference",
	trueEccApplyToAll: "trueEccApplyToAll",
	creatorRecruiter: "creatorRecruiter",

	companyCity: "companyCity",
	companyArea: "companyArea",
	companyLocationCompleteAddress: "companyLocationCompleteAddress",
	companyLocationGoogleAddress: "companyLocationGoogleAddress",
	isCompanyAddressSame: "isCompanyAddressSame",
	interviewCity: "interviewCity",
	interviewArea: "interviewArea",
	interviewCompleteAddress: "interviewCompleteAddress",
	interviewGoogleAddress: "interviewGoogleAddress",
	interviewMethod: "interviewMethod",
};

export const INTERVIEW_INFO_MESSAGES = {
	strictWarningText: "This may reduce the number of applications on your job",
};

export const JOB_POST_FIELDS = {
	jobTitle: "job_title",
	jobCategory: "job_category",
	minimumEducation: "minimum_education",
	jobType: "jobType",
	shiftType: "shiftType",
	experienceType: "experience_type",
	gender: "gender",
	minSalary: "min_salary",
	maxSalary: "max_salary",
	incentive: "incentive",
	minYears: "min_years",
	maxYears: "max_years",
	numberOfOpenings: "number_of_openings",
	isWfh: "is_wfh",
	engDesc: "eng_desc",
	jobExpiryDuration: "jobExpiryDuration",
	depositStatus: "depositStatus",
	purpose: "purpose",
	amount: "amount",
	englishLevel: "english_level",
	depositReason: "reason",
	modeOfInterview: "mode_of_interview",
	companyName: "company_name",
	contactName: "contact_name",
	contactNumber: "contact_number",
	contactEmail: "contact_email",
	landmark: "landmark",
	buildingName: "building_name",
	area: "area",
	city: "city",
	jobAddressCheckbox: "jobAddressCheckbox",
	jobBuildingName: "job_building_name",
	jobArea: "job_area",
	jobCity: "job_city",
	applicantLocation: "applicant_location",
	includesIncetivesOrNot: "is_incentives_involved",
	creatorOrg: "creator_org",
	remoteApplicantEligible: "remote_applicant_eligible",
};

export const FULL_TIME = "FULL_TIME";
export const PART_TIME = "PART_TIME";
export const defaultCategoryConfigId = [11];

export const JOB_TYPE_OPTIONS = [
	{
		name: "Full time",
		id: FULL_TIME,
	},
	{
		name: "Part time",
		id: PART_TIME,
	},
];

export const DELEVIRY_JOB_TYPE_OPTIONS = [
	...JOB_TYPE_OPTIONS,
	{
		name: "Both (Full-time and Part-time)",
		id: "FULL_PART_TIME",
	},
];

export const JOB_TYPE_OPTIONS_CONFIG = {
	[FULL_TIME]: "Full time",
	[PART_TIME]: "Part time",
	FULL_PART_TIME: "Both (Full-time and Part-time)",
	"Full time": FULL_TIME,
	"Part time": PART_TIME,
	"Both (Full-time and Part-time)": "FULL_PART_TIME",
};

export const JOB_LOCATION_TYPE = {
	SINGLE_JOB_LOCATION: "SINGLE_JOB_LOCATION",
	WORK_FROM_HOME: "WORK_FROM_HOME",
	FIELD_JOB: "FIELD_JOB",
};

export const BE_VALUES_FOR_LOCATION_TYPES = {
	SINGLE_JOB_LOCATION: "single_location",
	WORK_FROM_HOME: "wfh",
	FIELD_JOB: "field_location",
	single_location: "SINGLE_JOB_LOCATION",
	wfh: "WORK_FROM_HOME",
	field_location: "FIELD_JOB",
};

export const UR_JOB_DETAILS_TEXT_MAPPING = {
	single_location: "Single Job Location",
	wfh: "Work from Home",
	field_location: "Field Job",
};

export const JOB_POST_MIXPANEL_PROPERTIES_VALUES = {
	SINGLE_JOB_LOCATION: "Single Job location",
	WORK_FROM_HOME: "Work from home",
	FIELD_JOB: "Field City job",
};

export const JOB_LOCATION_TYPE_HEADER_DESCRIPTION = {
	SINGLE_JOB_LOCATION: {
		heading: "Work from office",
		description: "Candidates would be required to work from a fixed location",
		icon: () => <SingleJobLocationIcon />,
		value: "SINGLE_JOB_LOCATION",
		"data-job-value": "SINGLE_JOB_LOCATION",
	},
	WORK_FROM_HOME: {
		heading: "Work from home",
		description:
			"Candidates would be required to work from home (their own premises)",
		icon: () => <WorkFromHomeIcon />,
		value: "WORK_FROM_HOME",
		"data-job-value": "WORK_FROM_HOME",
	},
	FIELD_JOB: {
		heading: "Field jobs",
		description:
			"Candidates would be required to work in the field, with minimal time spent in the office",
		icon: () => <FieldJobIcon />,
		value: "FIELD_JOB",
		"data-job-value": "FIELD_JOB",
	},
};

export const INTERVIEW_METHODS = {
	IN_PERSON_INTERVIEW: "in-person",
	TELEPHONIC_ONLINE_INTERVIEW: "call",
	"in-person": "In-person interview",
	call: "Telephonic/Online interview",
};

export const INTERVIEW_METHODS_MIXPANEL_VALUE = {
	"in-person": "In-person",
	call: "Telephonic",
};

export const INTERVIEW_METHOD_OPTIONS = [
	{
		label: "In-person interview",
		value: INTERVIEW_METHODS.IN_PERSON_INTERVIEW,
	},
	{
		label: "Telephonic/Online interview",
		value: INTERVIEW_METHODS.TELEPHONIC_ONLINE_INTERVIEW,
	},
];

export const INTERVIEW_ADDRESS_TYPE = {
	SAME_AS_JOB_LOCATION: true,
	DIFFERENT_FROM_JOB_LOCATION: false,
};
export const INTERVIEW_ADDRESS_OPTIONS = [
	{
		label: "Same as job location",
		value: INTERVIEW_ADDRESS_TYPE.SAME_AS_JOB_LOCATION,
	},
	{
		label: "Different from job location",
		value: INTERVIEW_ADDRESS_TYPE.DIFFERENT_FROM_JOB_LOCATION,
	},
];

export const APPLICANT_LOCATION_OPTIONS_WFH = [
	{
		label: "Specific city",
		value: APPLICATION_LOCATION_VALUES_FOR_NON_WFH.ENTIRE_CITY,
	},
	{
		label: "Anywhere in India",
		value: APPLICATION_LOCATION_VALUES_FOR_WFH.ENTIRE_INDIA,
	},
];

export const CONFIG_ALLOW_POST_FIELD_JOBS = "allow_post_field_jobs_in_job_post";

export const EXPERIENCE_LEVELS = {
	FRESHER: "fresher",
	ANY: "any",
	EXPERIENCED: "experienced",
	fresher: "f",
	any: "a",
	experienced: "e",
};

export const EXPERIENCE_LEVELS_FE_BE_MAP = {
	f: "fresher",
	a: "any",
	e: "experienced",
};
export const EXPERIENCED_BUCKETS = [
	{ label: "Min 1 year", minYears: 1, maxYears: 31 },
	{ label: "Min 3 years", minYears: 3, maxYears: 31 },
	{ label: "Min 5 years", minYears: 5, maxYears: 31 },
	{ label: "Min 10 years", minYears: 10, maxYears: 31 },
];

export const CONFIG_EXPERIENCE_TIGHTENING_CAT_X_CITY =
	"experience_tightened_category_city";
export const DUPLICATE_ERROR_WARNING_TEXTS = {
	SINGLE_JOB_LOCATION: {
		warning:
			"Other recruiters in your company have listed the same job in the same location. If you still post in <location>, you may get lesser or no applications.",
		error:
			"Duplicate, cannot proceed. You have the same job active in the same location. Please try in a different location or after active job expires.",
	},
	WORK_FROM_HOME: {
		warning:
			"Other recruiters have listed the same job in the same city for this company. If you still post this job, you may get lesser or no applications.",
		error:
			"Duplicate, cannot proceed. You have the same job active in the same city. Please try in a different city or after active job expires.",
		warning_anywhere:
			"Other recruiters have listed the same job for this company. If you still post this job, you may get lesser or no applications.",
		error_anywhere:
			"Duplicate, cannot proceed. You have the same job active. Please try after active job expires",
	},
	FIELD_JOB: {
		warning:
			"Other recruiters have listed the same job in the same city for this company. If you still post this job, you may get lesser or no applications.",
		error:
			"Duplicate, cannot proceed. You have the same job active in the same city. Please try in a different city or after active job expires.",
	},
};

export const missingFieldsStepMapping = {
	deposit_mode: 0,
	tecc_employer_preference: 2,
	company_address: 2,
	company_address_area: 2,
	company_address_city: 2,
};
export const DEFAULT_MIN_EXPERIENCE_VALUE = 0;
export const MAX_OLD_VIEW_EXPERIENCE_VALUE = 29;

export const FIELD_JOBS_AREA_OPTIONS_KEYS = {
	SPECIFIC_AREA: "SPECIFIC_AREA",
	SAME_CITY: "SAME_CITY",
};

export const FIELD_JOBS_AREA_OPTIONS_KEYS_MIXPANEL_VALUES = {
	SPECIFIC_AREA: "SPECIFIC_AREA",
	SAME_CITY: "SAME_CITY",
};

export const FIELD_JOBS_AREA_OPTIONS_FE_BE_SYNC = {
	null: "SAME_CITY",
	undefined: "SAME_CITY",
	SPECIFIC: "SPECIFIC_AREA",
	SPECIFIC_AREA: "SPECIFIC",
	SAME_CITY: null,
};

export const FIELD_JOBS_AREA_OPTIONS = [
	{
		value: FIELD_JOBS_AREA_OPTIONS_KEYS.SPECIFIC_AREA,
		label: "One specific area",
	},
	{
		value: FIELD_JOBS_AREA_OPTIONS_KEYS.SAME_CITY,
		label: "Anywhere in the city",
	},
];

export const CUSTOM_OPTION = "CUSTOM_OPTION";
export const FEEDBACK_LENGTH_CUTOFF = 125;
export const SALARY_INCENTIVE_FIREBASE_CONFIG = "emp_salary_incentive_category_city";
export const ADDITIONAL_SALARY_PERKS = {
	OTHER_PERKS: "other_perks",
};

export const FORM_SECTION_HEADINGS_DESCRIPTION = {
	INTERVIEW_METHOD: {
		heading: "Interview method and address",
		description: "Let candidates know how interview will be conducted for this job.",
	},
	FIELD_JOB_INTERVIEW_METHOD: {
		heading: "Company Address",
		description: "Let candidates know where the company is located at",
	},
	INTERVIEWER_DETAILS: {
		heading: "Interviewer Details",
		description: "Who would be connecting with candidates for this job ?",
	},
	BASIC_JOB_DETAILS: {
		heading: "Job details",
		description: "We use this information to find the best candidates for the job.",
	},
	BASIC_LOCATION_DETAILS: {
		heading: "Location",
		description: "Let candidates know where they will be working from.",
	},
	SALARY_COMPENSATION: {
		heading: "Compensation",
		description:
			"Job postings with right salary & incentives will help you find the right candidates.",
	},
	SAMPLE: {
		heading: "",
		description: "",
	},
};

export const MISSING_FIELDS = {
	DEPOSIT_MODE: "deposit_mode",
	TECC_EMPLOYER_PREFERENCE: "tecc_employer_preference",
	COMPANY_ADDRESS: "company_address",
	COMPANY_ADDRESS_CITY: "company_address_city",
	COMPANY_ADDRESS_AREA: "company_address_area",
};

export const SALARY_ERROR_TYPES = {
	EMPTY_MIN: "Empty Min",
	EMPTY_MAX: "Empty Max",
	EMPTY_INCENTIVE: "Empty Incentive",
	MAX_LESS_THAN_MIN: "Max < Min",
	HIGH_MIN_SALARY: "High Total Sal",
	LOW_TOTAL_SALARY: "Low Total Sal",
};

export const SUBCITY_LABEL_MAPPINGS = {
	"Delhi-NCR": "Delhi-NCR (All NCR region cities)",
};
