export const RELEASE_VERSION = "2.9.7.7";

export const TOP_RECRUITER_LOCAL_KEY = "apna_top_recruiter_shown";

export const CDN = "https://cdn.apna.co/employerDashboard_FE";

export const JOB_CATEGORIES_DROPDOWN = [
	{ value: 1, label: "Accounts" },
	{ value: 41, label: "AC Technician" },
	{ value: 2, label: "Admin / Office Assistant" },
	{ value: 66, label: "Aircraft Maintainance" },
	{ value: 3, label: "Back Office" },
	{ value: 68, label: "Baker" },
	{ value: 59, label: "BCA / MCA" },
	{ value: 4, label: "Beautician / Hair Stylist" },
	{ value: 5, label: "Business Development" },
	{ value: 43, label: "Carpenter" },
	{ value: 61, label: "Chemical Engineer" },
	{ value: 56, label: "Civil Engineer" },
	{ value: 7, label: "Computer / Data Entry Operator" },
	{ value: 8, label: "Content Writing" },
	{ value: 9, label: "Cook / Chef" },
	{ value: 67, label: "COPA" },
	{ value: 10, label: "Counsellor" },
	{ value: 11, label: "Delivery Person" },
	{ value: 12, label: "Digital Marketing / Online Marketing" },
	{ value: 60, label: "Draughtsman" },
	{ value: 13, label: "Driver" },
	{ value: 54, label: "DTP Operator" },
	{ value: 57, label: "Electrical Engineer" },
	{ value: 44, label: "Electrician / Wireman" },
	{ value: 58, label: "Electronic Engineer" },
	{ value: 15, label: "Engineer (Other)" },
	{ value: 53, label: "Finance" },
	{ value: 62, label: "Foundry" },
	{ value: 16, label: "Graphic Designer" },
	{ value: 17, label: "Hardware & Network Engineer" },
	{ value: 18, label: "Hospitality / Hotel Management" },
	{ value: 19, label: "Hotel Staff" },
	{ value: 20, label: "Housekeeping" },
	{ value: 21, label: "Human Resource" },
	{ value: 65, label: "Instrument Mechanic" },
	{ value: 51, label: "IT Support" },
	{ value: 22, label: "Lab Technician" },
	{ value: 23, label: "Machine Operator" },
	{ value: 24, label: "Mavalue" },
	{ value: 52, label: "Marketing" },
	{ value: 55, label: "Mechanical Engineer" },
	{ value: 25, label: "Medical" },
	{ value: 45, label: "Mobile Technician" },
	{ value: 26, label: "Nurse" },
	{ value: 27, label: "Office Help / Peon" },
	{ value: 999, label: "Other" },
	{ value: 64, label: "Painter" },
	{ value: 46, label: "Plumber" },
	{ value: 28, label: "Receptionist / Front Office Desk" },
	{ value: 42, label: "Refrigerator Technician" },
	{ value: 29, label: "Retail / Counter Sales" },
	{ value: 30, label: "Sales (Field Work)" },
	{ value: 32, label: "Security Guard" },
	{ value: 33, label: "Software / Web Developer" },
	{ value: 34, label: "Tailor / Cutting Master" },
	{ value: 35, label: "Teacher" },
	{ value: 36, label: "Technician (Other)" },
	{ value: 37, label: "Telecaller / BPO" },
	{ value: 63, label: "Tool and Die Maker" },
	{ value: 38, label: "Trainer" },
	{ value: 47, label: "Turner-Fitter" },
	{ value: 39, label: "Ward Helper" },
	{ value: 48, label: "Welder" },
];

export const LOCATION_CLUSTERS = [
	{ value: 1, label: "Dadar se CST" },
	{ value: 2, label: "Borivali se Dadar" },
	{ value: 3, label: "Thane se Dadar" },
	{ value: 4, label: "Dadar se Churchgate" },
	{ value: 5, label: "Dahanu Rd. se Borivali" },
	{ value: 6, label: "Karjat/ Kasara se Thane" },
	{ value: 7, label: "Vashi se Kurla" },
	{ value: 8, label: "Vashi se Thane" },
	{ value: 9, label: "Kurla se CST" },
	{ value: 10, label: "Roha/ panvel se Vashi" },
];

export const JOB_FILTERS = [
	{
		label: "Within Group",
		value: "within_group",
	},
	{
		label: "Outside Group",
		value: "outside_group",
	},
];

export const JOB_EXPERIENCE = {
	fresher: 1,
	experienced: 2,
};

export const JOB_EXPERIENCE_STRINGS = {
	fresher: "fresher",
	experienced: "experienced",
};

export const SALARY_RANGE = [
	{
		label: "Less than 15k",
		value: 1,
	},
	{
		label: "15k to 25k",
		value: 2,
	},
	{
		label: "Greater than 25k",
		value: 3,
	},
];

export const SALARY_RANGE_VALUE = {
	1: {
		salary_from: 0,
		salary_to: 15000,
	},
	2: {
		salary_from: 15000,
		salary_to: 25000,
	},
	3: {
		salary_from: 25000,
	},
};
export const DATASOURCE = [
	{
		key: "1",
		job_id: "Mike",
		source: 32,
		jobscategory_id: "10 Downing Street",
	},
	{
		key: "2",
		job_id: "Mike",
		source: 32,
		jobscategory_id: "10 Downing Street",
	},
	{
		key: "3",
		job_id: "Mike",
		source: 32,
		jobscategory_id: "10 Downing Street",
	},
	{
		key: "4",
		job_id: "Mike",
		source: 32,
		jobscategory_id: "10 Downing Street",
	},
];

export const COLUMNS = [
	{
		title: "JobId",
		dataIndex: "job_id",
		key: "job_id",
	},
	{
		title: "Name",
		dataIndex: "source",
		key: "source",
	},
	{
		title: "Category",
		dataIndex: "jobscategory_id",
		key: "jobscategory_id",
	},
];

export const PROFILE_BG_COLORS = ["#9691cb", "#fc9f5b", "#83bde4", "#ffd166"];

export const REJECTED_APPLICATION_STATUS = new Set(["call_back", "call_again"]);

export const JOB_UPDATE_STATUS = {
	active: 0,
	"under review": 1,
	expired: 2,
	"not approved": 3,
	"payment pending": 4,
	delete: 5,
};
export const SET_JOB_UPDATE_STATUS = {
	0: "active",
	1: "under review",
	2: "expired",
	3: "not approved",
	4: "payment pending",
	5: "delete",
};

export const MONTHS_SHORT_HAND = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sept",
	"Oct",
	"Nov",
	"Dec",
];

export const DAYS_SHORT_HAND = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const GENDER_FORM_DATA = {
	female: "f",
	male: "m",
	both: null,
};

export const GENDER_FORM_DATA_PREFILL_DEFINITION = {
	female: "f",
	male: "m",
};

export const GENDER_DATA = {
	f: "Female",
	m: "Male",
	null: "Both",
};

export const ENGLISH_LEVEL_FORM_DATA = {
	noEnglish: "No English",
	thodaEnglish: "Thoda English",
	goodEnglish: "Good English",
	fluentEnglish: "Fluent English",
};

export const ENGLISH_LEVEL_ORDERED = {
	noEnglish: 0,
	thodaEnglish: 1,
	goodEnglish: 2,
	fluentEnglish: 3,
};

export const JOB_TABS_HEADINGS = {
	0: "Applied",
	1: "Suggested",
	2: "Details",
	3: "Stats",
};

export const EXPIRE_JOB_TAB_HEADINGS = {
	0: "Applied",
	1: "Details",
	2: "Stats",
};

export const JOB_EXPIRY_DAYS = {
	"10Days": 10,
	"30Days": 30,
	"15Days": 15,
};

export const APPLICATION_STATUSES = {
	apply: "Candidate applied to your job",
	call_back: "Candidate tried calling you",
	call_again: "Candidate tried calling you",
	call_attempted: "Candidate tried calling you",
	waitlist: "Candidate is waitlisted",
	shortlist: "You shortlisted this profile",
	job_not_fit: "",
	vac_closed: "Candidate reported this vacancy is closed",
	wr_num: "Candidate reported this number is incorrect",
	interview_fixed: "Candidate is shortlisted for interview",
	asking_for_money: "Candidate reported you asked for money",
};

export const YES_VARIABLES_IN_STATS_TAB = [
	"vacancy_closed",
	"interview_fixed",
	"call_attempted",
	"asking_for_money",
];

export const VIEWED_VARIABLES_IN_STATS_TAB = ["viewed"];
export const WHATSAPP_SENT_VARIABLES_IN_STATS_TAB = ["whatsapp_sent"];
export const NO_VARIABLES_IN_STATS_TAB = ["call_back", "call_again"];
export const DAYS_IN_STATS_TAB_GRAPH = {
	SEVEN_DAYS: 7,
	FOURTEEN_DAYS: 14,
	ALL_DAYS_DATE: "2019-07-01",
};
export const OVERALL_VARIABLES_IN_STATS_TAB = ["overall"];
export const DEFAULT_CITY_ID = 1;

export const APPROVAL_COEFFICIENT_DATA = {
	0.9: {
		color: "#2bb793",
		label: "Quickly read once and auto-approve",
		textColor: "#fff",
	},
	0.8: {
		color: "#138808",
		label: "Check if company is legit, read once and auto-approve",
		textColor: "#fff",
	},
	0.75: {
		color: "#ffd166",
		label: "May or may not need to call the customer",
		textColor: "#000",
	},
	0.6: {
		color: "#FF6700",
		label: "Don't approve without calling",
		textColor: "#fff",
	},
	0.4: {
		color: "#9691cb",
		label: "Call mandatory",
		textColor: "#fff",
	},
	0.05: {
		color: "#ed767e",
		label: "Highly likely to be rejected",
		textColor: "#fff",
	},
	0: {
		color: "transparent",
		label: "",
		textColor: "#fff",
	},
};
export const APPROVAL_COEFFICIENT_DATA_FOR_FILTER = [
	{
		label: "",
		id: 0.9,
		color: "#2bb793",
	},
	{
		label: "",
		id: 0.8,
		color: "#138808",
	},
	{
		label: "",
		id: 0.75,
		color: "#ffd166",
	},
	{
		label: "",
		id: 0.6,
		color: "#FF6700",
	},
	{
		label: "",
		id: 0.4,
		color: "#9691cb",
	},
	{
		label: "",
		id: 0.05,
		color: "#ed767e",
	},
];
export const LANGUAGE_SUFFIX = ["en", "kn", "bn", "te"];
export const MAX_NO_OF_OPENINGS = 250;
export const ASSESSMENT_OPTIONS = {
	ONE: "1",
	TWO: "2",
	THREE: "3",
	FOUR: "4",
};

export const JOB_WARNING_LIMIT_COUNT = 3;
export const EMPLOYER_TYPES = [
	{ key: 1, label: "Consultant", value: "consultant" },
	{ key: 2, label: "Enterprise", value: "enterprise" },
	{ key: 3, label: "SMB", value: "smb" },
	{ key: 4, label: "Key Account", value: "key-account" },
];
export const EMPLOYER_TYPES_KEYS = {
	CONSULTANT: "consultant",
	ENTERPRISE: "enterprise",
	SMB: "smb",
	KEY_ACCOUNT: "key-account",
};
export const EMPLOYER_TYPES_FOR_FILTER = [
	{ label: "Consultant", value: "consultant" },
	{ label: "Enterprise", value: "enterprise" },
	{ label: "SMB", value: "smb" },
	{ label: "Key Account", value: "key-account" },
	{ label: "Not Tagged", value: "blank" },
	{ label: "Verified Company", value: "company-verified" },
];
export const NUMBER_OF_EMPLOYEES = [
	{ key: 1, label: "0-50", value: "0-50" },
	{ key: 2, label: "51-100", value: "51-100" },
	{ key: 3, label: "101-300", value: "101-300" },
	{ key: 4, label: "301-500", value: "301-500" },
	{ key: 5, label: "501-1000", value: "501-1000" },
	{ key: 6, label: "1000 above", value: "1000 above" },
	{ key: 7, label: "Not sure", value: "Not sure" },
];
export const ADDITIONAL_PERKS = [
	{ key: 0, value: "Overtime pay" },
	{ key: 1, value: "Performance bonus" },
	{ key: 2, value: "Medical Insurance" },
	{ key: 3, value: "Travelling allowance" },
];

export const ENGLISH_LANGUAGE_OPTIONS = [
	{
		label: "No English",
		value: "noEnglish",
	},
	{
		label: "Thoda English",
		value: "thodaEnglish",
	},
	{
		label: "Good English",
		value: "goodEnglish",
	},
	{
		label: "Fluent English",
		value: "fluentEnglish",
		tooltip:
			"Fluent English-speaking requirements will reduce the number of applicants significantly",
	},
];

export const JOB_DELETION_OPTIONS = [
	{
		label: "DNA (>24h)",
		value: "We could not connect with you on call, on the mentioned HR number.",
	},
	{
		label: "Can't verify POC/ HR",
		value:
			"We were unable to verify your association with the company that you are hiring for.",
	},
	{
		label: "Can't verify (COT)  client requirement",
		value: "We were unable to verify the client for whom you are hiring.",
	},
	{
		label: "Can't verify business",
		value:
			"We were unable to verify your business because either we didn't receive the verification documents requested or the documents submitted didn't qualify our approval criteria.",
	},
	{
		label: "Duplicate by org",
		value: "A very similar job has already been posted by your organization",
	},
	{
		label: "Duplicate by recruiter",
		value: "A very similar job has already been posted by you",
	},
	{
		label: "Out of scope",
		value:
			"We won't be able to help you hire for the position or in the location posted by you.",
	},
	{
		label: "No fixed salary",
		value: "You are not offering any fixed salary.",
	},
	{
		label: "Freelancer",
		value:
			"We currently do not work with freelancers, please download the apna app from PlayStore and post your jobs in the groups.",
	},
	{
		label: "Charge fee",
		value:
			"We allow jobs that do not take the fees or charges for the purpose mentioned by you.",
	},
	{
		label: "Candidate Job Post",
		value: "You are searching for a job and not looking to hire.",
	},
	{
		label: "Suspicious / Complaint",
		value: "Your previous job(s) has violated Apna's Trust & Safety rules.",
	},
	{
		label: "Free trial over",
		value:
			"Your free trial is over, please contact your account manager to learn about our paid services.",
	},
	{
		label: "Deletion on request",
		value: "As we received your request to delete your job(s).",
	},
];

export const APPLICATION_STATUSES_SHORTHAND = new Set([
	"vacancy_closed",
	"interview_fixed",
	"asking_for_money",
	"whatsapp_sent",
	"hr_will_call",
	"asked_for_docs",
	"wrong_candidate",
	"not_selected",
	"hr_rude",
	"call_reminder",
	"assessment_passed",
]);

export const MAX_CHARS_FOR_QUESTIONS = 500;

export const DOCUMENT_TYPE = [
	{
		label: "Company PAN Card",
		value: "COMPANY_PAN",
	},
	{
		label: "Company GST",
		value: "GST",
	},
	{
		label: "Udyog Aadhaar Card",
		value: "UDYOG_AADHAR",
	},
	{
		label: "Shops and Establishment Act",
		value: "SHOPS_AND_EST_ACT",
	},
	{
		label: "Food License (Restaurant/Cafe etc.)",
		value: "FOOD_LICENSE",
	},
];

export const VERIFICATION_STATUS_CLASSES = {
	verified: "apna-active-job-tag",
	pending: "apna-under-review-job-tag",
	non_verified: "apna-under-review-job-tag",
	rejected: "apna-expire-job-tag",
	"": "apna-under-review-job-tag",
};

export const FEEDBACK_ACTION_TYPES = {
	noOfApplications: "APPLICATIONS_RECEIVED_EXPERIENCE",
	candidatesSuitable: "CANDIDATES_QUALITY_EXPERIENCE",
	jobPostingExperience: "JOB_POSTING_EXPERIENCE",
	customerSupportExperience: "CUSTOMER_SUPPORT_EXPERIENCE",
	remark: "REMARK",
	candidateHired: "CANDIDATES_HIRED",
	resetFields: "RESET_FIELDS",
	submittedFeedback: "SUBMITTED_FEEDBACK",
};

export const FEEDBACK_LABELS = {
	excellent: "Excellent",
	expectations: "Met expectations",
	improvement: "Needs improvement",
};

export const FEEDBACK_TITLES = {
	noOfApplications: "<b> Number of applications </b> received",
	candidatesSuitable: " <b> Candidates suitable </b> as per requirement ",
	jobPostingExperience: "<b> Job Posting Experience </b>",
	customerSupportExperience: " <b>Customer Support</b> Experience ",
};

export const AUTO_OPENED_FEEDBACK = "__AUTO_OPENED_FEEDBACK__";

export const JOB_APPLICATION_STATES = [
	{ label: "Hired", value: "hired" },
	{ label: "Rejected", value: "rejected" },
	{ label: "Shortlisted", value: "shortlisted" },
	{ label: "Interview Fixed", value: "interview_fixed" },
	{ label: "Document Pending", value: "document_pending" },
	{ label: "Absent for Interview", value: "absent_for_interview" },
	{ label: "Fresh Applicants", value: "new_candidate" },
	{ label: "Assesment Screening", value: "assessment_screening" },
	{ label: "CV Review", value: "cv_review" },
	{ label: "Telephonic Interview", value: "telephonic_screening" },
	{ label: "Assignment", value: "assignment" },
	{ label: "Waitlisted", value: "waitlisted" },
	{ label: "Offered", value: "offered" },
	{ label: "Offer Accepted", value: "offer_accepted" },
	{ label: "Offer Declined", value: "offer_declined" },

	// { label: "Asked For Docs", value: "asked_for_docs" },
];

export const OLD_JOB_APPLICATION_STATES = [
	{ label: "Hired", value: "hired" },
	{ label: "Rejected", value: "rejected" },
	{ label: "Shortlisted", value: "shortlisted" },
	{ label: "Interview Fixed", value: "interview_fixed" },
	{ label: "Document Pending", value: "document_pending" },
	{ label: "Absent for Interview", value: "absent_for_interview" },
	// { label: "Asked For Docs", value: "asked_for_docs" },
];
export const JOB_APPLICATION_STATES_MAPPING = {
	hired: "Hired",
	rejected: "Rejected",
	shortlisted: "Shortlisted",
	interview_fixed: "Interview Fixed",
	document_pending: "Document Pending",
	absent_for_interview: "Absent for Interview",
	new_candidate: "New Candidates",
	assessment_screening: "Assesment Screening",
	cv_review: "CV Review",
	// telephonic_screening: "Telephonic Interview",
	telephonic_round: "Telephonic Round",
	assignment: "Assignment",
	on_hold: "On Hold",
	// waitlisted: "Waitlisted",
	offer_job: "Offer Job",
	offer_accepted: "Offer Accepted",
	offer_declined: "Offer Declined",
	did_not_join: "Did Not Join",
	left_job: "Left Job",
	interview: "Interview",
};
export const CS_TEAM_COMMENT_FILTER = [
	{ id: "LF", type: "Low Fulfillment Process" },
	{ id: "EF", type: "Employer Feedback" },
	{ id: "CHF", type: "Call HR Feedback" },
	{ id: "MF", type: "Marketplace Feedback" },
];
export const CS_FILTER_CHIPS = {
	LF: [
		{ id: "LF-DNA", label: "DNA", value: "internal-insight-lf-dna" },
		{ id: "LF-CB", label: "Call back", value: "internal-insight-lf-callback" },
		{ id: "LF-C", label: "Changes", value: "internal-insight-lf-changes" },
		{ id: "LF-NC", label: "No Changes", value: "internal-insights-lf-nc" },
	],
	EF: [
		{ id: "EF-DNA", label: "DNA", value: "internal-insight-ef-dna" },
		{ id: "EF-CB", label: "Call back", value: "internal-insight-ef-callback" },
		{ id: "EF-R", label: "Reactivate", value: "internal-insight-ef-reactivate" },
		{
			id: "EF-NC",
			label: "Not Reactivate",
			value: "internal-insight-ef-notreactivate",
		},
		{
			id: "EF-II",
			label: "Irrelevancy issues",
			value: "internal-insight-ef-irrelevancy-issues",
		},
		{
			id: "EF-CI",
			label: "Candidate issues",
			value: "internal-insight-ef-candidate-issues",
		},
	],
	CHF: [
		{ id: "CHF-DNA", label: "DNA", value: "internal-insight-chf-dna" },
		{ id: "CHF-CB", label: "Call back", value: "internal-insight-chf-callback" },
		{
			id: "CHF-CT",
			label: "Complaint True",
			value: "internal-insight-chf-complainttrue",
		},
		{
			id: "CHF-CF",
			label: "Complaint Untrue",
			value: "internal-insight-chf-complaintfalse",
		},
	],
	MF: [
		{ id: "MF-DNA", label: "DNA", value: "internal-insight-mf-dna" },
		{ id: "MF-CB", label: "Call back", value: "internal-insight-mf-callback" },
		{
			id: "MF-CT",
			label: "Complaint True",
			value: "internal-insight-mf-complainttrue",
		},
		{
			id: "MF-CF",
			label: "Complaint Untrue",
			value: "internal-insight-mf-complaintfalse",
		},
		{
			id: "MF-UR",
			label: "UR Remark",
			value: "internal-insight-mf-ur-remark",
		},
		{
			id: "MF-S",
			label: "Suspicious",
			value: "internal-insight-mf-suspicious-activity",
		},
		{
			id: "MF-QC",
			label: "QC Corrected",
			value: "internal-insight-mf-QC-Ok",
		},
	],
};

export const LANGUAGE_SUFFIX_BY_CITY = [
	{ language: "Kannada", label: "kn", name: "Bengaluru" },
	{ language: "Bengali", label: "bn", name: "Kolkata" },
	{ language: "Telugu ", label: "te", name: "Hyderabad" },
	{ language: "Tamil ", label: "ta", name: "Chennai" },
];
export const eventMap = {
	activate: "Job activate - internal",
	expire: "Job expire - internal",
	archive: "Job archive - internal",
};

export const ATS_SNACKBAR_COLORS = {
	warning: "#FC9F5B",
	info: "#31445A",
	success: "#2BB792",
};

export const FEES_DEPOSIT_OPTIONS = [
	{
		id: 1,
		label: "Asset/ Inventory Charge",
		placeholder:
			"Eg. Laptop, Uniform, Mobile, Bag, Shoes, Safety kit, Headphones etc",
	},
	{
		id: 2,
		label: "Security deposit( Refundable)",
		placeholder: "Eg. For candidate assurance, Work-related security etc",
	},
	{
		id: 3,
		label: "Registration/ Training Fees",
		placeholder:
			"Eg. Registration fees, Training fees, Onboarding fees, ID creation fees etc",
	},
	{
		id: 4,
		label: "Commission",
		placeholder: "Please mention reason of charging here",
	},
	{
		id: 5,
		label: "Other Reason",
		placeholder: "Please mention reason of charging here",
	},
];

export const FEE_DEPOSIT_OPTIONS_WHICH_REQUIRE_REASON = [
	"Registration/ Training Fees",
	"Other Reason",
];

export const FEE_DEPOSIT_OPTIONS_WHICH_REQUIRE_REASON_ERROR_CHECK = [
	"Asset/ Inventory Charge",
	"Registration/ Training Fees",
	"Other Reason",
];

export const ASSET_OR_INVENTORY_CHARGE_OPTIONS_DEFAULT = [
	"Bag",
	"Laptop",
	"Toolkit",
	"Uniform",
];

export const FEE_DEPOSIT_MODE_OPTIONS = [
	{
		id: 2,
		label: "Before the interview",
		value: "Before Interview",
	},
	{
		id: 3,
		label: "After job confirmation",
		value: "After Job Confirmation",
	},
	{
		id: 1,
		label: "Deducted from salary",
		value: "From Salary",
	},
];

export const DEPOSIT_STATUS = {
	YES: "Yes",
	NO: "No",
};

export const skillTagInitialMessage = {
	type: "normal",
	message:
		"We will ask questions to candidates as per your selection, and only those that qualify will be able to apply",
};

export const skillTagWarningMessage = {
	type: "warning",
	message:
		"Adding more requirements might reduce the number of applications on your job",
};

export const degreeTagInitialMessage = {
	type: "normal",
	message:
		"Candidates who have any of the selected qualifications will be able to apply on this job",
};

export const languageTagInitialMessage = {
	type: "normal",
	message:
		"Candidates who know any of the selected languages will be able to apply on this job",
};
export const tagMaximumMessage = value => ({
	type: "error",
	message: `Maximum of ${value} tags are allowed`,
});

export const JOB_FORM_STEP_4_MESSAGE =
	"Please mention if you need candidate to have any special skills/software, assets owned (like bike, laptop, internet), documents, age, degree/certifications, or any other requests (like willingness to work with COVID-19 patients)";

export const SUGGESTED_BROWSERS = [
	{
		name: "Google Chrome",
		minVersion: 57,
		icon: "chrome",
		installLink: "https://www.google.com/intl/en_in/chrome/",
	},
	{
		name: "Mozilla Firefox",
		minVersion: 52,
		icon: "firefox",
		installLink: "https://www.mozilla.org/en-US/firefox/new/",
	},
	{
		name: "Microsoft Edge",
		minVersion: 16,
		icon: "edge",
		installLink: "https://www.microsoft.com/en-us/edge",
	},
	{
		name: "Apple Safari",
		minVersion: 10.1,
		icon: "safari",
		installLink: "https://developer.apple.com/safari/download/",
	},
];

export const UNSUPPORTED_BROWSERS = [
	{
		name: "Internet Explorer",
		version: 11,
	},
];

export const COMPANY_DOC_VERIFICATION_STATUS = {
	NON_VERIFIED: "non_verified",
	PENDING: "pending",
	DOC_VERIFIED: "doc_verified",
	ONLINE_VERIFIED: "online_verified",
	BOTH_VERIFIED: "verified",
	REJECTED: "rejected",
};

export const VERIFICATION_STATUS = [
	{
		key: 1,
		label: "NON VERIFIED",
		value: COMPANY_DOC_VERIFICATION_STATUS.PENDING,
	},
	{
		key: 2,
		label: "DOCS VERIFIED",
		value: COMPANY_DOC_VERIFICATION_STATUS.DOC_VERIFIED,
	},
	{
		key: 3,
		label: "ONLINE VERIFIED",
		value: COMPANY_DOC_VERIFICATION_STATUS.ONLINE_VERIFIED,
	},
	{
		key: 4,
		label: "BOTH VERIFIED",
		value: COMPANY_DOC_VERIFICATION_STATUS.BOTH_VERIFIED,
	},
	{ key: 5, label: "REJECTED", value: COMPANY_DOC_VERIFICATION_STATUS.REJECTED },
];
export const VERIFICATION_BACKGROUND = {
	verified: "#138808",
	doc_verified: "#fff1d1",
	online_verified: "#fff1d1",
	rejected: "#ed767e",
	pending: "#ed767e",
};

export const VERIFICATION_COUNT_DOWN_LIMIT = 120; // In seconds i.e 2 minutes

export const APPLICATION_LOCATION_VALUES_FOR_NON_WFH = {
	WITHIN_10KM: "WITHIN_10KM",
	WITHIN_25KM: "WITHIN_25KM",
	ENTIRE_CITY: "SAME_CITY",
};

export const APPLICATION_LOCATION_VALUES_FOR_WFH = {
	ENTIRE_CITY: "SAME_CITY",
	ENTIRE_INDIA: "ANYWHERE",
};
export const PAY_TYPE = {
	FIXED_ONLY: "FIXED",
	FIXED_AND_INCENTIVE: "FIXED_INCENTIVE",
	INCENTIVE_ONLY: "INCENTIVE",
};

export const NON_VERIFIED_STATES = {
	REJECTED: "rejected",
	PENDING: "pending",
};

export const QC_STATUS = {
	APPROVED: "ok",
	PENDING: "pending",
	FAILED: "failed",
};

export const VERIFICATION_STATUS_REMAPPING = {
	verified: "verified",
	doc_verified: "verified",
	online_verified: "verified",
	pending: "pending",
	non_verified: "non_verified",
	rejected: "rejected",
};

export const JOB_REMARKS = {
	AUTO_ACTIVATION_PASS: "new-job-auto-activate-test-pass",
	AUTO_ACTIVATION_FAIL: "new-job-auto-activate-test-fail",
	JOB_UPDATED: "employer-job-update",
};

export const JOB_AUTO_ACTIVATION_RESULT = {
	PASS: "pass",
	FAIL: "fail",
};

export const DAYS = [
	{
		index: 0,
		label: "Mon",
		value: "monday",
	},
	{
		index: 1,
		label: "Tue",
		value: "tuesday",
	},
	{
		index: 2,
		label: "Wed",
		value: "wednesday",
	},
	{
		index: 3,
		label: "Thu",
		value: "thursday",
	},
	{
		index: 4,
		label: "Fri",
		value: "friday",
	},
	{
		index: 5,
		label: "Sat",
		value: "saturday",
	},
	{
		index: 6,
		label: "Sun",
		value: "sunday",
	},
];

export const WORK_TIMING_PREFILL = {
	NONE: {
		startTime: "none",
		endTime: "none",
	},
	DAY_SHIFT: {
		startTime: "09:30 AM",
		endTime: "06:30 PM",
	},
	NIGHT_SHIFT: {
		startTime: "08:30 PM",
		endTime: "05:30 AM",
	},
};
export const UNDER_REVIEW_COMMENT_OPTIONS_TYPES = {
	DNA1: "internal-dna1",
	DNA2: "internal-dna2",
	POC_DOC: "internal-wait-docs-poc",
	COT_DOC: "internal-wait-docs-cot",
	COMPANY_DOC: "internal-wait-docs",
	REVIEW_PENDING: "review-pending",
	OTHERS: "internal-other",
};

export const UNDER_REVIEW_COMMENT_OPTIONS = [
	{
		label: "DNA1",
		value: UNDER_REVIEW_COMMENT_OPTIONS_TYPES.DNA1,
		actionRequired: true,
		canReject: true,
	},
	{
		label: "DNA2",
		value: UNDER_REVIEW_COMMENT_OPTIONS_TYPES.DNA2,
		actionRequired: true,
		canReject: true,
	},
	{
		label: "POC doc",
		value: UNDER_REVIEW_COMMENT_OPTIONS_TYPES.POC_DOC,
		actionRequired: true,
		canReject: true,
	},
	{
		label: "COT doc",
		value: UNDER_REVIEW_COMMENT_OPTIONS_TYPES.COT_DOC,
		actionRequired: true,
		canReject: true,
	},
	{
		label: "Company doc",
		value: UNDER_REVIEW_COMMENT_OPTIONS_TYPES.COMPANY_DOC,
		actionRequired: true,
		canReject: true,
	},
	{
		label: "review pending",
		value: UNDER_REVIEW_COMMENT_OPTIONS_TYPES.REVIEW_PENDING,
		actionRequired: false,
		canReject: false,
	},
	{
		label: "Others",
		value: UNDER_REVIEW_COMMENT_OPTIONS_TYPES.OTHERS,
		actionRequired: false,
		canReject: false,
	},
];

export const NEGATIVE_COMPANY_DOC_STATUSES = ["rejected", "non_verified", ""];

export const ECC_PREFERENCES_OPTIONS = {
	CALL: "CALL",
	MESSAGE: "MESSAGE",
};

export const CUSTOM_ECC_QUESTION_KEY = "CUSTOM";

export const DEFAULT_SELECTED_ECC_OPTIONS = ["CV"];

export const DEFAULT_MIN_AGE = 18;

export const DEFAULT_MAX_AGE = 60;

export const DEFAULT_AUTOCOMPLETE_LETTER_COUNT = 3;

export const JOB_FILTER_STATUS = [
	{
		label: "All",
		value: "all",
	},
	{
		label: "Active",
		value: "0",
	},
	{
		label: "Expired",
		value: "2",
	},
	{
		label: "Select Plan",
		value: "4",
	},
	{
		label: "Under Review",
		value: "1",
	},
	{
		label: "Not Approved",
		value: "3",
	},
];

export const COIN_HISTORY_FILTERS = [
	{
		label: "All",
		value: "all",
	},
	{
		label: "Coins added",
		value: "added",
	},
	{
		label: "Coins spent",
		value: "spent",
	},
	{
		label: "Coins returned",
		value: "returned",
	},
	{
		label: "Invoices",
		value: "invoices",
	},
	{
		label: "Failed transactions",
		value: "failed",
	},
	{
		label: "Pending transactions",
		value: "pending",
	},
];

export const TRANSACTION_FILTERS = [
	"all",
	"spent",
	"added",
	"returned",
	"invoices",
	"failed",
	"pending",
];

export const TRANSACTIONS_REF_TYPE = {
	TRANSFER: "Transfer",
	PAID_JOB: "paid_job",
	UNDER_FULFILLMENT: "under_fulfillment",
	PAYMENT: "Payment",
	CEREBRO: "cerebro",
	COMPLIMENTARY: ["", "prod_test", "other"],
};

export const TRANSACTIONS_TYPE = {
	CREDIT: "Credit",
	DEBIT: "Debit",
};

export const JOB_ACTIVATION_MODES_FILTERS = [
	{ label: "Auto Activate", value: "auto_activate" },
	{ label: "Auto Delete", value: "auto_delete" },
	{ label: "Manual", value: "manual" },
];

export const JOB_STATUS_FILTER_QC = [
	{
		label: "Active",
		value: "0",
	},
	{
		label: "Expired",
		value: "2",
	},
	{
		label: "Under Review",
		value: "1",
	},
	{
		label: "Not Approved",
		value: "3",
	},
];

export const JOB_SUPPORT_TEAM_FILTERS = [
	{ label: "Team UR", value: "team_ur" },
	{ label: "Team CS", value: "team_cs" },
];

export const JOB_POST_FORM_CONFIG = {
	minimumCharactersJobRole: "3",
	minimumCharactersCustomJob: "5",
	fuzzyJobRoleSize: "5",
};

export const REMOTE_CONFIG_FETCH_INTERVAL = 1800000;

export const WORK_HOURS_TIME_DIFF_MIN = 0.5;

export const WORK_HOURS_TIME_DIFF_MAX = 16;

export const CONSULTANT_MODAL_STATUSES = {
	YES: "Yes",
	NO: "No",
};

export const ACCEPTED_RESUME_TYPES = {
	jpg: "jpg",
	JPG: "jpg",
	jpeg: "jpg",
	JPEG: "jpg",
	pdf: "pdf",
	PDF: "pdf",
};

export const POC_DOC_TYPE_OPTIONS = [
	// {
	// 	value: "COMPANY_ID_CARD",
	// 	label: "Company ID card",
	// },
	{
		value: "OFFER_LETTER",
		label: "Offer Letter",
	},
	// {
	// 	value: "VISITING_CARD",
	// 	label: "Visiting Card",
	// },
	{
		value: "LAST_PAYSLIP",
		label: "Last Payslip",
	},
	{
		value: "GOVT_RECOGNISED_ID",
		label: "Government Recognised ID",
	},
];

export const NULL_FILE = new File([], "");

export const CANDIDATES_PAGINATION_SIZE = 10;

export const BAN_TYPES = {
	STRIKE_1: "STRIKE 1",
	STRIKE_2: "STRIKE 2",
	STRIKE_3: "STRIKE 3",
	OLD_USER_BAN: "Old User Ban",
};

export const JOBS_LIST_SIZE = 15;

export const ACTIONS_DATA_FOR_FILTER = [
	{
		label: "DNA1",
		value: "internal-dna1",
	},
	{
		label: "DNA2",
		value: "internal-dna2",
	},
	{
		label: "Other ",
		value: "internal-other",
	},
	{
		label: "Awaiting Documents",
		value: "internal-wait-docs",
	},
	{
		label: "Review Pending",
		value: "review-pending",
	},
	{
		label: "OOO Jobs",
		value: "internal-call-pending",
	},
	{
		label: "Reactivation",
		value: "employer-job-update",
	},
	{
		label: "Edited jobs",
		value: "internal-job-update",
	},
];
export const SLA_DATA_FOR_FILTER = [
	{
		label: "Between 0 to 10 mins",
		value: "1,10",
	},
	{
		label: "Between 11 to 30 mins",
		value: "11,30",
	},
	{
		label: "Between 30 to 60 mins",
		value: "30,60",
	},
	{
		label: "Between 1 to 3 hrs",
		value: "60,180",
	},
	{
		label: "More than 3 hrs",
		value: "180,",
	},
];

export const VERIFICATION_DATA_FOR_FILTER = [
	{
		label: "Company Doc Uploaded",
		value: "company-doc-uploaded",
	},
	{
		label: "Email Verification",
		value: "email-verification",
	},
	{
		label: "POC Doc Uploaded",
		value: "poc-doc-uploaded",
	},
];

export const PREMIUM_DATA_FOR_FILTER = [
	{
		label: "Paid",
		value: "paid",
	},
	{
		label: "Free",
		value: "unpaid",
	},
];

export const SOURCE_DATA_FOR_FILTER = [{ label: "FOS", value: "fos" }];

export const UR_FILTERS = [
	{
		label: "Lead Type",
		value: "lead",
	},
	{
		label: "City",
		value: "city",
	},
	{
		label: "Color",
		value: "color",
	},
	{
		label: "Action",
		value: "action",
	},
	{
		label: "SLA",
		value: "sla",
	},
	{
		label: "Verification Pending",
		value: "verification",
	},
	{
		label: "Premium",
		value: "premium",
	},
	{ label: "Source", value: "source" },
];

export const CREATOR_RECRUITER_JP_FORM = {
	recruiter: "recruiter",
	creator: "myself",
};

export const POC_VERIFICATION_STATUS = {
	VERIFIED: "verified",
	NON_VERIFIED: "non_verified",
	PENDING: "pending",
	REJECTED: "rejected",
};

export const COMPANY_CHANGE_CONSULTANT_REASONS = [
	// {
	// 	value: 1,
	// 	label: "I am posting for a different client company",
	// 	selection_key: "CONSULTANT_CLIENT_CHANGE",
	// },
	{
		value: 2,
		label: "I changed my company",
		selection_key: "CONSULTANT_COMPANY_CHANGE",
	},
	{
		value: 3,
		label: "I want to post for another company/business/consultancy of my own",
		selection_key: "CONSULTANT_DIFFERENT_COMPANY",
	},
	{
		value: 7,
		label: "I am not a consultant",
		selection_key: "CONSULTANT_CONSULTANCY_SWITCH",
	},
];

export const COMPANY_CHANGE_NON_CONSULTANT_REASONS = [
	{
		value: 4,
		label: "I changed my company",
		selection_key: "NON_CONSULTANT_COMPANY_CHANGE",
	},
	{
		value: 5,
		label: "I belong to a consultancy & want to post for my client's company",
		selection_key: "NON_CONSULTANT_CONSULTANCY_SWITCH",
	},
	{
		value: 6,
		label: "I want to post for another company/business/consultancy of my own",
		selection_key: "NON_CONSULTANT_DIFFERENT_COMPANY",
	},
];

export const WORKSPACE_ORG_VERIFICATION_STATUS = {
	verified: "VERIFIED",
	unverified: "UNVERIFIED",
	suspended: "SUSPENDED",
};

export const WHATFIX_SUGGESTED_HIGHLIGHT = "whatfixSuggestedHighlight";

export const AUTO_RENEW_FILTERS = {
	"Auto-Renew": [
		{
			id: "on",
			name: "On",
			type: "Auto-Renew",
		},
		{
			id: "off",
			name: "Off",
			type: "Auto-Renew",
		},
	],
};

export const EMPLOYER_JOB_LIST_FILTERS = {
	area: "area",
	premium: "premium",
};

export const PAYMENT_STATUS = {
	pending: "pending",
	success: "success",
	failure: "failure",
};

export const COIN_PRODUCT = "Coin";

export const PAYMENT_COUPON_DISCOUNT_TYPES = {
	percent: "percent",
	absolute: "absolute",
};
export const EMPLOYER_CALL_FEEDBACK_OPTIONS = [
	{
		label: "Company doc pending",
		value: UNDER_REVIEW_COMMENT_OPTIONS_TYPES.COMPANY_DOC,
		actionRequired: true,
		canReject: true,
	},
	{
		label: "POC pending",
		value: UNDER_REVIEW_COMMENT_OPTIONS_TYPES.POC_DOC,
		actionRequired: true,
		canReject: true,
	},
	{
		label: "COT pending",
		value: UNDER_REVIEW_COMMENT_OPTIONS_TYPES.COT_DOC,
		actionRequired: true,
		canReject: true,
	},
];
export const REPORT_RESUME_OPTIONS = [
	{
		label: "Vulgar/Inappropriate resume",
		value: "VULGAR_INAPPROPRIATE",
	},
	{
		label: "Misleading Information",
		value: "MISLEADING_INFORMATION",
	},
	{
		label: "Fake Resume",
		value: "FAKE",
	},
	{
		label: "Others",
		value: "OTHERS",
	},
];

export const REPORT_RESUME_COMMENT_LENGTH = 300;

export const EMPLOYER_HELP_FROM_SECTIONS = {
	UNDER_REVIEW: "UNDER_REVIEW",
	ACTIVE: "ACTIVE",
};

export const DUPLICATE_JOB_POST_EVENT = "Duplicate Job Found";
export const SHOW_PREMIUM_TAGS_DATE = 1644192000000; // Feb 7 2022

export const MANAGE_COINS_FLOW = "manageCoinsFlow";

export const PRE_SELECTED_COINS_TO_BUY = 10000;
export const SELF_CHECKOUT_LIMIT = 100000; // 1 Lakh

export const POPUP_CONTENT = {
	ACTIVATION_PLANS: "ACTIVATION_PLANS",
	SHOW_BALANCE: "SHOW_BALANCE",
};

export const PRODUCT_TYPE = {
	PRODUCT: "product",
	PACKAGE: "package",
};

export const TRANSACTION_TYPE = {
	PAYMENT: "Payment",
	TRANSFER: "Transfer",
};

export const CandidateSkillListItems = {
	EXPERIENCE: "EXPERIENCE",
	EDUCATION: "EDUCATION",
	GENDER: "GENDER",
	SKILLS: "SKILLS",
	RESUME: "RESUME",
};

export const EXPERIENCE_LEVEL = {
	EXPERIENCED: "e",
	FRESHER: "f",
};
export const TAG_TYPES = {
	DEGREE: "degree",
	ASSET: "asset",
	SKILL: "skill",
	LANGUAGE_REQUIREMENTS: "language_requirement",
	SALARY: "salary_perk",
};

export const DASHBOARD_TYPES = {
	UR: "UR",
	QC: "QC",
	SUPPORT: "SUPPORT",
};

export const KALEYRA_CONFIGS = {
	KALEYRA_CALL_JOB_STATUS_ENABLE_CONFIG: "KALEYRA_CALL_JOB_STATUS_ENABLE_CONFIG",
};

export const KALEYRA_CALL_SUPPORT_TYPES = {
	SUPPORT: "SUPPORT",
	INTERNAL: "VERIFICATION",
};

export const KALEYRA_CALL_SUPPORT_STATUS_TYPES = {
	PENDING: "PENDING",
	INITIATED: "INITIATED",
	COMPLETED: "COMPLETED",
};

export const KALEYRA_RECORDING_TYPES = [
	"internal-connected-recording",
	"support-connected-recording",
	"support-call-connected-recording",
];

export const supportParamsMapping = {
	companyName: "org",
	phoneNumber: "phone",
	jobId: "jobId",
	consultantName: "consultancy",
};
export const REMOTE_APPLICANT_ELIGIBLE = {
	YES: "true",
	NO: "false",
};

export const UNREQUIRED_DATA_FOR_APPLIED_CANDIDATE_EVENT = [
	"Score",
	"Skills Matched Ratio",
	"Assets Matched Ratio",
	"Candidate Skills",
	"Candidate Assets",
	"Last Job Applied (days)",
	"Candidate Current Salary",
	"App Version",
];

export const COMPANY_DATA_SEARCH_API_KEY = "search_api";

export const ACTIVATION_WARNING_MODAL_STATES = {
	CLOSE: 0,
	OPEN: 1,
};
export const ENABLE_AUTO_RENEWAL_FEATURE = false;
export const ENABLE_INTERNAL_JOB_POST = false;

export const ENABLE_INTERNAL_CREDIT_FEATURE = false;
export const ENABLE_FREEJOB_HEADER_FEATURE = true;

export const VERIFICATION_MODALS = {
	JOB_VERIFICATION: "JOB_VERIFICATION",
	JOB_UNDER_REVIEW: "JOB_UNDER_REVIEW",
	HR_NUMBER_VERIFICATION: "HR_NUMBER_VERIFICATION",
};

export const MEMBERS_PAGE_TABS = {
	MY_TEAM: "myTeam",
	CONSULTANTS: "consultancies",
};

export const ADMIN_TEAM_MEMBERS_TABS = [
	{
		label: "My Team",
		value: MEMBERS_PAGE_TABS.MY_TEAM,
	},
	{
		label: "Consultancies",
		value: MEMBERS_PAGE_TABS.CONSULTANTS,
	},
];

export const MAX_TEAM_MEMBERS_PAGE_SIZE = 25;
export const ENABLE_TO_ADD_EMPLOYEE_FEATURE = false;
export const STANDARD_DAYJS_FORMAT = "YYYY-MM-DD";
export const STANDARD_DAYJS_FORMAT_1 = "DD-MM-YYYY";

export const CSV_FILE_NAME_FORMAT = "DD-MM-YYYY + hh:mm:ss A";

export const HELP_WHATSAPP_LINK =
	"https://api.whatsapp.com/send?phone=+918828229992&text=Hi!%20I%20need%20help%20with%20my%20job%20of%20%JOB_TITLE%%20at%20%JOB_CITY%.%20Can%20you%20please%20help%20me%3F%20%0AJob%20ID%3A%20%JOB_ID%%20%0AJob%20Status%3A%20%JOB_STATUS%";

export const BANNED_EMPLOYEE_POPUP_CONTACT_US_LINK =
	"https://api.whatsapp.com/send?phone=+918828229992&text=Hi, I need help with my account and would like to appeal the decision of suspending it for *%DURATION%*";

export const VERIFICATION_FLOW_TYPES = {
	WHITELISTED_CONSULTANT: "WHITELISTED_CONSULTANT",
	WHITELISTED_NON_CONSULTANT: "WHITELISTED_NON_CONSULTANT",
	NON_WHITELISTED_CONSULTANT: "NON_WHITELISTED_CONSULTANT",
	NON_WHITELISTED_NON_CONSULTANT: "NON_WHITELISTED_NON_CONSULTANT",
	HR_VERIFICATION: "HR_VERIFICATION",
};

export const VERIFICATION_STATUSES = {
	// pending/completed/approved/rejected
	PENDING: "PENDING",
	COMPLETED: "COMPLETED",
	APPROVED: "APPROVED",
	REJECTED: "REJECTED",
	SUBMITTED: "SUBMITTED",
	UNDER_REVIEW: "UNDER_REVIEW",
};

export const INTERNAL_JOB_VERIFICATION_FLOWS = {
	COT_VERIFICATION: "COT_VERIFICATION",
	COMPANY_VERIFICATION: "COMPANY_VERIFICATION",
	KYC_VERIFICATION: "KYC_VERIFICATION",
};

export const VERIFICATION_STEPS = {
	EMAIL_STORAGE: "EMAIL_STORAGE",
	USER_VERIFICATION: "USER_VERIFICATION",
	COMPANY_VERIFICATION: "COMPANY_VERIFICATION",
	CONSULTANT_MANDATE: "CONSULTANT_MANDATE",
	EMAIL_VERIFICATION: "EMAIL_VERIFICATION",
	HR_NUMBER_VERIFICATION: "HR_NUMBER_VERIFICATION",
};

export const POC_TYPES = {
	PAN_CARD: "panCard",
	DL: "drivingLicense",
	PAY_SLIP: "paySlip",
};
export const FREE_JOBS_BANNER = "freeJobsBanner";

// NameRegex will not allow name to start with whitespace
export const nameRegex = /^[a-zA-Z][\sa-zA-Z]*$/;
export const websiteRegex = /^[A-Za-z0-9@. ]+/;
// CompanyList will accept company separated with comma's
export const companyListRegex = /^[A-Za-z0-9, ]+/;
// Email Regex for checking whether input can form a right email.
export const emailRegex = /^[A-Za-z0-9.@]+/;
// Phone number regex
export const phoneNumberRegex = /^\d*(?:\.\d{1,2})?$/;
// 4xx error Regex
export const error4xxRegex = /^4\d{2}$/;
export const REPORT_COMMENT_SIZE = {
	min: 25,
	max: 700,
};

export const USER_ACCOUNT_STATUS = {
	TRIAL: "Trial",
	PAID: "paid",
};

export const ACTIVE_JOB_TABS = {
	0: "applied",
	1: "suggested",
	2: "jobDetails",
	3: "stats",
};

export const SET_ACTIVE_JOB_TABS = {
	applied: 0,
	suggested: 1,
	jobDetails: 2,
	stats: 3,
};
export const PLAN_SELECTED_REMARK_TYPE = "employer-status-under-review";
export const URL_PARAMS = "URL_PARAMS";
export const URL_PARAM_KEYS = [
	"utm_medium",
	"utm_campaign",
	"utm_source",
	"utm_adset",
	"utm_content",
	"utm_custom_1",
	"utm_custom_2",
	"matchtype",
	"gclid",
	"utm_term",
];

export const UTM_PARAMS = {
	utm_custom_2: "utm_custom_2",
};

export const UTM_CONSTANTS_VALUES = {
	[UTM_PARAMS.utm_custom_2]: "Whatsapp",
};

export const ATS_ACTIVE_JOB_TABS = {
	0: "all_candidates",
	2: "rejected",
	3: "jobDetails",
	4: "stats",
};
export const SET_ATS_ACTIVE_JOB_TABS = {
	all_candidates: 0,
	rejected: 2,
	jobDetails: 3,
	stats: 4,
};
export const REMARKS = {
	mobile: "mobile_web_csv_bulk",
	desktop: "desktop_csv_bulk",
};
export const POST_JOB_FORM_ALLOW_REDIRECT_TO_POPUP = true;

export const COUPON_ERROR_MESSAGE = "Invalid Coupon code";
export const COUPON_CODE = {
	success: 0,
	invalid: -1,
};
export const FIRST_TIME_COUPON = "FIRST15";

export const JOB_STALLING_BANNER_DATE_FORMAT = "DD/MM/YYYY";

export const TNC_SIGNED = "T&C Signed";

export const ACTIVATION_PLANS_TYPE = {
	INSUFFICIENT_BALANCE: "Insufficient balance",
	FREE_JOB_AVAILABLE: "Free job available",
	SUFFICIENT_FOR_ALL_PLANS: "Sufficient balance for all plans",
	SUFFICIENT_FOR_SOME_PLANS: "Sufficient balance for some plans",
};

export const RETRY_PAYMENT_MODAL_CODES = {
	0: "Success",
	1: "Something_went_wrong_in_code",
	2: "Invalid coupon",
	3: "Invalid package",
	4: "Order cancelled",
};

export const RETRY_PAYMENT_MODAL_STATES = {
	Success: 0,
	Something_went_wrong_in_code: 1,
	"Invalid coupon": 2,
	"Invalid package": 3,
	"Order cancelled": 4,
};

export const GTAG_EVENTS = {
	FirstTimeLoginPageView: "FirstTimeLoginPageView",
	Login: "Login",
	PostJobButton: "PostJobButton",
	JobPostingStep1: "JobPostingStep1",
	JobPostingStep2: "JobPostingStep2",
	JobPostingStep3: "JobPostingStep3",
	ExitWithOutPosting: "ExitWithOutPosting",
	JobPostedSuccessfully: "JobPostedSuccessfully",
};

// free jobs landing page
export const FREE_RENEW_SUCCESS_MODAL_CTAS = {
	CHECK_FREE_JOBS: "Check FREE jobs",
	GO_TO_RENEWED_JOB: "Go to renewed job",
	POST_NEW_JOB: "Post a new job",
};

// free jobs landing page pathname
export const FREE_JOBS_PAGE = "/free-jobs";

export const RE_VERIFICATION_REQUIRED_TEXT_WARNING =
	"To ensure your organization's brand integrity, you need to re-verify your account. It will take less than 3 minutes";
export const VERIFICATION_REQUIRED_TEXT_SUBHEADING =
	"Your job has been submitted successfully. It will be live as soon as you complete the verification.";
export const RE_VERIFICATION_REQUIRED_TEXT_SUBHEADING =
	"To ensure your organization's brand integrity, you need to re-verify your account once to make your new job live.";

// Suppoorted Browser Version, all the version greater than this will be supported
export const SUPPORTED_BROWSERS = {
	desktop: {
		safari: ">=12.1",
		chrome: ">=60",
		firefox: ">=55",
		opera: ">=47",
		edge: ">=79",
	},
	mobile: {
		safari: ">=12.1",
		"Android Browser": ">=60",
		"Samsung Internet for Android": ">=5",
		chrome: ">=60",
		firefox: ">=55",
	},
};

export const BROWSER_UPDATES_LINKS = {
	chrome: "https://www.google.com/chrome/update/",
	firefox: "https://support.mozilla.org/en-US/kb/update-firefox-latest-release",
	edge:
		"https://support.microsoft.com/en-us/topic/update-to-the-new-microsoft-edge-182d0668-e3f0-49da-b8bb-db5675245dc2",
	opera: "http://www.opera.com/download/",
	safari: "http://www.apple.com/safari/",
	samsung:
		"https://www.samsung.com/sg/support/apps-services/install-or-update-samsung-internet/",
};
export const LOW_RESPONSE_RATE = 15;
export const LOW_RESPONSE_SCORE = 2;

export const JOB_TITLE_TYPE = {
	NON_STANDARD: "NON_STANDARD",
	STANDARD_SINGLE_SELECT: "STANDARD_SINGLE_SELECT",
	STANDARD_SINGLE_MAPPING: "STANDARD_SINGLE_MAPPING",
};
export const SHIELD_ID = "SHIELD_ID_V2";

export const GSTCheck = "GST Details";
export const CompanyDocTypes = {
	UDYAM: "UDYAM",
	CIN: "CIN",
	PAN: "PAN",
	UDHYOG: "UDHYOG",
	FSSAI: "FSSAI",
	SHOPEST: "SHOPEST",
};

export const DEVICE_IDENTIFIER_FETCH_ATTEMPTED = "DEVICE_IDENTIFIER_FETCH_ATTEMPTED";
export const DEVICE_IDENTIFIER = "DEVICE_IDENTIFIER";
export const DEVICE_REQUEST_ID = "DEVICE_REQUEST_ID";
export const DEVICE_INFO = "DEVICE_INFO";
export const LOADER_MODAL_TYPE = {
	VERIFYING_PAYMENT_ORDER: "VERIFYING_PAYMENT_ORDER",
	JOB_POSTING: "JOB_POSTING",
};

export const LOADER_MODAL_CONTENT = {
	[LOADER_MODAL_TYPE.VERIFYING_PAYMENT_ORDER]: "Verifying your order ...",
	[LOADER_MODAL_TYPE.JOB_POSTING]: "Posting your job",
};

export const JOB_POSTING_MODAL_TYPE = {
	LOADER: "Loader",
	ERROR: "Error",
};

export const JOB_POSTING_MODAL_ERROR_CODES = {
	PRICE_MISMATCH: 1,
	SERVICE_ID_INVALID: 2,
	JOB_IN_GIVEN_STATE: 3,
	INSUFFICIENT_BALANCE: 4,
};

export const FRAUD_DATA_STATUS = {
	SUSPICIOUS: "Suspicious",
	NOT_AVAILABLE: "Not Available",
	NON_SUSPICIOUS: "Non-Suspicious",
};
export const FRAUD_DATA_TITLE = {
	COMPANY_DOCUMENT_NUMBER: "Company document Number",
	KYC_DOCUMENT_NUMBER: "KYC document number",
	USER_DEVICE_ID: "User device ID",
	CANDIDATE_ACCOUNT_WITH_SAME_PHONE: "Candidate account with same phone number",
	KYC_NAME_CHECK: "User name check",
	USER_AGE: "User age",
	OTHER_INDICATORS: "Other indicators",
};
export const MODAL_CASE_TITLES = [
	FRAUD_DATA_TITLE.COMPANY_DOCUMENT_NUMBER,
	FRAUD_DATA_TITLE.KYC_DOCUMENT_NUMBER,
	FRAUD_DATA_TITLE.USER_DEVICE_ID,
];
export const OTP_ERROR_MESSAGES = {
	"401": "OTP entered is either invalid or expired, please try again",
	"403": "OTP entered is either invalid or expired, please try again after some time",
	"429": "OTP entered is either invalid or expired, please try again after some time",
};
