/* eslint-disable no-case-declarations */
import { jobsActionTypes } from "../constants/types";

const initialState = {
	applicantsStatusByJobId: {},
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case jobsActionTypes.setJobApplicationsStatus: {
			const { jobId, applicantsDataForJob } = payload;
			return {
				...state,
				applicantsStatusByJobId: {
					...state.applicantsStatusByJobId,
					[jobId]: {
						...state.applicantsStatusByJobId?.[jobId],
						...applicantsDataForJob,
					},
				},
			};
		}
		case jobsActionTypes.setApplicationStatus: {
			const { jobId, userId, applicationData } = payload;
			return {
				...state,
				applicantsStatusByJobId: {
					...state.applicantsStatusByJobId,
					[jobId]: {
						...state.applicantsStatusByJobId?.[jobId],
						[userId]: {
							...state.applicantsStatusByJobId?.[jobId]?.[userId],
							hiring_state: applicationData?.state,
						},
					},
				},
			};
		}
		default:
			return state;
	}
};
