export const TOTAL_COIN_BALANCE = "TOTAL_COIN_BALANCE";
export const TOGGLE_BUY_COINS_SIDE_DRAW = "TOGGLE_BUY_COINS_SIDE_DRAW";
export const ACCOUNT_ID = "ACCOUNT_ID";
export const BUY_COINS = "BUY_COINS";
export const UPDATE_APNA_COIN_PRODUCT = "UPDATE_APNA_COIN_PRODUCT";
export const EXTRA_COINS_REQUIRED = "EXTRA_COINS_REQUIRED";
export const RENEWAL_SUCCESS_MODAL = "RENEWAL_SUCCESS_MODAL";
export const DEBIT_COINS_DETAILS = "DEBIT_COINS_DETAILS";
export const PAYMENT_STATUS_SUCCESS = "PAYMENT_STATUS_SUCCESS";
export const IS_ACCOUNT_DETAILS_FETCHED = "IS_ACCOUNT_DETAILS_FETCHED";
export const SHOW_ACTIVATION_PLANS_POPUP = "SHOW_ACTIVATION_PLANS_POPUP";
export const PAID_JOBS_COUNT = "PAID_JOBS_COUNT";
export const SHOW_COMPLIMENTARY_COINS_MESSAGE = "SHOW_COMPLIMENTARY_COINS_MESSAGE";
export const REFETCH_ACTIVATION_PLANS = "REFETCH_ACTIVATION_PLANS";
export const UPDATE_SELECTED_ACTIVATION_PLAN = "UPDATE_SELECTED_ACTIVATION_PLAN";
export const SHOW_APNA_PROMISE_MODAL = "SHOW_APNA_PROMISE_MODAL";
export const FREE_BANNER_JOBS = "FREE_BANNER_JOBS";
export const UPDATE_REFUND_DETAILS = "UPDATE_REFUND_DETAILS";
export const INVALID_RETRY_PAYMENT_MODAL = "INVALID_RETRY_PAYMENT_MODAL";
export const IS_FIRST_PURCHASE = "IS_FIRST_PURCHASE";
export const EXPIRED_FREE_JOBS_LIST = "EXPIRED_FREE_JOBS_LIST";
export const FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS";
export const IS_FREE_JOB_DETAILS_FETCHED = "IS_FREE_JOB_DETAILS_FETCHED";
export const MIN_BALANCE_FOR_TRANSFER = "MIN_BALANCE_FOR_TRANSFER";
export const JOB_POSTING_MODAL = "JOB_POSTING_MODAL";
