/* eslint-disable no-restricted-properties */

import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

/* eslint-disable no-param-reassign */
export const isMobile = () => {
	const isMobileView = navigator.userAgent
		.toLowerCase()
		.match(/android|blackBerry|iPhone|iPad|iPod|mini|iemobile|wpdesktop/i);
	return Boolean(isMobileView);
};

export const dateFormat = date => {
	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const todayDateFunction = new Date();
	const newDateFunction = new Date(date);
	const todayDate = todayDateFunction.getDate();
	const currentMonth = todayDateFunction.getMonth();
	const currentYear = todayDateFunction.getFullYear();
	const newDate = newDateFunction.getDate();
	const newMonth = newDateFunction.getMonth();
	const newYear = newDateFunction.getFullYear();
	let hours = newDateFunction.getHours();
	let minutes = newDateFunction.getMinutes();
	const ampm = hours >= 12 ? "pm" : "am";
	hours %= 12;
	hours = hours || 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? `0${minutes}` : minutes;
	if (todayDate === newDate && currentMonth === newMonth && currentYear === newYear) {
		const strTime = `${hours}:${minutes} ${ampm}`;
		return strTime;
	}
	if (newDate === todayDate - 1) {
		const strTime = "yesterday";
		return strTime;
	}
	const strTime = `${monthNames[newMonth]} ${newDate}`;
	return strTime;
};

export const sortBy = prop => {
	return function(a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		}
		if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	};
};

export const sortUtil = prop => {
	return function(a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		}
		if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	};
};

export const abbreviateNumber = function(num, fixed) {
	if (num === null) {
		return null;
	} // terminate early
	if (num === 0) {
		return "0";
	} // terminate early
	fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
	const b = num.toPrecision(2).split("e"); // get power
	const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3); // floor at decimals, ceiling at trillions
	const c =
		k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed); // divide by power
	const d = c < 0 ? c : Math.abs(c); // enforce -0 is 0
	const e = d + ["", "K", "M", "B", "T"][k]; // append power
	return e;
};

const logBase = (() => {
	const { log } = Math;
	return (n, base) => {
		return log(n) / (base ? log(base) : 1);
	};
})();
export const convertToMBs = byte => {
	if (byte === 0) {
		return "0 B";
	}
	const sizeName = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	const i = Math.floor(logBase(byte, 1024).toFixed(1));
	const p = 1024 ** i;
	const s = (byte / p).toFixed(1);
	const size = `${s} ${sizeName[i]}`;
	return size;
};

export const filterDuplicates = arr => {
	const result = arr.reduce((unique, o) => {
		if (!unique.some(obj => obj.id === o.id)) {
			unique.push(o);
		}
		return unique;
	}, []);
	return result;
};

export const timeAgo = timeCreated => {
	const date = new Date(timeCreated); // some mock date
	const milliseconds = date.getTime();
	const seconds = Math.floor((new Date() - milliseconds) / 1000);

	let interval = seconds / 31536000;

	if (interval > 1) {
		const time = Math.floor(interval);
		return time + (time > 1 ? " years ago" : " year ago");
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		const time = Math.floor(interval);
		return time + (time > 1 ? " months ago" : " month ago");
	}
	interval = seconds / 86400;
	if (interval > 1) {
		const time = Math.floor(interval);
		return time + (time > 1 ? " days ago" : " day ago");
	}
	interval = seconds / 3600;
	if (interval > 1) {
		const time = Math.floor(interval);
		return time + (time > 1 ? " hours ago" : " hour ago");
	}
	interval = seconds / 60;
	if (interval > 1) {
		const time = Math.floor(interval);
		return time + (time > 1 ? " minutes ago" : " minute ago");
	}
	return `${Math.floor(seconds)}now`;
};

export const getMessageDateString = timeStamp => {
	const date = dayjs(Number(timeStamp));
	const currentDate = dayjs();
	const diffDate = currentDate.diff(date, "d");
	let dateStr;
	if (diffDate < 1) {
		dateStr = "Today";
	} else if (diffDate === 1) {
		dateStr = "Yesterday";
	} else {
		dateStr = date.format("DD MMMM YYYY");
	}
	return dateStr;
};

export const getMessageDateTimeStamp = timeStamp => {
	let date = dayjs(timeStamp);
	// set time as 12:00AM
	date = date.hour(0);
	date = date.minute(0);
	date = date.second(0);
	date = date.millisecond(0);
	return date.valueOf();
};

export const getLastMessageDateString = timeStamp => {
	let dateStr = "";
	dayjs.extend(LocalizedFormat);
	if (timeStamp) {
		const date = dayjs(Number(timeStamp));
		const currentDate = dayjs();
		const diffDate = currentDate.diff(date, "d");
		const diffYear = currentDate.diff(date, "y");
		if (diffDate < 1) {
			dateStr = date.format("LT");
		}
		if (diffDate === 1) {
			dateStr = "Yesterday";
		}
		if (diffDate > 1) {
			dateStr = diffYear < 1 ? date.format("DD MMM") : date.format("DD MMMM YYYY");
		}
	}

	return dateStr;
};
