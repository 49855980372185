import {
	ECC_JOB_RENEW_POPUP,
	ECC_PEREFENCE_MODAL_SHOWN,
	REJECTING_LOADER,
} from "../constants/types";

const initialState = {
	firstTimePopupShown: false,
	showEccJobPostRenewPopup: false,
	showRejectingLoader: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ECC_PEREFENCE_MODAL_SHOWN:
			return {
				...state,
				firstTimePopupShown: true,
			};
		case ECC_JOB_RENEW_POPUP:
			return {
				...state,
				showEccJobPostRenewPopup: action.value,
			};
		case REJECTING_LOADER:
			return {
				...state,
				showRejectingLoader: action.value,
			};
		default:
			return state;
	}
};
