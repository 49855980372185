import styled, { css } from "styled-components";

const PrimaryButton = styled.button`
	cursor: pointer;
	padding: 10px 16px;
	min-width: 96px;
	text-align: center;
	background-color: #1f8268;
	border: none;
	outline: none;
	color: white;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	border-radius: 4px;

	${({ block }) =>
		block &&
		css`
			width: 100%;
		`}
	&:not(:disabled) {
		&:hover {
			background-color: #1f8268;
		}

		&:active {
			background-color: #186550;
		}
	}

	&:disabled {
		cursor: not-allowed;
		background-color: #dfe1e6;
		color: #b3bac5;
	}

	${props => props?.customStyles && props?.customStyles}
`;

export default PrimaryButton;
