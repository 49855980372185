/* eslint-disable */
const Clevertap = {
	initialize: function(accountId) {
		window.clevertap = {
			event: [],
			profile: [],
			account: [],
			onUserLogin: [],
			notifications: [],
			privacy: [],
		};

		window.clevertap.account.push({ id: accountId });
		(function() {
			var wzrk = document.createElement("script");
			wzrk.type = "text/javascript";
			wzrk.async = true;
			wzrk.src =
				("https:" == document.location.protocol
					? "https://d2r1yp2w7bby2u.cloudfront.net"
					: "http://static.clevertap.com") + "/js/a.js";
			var s = document.getElementsByTagName("script")[0];
			s.parentNode.insertBefore(wzrk, s);
		})();
	},

	trackEvent: function(name, payload) {
		if (payload) {
			const newPayload = {};
			for (const key of Object.keys(payload)) {
				newPayload[key] = Array.isArray(payload[key])
					? payload[key]?.join(", ")
					: payload[key];
			}
			window.clevertap.event.push(name, newPayload);
		} else {
			window.clevertap.event.push(name);
		}
	},

	profile: function(payload) {
		window.clevertap.profile.push(payload);
	},

	enableLogging: function() {
		window.sessionStorage["WZRK_D"] = " ";
	},

	onUserLogin: function(payload) {
		window.clevertap.onUserLogin.push(payload);
	},

	requestWebPushPermission: function() {
		window.clevertap.notifications.push({
			titleText: "Enable notifications to receive updates on your job post",
			bodyText: "We'll send you notifications when you receive new candidates!.",
			okButtonText: "Yes",
			rejectButtonText: "Not now",
			okButtonColor: "#2bb793",
			serviceWorkerPath: "./serviceworker.js",
		});
	},

	logout: function() {
		window.clevertap.logout();
	},
};

export default Clevertap;
