import {
	POST_CALL_CONNECT_STEP_ONE,
	SHOW_CONNECTED_ON_CALL,
	MOVED_TO_REJECTED,
	CALL_FAILED_STEP_ONE,
	SHOW_CONNECTED_ON_WHATSAPP,
	SHOW_CONNECTED_ON_MOBILE_CALL,
	CLOSE_MODAL,
	UPDATE_TOAST,
	SHOW_TOAST,
	CLEAR_TOAST,
} from "../constants/types";

const initialState = {
	showModal: false,
	candidateDetails: {},
	modal: null,
	jobId: null,
	tab: null,
	page: null,
	showToast: false,
	toastStatus: null,
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case POST_CALL_CONNECT_STEP_ONE:
			return {
				...state,
				showModal: true,
				candidateDetails: payload.user,
				modal: payload.modal,
				jobId: payload.jobId,
				tab: payload.tab,
				page: payload.page,
			};
		case SHOW_CONNECTED_ON_CALL:
			return {
				...state,
				showModal: true,
				candidateDetails: payload.user,
				modal: payload.modal,
				jobId: payload.jobId,
				tab: payload.tab,
				page: payload.page,
			};
		case MOVED_TO_REJECTED:
			return {
				...state,
				showModal: true,
				candidateDetails: payload.user,
				modal: payload.modal,
				jobId: payload.jobId,
				tab: payload.tab,
				page: payload.page,
			};
		case CALL_FAILED_STEP_ONE:
			return {
				...state,
				showModal: true,
				candidateDetails: payload.user,
				modal: payload.modal,
				jobId: payload.jobId,
				tab: payload.tab,
				page: payload.page,
			};
		case SHOW_CONNECTED_ON_WHATSAPP:
			return {
				...state,
				showModal: true,
				candidateDetails: payload.user,
				modal: payload.modal,
				jobId: payload.jobId,
				tab: payload.tab,
				page: payload.page,
			};
		case SHOW_CONNECTED_ON_MOBILE_CALL:
			return {
				...state,
				showModal: true,
				candidateDetails: payload.user,
				modal: payload.modal,
				jobId: payload.jobId,
				tab: payload.tab,
				page: payload.page,
			};
		case CLOSE_MODAL:
			return {
				showModal: false,
				candidateDetails: {},
				modal: null,
				jobId: null,
				tab: null,
				page: null,
			};
		case UPDATE_TOAST:
			return {
				showModal: false,
				candidateDetails: payload.user,
				modal: payload.modal,
				jobId: payload.jobId,
				tab: payload.tab,
				page: payload.page,
				showToast: payload.showToast,
				toastStatus: payload.toastStatus,
			};
		case SHOW_TOAST:
			return {
				...state,
				showToast: true,
				toastStatus: payload.toastStatus,
			};
		case CLEAR_TOAST:
			return {
				...state,
				showToast: false,
				toastStatus: null,
			};
		default:
			return state;
	}
};
