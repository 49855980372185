import React from "react";
import styled from "styled-components";
import { CloseIcon, JobUnderReviewIcon } from "../../../assets/svg";

const JobModalCommonContainer = ({
	children,
	headerText,
	onCloseClick,
	iconColor = "#FC9F5B",
	iconSize = 60,
}) => {
	return (
		<JobModalContentContainer>
			<CloseButton onClick={onCloseClick} />
			<HeadIcon size={iconSize} color={iconColor} />
			<Heading>{headerText}</Heading>
			{children}
		</JobModalContentContainer>
	);
};

const JobModalContentContainer = styled.div`
	position: relative;
`;
const CloseButton = styled(CloseIcon)`
	cursor: pointer;
	position: absolute;
	top: -10px;
	right: -10px;
`;
const HeadIcon = styled(JobUnderReviewIcon)`
	display: block;
	margin: 0 auto 8px;
`;

const Heading = styled.div`
	font-weight: bold;
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	color: #31445a;
`;

export default JobModalCommonContainer;
