/* eslint-disable no-param-reassign */
import httpClient from "axios";
import config from "../../../config";
import { updateUrl } from "../../../utils/apiClients";
import CommonUtils from "../../../utils/commonUtils";
import { VERIFICATION_STATUSES, VERIFICATION_STEPS } from "../../../utils/constants";
import {
	CONTRACT_UPLOAD,
	RESEND_EMAIL,
	RESEND_EMAIL_V2,
	TRIGGER_CLIENT_EMAIL_VERIFICATION,
	UPDATE_JOB_ORG,
	LINK_COMPANY_AND_CERTIFICATE_CARD,
	VERIFICATION_DOC_UPLOAD,
	VERIFY_COMPANY_CARD_DETAILS,
	VERIFY_EMAIL,
	VERIFY_CLIENT_EMAIL,
	VERIFY_CREATOR_EMAIL,
	VERIFY_OTP_AADHAR_NUMBER,
	CONSENT_API,
	VERIFICATION_DETAILS_API,
	TOGGLE_POC_UPLOAD,
	VERIFY_KYC_DOC,
	VERIFY_CONSULTANT_MANDATE,
	GET_REQUEST_ID_EKYC,
	GET_MOBILE_NUMBER_EKYC,
	UPDATE_CV_STATUS,
	VERIFICATION_LATEST_DETAILS_API,
	EDIT_EMAIL_API,
	GET_KYC_DETAILS,
	VERIFY_OTP_FROM_APNA_VERIFICATION_VERSION,
	TRIGGER_OTP_EKYC_VERSION,
} from "../constants/apiUrls";

const apiClient = httpClient.create({
	baseURL: config.baseUrl,
});

apiClient.interceptors.request.use(con => {
	const TOKEN = CommonUtils.getItemFromLocalStorage("__token__");
	if (TOKEN) con.headers.Authorization = `Token ${TOKEN}`;
	con.headers["Content-Type"] = "application/json;charset=UTF-8";
	return con;
});

export const verifyEmail = (jobId, email, additionalProps) => {
	const data = new FormData();
	data.append("email", email);
	if (additionalProps) {
		const { alternateEmailOption, alternateEmailSkipReason } = additionalProps;
		data.append("alternate_email_option", alternateEmailOption);
		data.append("alternate_email_skip_reason", alternateEmailSkipReason);
	}
	return apiClient.post(updateUrl(VERIFY_EMAIL, { job_id: jobId }), data);
};

export const resendEmail = jobId => {
	return apiClient.post(updateUrl(RESEND_EMAIL, { job_id: jobId }), {});
};
export const resendEmailV2 = jobId => {
	return apiClient.post(updateUrl(RESEND_EMAIL_V2, { job_id: jobId }), {});
};

export const updateJobOrg = (jobId, data) => {
	return apiClient.post(updateUrl(UPDATE_JOB_ORG, { job_id: jobId }), data);
};

// Client verification APIs

export const clientEmailVerification = (jobId, email) => {
	return apiClient.post(
		updateUrl(TRIGGER_CLIENT_EMAIL_VERIFICATION, { job_id: jobId }),
		{ email }
	);
};

export const verifyClientEmail = token => {
	// COT
	return apiClient.put(
		updateUrl(VERIFY_CLIENT_EMAIL, { verification_token: token }),
		{}
	);
};

export const verifyCreatorEmail = params => {
	return apiClient.get(VERIFY_CREATOR_EMAIL + params);
};

export const contractUpload = (jobId, file) => {
	const data = new FormData();
	data.append("file", file);
	return apiClient.post(updateUrl(CONTRACT_UPLOAD, { job_id: jobId }), data);
};

export const postVerifyCompanyVerificationEnteredCardDetails = ({ payload = {} }) => {
	return apiClient.post(VERIFY_COMPANY_CARD_DETAILS, payload);
};

export const linkCeritifcateCardAndCompany = ({ payload = {} }) => {
	return apiClient.post(LINK_COMPANY_AND_CERTIFICATE_CARD, payload);
};

export const uploadVerificationDocument = ({
	file = "",
	orgId = "",
	docNumber = "",
	docType = "",
	userId = "",
	isInternal = false,
	legalName = "",
	city = "",
}) => {
	const data = new FormData();
	data.append("organization_id", orgId);
	data.append("doc_number", docNumber);
	data.append("type", docType);

	data.append("file", file);
	if (isInternal) {
		data.append("legal_name", legalName);
		data.append("city", city);
	}
	return apiClient.post(updateUrl(VERIFICATION_DOC_UPLOAD, { user_id: userId }), data);
};

export const verificationDetailsAPI = ({ jobId, callLatest = false }) => {
	let URL;
	if (callLatest) URL = `${VERIFICATION_LATEST_DETAILS_API.replace("%JOB_ID%", jobId)}`;
	else URL = `${VERIFICATION_DETAILS_API.replace("%JOB_ID%", jobId)}`;
	return apiClient.get(URL);
};

export const skipEmailVerification = jobId => {
	const data = new FormData();
	data.append("skip_email", true);
	return apiClient.post(updateUrl(VERIFY_EMAIL, { job_id: jobId }), data);
};

export const togglePocUpload = ({ jobId, data }) => {
	return apiClient.post(updateUrl(TOGGLE_POC_UPLOAD, { job_id: jobId }), data);
};

export const verifyAndMarkCompanyVerificationDone = ({ jobId, data = {} }) => {
	return apiClient.put(updateUrl(UPDATE_CV_STATUS, { job_id: jobId }), data);
};

export const verifyKycDoc = ({ jobId, data }) => {
	return apiClient.post(updateUrl(VERIFY_KYC_DOC, { job_id: jobId }), data);
};

export const getEmailVerificationStatus = async jobId => {
	try {
		const res = await verificationDetailsAPI({ jobId, callLatest: true });
		const steps = res?.data?.verification_steps || [];

		const emailStep = steps.find(
			({ step }) => step === VERIFICATION_STEPS.EMAIL_VERIFICATION
		);

		if (emailStep) {
			return (
				emailStep.status === VERIFICATION_STATUSES.COMPLETED ||
				emailStep.status === VERIFICATION_STATUSES.APPROVED
			);
		}

		return false;
	} catch (e) {
		return false;
	}
};

export const verifyConsultantMandate = ({ jobId, data }) => {
	return apiClient.put(updateUrl(VERIFY_CONSULTANT_MANDATE, { job_id: jobId }), data);
};

export const requestIDForEKYC = () => {
	const URL = `${GET_REQUEST_ID_EKYC}`;
	return apiClient.get(URL);
};
export const triggerOTPForEKYC = (data, apiVersion = 2) => {
	const URL = `${TRIGGER_OTP_EKYC_VERSION.replace("%VERSION%", apiVersion)}`;
	return apiClient.post(URL, data);
};
export const mobileNumberForEKYC = data => {
	const URL = `${GET_MOBILE_NUMBER_EKYC}`;
	return apiClient.post(URL, data);
};

export const verifyOTPforAadharNumber = data => {
	const URL = `${VERIFY_OTP_AADHAR_NUMBER}`;
	return apiClient.post(URL, data);
};
export const verifyOTPforAadhaarNumberAsync = ({ payload = {}, apiVersion = 2 }) => {
	const URL = `${VERIFY_OTP_FROM_APNA_VERIFICATION_VERSION.replace(
		"%VERSION%",
		apiVersion
	)}`;
	return apiClient.post(URL, payload);
};
export const updateConsentAPI = ({ jobId, payload = {} }) => {
	const URL = `${CONSENT_API.replace("%JOB_ID%", jobId)}`;
	return apiClient.post(URL, payload);
};

export const verifyCreatorEmailFromEmailPage = params => {
	const baseUrlTemp = config.baseUrl || "https://backend-stg.apna.co";
	return httpClient.get(baseUrlTemp + VERIFY_CREATOR_EMAIL + params);
};

export const verifyClientEmailFromEmailPage = token => {
	// COT
	const baseUrlTemp = config.baseUrl || "https://backend-stg.apna.co";

	return httpClient.put(
		updateUrl(baseUrlTemp + VERIFY_CLIENT_EMAIL, { verification_token: token }),
		{}
	);
};

export const editCreatorEmail = ({ jobId, payload }) => {
	const URL = `${EDIT_EMAIL_API.replace("%JOB_ID%", jobId)}`;
	return apiClient.put(URL, payload);
};

export const getKycDetails = data => {
	const URL = `${GET_KYC_DETAILS}`;
	return apiClient.post(URL, data);
};
export default apiClient;
