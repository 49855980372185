import * as React from "react";

function SvgComponent() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={18}
			viewBox="0 0 20 18"
			fill="none"
		>
			<path
				d="M18.75 2.75h-1.666v7.917a1.667 1.667 0 01-1.667 1.666h-5.016l-2.917 2.084h3.225l4.895 3.263a.417.417 0 00.647-.347v-2.916h2.5a.833.833 0 00.833-.834v-10a.833.833 0 00-.833-.833z"
				fill="#4D3951"
			/>
			<path
				d="M15.417.25H1.25a.833.833 0 00-.833.833v9.584a.833.833 0 00.833.833h2.5v3.75a.417.417 0 00.66.34l5.724-4.09h5.283a.833.833 0 00.833-.833V1.083a.833.833 0 00-.833-.833z"
				fill="#4D3951"
			/>
		</svg>
	);
}

export default SvgComponent;
