/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { JobUnderReviewIcon, CloseIcon } from "../../assets/svg";
import Button from "../../components/Buttons/Button";
import { editForm, toggleHrNumberVerificationModal } from "../../actions";
import Mixpanel from "../../utils/Mixpanel";

const JobHrNumVerificationModal = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const hrNumber = useSelector(state => state.modal.hrNumber.number);
	const jobId = useSelector(state => state.modal.hrNumber.jobId);

	const goToHome = () => {
		dispatch(toggleHrNumberVerificationModal());
		if (window.innerWidth < 998) {
			history.push("/dashboard");
		}
	};

	const postNewJob = () => {
		Mixpanel.track("New job click job submitted overlay", {
			"Job ID": jobId,
			"HR Number": hrNumber,
			verified: false, // this is modal is only visible if hr is not verified
		});
		dispatch(toggleHrNumberVerificationModal());
		dispatch(
			editForm({
				showForm: true,
				editFormData: {},
			})
		);
		history.push("/post-job");
	};

	return (
		<Container>
			<Wrapper>
				<CloseButtonContainer>
					<CloseButton onClick={goToHome}>
						<CloseIcon />
					</CloseButton>
				</CloseButtonContainer>
				<JobUnderReviewIcon />
				<Text>
					<strong>Your job is under-review</strong>
					<br />
					<br />
					For quicker approval, verify HR Phone Number
					<strong>
						&nbsp;
						{hrNumber}
						&nbsp;
					</strong>
					by clicking on the link received on whatsapp/SMS
				</Text>
				<ButtonGroup>
					<MainCTAButton onClick={goToHome}>
						{window.innerWidth > 998 ? "OK" : "Go to Home"}
					</MainCTAButton>
				</ButtonGroup>

				<PostNewJobCTA onClick={postNewJob}>Post a new job</PostNewJobCTA>
			</Wrapper>
		</Container>
	);
};

// Styles
const Container = styled.div``;
const Wrapper = styled.div`
	text-align: center;
`;
const CloseButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-right: -15px;
`;
const CloseButton = styled.div`
	cursor: pointer;
`;
const Text = styled.p`
	font-size: 16px;
	line-height: 1.38;
	text-align: center;
	color: #6a6a6a;
	margin: 20px 0 28px 0;
`;

const ButtonGroup = styled.div``;

const MainCTAButton = styled(Button)`
	min-width: 160px;
	height: 48px;
	margin-bottom: 20px;
`;

const PostNewJobCTA = styled.span`
	color: #2bb793;
	font-style: bold;
	font-size: 13px;
	cursor: pointer;
`;

export default JobHrNumVerificationModal;
