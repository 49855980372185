/* eslint-disable import/prefer-default-export */
/* eslint-disable prettier/prettier */
export const SHOW_UPLOAD_DOC = "SHOW_UPLOAD_DOC";
export const HIDE_UPLOAD_DOC = "HIDE_UPLOAD_DOC";
export const TOGGLE_HR_NUM_VERIFICATION_MODAL = "TOGGLE_HR_NUM_VERIFICATION_MODAL";
export const TOGGLE_EDIT_WARNING_MODAL = "TOGGLE_EDIT_WARNING_MODAL";
export const SHOW_JOB_VERIFICATION_MODAL = "SHOW_JOB_VERIFICATION_MODAL";
export const HIDE_JOB_VERIFICATION_MODAL = "HIDE_JOB_VERIFICATION_MODAL";
export const SHOW_POC_VERIFICATION_OPTIONS_MODAL = "SHOW_POC_VERIFICATION_OPTIONS_MODAL";
export const HIDE_POC_VERIFICATION_OPTIONS_MODAL = "HIDE_POC_VERIFICATION_OPTIONS_MODAL";
export const SHOW_POC_DOC_UPLOAD_MODAL = "SHOW_POC_DOC_UPLOAD_MODAL";
export const HIDE_POC_DOC_UPLOAD_MODAL = "HIDE_POC_DOC_UPLOAD_MODAL";
export const TOGGLE_KNOW_MORE_MODAL = "TOGGLE_KNOW_MORE_MODAL";
export const SHOW_DELETE_JOB_MODAL = "SHOW_DELETE_JOB_MODAL";
export const HIDE_DELETE_JOB_MODAL = "HIDE_DELETE_JOB_MODAL";
export const TOGGLE_CSV_DOWNLOAD_POPUP = "TOGGLE_CSV_DOWNLOAD_POPUP";