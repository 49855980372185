import React from "react";
import PropTypes from "prop-types";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	root: {
		backgroundColor: "#f5f7fb",
		color: "#31445a",
	},
	filledSuccess: {
		color: "#2BB792",
	},
	filledError: {
		color: "#FC9F5B",
	},
	filledInfo: {
		color: "#31445A",
	},
});

const Notification = ({
	open,
	type,
	duration,
	onClose,
	message,
	verticalPosition,
	horizontalPosition,
}) => {
	const classes = useStyles();
	return (
		<Snackbar
			anchorOrigin={{
				vertical: verticalPosition || "top",
				horizontal: horizontalPosition || "right",
			}}
			open={open}
			autoHideDuration={duration}
			onClose={onClose}
		>
			<MuiAlert
				elevation={6}
				variant="filled"
				onClose={onClose}
				severity={type}
				color={type || "success"}
				classes={classes}
			>
				{message}
			</MuiAlert>
		</Snackbar>
	);
};

Notification.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	duration: PropTypes.number,
	type: PropTypes.oneOf(["success", "warning", "info", "error"]),
	message: PropTypes.string.isRequired,
};

Notification.defaultProps = {
	duration: 5000,
	type: "success",
};

export default Notification;
