import { OPEN_LOADER, CLOSE_LOADER } from "../constants/LoaderTypes";

const initialState = {
	show: false,
};

const loader = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_LOADER:
			return {
				show: true,
			};
		case CLOSE_LOADER:
			return {
				show: false,
			};
		default:
			return state;
	}
};

export default loader;
