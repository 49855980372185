import { FETCHED_LEAD_PROFILES, SET_LOADING_PROFILES } from "../constants/types";

const initialState = { loadingProfiles: false };

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case FETCHED_LEAD_PROFILES:
			return { ...state, ...payload };
		case SET_LOADING_PROFILES:
			return { ...state, loadingProfiles: payload };
		default:
			return state;
	}
};
