import { jobsActionTypes } from "../constants/types";

const initialState = {
	jobs: {},
	jobOrder: [],
	jobCount: 0,
	isNewUser: false,
	noOfDaysFromLastJob: 0,
	isLoading: false,
	errorMessage: "",
	selectedJobId: null,
	showCreatorRecruiterModalData: {
		show: false,
		jobId: null,
	},
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case jobsActionTypes.startJobsLoading:
			return { ...state, isLoading: true };
		case jobsActionTypes.stopJobsLoading:
			return { ...state, isLoading: false };
		case jobsActionTypes.clearJobsError:
			return { ...state, isLoading: false, errorMessage: "" };
		case jobsActionTypes.setJobsError: {
			const { errorMessage } = payload;
			return { ...state, isLoading: false, errorMessage };
		}
		case jobsActionTypes.setJobCount: {
			const { jobCount } = payload;
			return { ...state, jobCount };
		}
		case jobsActionTypes.setNoOfDaysFromLastJob: {
			const { noOfDays } = payload;
			return { ...state, noOfDaysFromLastJob: noOfDays };
		}
		case jobsActionTypes.setIsNewUserOnBoarding: {
			const { isNewUser } = payload;
			return { ...state, noOfDaysFromLastJob: isNewUser };
		}
		case jobsActionTypes.setJobs: {
			const { jobs, jobOrder } = payload;
			return { ...state, jobs, jobOrder };
		}
		case jobsActionTypes.updateJob: {
			const { jobData } = payload;
			return { ...state, jobs: { ...state.jobs, [jobData?.id]: jobData } };
		}
		case jobsActionTypes.setSelectedJob: {
			const { jobId } = payload;
			return { ...state, selectedJobId: jobId };
		}
		case jobsActionTypes.updateATSFunnelSteps: {
			const { updatedFunnelData, selectedJobId } = payload;
			return {
				...state,
				jobs: {
					[selectedJobId]: {
						...state.jobs[selectedJobId],
						ats_template: updatedFunnelData,
					},
				},
			};
		}
		case jobsActionTypes.addATSFunnelStep: {
			const { funnelId, selectedJobId } = payload;
			let newFunnelList = [...state.jobs[selectedJobId].ats_template.funnel_list];

			newFunnelList = newFunnelList.map(val => {
				if (val.order_id <= funnelId) return val;
				return { ...val, order_id: val.order_id + 1 };
			});

			newFunnelList.push({
				order_id: funnelId + 1,
			});

			newFunnelList.sort((a, b) => a.order_id - b.order_id);
			return {
				...state,
				jobs: {
					[selectedJobId]: {
						...state.jobs[selectedJobId],
						ats_template: {
							...state.jobs[selectedJobId].ats_template,
							funnel_list: newFunnelList,
						},
					},
				},
			};
		}
		case jobsActionTypes.showCreatorRecruiterModal: {
			const { value } = payload;
			return {
				...state,
				showCreatorRecruiterModalData: value,
			};
		}

		default:
			return state;
	}
};
