// job application tab count

export const INIT_JOB_APPLICATIONS_TAB_COUNT = "INIT_JOB_APPLICATIONS_TAB_COUNT";
export const INIT_JOB_APPLICATION_TAB = "INIT_JOB_APPLICATION_TAB";
export const UPDATE_JOB_APPLICATION_TAB = "UPDATE_JOB_APPLICATION_TAB";
export const INCREASE_TAB_COUNT = "INCREASE_TAB_COUNT";
export const DECREASE_TAB_COUNT = "DECREASE_TAB_COUNT";
export const UPDATE_TAB_COUNT = "UPDATE_TAB_COUNT";
export const RESET_JOB_APPLICATIONS_TAB_COUNT = "RESET_JOB_APPLICATIONS_TAB_COUNT";
export const UPDATE_JOB_APPLICATIONS_LOADER = "UPDATE_JOB_APPLICATIONS_LOADER";
export const UPDATE_JOB_APPLICATIONS_LIST = "UPDATE_JOB_APPLICATIONS_LIST";
export const RESET_JOB_APPLICATIONS = "RESET_JOB_APPLICATIONS";
export const FETCHED_SUGGESTED_CANDIDATES = "FETCHED_SUGGESTED_CANDIDATES";
export const UPDATE_CANDIDATE_APPLICATION = "UPDATE_CANDIDATE_APPLICATION";

// number masking call
export const CALL_INITIATED = "CALL_INITIATED";
export const CALL_FAILED = "CALL_FAILED";
export const CALL_ENDED = "CALL_ENDED";
export const CALL_DISCONNECTED = "CALL_DISCONNECTED";
export const RESET_CALL = "RESET_CALL";
export const CALL_ID_UPDATED = "CALL_ID_UPDATED";

// show modal
export const SET_SHOW_MODAL = "SET_SHOW_MODAL";
export const POST_CALL_CONNECT_STEP_ONE = "POST_CALL_CONNECT_STEP_ONE";
export const SHOW_CONNECTED_ON_CALL = "SHOW_CONNECTED_ON_CALL";
export const MOVED_TO_REJECTED = "MOVED_TO_REJECTED";
export const CALL_FAILED_STEP_ONE = "CALL_FAILED_STEP_ONE";
export const SHOW_CONNECTED_ON_WHATSAPP = "SHOW_CONNECTED_ON_WHATSAPP";
export const SHOW_CONNECTED_ON_MOBILE_CALL = "SHOW_CONNECTED_ON_MOBILE_CALL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const UPDATE_TOAST = "UPDATE_TOAST";

// defered job applications
export const INIT_DEFERED_STATE = "INIT_DEFERED_STATE";
export const CLEAR_DEFERED_STATE = "CLEAR_DEFERED_STATE";
export const INIT_DEFERED_TOAST = "INIT_DEFERED_TOAST";
export const CLEAR_DEFERED_TOAST = "CLEAR_DEFERED_TOAST";
export const SHOW_TOAST = "SHOW_TOAST";
export const CLEAR_TOAST = "CLEAR_TOAST";

// true ecc preference
export const ECC_PEREFENCE_MODAL_SHOWN = "ECC_PEREFENCE_MODAL_SHOWN";
export const ECC_JOB_RENEW_POPUP = "ECC_JOB_RENEW_POPUP";

export const REJECTING_LOADER = "REJECTING_LOADER";

// stale jobs status
export const UPDATE_JOB_STALE_STATUS = "UPDATE_JOB_STALE_STATUS";

// recently updated candidates
export const UPDATE_RECENT_CANDIDATE_QUEUE = "UPDATE_RECENT_CANDIDATE_QUEUE";
export const RESET_RECENT_CANDIDATE_QUEUE = "RESET_RECENT_CANDIDATE_QUEUE";

// wa mini redirect - open extended profile
export const FETCHED_APPLICANT_PROFILE = "FETCHED_APPLICANT_PROFILE";
export const RESET_APPLICANT_PROFILE = "RESET_APPLICANT_PROFILE";

// Education Flow
export const SET_TAB_AWARENESS_MODE = "SET_TAB_AWARENESS_MODE";
export const UPDATE_CURRENT_STAGE = "UPDATE_CURRENT_STAGE";
export const TAB_AWARENESS_FLOW_SHOWN = "TAB_AWARENESS_FLOW_SHOWN";
