import React from "react";

const BriefcaseAltIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={31}
			height={28}
			viewBox="0 0 31 28"
			fill="none"
		>
			<path
				d="M21.125 1.8125C21.125 1.25 20.75 0.875 20.1875 0.875H10.8125C10.25 0.875 9.875 1.25 9.875 1.8125V5.5625H8V27.125H23V5.5625H21.125V1.8125ZM11.75 2.75H19.25V5.5625H11.75V2.75Z"
				fill="#4D3951"
			/>
			<path
				d="M0.5 6.5V26.1875C0.5 26.75 0.875 27.125 1.4375 27.125H5.1875V5.5625H1.4375C0.875 5.5625 0.5 5.9375 0.5 6.5Z"
				fill="#4D3951"
			/>
			<path
				d="M29.5625 5.5625H25.8125V27.125H29.5625C30.125 27.125 30.5 26.75 30.5 26.1875V6.5C30.5 5.9375 30.125 5.5625 29.5625 5.5625Z"
				fill="#4D3951"
			/>
		</svg>
	);
};

export default BriefcaseAltIcon;
