/* eslint-disable no-case-declarations */
import {
	SELECTED_JOB,
	SHOW_UNDER_REVIEW_MODAL,
	JOB_COUNT_FROM_JOBS_LIST,
	NO_OF_DAYS_FROM_LAST_JOB,
	SHOW_FEEDBACK_FORM,
	HIDE_FEEDBACK_FORM,
	CHANGE_FEEDBACK,
	CHANGE_APPLICATION_STATUS,
	CHANGE_FEEDBACK_UPDATE,
	UPDATE_JOB_APPLICATION,
	UPDATE_JOB_APPLICATION_LIST,
	JOB_APPLICATION_COUNT,
	JOB_APPLICATION_PAGE_CHANGE,
	JOB_MATCHED_APPLICATION_UPDATE,
	UPDATE_SELECTED_JOB_HR_NUM,
	SET_ALL_ATS_FUNNEL_STEPS,
	SET_ALL_ATS_FUNNEL_VERBOSE_STEPS,
	POST_NEW_JOB_EXPIRE_OLD_JOB,
	CLEAR_FORM,
	SET_CITY_AREAS_FILTER,
	SET_SELECTED_AREAS,
	SET_SELECTED_JOB_STATUS,
	SET_TOTAL_FILTERED_JOBS,
	SET_FILTERED_JOBS,
	SET_FILTERS_APPLIED,
	SET_FILTERS_PRESENT,
	SET_TOTAL_CITIES,
	SET_TOTAL_AREAS,
	SET_JOB_STATUS_COUNT,
	UPDATE_SELECTED_JOB_EMAIL_VERIFIED,
	UPDATE_SELECTED_JOB_OWNER_DATA,
	UPDATE_FILTERED_JOBS,
	SET_EXPERIENCE_TIGHTENING_CONFIG,
	SET_SELECTED_PREMIUM_FILTERS,
	UPDATE_CURR_CREATOR_ORG_STATUS,
	UPDATE_IS_EMPLOYER_KALEYRA_CALL_REQUESTED,
	REMOVE_JOB_FROM_STORE,
	SET_VERIFICATION_DETAILS,
	FETCH_AND_SET_VERIFICATION_DETAILS,
	SET_SELECTED_CATEGORY_ID_IN_JOB_POST_FORM,
} from "../constants/FormTypes";
import { UPDATE_EMPLOYER_TYPE } from "../constants";
import { ACTIVATION_WARNING_MODAL_STATES, JOB_FILTER_STATUS } from "../utils/constants";

import { SHOW_UPLOAD_DOC, HIDE_UPLOAD_DOC } from "../constants/ModalTypes";
import { createATSFunnelVerboseMap } from "../ecc/utils/helpers";
import {
	SET_IN_CALL_JOB_DETAILS,
	SET_IN_CALL_POLL_COUNTS,
	SHOW_UR_JOB_ACTIVATION_WARNING_MODAL,
} from "../constants/InternalUserTypes";

const initialState = {
	selectedJobDetails: {},
	showUnderReviewModal: false,
	totalJobsCount: 0,
	noOfDaysFromLastJob: 0,
	allATSFunnelSteps: [],
	allATSFunnelVerbose: {},
	editWarning: {
		newJob: false,
		jobIdToExpire: null,
	},
	cityAreasLocation: [],
	selectedAreas: {},
	selectedJobStatus: JOB_FILTER_STATUS[0]?.value,
	filteredJobCount: 0,
	filteredJobsList: [],
	filtersApplied: false,
	filtersPresent: false,
	totalFilteredAreas: 0,
	totalFilteredCities: 0,
	jobStatusCount: {},
	selectedPremiumFilters: {},
	selectedJobInCallDetails: {},
	jobInCallPollCount: 0,
	showWFHOption: true,
	isHelpAlreadyRequested: false,
	showActivationWarningModal: ACTIVATION_WARNING_MODAL_STATES.CLOSE,
	verificationDetails: {},
	structuredVerificationDetails: {},
	experienceTighteningConfig: {},
	selectedCategoryIdPostForm: "",
};

const jobData = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_EMPLOYER_TYPE:
			return {
				...state,
				selectedJobDetails: {
					...state.selectedJobDetails,
					created_by: {
						...state.selectedJobDetails.created_by,
						lead_type: action.payload,
					},
				},
			};
		case JOB_APPLICATION_COUNT:
			return {
				...state,
				selectedJobDetails: {
					...state.selectedJobDetails,
					applicants_count: action.count,
				},
			};
		case JOB_MATCHED_APPLICATION_UPDATE:
			return {
				...state,
				selectedJobDetails: {
					...state.selectedJobDetails,
					matched_application_data: action.data,
				},
			};
		case JOB_APPLICATION_PAGE_CHANGE:
			return {
				...state,
				selectedJobDetails: {
					...state.selectedJobDetails,
					currentPageNo: action.page,
				},
			};
		case SELECTED_JOB:
			if (state.selectedJobDetails.id === action.value.id) {
				return {
					...state,
					selectedJobDetails: {
						...state.selectedJobDetails,
						...action.value,
					},
				};
			}

			return {
				...state,
				selectedJobDetails: action.value,
				showActivationWarningModal: ACTIVATION_WARNING_MODAL_STATES.CLOSE,
			};
		case UPDATE_SELECTED_JOB_OWNER_DATA:
			return {
				...state,
				selectedJobDetails: {
					...state.selectedJobDetails,
					created_by: {
						...state.selectedJobDetails?.created_by,
						...action.value,
					},
				},
			};
		case SHOW_UNDER_REVIEW_MODAL:
			return {
				...state,
				showUnderReviewModal: action.value,
			};
		case SHOW_UPLOAD_DOC:
			return {
				...state,
				showUnderReviewModal: true,
			};
		case HIDE_UPLOAD_DOC:
			return {
				...state,
				showUnderReviewModal: false,
			};
		case JOB_COUNT_FROM_JOBS_LIST:
			return {
				...state,
				totalJobsCount: action.value,
			};
		case NO_OF_DAYS_FROM_LAST_JOB:
			return {
				...state,
				noOfDaysFromLastJob: action.value,
			};
		case SHOW_FEEDBACK_FORM:
			return {
				...state,
				selectedJobDetails: {
					...state.selectedJobDetails,
					applications: {
						...state.selectedJobDetails?.applications,
						[action.page]: {
							...state.selectedJobDetails.applications[action.page],
							[action.payload]: {
								...state.selectedJobDetails?.applications?.[
									action.page
								]?.[action.payload],
								show: true,
							},
						},
					},
				},
			};
		case HIDE_FEEDBACK_FORM:
			return {
				...state,
				selectedJobDetails: {
					...state.selectedJobDetails,
					applications: {
						...state.selectedJobDetails?.applications,
						[action.page]: {
							...state.selectedJobDetails.applications[action.page],
							[action.payload]: {
								...state.selectedJobDetails?.applications?.[
									action.page
								]?.[action.payload],
								show: false,
							},
						},
					},
				},
			};
		case CHANGE_FEEDBACK:
			return {
				...state,
				selectedJobDetails: {
					...state.selectedJobDetails,
					applications: {
						...state.selectedJobDetails?.applications,
						[action.page]: {
							...state.selectedJobDetails?.applications?.[action.page],
							[action.payload]: {
								...state.selectedJobDetails?.applications?.[
									action.page
								]?.[action.payload],
								employer_feedback: action.feedback,
							},
						},
					},
				},
			};
		case CHANGE_APPLICATION_STATUS:
			return {
				...state,
				selectedJobDetails: {
					...state.selectedJobDetails,
					applications: {
						...state.selectedJobDetails?.applications,
						[action.page]: {
							...state.selectedJobDetails.applications[action.page],
							[action.payload]: {
								...state.selectedJobDetails?.applications?.[
									action.page
								]?.[action.payload],
								hiring_state: action.status.value,
							},
						},
					},
				},
			};
		case CHANGE_FEEDBACK_UPDATE:
			return {
				...state,
				selectedJobDetails: {
					...state.selectedJobDetails,
					applications: {
						...state.selectedJobDetails?.applications,
						[action.page]: {
							...state.selectedJobDetails.applications[action.page],
							[action.payload]: {
								...state.selectedJobDetails?.applications?.[
									action.page
								]?.[action.payload],
								edit: true,
							},
						},
					},
				},
			};
		case UPDATE_JOB_APPLICATION:
			return {
				...state,
				selectedJobDetails: {
					...state.selectedJobDetails,
					applications: {
						...state.selectedJobDetails?.applications,
						[action.payload]: {
							...state.selectedJobDetails?.applications?.[action.payload],
							...action,
						},
					},
				},
			};
		case UPDATE_JOB_APPLICATION_LIST:
			return {
				...state,
				selectedJobDetails: {
					...state.selectedJobDetails,
					applications: {
						...state.selectedJobDetails.applications,
						[action.pageNo]: { ...action.data },
					},
				},
			};
		case UPDATE_SELECTED_JOB_HR_NUM:
			if (state?.selectedJobDetails?.id === action?.jobId) {
				return {
					...state,
					selectedJobDetails: {
						...state?.selectedJobDetails,
						phone_number_details: {
							...state?.selectedJobDetails?.phone_number_details,
							hr: action?.hr
								? action?.hr
								: state?.selectedJobDetails?.phone_number_details?.hr,
							employer: action?.employer
								? action?.employer
								: state?.selectedJobDetails?.phone_number_details
										?.employer,
						},
					},
				};
			}
			return state;

		case UPDATE_SELECTED_JOB_EMAIL_VERIFIED:
			if (state?.selectedJobDetails?.id === action?.jobId) {
				return {
					...state,
					selectedJobDetails: {
						...state.selectedJobDetails,
						email_verification_details: {
							...state.selectedJobDetails?.email_verification_details,
							is_verified: true,
						},
					},
				};
			}
			return state;
		case SET_ALL_ATS_FUNNEL_VERBOSE_STEPS:
			return {
				...state,
				allATSFunnelVerbose: createATSFunnelVerboseMap(action.data || []),
			};
		case SET_ALL_ATS_FUNNEL_STEPS:
			return {
				...state,
				allATSFunnelSteps: action.data,
			};
		case POST_NEW_JOB_EXPIRE_OLD_JOB:
			return {
				...state,
				editWarning: {
					newJob: action?.newJob,
					jobIdToExpire: action?.jobId,
				},
			};
		case CLEAR_FORM:
			return {
				...state,
				editWarning: {
					newJob: false,
					jobIdToExpire: null,
				},
			};
		case SET_CITY_AREAS_FILTER:
			return {
				...state,
				cityAreasLocation: action.data,
			};
		case SET_SELECTED_AREAS:
			return {
				...state,
				selectedAreas: action.data,
			};
		case SET_SELECTED_JOB_STATUS:
			return {
				...state,
				selectedJobStatus: action.data,
			};
		case SET_TOTAL_FILTERED_JOBS:
			return {
				...state,
				filteredJobCount: action.data,
			};
		case SET_FILTERED_JOBS:
			return {
				...state,
				filteredJobsList: action.data,
			};
		case UPDATE_FILTERED_JOBS:
			if (action.data.id) {
				return {
					...state,
					filteredJobsList: state.filteredJobsList.map(job =>
						job.id === action.data.id ? action.data : job
					),
				};
			}
			return state;
		case REMOVE_JOB_FROM_STORE:
			if (action.payload.id) {
				return {
					...state,
					filteredJobsList: state.filteredJobsList.filter(
						job => job.id !== action.payload.id
					),
				};
			}
			return state;
		case SET_FILTERS_APPLIED:
			return {
				...state,
				filtersApplied: action.data,
			};
		case SET_FILTERS_PRESENT:
			return {
				...state,
				filtersPresent: action.data,
			};
		case SET_TOTAL_CITIES:
			return {
				...state,
				totalFilteredCities: action.data,
			};
		case SET_TOTAL_AREAS:
			return {
				...state,
				totalFilteredAreas: action.data,
			};
		case SET_JOB_STATUS_COUNT:
			return {
				...state,
				jobStatusCount: action.data,
			};
		case SET_SELECTED_PREMIUM_FILTERS:
			return {
				...state,
				selectedPremiumFilters: action.data,
			};
		case SET_IN_CALL_JOB_DETAILS:
			return {
				...state,
				selectedJobInCallDetails: action.data,
			};
		case SET_IN_CALL_POLL_COUNTS:
			return {
				...state,
				jobInCallPollCount: action.data,
			};
		case UPDATE_IS_EMPLOYER_KALEYRA_CALL_REQUESTED:
			return {
				...state,
				isHelpAlreadyRequested: action.data,
			};
		case UPDATE_CURR_CREATOR_ORG_STATUS:
			return {
				...state,
				selectedJobDetails: {
					...state.selectedJobDetails,
					creator_organization: {
						...state.selectedJobDetails?.creator_organization,
						verification_status: action.data,
					},
				},
			};
		case SHOW_UR_JOB_ACTIVATION_WARNING_MODAL:
			if (action?.payload) {
				return {
					...state,
					showActivationWarningModal: ACTIVATION_WARNING_MODAL_STATES.CLOSE,
				};
			}
			return {
				...state,
				showActivationWarningModal: state.showActivationWarningModal + 1,
			};
		case SET_VERIFICATION_DETAILS:
			return { ...state, verificationDetails: action.payload };
		case FETCH_AND_SET_VERIFICATION_DETAILS:
			return { ...state, structuredVerificationDetails: action.payload };
		case SET_EXPERIENCE_TIGHTENING_CONFIG:
			return {
				...state,
				experienceTighteningConfig: action?.data || {},
			};
		case SET_SELECTED_CATEGORY_ID_IN_JOB_POST_FORM:
			return {
				...state,
				selectedCategoryIdPostForm: action?.data,
			};

		default:
			return state;
	}
};
export default jobData;
