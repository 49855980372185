import * as React from "react";

const WorkFromHomeIcon = props => (
	<svg width={41} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M16.832 31.667v-8.334H23.5v8.334c0 .916.75 1.666 1.667 1.666h5c.916 0 1.666-.75 1.666-1.666V20h2.834c.766 0 1.133-.95.55-1.45L21.282 6a1.68 1.68 0 0 0-2.233 0L5.116 18.55c-.567.5-.217 1.45.55 1.45h2.833v11.667c0 .916.75 1.666 1.667 1.666h5c.916 0 1.666-.75 1.666-1.666Z"
			fill="#5E6C84"
		/>
	</svg>
);

export default WorkFromHomeIcon;
