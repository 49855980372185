/* eslint-disable no-unused-expressions */
import { batch } from "react-redux";
import CommonUtils from "../../utils/commonUtils";
import { jobsActionTypes } from "../constants/types";

// write basic actions first
export const startJobsLoading = () => ({
	type: jobsActionTypes.startJobsLoading,
});

export const stopJobsLoading = () => ({
	type: jobsActionTypes.stopJobsLoading,
});

export const setJobsError = errorMessage => ({
	type: jobsActionTypes.setJobsError,
	payload: { errorMessage },
});

export const clearJobsError = () => ({
	type: jobsActionTypes.clearJobsError,
});

export const clearJobs = () => ({
	type: jobsActionTypes.clearJobs,
});

export const setJobs = (jobs, jobOrder) => ({
	type: jobsActionTypes.setJobs,
	payload: { jobs, jobOrder },
});

export const addJobToJobList = job => ({
	type: jobsActionTypes.addJobToJobList,
	payload: { job },
});

export const setJobCount = jobCount => ({
	type: jobsActionTypes.setJobCount,
	payload: { jobCount },
});

export const setNoOfDaysFromLastJob = noOfDays => ({
	type: jobsActionTypes.setNoOfDaysFromLastJob,
	payload: { noOfDays },
});

export const setIsNewUserOnBoarding = isNewUser => ({
	type: jobsActionTypes.setIsNewUserOnBoarding,
	payload: { isNewUser },
});

export const setSelectedJob = jobId => ({
	type: jobsActionTypes.setSelectedJob,
	payload: { jobId },
});

export const showCreatorRecruiterModal = value => ({
	type: jobsActionTypes.showCreatorRecruiterModal,
	payload: { value },
});

export const updateJob = jobData => ({
	type: jobsActionTypes.updateJob,
	payload: { jobData },
});

/**
 * Takes jobs Api response and updates it to the jobReducer.
 */
export const processAndSetJobs = (jobList = []) => dispatch => {
	if (jobList?.length === 0) {
		batch(() => {
			dispatch(setJobCount(0));
			dispatch(setNoOfDaysFromLastJob(0));
			dispatch(setIsNewUserOnBoarding(true));
		});
	} else if (jobList.length > 0) {
		const jobs = {};
		const jobOrder = [];
		jobList.forEach(job => {
			jobs[(job?.id)] = job;
			jobOrder.push(job.id);
		});
		const noOfDaysFromLastJobs = CommonUtils.differenceInDays(
			jobList[0].created_on,
			true
		);
		batch(() => {
			dispatch(setJobCount(jobList.length));
			dispatch(setNoOfDaysFromLastJob(noOfDaysFromLastJobs));
			dispatch(setJobs(jobs, jobOrder));
			dispatch(setIsNewUserOnBoarding(false));
		});
	}
};
