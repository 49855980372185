import { jobUserChannelTypes } from "../constants/types";
import { updateBulkUserChannelMapping } from "../utils/helpers";

// final data will be in {[jobId]: {[userId] : channelId}}
const initialState = {};

const jobUserChannelReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case jobUserChannelTypes.setJobUserChannelMapping: {
			const { jobId, userId, channelId } = payload;
			return { ...state, [jobId]: { ...state[jobId], [userId]: channelId } };
		}
		case jobUserChannelTypes.bulkJobUserChannelMapping: {
			const { data } = payload;
			const newState = updateBulkUserChannelMapping(data, state);
			return newState;
		}
		case jobUserChannelTypes.clearJobUserChannelMapping:
			return initialState;
		default:
			return state;
	}
};

export default jobUserChannelReducer;
