import { FETCHED_APPLICANT_PROFILE, RESET_APPLICANT_PROFILE } from "../constants/types";

const initialState = { open: false };

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case FETCHED_APPLICANT_PROFILE:
			return { ...state, ...payload };
		case RESET_APPLICANT_PROFILE:
			return { open: false };
		default:
			return state;
	}
};
