import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import CommonUtils from "../../utils/commonUtils";
import { LOCAL_CHANNELS, CALL_DETAIL } from "../constants";
import { getMessageDateTimeStamp } from "./formatDate";

/* eslint-disable import/prefer-default-export */
export const getProfileExperienceText = ({ experience, years }) => {
	let text = "Fresher";
	if (experience === "e") {
		text = "Experienced";
		if (years > 0) {
			if (years <= 1) {
				text = `${years} Yr Exp.`;
			} else {
				text = `${years} Yrs Exp.`;
			}
		}
	}
	return text;
};

export const getMessageTimeStamp = timeStamp => {
	dayjs.extend(LocalizedFormat);
	const date = dayjs(timeStamp);
	return date.format("LT");
};

export const updateChannelInLS = channels => {
	CommonUtils.setItemInLocalStorage(LOCAL_CHANNELS, JSON.stringify(channels));
};

export const titlizeSlug = slug => {
	if (slug && slug.length > 0) {
		const words = slug.split("_");
		for (let i = 0; i < words.length; i += 1) {
			const word = words[i];
			words[i] = word.charAt(0).toUpperCase() + word.slice(1);
		}

		return words.join(" ");
	}
	return "";
};

export const updateBulkUserChannelMapping = (data = {}, state = {}) => {
	// for every job in data, update it in state
	const newState = { ...state };
	Object.keys(data).forEach(jobId => {
		let currentJobData = state[jobId] || {};
		currentJobData = { ...currentJobData, ...data[jobId] };
		newState[jobId] = currentJobData;
	});
	return newState;
};

/**
 * @returns {Boolean} - True if the chat is already an active chat
 */
export const getIndexChatInActiveChats = (userId, jobId, activeChats = []) => {
	return activeChats.some(
		item =>
			item?.userId?.toString() === userId?.toString() &&
			item?.jobId?.toString() === jobId.toString()
	);
};

export const getUserProfileFromStore = (userId, state) => {
	if (state?.userProfiles?.[userId]) return state.userProfiles[userId];
	if (state?.suggestedLeads?.suggestedCandidates?.length) {
		const user = state?.suggestedLeads?.suggestedCandidates.find(
			item => String(item?.id) === String(userId)
		);
		return user;
	}
	return null;
};

export const updateChannelBulkPostMapping = (
	posts = {},
	postOrder = [],
	channelStateData = {}
) => {
	const dateWiseData = { ...(channelStateData?.dateWisePosts || {}) };
	postOrder.forEach(order => {
		const post = posts[order];
		const postDateString = getMessageDateTimeStamp(post?.update_at);
		const dateStringData = dateWiseData?.[postDateString] || [];
		const newDateStringData = [...new Set([order, ...dateStringData])];
		dateWiseData[postDateString] = newDateStringData;
	});
	return dateWiseData;
};

/**
 *
 * @param {Array} funnel - ATS funnel list
 * @returns a map of funnel name mapped with available pre and post select values
 */
export const createATSFunnelVerboseMap = (funnel = []) => {
	const data = {};
	funnel.forEach(item => {
		data[(item?.name)] = {
			button_after_action: item?.json_data?.button_after_action,
			button_before_action:
				item?.json_data?.button_before_action?.length > 0
					? item?.json_data?.button_before_action
					: titlizeSlug(item?.name),
		};
	});
	return data;
};

export const calculateTimeLeft = expiryTime => {
	const expiryInMS = expiryTime * 1000;
	const currentTimeInMS = new Date().getTime();
	if (expiryInMS > currentTimeInMS) {
		const difference = new Date(expiryInMS) - currentTimeInMS;
		const remainingTime = new Date(difference);
		let hours = remainingTime.getUTCHours();
		let minutes = remainingTime.getUTCMinutes();
		let seconds = remainingTime.getUTCSeconds();
		hours = hours < 10 ? `0${hours}` : hours;
		minutes = minutes < 10 ? `0${minutes}` : minutes;
		seconds = seconds < 10 ? `0${seconds}` : seconds;

		const strTime = `${hours}:${minutes}:${seconds}`;
		return strTime;
	}
	return "00:00:00";
};

export const abbreviateFileName = fileName => {
	const fileNameLength = fileName?.length;
	const fileText =
		(fileNameLength < 30
			? fileName
			: `${fileName?.slice(0, 15)}....${fileName?.slice(
					fileNameLength - 10,
					fileNameLength
			  )}`) || "";
	return fileText;
};

export const updateCallDetailInLS = callDetail => {
	CommonUtils.setItemInLocalStorage(CALL_DETAIL, JSON.stringify(callDetail));
};

export const getCallDetailsFromLS = () => {
	const callDetails = JSON.parse(CommonUtils.getItemFromLocalStorage(CALL_DETAIL));
	return callDetails;
};

export const atsEnabled = (citiesList, cityId) =>
	citiesList.find(city => city?.id === cityId)?.ecc_disabled === false;
