import React from "react";
import styled, { css } from "styled-components";
import homepageData from "../../cms/homepage-data";
import { Container } from "../../styles/commonStyles";
import media, { mediaMaxWidth } from "../../utils/mediaQuery";
import PastHighlights from "./PastHighlights";

const { homepage } = homepageData || {};

function TopCompanies({ zonalLandingPageName = null }) {
	const ZonalMonumentImage = {
		northZone: {
			imageUrl: "/cloudinary/zonal_landing_page/NothZoneMonument.png",
			name: "Zomato",
			height: 354,
			width: 420,
		},
		southZone: {
			imageUrl: "/cloudinary/zonal_landing_page/SouthZoneMonument.png",
			name: "Zomato",
			height: 354,
			width: 420,
		},
		centralEastZone: {
			imageUrl: "/cloudinary/zonal_landing_page/EastCentralZoneMonument.png",
			name: "Zomato",
			height: 354,
			width: 420,
		},
		westZone: {
			imageUrl: "/cloudinary/zonal_landing_page/WestZoneMonument.png",
			name: "Zomato",
			height: 354,
			width: 420,
		},
	};

	const logos = [
		{
			imageUrl: "/cloudinary/home_page_images/zomato-logo.png",
			name: "Zomato",
			height: 60,
			width: 120,
		},
		{
			imageUrl: "/cloudinary/home_page_images/byjus-logo-updated.png",
			name: "Byju's",
			height: 60,
			width: 120,
		},
		{
			imageUrl: "/cloudinary/home_page_images/Burger_King_logo_without_bg.png",
			name: "Burger King",
			height: 60,
			width: 120,
		},
		{
			imageUrl: "/cloudinary/home_page_images/delhivery-logo.png",
			name: "Delhivery",
			height: 60,
			width: 120,
		},
	];
	return (
		<Wrapper>
			{zonalLandingPageName !== null && (
				<MonumentImageContanier>
					<Image
						src={`https://cdn.apna.co${ZonalMonumentImage[zonalLandingPageName].imageUrl}`}
						height={ZonalMonumentImage[zonalLandingPageName].height}
						width={ZonalMonumentImage[zonalLandingPageName].width}
					/>
				</MonumentImageContanier>
			)}
			<CustomContainer isZonalPage={zonalLandingPageName !== null}>
				<Title>{homepage?.data?.attributes?.logoCloud?.title}</Title>
				<LogosContainer>
					{zonalLandingPageName === null && (
						<>
							{logos.map(logo => (
								// eslint-disable-next-line react/jsx-indent
								<BrandLogo key={logo.name}>
									<Image
										src={`https://cdn.apna.co${logo.imageUrl}`}
										height={logo.height}
										width={logo.width}
									/>
								</BrandLogo>
							))}
							<Divider />
						</>
					)}
					<div id="past-highlights">
						<PastHighlights />
					</div>
				</LogosContainer>
			</CustomContainer>
		</Wrapper>
	);
}

export default TopCompanies;

const CustomContainer = styled(Container)`
	${media.extraLarge`
        max-width: 1300px;
    `};
	${({ isZonalPage }) =>
		isZonalPage &&
		css`
			margin: 0;
		`}
`;

const Wrapper = styled.div`
	margin-top: 104px;
	display: flex;
	align-items: flex-end;

	${mediaMaxWidth.medium`
		margin-top: 64px;
	`}
`;

const Image = styled.img``;

const MonumentImageContanier = styled.div`
	height: 354px;
	width: 420px;

	img {
		object-fit: contain;
	}

	${mediaMaxWidth.small`
		display: none;
	`}
`;

const BrandLogo = styled.div`
	/* width: 140px; */
	height: 60px;

	img {
		height: 100%;
		width: auto;
	}

	${mediaMaxWidth.large`
		height: 48px;

		img {
			object-fit: contain;
		}
	`}

	${mediaMaxWidth.small`
		height: 42px;
	`}
`;

const Title = styled.h4`
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	text-transform: capitalize;
	color: #4d3951;
`;

const Divider = styled.div`
	width: 1px;
	height: 81px;
	background-color: #4d3951;
`;

const LogosContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	> * + * {
		margin-left: 36px;
	}

	${mediaMaxWidth.medium`
		justify-content: center;

		& ${Divider} {
			display: none;
		}

		#past-highlights {
			display: none;
		}
	`}

	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		flex-wrap: wrap;

		& ${Divider} {
			min-width: 100%;
			height: 0.5px;
			margin: 20px 0;
		}
	}
`;
