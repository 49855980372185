import * as React from "react";

const SingleJobLocationIcon = props => (
	<svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M20 11.667V8.333C20 6.5 18.5 5 16.666 5h-10a3.343 3.343 0 0 0-3.333 3.333v23.334C3.333 33.5 4.833 35 6.666 35h26.667c1.833 0 3.333-1.5 3.333-3.333V15c0-1.833-1.5-3.333-3.333-3.333H20Zm-3.334 20h-10v-3.334h10v3.334Zm0-6.667h-10v-3.333h10V25Zm0-6.667h-10V15h10v3.333Zm0-6.666h-10V8.333h10v3.334Zm16.667 20H20V15h13.333v16.667ZM30 18.333h-6.667v3.334H30v-3.334ZM30 25h-6.667v3.333H30V25Z"
			fill="#5E6C84"
		/>
	</svg>
);

export default SingleJobLocationIcon;
