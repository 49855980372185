import {
	INIT_DEFERED_STATE,
	CLEAR_DEFERED_STATE,
	INIT_DEFERED_TOAST,
	CLEAR_DEFERED_TOAST,
} from "../constants/types";
import { DEFER_CONNECTION_UPDATE_DELAY } from "../constants";
import {
	increaseApplicationCount,
	decreaseApplicationCount,
} from "./update-applications-count-action";
import { updateCandidateList } from "./update-candidates-list-action";
import { updatedRecentCandidateQueue } from "./recently-updated-candidates-action";

export const startDeferedStatusUpdate = (user, jobId, tab, nextState, timerRef) => {
	return {
		type: INIT_DEFERED_STATE,
		payload: {
			userId: user?.id,
			jobId,
			tab,
			nextState,
			user,
			timerRef,
		},
	};
};

export const clearDeferedStatusUpdate = (user, jobId) => {
	return {
		type: CLEAR_DEFERED_STATE,
		payload: { userId: user?.id, jobId },
	};
};

export const initiateDeferredConnectionUpdate = (
	user,
	jobId,
	tab,
	page,
	nextState,
	updatedUser
) => dispatch => {
	const statusTimer = setTimeout(() => {
		dispatch(
			updatedRecentCandidateQueue(
				updatedUser,
				jobId,
				updatedUser?.connection_status,
				page
			)
		);
		dispatch(updateCandidateList(user, jobId, tab, page));
		dispatch(decreaseApplicationCount(user?.connection_status));
		dispatch(increaseApplicationCount(nextState));
		dispatch(clearDeferedStatusUpdate(user, jobId));
	}, DEFER_CONNECTION_UPDATE_DELAY);
	dispatch(startDeferedStatusUpdate(user, jobId, tab, nextState, statusTimer));
};

export const cancelDeferedUpdate = (currentJobId, selectedTab) => (
	dispatch,
	getState
) => {
	const { teccDeferedApplications } = getState();
	if (Object.keys(teccDeferedApplications)?.length > 0) {
		Object.keys(teccDeferedApplications).forEach(jobId => {
			const deferedApplications = teccDeferedApplications[jobId] || {};
			if (Object.keys(deferedApplications)?.length > 0) {
				Object.keys(deferedApplications).forEach(userId => {
					const applicantData =
						teccDeferedApplications?.[jobId]?.[userId] || {};
					const { timerRef, tab, user } = applicantData;
					if (timerRef) {
						clearTimeout(timerRef);
						dispatch(clearDeferedStatusUpdate(user, jobId));
						if (selectedTab !== tab && currentJobId === jobId) {
							dispatch(decreaseApplicationCount(tab));
						}
					}
				});
			}
		});
	}
};

export const initDeferedToast = (user, jobId, timerRef) => {
	return {
		type: INIT_DEFERED_TOAST,
		payload: {
			userId: user?.id,
			jobId,
			user,
			timerRef,
		},
	};
};
export const clearDeferedToast = (user, jobId) => {
	return {
		type: CLEAR_DEFERED_TOAST,
		payload: { userId: user?.id, jobId },
	};
};

export const cancelDeferedToasts = () => (dispatch, getState) => {
	const { teccDeferedToasts } = getState();
	if (Object.keys(teccDeferedToasts)?.length > 0) {
		Object.keys(teccDeferedToasts).forEach(jobId => {
			const deferedApplications = teccDeferedToasts[jobId] || {};
			if (Object.keys(deferedApplications)?.length > 0) {
				Object.keys(deferedApplications).forEach(userId => {
					const applicantData = teccDeferedToasts?.[jobId]?.[userId] || {};
					const { timerRef } = applicantData;
					if (timerRef) {
						clearTimeout(timerRef);
						dispatch(clearDeferedToast({ userId, jobId }));
					}
				});
			}
		});
	}
};
