/* eslint-disable import/prefer-default-export */

export const ADD_CHANGE = "ADD_CHANGE";
export const SHOW_FORM = "SHOW_FORM";
export const EDIT_FORM = "EDIT_FORM";
export const CLEAR_EDIT_FORM = "CLEAR_EDIT_FORM";
export const RESET_FORM = "RESET_FORM";
export const DUPLICATE_JOB = "DUPLICATE_JOB";
export const CLEAR_FORM = "CLEAR_FORM";
export const BULK_ADD = "BULK_ADD";
export const SELECTED_JOB = "SELECTED_JOB";
export const SHOW_UNDER_REVIEW_MODAL = "SHOW_UNDER_REVIEW_MODAL";
export const JOB_COUNT_FROM_JOBS_LIST = "JOB_COUNT_FROM_JOBS_LIST";
export const NO_OF_DAYS_FROM_LAST_JOB = "NO_OF_DAYS_FROM_LAST_JOB";
export const SHOW_PROFILE_PAGE = "SHOW_PROFILE_PAGE";
export const SHOW_CONSULTANT_MODAL = "SHOW_CONSULTANT_MODAL";
export const SHOW_ONBOARDING_MODAL = "SHOW_ONBOARDING_MODAL";
export const SHOW_CHANGE_COMPANY_MODAL = "SHOW_CHANGE_COMPANY_MODAL";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_COMPANY_DOC = "UPDATE_COMPANY_DOC";
export const UPLOAD_COMPANY_DOC = "UPLOAD_COMPANY_DOC";
export const EDIT_COMPANY_DOC = "EDIT_COMPANY_DOC";
export const SHOW_FEEDBACK_FORM = "SHOW_FEEDBACK_FORM";
export const HIDE_FEEDBACK_FORM = "HIDE_FEEDBACK_FORM";
export const CHANGE_FEEDBACK = "CHANGE_FEEDBACK";
export const CHANGE_FEEDBACK_UPDATE = "CHANGE_FEEDBACK_UPDATE";
export const CHANGE_APPLICATION_STATUS = "CHANGE_APPLICATION_STATUS";
export const SUBMIT_APPLICATION_FEEDBACK = "SUBMIT_APPLICATION_FEEDBACK";
export const UPDATE_JOB_APPLICATION = "UPDATE_JOB_APPLICATION";
export const UPDATE_JOB_APPLICATION_LIST = "UPDATE_JOB_APPLICATION_LIST";
export const UPDATE_CUSTOM_JOB_ROLE = "UPDATE_CUSTOM_JOB_ROLE";
export const UPDATE_IS_LEGACY_JOB = "UPDATE_IS_LEGACY_JOB";
export const JOB_APPLICATION_REQUESTED = "JOB_APPLICATION_REQUESTED";
export const JOB_APPLICATION_SUCCEEDED = "JOB_APPLICATION_SUCCEEDED";
export const JOB_APPLICATION_FAILED = "JOB_APPLICATION_FAILED";
export const JOB_APPLICATION_COUNT = "JOB_APPLICATION_COUNT";
export const JOB_APPLICATION_PAGE_CHANGE = "JOB_APPLICATION_PAGE_CHANGE";
export const JOB_MATCHED_APPLICATION_REQUESTED = "JOB_MATCHED_APPLICATION_REQUESTED";
export const JOB_MATCHED_APPLICATION_UPDATE = "JOB_MATCHED_APPLICATION_UPDATE";
export const UPDATE_SELECTED_JOB_HR_NUM = "UPDATE_SELECTED_JOB_HR_NUM";
export const UPDATE_SELECTED_JOB_EMAIL_VERIFIED = "UPDATE_SELECTED_JOB_EMAIL_VERIFIED";
export const ADD_ATS_FUNNEL_STEP = "ADD_ATS_FUNNEL_STEP";
export const SET_ALL_ATS_FUNNEL_STEPS = "SET_ALL_ATS_FUNNEL_STEPS";
export const SET_ALL_ATS_FUNNEL_VERBOSE_STEPS = "SET_ALL_ATS_FUNNEL_VERBOSE_STEPS";
export const POST_NEW_JOB_EXPIRE_OLD_JOB = "POST_NEW_JOB_EXPIRE_OLD_JOB";
export const SET_AGE_UNLEASH_STATUS = "SET_AGE_UNLEASH_STATUS";
export const SET_CITY_AREAS_FILTER = "SET_CITY_AREAS_FILTER";
export const SET_SELECTED_AREAS = "SET_SELECTED_AREAS";
export const SET_SELECTED_JOB_STATUS = "SET_SELECTED_JOB_STATUS";
export const SET_TOTAL_FILTERED_JOBS = "SET_TOTAL_FILTERED_JOBS";
export const SET_FILTERED_JOBS = "SET_FILTERED_JOBS";
export const UPDATE_FILTERED_JOBS = "UPDATE_FILTERED_JOBS";
export const SET_FILTERS_APPLIED = "SET_FILTERS_APPLIED";
export const SET_FILTERS_PRESENT = "SET_FILTERS_PRESENT";
export const SET_TOTAL_CITIES = "SET_TOTAL_CITIES";
export const SET_TOTAL_AREAS = "SET_TOTAL_AREAS";
export const SET_JOB_STATUS_COUNT = "SET_JOB_STATUS_COUNT";
export const NEW_JOB_POSTED = "NEW_JOB_POSTED";
export const SET_WORKSPACE = "SET_WORKSPACE";
export const UPDATE_WORKSPACE = "UPDATE_WORKSPACE";
export const UPDATE_POC_DOC = "UPDATE_POC_DOC";
export const UPDATE_SELECTED_JOB_OWNER_DATA = "UPDATE_SELECTED_JOB_OWNER_DATA";
export const SET_SELECTED_PREMIUM_FILTERS = "SET_SELECTED_PREMIUM_FILTERS";
export const SET_JOB_ROLE_DATA = "SET_JOB_ROLE_DATA";
export const SET_EMPLOYER_FEEDBACK_OPTIONS = "SET_EMPLOYER_FEEDBACK_OPTIONS";
export const SET_IS_WORKSPACE_UPDATED = "SET_IS_WORKSPACE_UPDATED";
export const UPDATE_CURR_CREATOR_ORG_STATUS = "UPDATE_CURR_CREATOR_ORG_STATUS";
export const UPDATE_IS_EMPLOYER_KALEYRA_CALL_REQUESTED =
	"UPDATE_IS_EMPLOYER_KALEYRA_CALL_REQUESTED";
export const REMOVE_JOB_FROM_STORE = "REMOVE_JOB_FROM_STORE";
export const SET_VERIFICATION_DETAILS = "SET_VERIFICATION_DETAILS";
export const FETCH_AND_SET_VERIFICATION_DETAILS = "FETCH_AND_SET_VERIFICATION_DETAILS";
export const DUPLICATE_JOB_FOUND = "DUPLICATE_JOB_FOUND";
export const UPDATE_CALL_LATEST_VERIFICATION_API_FLAG =
	"UPDATE_CALL_LATEST_VERIFICATION_API_FLAG";
export const UPDATE_VERIFICATION_MODAL_OPEN_STATE =
	"UPDATE_VERIFICATION_MODAL_OPEN_STATE";
export const SET_JOBS_FIELD_JOB_ALLOW_OPTION = "SET_JOBS_FIELD_JOB_ALLOW_OPTION";
export const SHOW_MISSING_FIELDS_INFO_PANEL = "SHOW_MISSING_FIELDS_INFO_PANEL";
export const SAVE_JPF_MISSING_FIELDS = "SAVE_JPF_MISSING_FIELDS";
export const SET_IS_JPF_ERROR = "SET_IS_JPF_ERROR";
export const SET_EXPERIENCE_TIGHTENING_CONFIG = "SET_EXPERIENCE_TIGHTENING_CONFIG";
export const SET_SELECTED_CATEGORY_ID_IN_JOB_POST_FORM =
	"SET_SELECTED_CATEGORY_ID_IN_JOB_POST_FORM";
export const SET_JOB_ALLOW_OPTION = "SET_JOB_ALLOW_OPTION";
export const UPDATE_JOB_ALLOW_OPTION = "UPDATE_JOB_ALLOW_OPTION";
export const SET_EDUCATION_OPTIONS = "SET_EDUCATION_OPTIONS";
export const SET_ENGLISH_OPTIONS = "SET_ENGLISH_OPTIONS";
export const SET_JOB_TAGS = "SET_JOB_TAGS";
export const UPDATE_JOB_TAG = "UPDATE_JOB_TAG";
export const SET_RECOMMENDATION_TYPE = "SET_RECOMMENDATION_TYPE";
export const SET_CATEGORY_DROPDOWN_LIST = "SET_CATEGORY_DROPDOWN_LIST";
export const SET_MAX_VALUES_FOR_TAGS = "SET_MAX_VALUES_FOR_TAGS";
export const SET_ALLOWED_JOB_TITLES = "SET_ALLOWED_JOB_TITLES";
export const UPDATE_MAX_VALUES_FOR_TAGS = "UPDATE_MAX_VALUES_FOR_TAGS";
