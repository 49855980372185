/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, makeStyles, StylesProvider } from "@material-ui/core/styles";
import { Backdrop } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import styled from "styled-components";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import TagManager from "react-gtm-module";
import CommonUtils from "./utils/commonUtils";
import config from "./config";
import Notification from "./components/Notification/Notification";
import {
	closeNotification,
	hideJobVerificationModal,
	hidePocDocUploadModal,
	toggleHrNumberVerificationModal,
} from "./actions";
import Clevertap from "./utils/Clevertap";
import { getAuthToken } from "./utils/apiClients";
import Modal from "./components/Modal/Modal";
import JobHrNumVerificationModal from "./views/JobsPage/JobHrNumVerificationModal";
import EditJobWarningModal from "./views/PostJobFormNew/EditWarningModal";
import JobVerificationModal from "./views/JobsPage/JobVerificationModal";
import PocDocUploadModal from "./views/JobsPage/PocDocUploadModal";
import { lazyLoader } from "./utils/helpers";
import NotFoundPage from "./views/NotFoundPage";
import { remoteConfig } from "./firebase";

const OrgEmailVerification = lazyLoader(() =>
	import(
		/* webpackPrefetch: true */
		/* webpackChunkName: "OrgEmailVerification" */
		"./views/JobsPage/components/JobVerification/OrgEmailVerification/index.js"
	)
);

const Main = lazyLoader(() =>
	import(
		/* webpackPrefetch: true */
		/* webpackChunkName: "Mainjs" */
		"./views/Main/Main"
	)
);

const Login = lazyLoader(() =>
	import(
		/* webpackPrefetch: true */
		/* webpackChunkName: "Login" */
		"./views/Login/Login"
	)
);

const SEMLandingPage = lazyLoader(() => import("./views/SEMLanding/SEMLandingPage"));

const theme = createTheme({
	palette: {
		primary: {
			main: "#4d3951",
		},
		secondary: {
			main: "#2bb793",
			contrastText: "#fff",
		},
		textColor: {
			main: "#ffffff",
		},
	},
});

const useStyles = makeStyles(muiTheme => ({
	backdrop: {
		zIndex: muiTheme.zIndex.modal + 1,
		color: "#fff",
	},
}));

const App = () => {
	console.log("FAKE_COMMIT");
	const classes = useStyles();
	const dispatch = useDispatch();
	const notification = useSelector(state => state.notification);
	const [
		showHrNumberVerificationModal,
		showJobVerificationModal,
		editWarningModal,
		showPocDocUploadModal,
	] = useSelector(
		({
			modal: { hrNumber, jobVerificationModal, editWarning, pocDocUploadModal },
		}) => [hrNumber, jobVerificationModal, editWarning, pocDocUploadModal]
	);
	const loader = useSelector(state => state.loader.show);
	const [isBranchLoaded, setBranchLoaded] = useState(false);

	useEffect(() => {
		const tagManagerArgs = {
			gtmId: config.google.gtmId,
		};
		TagManager.initialize(tagManagerArgs);
		Clevertap.initialize(config.cleverTapAccountId);
		let userData = JSON.parse(CommonUtils.getItemFromLocalStorage("__user__"));
		const timerId = setTimeout(() => {
			setBranchLoaded(true);
		}, 3000);

		try {
			window.branch.init(config.branchSdkKey, async (err, data) => {
				// custom branch event as per sidharth request.
				window.branch.logEvent("Web Login", data?.data_parsed, error =>
					console.error(error)
				);
				if (data && data.data_parsed) {
					let authToken = null;
					const token = data?.data_parsed?.token;
					if (token) {
						// fetch actual token from api. and display loader
						const resp = await getAuthToken({ token });
						if (resp?.status === 200) {
							authToken = resp?.data?.auth_token;
							userData = JSON.stringify(resp?.data?.user_data);
						}
					} else {
						authToken = data?.data_parsed?.auth_token;
						userData = JSON.stringify(data.data_parsed?.user_data);
					}
					if (authToken) {
						CommonUtils.setItemInLocalStorage("__token__", authToken);
						CommonUtils.setItemInLocalStorage("__user__", userData);
						!CommonUtils.isLatestVersion() &&
							CommonUtils.updateReleaseVersion();
					}
				}
				clearTimeout(timerId);
				setBranchLoaded(true);
			});
		} catch (error) {
			setBranchLoaded(true);
			throw new Error(error);
		}
	}, []);
	useEffect(() => {
		try {
			if (remoteConfig?.fetchAndActivate) {
				remoteConfig?.fetchAndActivate();
			}
		} catch (err) {
			throw new Error(err);
		}
	}, []);
	const onJobVerificationModalClose = () => {
		dispatch(hideJobVerificationModal());
	};

	const onPocDocUploadModalClose = () => {
		dispatch(hidePocDocUploadModal());
	};

	if (isBranchLoaded) {
		const token = CommonUtils.getItemFromLocalStorage("__token__");
		return (
			<StylesProvider injectFirst>
				<ThemeProvider theme={theme}>
					<AppContainer>
						<Switch>
							<Route
								path={[
									"/post-a-free-job",
									"/bengaluru-post-a-free-job",
									"/hire-post-a-free-job",
								]}
								name="SEMLanding"
								component={SEMLandingPage}
							/>
							<Route
								path={[
									"/login",
									"/fos",
									"/apnahire-east-and-central-india",
									"/apnahire-west-india",
									"/apnahire-north-india",
									"/apnahire-south-india",
								]}
								name="Login"
								component={Login}
							/>
							<Route
								path="/verification/email"
								name="Email Verification"
								component={OrgEmailVerification}
							/>
							<Route
								path={[
									"/post-job",
									"/profile",
									"/jobs/:id",
									"/new-user",
									"/jobs/:id/:userId",
									"/manage-coins",
									"/download-applications",
									"/company-and-members",
									"/internal-user",
									"/dashboard",
									"/free-jobs",
									"/",
								]}
								exact
								name="Main"
								render={data => {
									return token ? (
										<Main history={data.history} />
									) : (
										<Redirect
											to={{
												pathname: `/login${data.location.pathname}`,
												search: data?.location?.search,
											}}
										/>
									);
								}}
							/>
							<Route path="*" component={NotFoundPage} />
						</Switch>
						<Notification
							open={notification.open}
							type={notification.type || "info"}
							message={notification.message}
							duration={notification.duration}
							onClose={() => dispatch(closeNotification())}
						/>
						<Backdrop open={loader} className={classes.backdrop}>
							<AnimatedLogo />
						</Backdrop>
						<Modal
							open={showHrNumberVerificationModal?.open}
							handleClose={() => {
								dispatch(toggleHrNumberVerificationModal());
							}}
						>
							<JobHrNumVerificationModal />
						</Modal>
						<Modal
							open={showJobVerificationModal.show}
							handleClose={onJobVerificationModalClose}
							maxWidth={640}
							borderRadius={2}
							paddingSpacing={[3]}
							disableBackdropClick
						>
							<JobVerificationModal
								showUpdateEmailOption={
									showJobVerificationModal.showUpdateEmailOption
								}
								handleClose={onJobVerificationModalClose}
							/>
						</Modal>
						<Modal
							open={showPocDocUploadModal}
							handleClose={onPocDocUploadModalClose}
							borderRadius={2}
							maxWidth={450}
							paddingSpacing={[3]}
							disableBackdropClick
						>
							<PocDocUploadModal handleClose={onPocDocUploadModalClose} />
						</Modal>
						<Modal
							open={editWarningModal?.open}
							maxWidth={626}
							handleClose={() => {}}
						>
							<EditJobWarningModal />
						</Modal>
					</AppContainer>
				</ThemeProvider>
			</StylesProvider>
		);
	}
	return (
		<Backdrop open className={classes.backdrop}>
			<AnimatedLogo />
		</Backdrop>
	);
};

// Styles
const AnimatedLogo = styled.img.attrs(
	({ src = "/apna-logo.svg", alt = "logo", width = 80 }) => ({
		src,
		alt,
		width,
	})
)`
	animation: pulse 1.3s infinite;
	@keyframes pulse {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
		}

		70% {
			transform: scale(1);
			box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
		}

		100% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		}
	}
`;

const AppContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100%;
	width: 100%;
	background-color: #f8f8f8;
`;

export default withRouter(App);
