import { SET_SELECTED_JOB_LEADS } from "../constants/types";

const initialState = { jobId: null, jobDetail: null };

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_SELECTED_JOB_LEADS:
			return { ...state, jobId: payload.jobId, jobDetail: payload.jobDetail };
		default:
			return state;
	}
};
