export const VERIFY_EMAIL = "/api/employer/v1/jobs/%job_id%/verify_email/";
export const RESEND_EMAIL = "/api/employer/v1/jobs/%job_id%/resend_email_link/";
export const RESEND_EMAIL_V2 = "/api/employer/v2/jobs/%job_id%/resend_email_link/";
export const UPDATE_JOB_ORG = "/api/employer/v1/jobs/%job_id%/update_organization/";
export const VERIFY_COMPANY_CARD_DETAILS =
	"/api/employer/verification/v1/declare-company";
export const LINK_COMPANY_AND_CERTIFICATE_CARD = "/api/employer/v1/link-certificate";
export const VERIFICATION_DOC_UPLOAD = "/api/employer/v1/users/%user_id%/documents/";
export const VERIFICATION_DETAILS_API = "api/employer/v1/%JOB_ID%/verification-status";
export const VERIFICATION_LATEST_DETAILS_API =
	"api/employer/v2/%JOB_ID%/latest-verification-status";

// Client verification APIs
export const TRIGGER_CLIENT_EMAIL_VERIFICATION =
	"/api/employer/v1/mandate-verification/%job_id%/email";
export const VERIFY_CLIENT_EMAIL = "/api/employer/v1/verify-mandate/%verification_token%";
export const VERIFY_CREATOR_EMAIL = "/api/employer/v2/verify_email/?";
export const CONTRACT_UPLOAD = "/api/employer/v1/mandate-verification/%job_id%/doc";
export const TOGGLE_POC_UPLOAD = "/api/internal/v1/%job_id%/allow-poc-upload";
export const UPDATE_CV_STATUS = "/api/internal/v1/%job_id%/update-cv-status";
export const VERIFY_KYC_DOC = "/api/internal/v1/%job_id%/verify-ekyc-poc";
export const VERIFY_CONSULTANT_MANDATE = "/api/internal/v1/verification/verify_mandate/";
// EKYC API's
export const GET_REQUEST_ID_EKYC = "/apna-verification/aadhar/initiate-aadhar-request";
export const TRIGGER_OTP_EKYC = "/apna-verification/aadhar/async/v2/trigger-otp";

export const GET_MOBILE_NUMBER_EKYC = "/apna-verification/aadhar/mobile-number";
export const GET_KYC_DETAILS = "/apna-verification/aadhar/v1/get-details";
export const VERIFY_OTP_AADHAR_NUMBER = "/api/employer/verification/v1/ekyc/link";
export const VERIFY_OTP_FROM_APNA_VERIFICATION =
	"/apna-verification/aadhar/async/v2/verify-otp";

export const CONSENT_API = "/api/employer/v1/%JOB_ID%/ekyc/mandate";
export const EDIT_EMAIL_API = "api/internal/v1/%JOB_ID%/update-email";
export const VERIFY_OTP_FROM_APNA_VERIFICATION_VERSION =
	"/apna-verification/aadhar/async/v%VERSION%/verify-otp";
export const TRIGGER_OTP_EKYC_VERSION =
	"/apna-verification/aadhar/async/v%VERSION%/trigger-otp";
