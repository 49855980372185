import {
	CALL_INITIATED,
	CALL_FAILED,
	CALL_ENDED,
	CALL_DISCONNECTED,
	RESET_CALL,
	CALL_ID_UPDATED,
} from "../constants/types";
import { updateCallDetailInLS } from "../utils/helper";

const initialState = {
	onGoing: false,
	callStatus: null,
	jobId: null,
	tab: null,
	page: null,
	userId: null,
	callId: null,
	user: {},
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case CALL_INITIATED: {
			const updatedCallDetail = {
				...state,
				onGoing: true,
				callStatus: payload.callStatus,
				jobId: payload.jobId,
				tab: payload.tab,
				page: payload.page,
				userId: payload.userId,
				user: payload.user,
			};
			updateCallDetailInLS(updatedCallDetail);
			return updatedCallDetail;
		}
		case CALL_ID_UPDATED: {
			updateCallDetailInLS({
				...state,
				callId: payload.callId,
			});
			return {
				...state,
				callId: payload.callId,
			};
		}
		case CALL_FAILED: {
			const updatedCallDetail = {
				...state,
				onGoing: false,
				callStatus: payload.callStatus,
				jobId: payload.jobId,
				tab: payload.tab,
				page: payload.page,
				userId: payload.userId,
				callId: null,
				user: {},
			};
			updateCallDetailInLS(updatedCallDetail);
			return updatedCallDetail;
		}
		case CALL_DISCONNECTED: {
			const updatedCallDetail = {
				...state,
				onGoing: false,
				callStatus: payload.callStatus,
				jobId: payload.jobId,
				tab: payload.tab,
				page: payload.page,
				userId: payload.userId,
				callId: null,
				user: {},
			};
			updateCallDetailInLS(updatedCallDetail);
			return updatedCallDetail;
		}
		case CALL_ENDED: {
			const updatedCallDetail = {
				...state,
				onGoing: false,
				callStatus: payload.callStatus,
				jobId: payload.jobId,
				tab: payload.tab,
				page: payload.page,
				userId: payload.userId,
				callId: null,
				user: {},
			};
			updateCallDetailInLS(updatedCallDetail);
			return updatedCallDetail;
		}
		case RESET_CALL: {
			const updatedCallDetail = {
				onGoing: false,
				callStatus: null,
				jobId: null,
				tab: null,
				page: null,
				userId: null,
				callId: null,
				user: {},
			};
			updateCallDetailInLS(updatedCallDetail);
			return updatedCallDetail;
		}
		default:
			return state;
	}
};
