import * as React from "react";

const FieldJobIcon = props => (
	<svg width={41} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M29.667 19.998c0-1.467-1.2-2.667-2.667-2.667h-4v2.667h4v3.533l-4.64 5.8h-4.693v-6.666h-5.334A5.332 5.332 0 0 0 7 27.998v4h2.667c0 2.213 1.786 4 4 4 2.213 0 4-1.787 4-4h5.973l6.027-7.533v-4.467Zm-16 13.333c-.734 0-1.334-.6-1.334-1.333H15c0 .733-.6 1.333-1.333 1.333Z"
			fill="#5E6C84"
		/>
		<path
			d="M17.667 18.665H11v2.666h6.667v-2.666ZM29.667 27.998c-2.214 0-4 1.787-4 4s1.786 4 4 4c2.213 0 4-1.787 4-4s-1.787-4-4-4Zm0 5.333c-.734 0-1.334-.6-1.334-1.333s.6-1.333 1.334-1.333c.733 0 1.333.6 1.333 1.333s-.6 1.333-1.333 1.333ZM12.333 4C13.8 4 15 5.2 15 6.665c0 1.467-1.2 2.667-2.667 2.667a2.675 2.675 0 0 1-2.666-2.667c0-1.467 1.2-2.667 2.666-2.667Zm8.214 11.906a7.682 7.682 0 0 1-4.307-2.347l-1.72-1.906a3.21 3.21 0 0 0-.84-.614c-.48-.253-.96-.4-1.6-.346-.653.053-1.213.36-1.64.813l4.56 4.56c1.333 1.107 3.2 2.053 5.067 2.427.826.173 1.6-.454 1.6-1.294 0-.64-.48-1.186-1.12-1.293Z"
			fill="#5E6C84"
		/>
	</svg>
);

export default FieldJobIcon;
