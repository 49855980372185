/* eslint-disable import/prefer-default-export */

export const OPEN_COMMENT_MODAL = "OPEN_COMMENT_MODAL";
export const COMMENT_DATA = "COMMENT_DATA";
export const SNACKBAR_SHOW = "SNACKBAR_SHOW";
export const ATS_SNACKBAR_SHOW = "ATS_SNACKBAR_SHOW";
export const JOB_SEARCH_BY = "JOB_SEARCH_BY";
export const IS_QC_DASHBOARD = "IS_QC_DASHBOARD";
export const SET_IN_CALL_JOB_DETAILS = "SET_IN_CALL_JOB_DETAILS";
export const SET_IN_CALL_POLL_COUNTS = "SET_IN_CALL_POLL_COUNTS";
export const SET_INTERNAL_DASHBOARD_TYPE = "SET_INTERNAL_DASHBOARD_TYPE";
export const SHOW_UR_JOB_ACTIVATION_WARNING_MODAL =
	"SHOW_UR_JOB_ACTIVATION_WARNING_MODAL";
