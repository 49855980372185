import {
	SET_TAB_AWARENESS_MODE,
	TAB_AWARENESS_FLOW_SHOWN,
	UPDATE_CURRENT_STAGE,
} from "../constants/types";

const initialState = {
	isTabAwarenessModeOn: false,
	currentStage: "",
	isTabAwarenessFlowShown: false,
	tabHeading: "",
	tabText: "",
};

export default (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case SET_TAB_AWARENESS_MODE:
			return { ...state, ...payload };

		case UPDATE_CURRENT_STAGE:
			return { ...state, ...payload };

		case TAB_AWARENESS_FLOW_SHOWN:
			return { ...state, ...payload };

		default:
			return state;
	}
};
