import { INIT_DEFERED_STATE, CLEAR_DEFERED_STATE } from "../constants/types";

const initialState = {};

export default (state = initialState, action) => {
	const { payload, type } = action;
	switch (type) {
		case INIT_DEFERED_STATE: {
			const { jobId, userId } = payload;
			return { ...state, [jobId]: { ...state[jobId], [userId]: { ...payload } } };
		}
		case CLEAR_DEFERED_STATE: {
			const { jobId, userId } = payload;
			return { ...state, [jobId]: { ...state[jobId], [userId]: null } };
		}
		default:
			return state;
	}
};
