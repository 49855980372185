import { channelPostsActionTypes } from "../constants/types";
import { getMessageDateTimeStamp } from "../utils/formatDate";
import { updateChannelBulkPostMapping } from "../utils/helpers";

const initialState = {};

const channelPostsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case channelPostsActionTypes.createChannel: {
			const { channelId } = payload;
			return {
				...state,
				[channelId]: {
					page: 0,
					posts: {},
					postOrder: [],
					hasNext: false,
					beforePostId: "",
					isLoading: true,
					errorMessage: "",
					dateWisePosts: {},
				},
			};
		}
		case channelPostsActionTypes.startLoadingForChannel: {
			const { channelId } = payload;
			return {
				...state,
				[channelId]: { ...state[channelId], isLoading: true, errorMessage: "" },
			};
		}
		case channelPostsActionTypes.stopLoadingForChannel: {
			const { channelId } = payload;
			return {
				...state,
				[channelId]: { ...state[channelId], isLoading: false },
			};
		}
		case channelPostsActionTypes.setErrorForChannel: {
			const { channelId, errorMessage } = payload;
			return {
				...state,
				[channelId]: { ...state[channelId], errorMessage },
			};
		}
		case channelPostsActionTypes.updateChannel: {
			const {
				channelId,
				posts,
				hasNext = false,
				beforePostId = "",
				postOrder,
				page,
			} = payload;
			return {
				...state,
				[channelId]: {
					...state[channelId],
					posts: { ...posts, ...state[channelId].posts },
					postOrder: [
						...new Set([...postOrder, ...state[channelId].postOrder]),
					],
					hasNext,
					beforePostId,
					errorMessage: "",
					isLoading: false,
					page,
					dateWisePosts: updateChannelBulkPostMapping(
						posts,
						postOrder,
						state?.[channelId] || {}
					),
				},
			};
		}
		case channelPostsActionTypes.addSingleMessageForChannel: {
			const { channelId, post } = payload;
			const channelPosts = state?.[channelId]?.posts || {};
			const channelPostOrder = state?.[channelId]?.postOrder || [];
			const dateWiseData = state?.[channelId]?.dateWisePosts || {};
			const dateString = getMessageDateTimeStamp(post?.update_at);
			const currentDateDate = dateWiseData?.[dateString] || [];
			if (channelPosts[(post?.id)]) {
				return state;
			}
			return {
				...state,
				[channelId]: {
					...state[channelId],
					posts: { ...channelPosts, [post?.id]: post },
					postOrder: [...new Set([post.id, ...channelPostOrder])],
					dateWisePosts: {
						...dateWiseData,
						[dateString]: [...new Set([...currentDateDate, post?.id])],
					},
				},
			};
		}
		case channelPostsActionTypes.addMoreToChannel: {
			const {
				channelId,
				posts,
				page,
				hasNext = false,
				beforePostId = "",
				postOrder,
			} = payload;
			return {
				...state,
				[channelId]: {
					...state[channelId],
					posts: { ...posts, ...state[channelId].posts },
					postOrder: [
						...new Set([...postOrder, ...state[channelId].postOrder]),
					],
					dateWisePosts: updateChannelBulkPostMapping(
						posts,
						postOrder,
						state?.channelId || {}
					),
					page,
					hasNext,
					beforePostId,
					errorMessage: "",
					isLoading: false,
				},
			};
		}
		case channelPostsActionTypes.clearChannel: {
			const { channelId } = payload;
			const { [channelId]: value, ...restChannels } = state;
			return restChannels;
		}
		case channelPostsActionTypes.clearAll:
			return initialState;
		default:
			return state;
	}
};

export default channelPostsReducer;
