import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Button from "../../components/Buttons/Button";
import { clearEditFormData, toggleEditWarningModal } from "../../actions";
import Mixpanel from "../../utils/Mixpanel";
import { getUserDetails } from "../../utils/commonUtils";

const EditJobWarningModal = () => {
	const dispatch = useDispatch();
	const editWarningModal = useSelector(state => state?.modal?.editWarning);
	const editFormData = useSelector(state => state?.forms?.editFormData);
	const { changedField, data } = editWarningModal;
	const editJobId = editFormData?.id;
	const userData = getUserDetails();
	const isInternal = userData?.is_internal_user;
	const mixpanelPayload = {
		"Job ID": editJobId,
		"Field Changed": changedField,
		"Old Value": data?.oldValue,
		"New Value": data?.newValue,
	};

	useEffect(() => {
		Mixpanel.track("Edit Warning popup shown", mixpanelPayload);
	}, []);

	const handleNoCTA = () => {
		dispatch(toggleEditWarningModal());
		Mixpanel.track("Edit Warning popup No CTA", mixpanelPayload);
	};

	const handleYesCTA = () => {
		dispatch(toggleEditWarningModal());
		dispatch(clearEditFormData());

		Mixpanel.track("Edit Warning popup Yes CTA", mixpanelPayload);
	};

	return (
		<Container>
			<Text>
				{`We noticed that you are hiring for a different ${changedField ||
					""}. Please post
				a new job to avoid any confusion among candidates who have already
				applied.`}
				<br />
				<br />
				<br />
			</Text>
			<ButtonGroup alignCenter={isInternal}>
				<CTAButton secondary onClick={handleNoCTA}>
					{`No, keep the same ${changedField || ""}`}
				</CTAButton>
				{!isInternal && (
					<CTAButton onClick={handleYesCTA}>
						Yes, Create a new job Post
					</CTAButton>
				)}
			</ButtonGroup>
		</Container>
	);
};

// Styles
const Container = styled.div``;

const Text = styled.p`
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	color: #6a6a6a;
	margin: 16px 0 40px 0;
`;

const ButtonGroup = styled.div`
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	justify-content: ${({ alignCenter = false }) => alignCenter && "center"};

	@media (max-width: 768px) {
		flex-direction: column-reverse;
	}
`;

const CTAButton = styled(Button)`
	min-width: 270px;
	height: 32px;
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
`;

export default EditJobWarningModal;
