import { eccPreferenceActionTypes } from "../constants/types";

const initialState = {
	shown_ecc_modal_for: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case eccPreferenceActionTypes.addCreatedById:
			return state.shown_ecc_modal_for.includes(action.id)
				? state
				: {
						...state,
						shown_ecc_modal_for: [...state.shown_ecc_modal_for, action.id],
				  };
		default:
			return state;
	}
};
