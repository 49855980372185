import { css } from "styled-components";

export const sizes = {
	extraLarge: 1200,
	large: 992,
	medium: 768,
	small: 576,
	extraSmall: 430,
	extraExtraSmall: 390,
	mini: 320,
};

const media = Object.keys(sizes).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (min-width: ${sizes[label]}px) {
			${css(...args)};
		}
	`;
	return acc;
}, {});

export const mediaMaxWidth = Object.keys(sizes).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (max-width: ${sizes[label] - 1}px) {
			${css(...args)};
		}
	`;
	return acc;
}, {});

export default media;
