/* eslint-disable import/prefer-default-export */
import * as types from "../constants/FormTypes";
import * as apiTypes from "../constants/ApiTypes";
import * as internalUserTypes from "../constants/InternalUserTypes";
import * as modalTypes from "../constants/ModalTypes";
import * as SearchbarTypes from "../constants/SearchbarTypes";
import * as notificationTypes from "../constants/NotificationTypes";
import * as loaderTypes from "../constants/LoaderTypes";
import * as monetisationTypes from "../constants/MonetisationTypes";
import { UPDATE_EMPLOYER_TYPE } from "../constants";
import { jobsActionTypes } from "../ecc/constants/types";
import {
	getATSFunnelSteps,
	getUserDocs,
	patchPocDoc,
	uploadPocDoc,
} from "../utils/apiClients";
import { JOB_POSTING_MODAL_TYPE, VERIFICATION_STEPS } from "../utils/constants";
import { verificationDetailsAPI } from "../views/JobsPage/utils/apiClients";
import { getJobDetail } from "../utils/internalApiClients";

export const showForm = value => ({ type: types.SHOW_FORM, value });
export const editForm = values => ({ type: types.EDIT_FORM, values });
export const resetForm = value => ({ type: types.RESET_FORM, value });
export const duplicateJob = values => ({ type: types.DUPLICATE_JOB, values });
export const updateCompanyDoc = value => ({ type: types.UPDATE_COMPANY_DOC, value });
export const updatePocDoc = (value, isHr = false) => ({
	type: types.UPDATE_POC_DOC,
	value,
	isHr,
});
export const uploadCompanyDoc = () => ({ type: types.UPLOAD_COMPANY_DOC });
export const editCompanyDocs = () => ({ type: types.EDIT_COMPANY_DOC });

export const addChange = values => ({ type: types.ADD_CHANGE, values });

export const addEducationLevels = educationLevels => ({
	type: apiTypes.ADD_EDUCATION_LEVELS,
	educationLevels,
});
export const addEngAndEdu = options => ({
	type: apiTypes.ADD_ENG_AND_EDU,
	options,
});
export const addDefaultEngAndEdu = options => ({
	type: apiTypes.ADD_DEFAULT_ENG_AND_EDU,
	options,
});

export const addJobTitles = jobCategories => ({
	type: apiTypes.ADD_JOB_TITLE,
	jobCategories,
});
export const addMumbaiAreas = mumbaiAreas => ({
	type: apiTypes.ADD_MUMBAI_AREAS,
	mumbaiAreas,
});

export const addCitiesList = citiesList => ({
	type: apiTypes.ADD_CITIES,
	citiesList,
});
export const addLanguageConfig = languageConfig => ({
	type: apiTypes.ADD_LANGUAGE_CONFIG,
	languageConfig,
});
export const clearFormDataStore = () => ({ type: types.CLEAR_FORM });
export const bulkAddFormData = values => ({ type: types.BULK_ADD, values });
export const selectedJob = value => ({
	type: types.SELECTED_JOB,
	value,
});
export const updateSelectedJobOwnerData = value => ({
	type: types.UPDATE_SELECTED_JOB_OWNER_DATA,
	value,
});
export const commentModal = value => ({
	type: internalUserTypes.OPEN_COMMENT_MODAL,
	value,
});

export const commentByInternalUser = value => ({
	type: internalUserTypes.COMMENT_DATA,
	value,
});

export const snackBar = value => ({
	type: internalUserTypes.SNACKBAR_SHOW,
	value,
});

export const ATSSnackBar = value => ({
	type: internalUserTypes.ATS_SNACKBAR_SHOW,
	value,
});

export const showDisappearingATSSnackBar = value => async dispatch => {
	dispatch(ATSSnackBar(value));
	setTimeout(() => {
		const snackBarData = { ...value, show: false };
		dispatch(ATSSnackBar(snackBarData));
	}, 7000);
};

export const jobSearchBy = value => ({
	type: internalUserTypes.JOB_SEARCH_BY,
	value,
});
export const isQcDashboardEnabled = value => ({
	type: internalUserTypes.IS_QC_DASHBOARD,
	value,
});

export const openJobUnderReviewModal = value => ({
	type: types.SHOW_UNDER_REVIEW_MODAL,
	value,
});

export const jobCountFromJobsList = value => ({
	type: types.JOB_COUNT_FROM_JOBS_LIST,
	value,
});

export const noOfDaysFromLastJob = value => ({
	type: types.NO_OF_DAYS_FROM_LAST_JOB,
	value,
});
export const showProfilePage = value => ({ type: types.SHOW_PROFILE_PAGE, value });
export const showConsultantModal = value => ({
	type: types.SHOW_CONSULTANT_MODAL,
	value,
});

export const showOnboardingModal = value => ({
	type: types.SHOW_ONBOARDING_MODAL,
	value,
});

export const showCompanyChangeModal = value => ({
	type: types.SHOW_CHANGE_COMPANY_MODAL,
	value,
});

export const setIsWorkspaceUpdated = value => ({
	type: types.SET_IS_WORKSPACE_UPDATED,
	value,
});

export const setUserDetails = values => ({
	type: types.SET_USER_DETAILS,
	values,
});
export const updateUserDetails = values => ({
	type: types.UPDATE_USER_DETAILS,
	values,
});

export const showUploadDocForm = () => ({
	type: modalTypes.SHOW_UPLOAD_DOC,
});

export const hideUploadDocForm = () => ({
	type: modalTypes.HIDE_UPLOAD_DOC,
});

/**
 * Display notification toast
 * @param {Object} data
 * @param {("success" | "info" | "warning" | "error")} data.message_type
 * @param {string} data.message
 * @param {number} [data.duration=6000]
 */
export const sendNotification = data => ({
	type: notificationTypes.OPEN_NOTIFICATION,
	...data,
});

export const closeNotification = () => ({
	type: notificationTypes.CLOSE_NOTIFICATION,
});

export const openLoader = () => ({
	type: loaderTypes.OPEN_LOADER,
});

export const closeLoader = () => ({
	type: loaderTypes.CLOSE_LOADER,
});

export const updateEmployerType = payload => ({
	type: UPDATE_EMPLOYER_TYPE,
	payload,
});

export const showJobFeedback = (data, page) => ({
	type: types.SHOW_FEEDBACK_FORM,
	payload: data.id,
	page,
});

export const hideJobFeedback = (data, page) => ({
	type: types.HIDE_FEEDBACK_FORM,
	payload: data.id,
	page,
});

export const changeFeedback = (data, page) => ({
	type: types.CHANGE_FEEDBACK,
	payload: data.id,
	feedback: data.feedback,
	page,
});

export const changeFeedbackUpdate = (data, page) => ({
	type: types.CHANGE_FEEDBACK_UPDATE,
	payload: data.id,
	page,
});

export const changeApplicationStatus = (data, page) => ({
	type: types.CHANGE_APPLICATION_STATUS,
	payload: data.id,
	status: data.status,
	page,
});

export const submitApplicationFeedback = data => ({
	type: types.SUBMIT_APPLICATION_FEEDBACK,
	...data,
});

export const updateJobApplication = data => ({
	type: types.UPDATE_JOB_APPLICATION,
	payload: data.id,
	...data,
});

export const updateJobApplicantsList = (data, pageNo) => ({
	type: types.UPDATE_JOB_APPLICATION_LIST,
	data,
	pageNo,
});

export const getJobApplicants = (jobId, pageNo) => ({
	type: types.JOB_APPLICATION_REQUESTED,
	jobId,
	pageNo,
});

export const updateJobApplicantsCount = count => ({
	type: types.JOB_APPLICATION_COUNT,
	count,
});

export const applicantsPageChange = page => ({
	type: types.JOB_APPLICATION_PAGE_CHANGE,
	page,
});

export const getMatchedApplicants = jobId => ({
	type: types.JOB_MATCHED_APPLICATION_REQUESTED,
	jobId,
});

export const updatedMatchedCandidate = data => ({
	type: types.JOB_MATCHED_APPLICATION_UPDATE,
	data,
});
export const openSearchbar = data => ({
	type: SearchbarTypes.SHOW_SEARCHBAR,
	...data,
});

export const closeSearchbar = () => ({
	type: SearchbarTypes.HIDE_SEARCHBAR,
});

export const toggleHrNumberVerificationModal = (hrNumber, jobId) => ({
	type: modalTypes.TOGGLE_HR_NUM_VERIFICATION_MODAL,
	hrNumber,
	jobId,
});

export const showJobVerificationModal = (showUpdateEmailOption = false) => ({
	type: modalTypes.SHOW_JOB_VERIFICATION_MODAL,
	showUpdateEmailOption,
});

export const hideJobVerificationModal = () => ({
	type: modalTypes.HIDE_JOB_VERIFICATION_MODAL,
});

export const showPocDocUploadModal = () => ({
	type: modalTypes.SHOW_POC_DOC_UPLOAD_MODAL,
});

export const hidePocDocUploadModal = () => ({
	type: modalTypes.HIDE_POC_DOC_UPLOAD_MODAL,
});

export const toggleEditWarningModal = (changedField = "", data = null) => ({
	type: modalTypes.TOGGLE_EDIT_WARNING_MODAL,
	changedField,
	data,
});

export const postNewJobAndExpireOldJob = (newJob, jobId) => ({
	type: types.POST_NEW_JOB_EXPIRE_OLD_JOB,
	newJob,
	jobId,
});

export const updateJobPhoneNumDetails = ({ jobId, employer, hr }) => ({
	type: types.UPDATE_SELECTED_JOB_HR_NUM,
	jobId,
	employer,
	hr,
});

export const updateJobEmailVerified = jobId => ({
	type: types.UPDATE_SELECTED_JOB_EMAIL_VERIFIED,
	jobId,
});

export const setAllATSFunnelSteps = funnelList => {
	const funnelSteps = funnelList.filter(val => {
		return val?.json_data?.is_visible === true;
	});
	return { type: types.SET_ALL_ATS_FUNNEL_STEPS, data: funnelSteps };
};

export const setAllATSFunnelVerboseSteps = funnelSteps => ({
	type: types.SET_ALL_ATS_FUNNEL_VERBOSE_STEPS,
	data: funnelSteps,
});

export const updateATSFunnelStep = (updatedFunnelData, selectedJobId) => ({
	type: jobsActionTypes.updateATSFunnelSteps,
	payload: { updatedFunnelData, selectedJobId },
});

export const addATSFunnelStep = (funnelId, selectedJobId) => ({
	type: jobsActionTypes.addATSFunnelStep,
	payload: { funnelId, selectedJobId },
});

export const fetchATSFunnelSteps = () => async dispatch => {
	try {
		const resp = await getATSFunnelSteps();
		if (resp?.data && resp?.status === 200) {
			dispatch(setAllATSFunnelSteps(resp?.data));
			dispatch(setAllATSFunnelVerboseSteps(resp?.data));
		}
	} catch (error) {
		console.log("Error in fetching funnels");
	}
};

export const clearEditFormData = () => ({
	type: types.CLEAR_EDIT_FORM,
});
export const setAgeUnleashStatus = data => ({
	type: types.SET_AGE_UNLEASH_STATUS,
	data,
});
export const addEccPreferenceConfig = eccPreferenceConfig => ({
	type: apiTypes.ADD_ECC_PREFERENCE_CONFIG,
	eccPreferenceConfig,
});
export const addCreditExpiryOptions = creditExpiryOptions => ({
	type: apiTypes.ADD_CREDIT_EXPIRY_OPTIONS,
	creditExpiryOptions,
});

export const setCityAreasFilter = (data = {}) => ({
	type: types.SET_CITY_AREAS_FILTER,
	data,
});

export const setSelectedAreas = (data = {}) => ({
	type: types.SET_SELECTED_AREAS,
	data,
});

export const setSelectedJobStatus = (data = "") => ({
	type: types.SET_SELECTED_JOB_STATUS,
	data,
});

export const setTotalFilteredJobs = (data = "") => ({
	type: types.SET_TOTAL_FILTERED_JOBS,
	data,
});

export const setFilteredJobs = (data = []) => ({
	type: types.SET_FILTERED_JOBS,
	data,
});

export const updateFilteredJobs = (data = {}) => ({
	type: types.UPDATE_FILTERED_JOBS,
	data,
});

export const setFiltersApplied = (data = false) => ({
	type: types.SET_FILTERS_APPLIED,
	data,
});

export const setFiltersPresent = (data = true) => ({
	type: types.SET_FILTERS_PRESENT,
	data,
});

export const setCompanyDocs = companyDocs => ({
	type: apiTypes.FETCH_COMPANY_DOCS_SUCCESS,
	companyDocs,
});

export const companyDocsFetchFailed = () => ({
	type: apiTypes.FETCH_COMPANY_DOCS_FAILED,
});

export const updateCreatorOrgStatus = data => ({
	type: types.UPDATE_CURR_CREATOR_ORG_STATUS,
	data,
});

export const fetchCompanyDocs = (userId, orgId) => async dispatch => {
	try {
		const resp = await getUserDocs(userId, orgId);
		if (resp?.status === 200 && resp?.data) {
			dispatch(setCompanyDocs(resp.data));
			if (resp?.data?.id) {
				// TODO: Temp hack for company entity V0 release
				dispatch(updateCreatorOrgStatus(resp?.data?.status));
			}
			return;
		}
		// eslint-disable-next-line no-empty
	} catch (e) {}
	dispatch(companyDocsFetchFailed());
};

export const setTotalAreas = (data = 0) => ({
	type: types.SET_TOTAL_AREAS,
	data,
});

export const setTotalCities = (data = 0) => ({
	type: types.SET_TOTAL_CITIES,
	data,
});

export const setJobStatusCount = (data = {}) => ({
	type: types.SET_JOB_STATUS_COUNT,
	data,
});

export const setNewJobPosted = (data = false) => ({
	type: types.NEW_JOB_POSTED,
	data,
});

export const setWorkspace = (data = {}) => ({
	type: types.SET_WORKSPACE,
	data,
});

export const updateWorkspace = (data = {}) => ({
	type: types.UPDATE_WORKSPACE,
	data,
});

export const uploadPocDocs = (
	userId,
	orgId,
	docId,
	payload,
	isHr,
	cb = () => null
) => async dispatch => {
	dispatch(openLoader());
	try {
		const res = await (docId
			? patchPocDoc(userId, orgId, docId, payload)
			: uploadPocDoc(userId, orgId, payload));

		const { status, data } = res || {};
		if ((status === 201 || status === 200) && data) {
			dispatch(
				updatePocDoc(
					{
						id: data?.id,
						type: data?.type,
						poc_file: data?.poc_file,
						poc_file2: data?.poc_file2,
						pocfile_name: data?.pocfile_name,
						pocfile2_name: data?.pocfile2_name,
						pocFileObj: null,
						pocFileObj2: null,
						poc_verification_status: data?.poc_verification_status,
						edit: false,
						diff: false,
					},
					isHr
				)
			);
			cb(data);
		}
	} catch (e) {
		console.log(e);
	}
	dispatch(closeLoader());
};

export const toggleKnowMoreModal = (show = false) => ({
	type: modalTypes.TOGGLE_KNOW_MORE_MODAL,
	show,
});

export const toggleBuyCoinsSideDraw = (show = false, coins = 600) => ({
	type: monetisationTypes.TOGGLE_BUY_COINS_SIDE_DRAW,
	show,
	coins,
});

export const updateTotalCoins = (payLoad = null) => ({
	type: monetisationTypes.TOTAL_COIN_BALANCE,
	payLoad,
});
export const updateAccountId = (payLoad = null) => ({
	type: monetisationTypes.ACCOUNT_ID,
	payLoad,
});

export const setSelectedPremiumFilters = (data = "") => ({
	type: types.SET_SELECTED_PREMIUM_FILTERS,
	data,
});

export const buyCoins = (
	payLoad = {
		show: false,
		coins: null,
		moneyAmount: null,
		packType: null,
		imageURL: "",
	}
) => ({
	type: monetisationTypes.BUY_COINS,
	payLoad,
});

export const updateApnaCoinProduct = (payload = {}) => ({
	type: monetisationTypes.UPDATE_APNA_COIN_PRODUCT,
	payload,
});

export const updateExtraCoinsRequired = (payLoad = 0) => ({
	type: monetisationTypes.EXTRA_COINS_REQUIRED,
	payLoad,
});

export const updatePaymentSuccess = (payLoad = false) => ({
	type: monetisationTypes.PAYMENT_STATUS_SUCCESS,
	payLoad,
});

export const toggleRenewalSuccessModal = (payLoad = false) => ({
	type: monetisationTypes.RENEWAL_SUCCESS_MODAL,
	payLoad,
});

export const setShowActivationPlansPopup = (payload = false) => ({
	type: monetisationTypes.SHOW_ACTIVATION_PLANS_POPUP,
	payload,
});

export const updateDebitCoinsApiDetails = (
	payLoad = {
		jobId: "",
		service: {},
		status: 1,
		jobData: {},
		jobActivationPlans: [],
	}
) => ({
	type: monetisationTypes.DEBIT_COINS_DETAILS,
	payLoad,
});

export const setInCallJobDetails = value => ({
	type: internalUserTypes.SET_IN_CALL_JOB_DETAILS,
	data: value,
});

export const setInCallJobsPollCount = value => ({
	type: internalUserTypes.SET_IN_CALL_POLL_COUNTS,
	data: value,
});

export const setEmployerFeedbackOptions = (data = false) => ({
	type: types.SET_EMPLOYER_FEEDBACK_OPTIONS,
	data,
});

export const setAllowWFHOptionsForPostingJob = (data = true) => ({
	type: types.SET_JOBS_WFH_ALLOW_OPTION,
	data,
});

export const setAllCities = (data = []) => ({
	type: apiTypes.SET_ALL_STATES,
	data,
});

export const setInternalDashboardType = value => ({
	type: internalUserTypes.SET_INTERNAL_DASHBOARD_TYPE,
	value,
});

export const setIsAccountDetailsFetched = (payload = true) => ({
	type: monetisationTypes.IS_ACCOUNT_DETAILS_FETCHED,
	payload,
});

export const setIsEmployerKaleyraCallRequested = (data = false) => ({
	type: types.UPDATE_IS_EMPLOYER_KALEYRA_CALL_REQUESTED,
	data,
});
export const toggleActivationWarningModal = (payload = false) => ({
	type: internalUserTypes.SHOW_UR_JOB_ACTIVATION_WARNING_MODAL,
	payload,
});

export const setTotalPaidJobsCount = (payload = 0) => ({
	type: monetisationTypes.PAID_JOBS_COUNT,
	payload,
});

export const showDeleteModal = (payload = []) => ({
	type: modalTypes.SHOW_DELETE_JOB_MODAL,
	payload,
});

export const hideDeleteModal = (payload = []) => ({
	type: modalTypes.HIDE_DELETE_JOB_MODAL,
	payload,
});

export const deleteJobIdState = (payload = "") => ({
	type: types.REMOVE_JOB_FROM_STORE,
	payload,
});

export const toggleCSVDownloadPopup = (
	payload = {
		showPopup: false,
		isDownloadSource: false,
		filename: "",
		jobId: "",
	}
) => ({
	type: modalTypes.TOGGLE_CSV_DOWNLOAD_POPUP,
	payload,
});

export const internalVerificationStatusFetchAndSet = data => async dispatch => {
	let payload = {};
	let structuredStepsData = {};
	try {
		dispatch(openLoader());

		const resp = await verificationDetailsAPI(data);
		if (resp?.status === 200) {
			payload = { ...resp?.data };
			Object.values(VERIFICATION_STEPS).forEach(val => {
				const stepData = resp?.data?.verification_steps?.find(
					el => el?.step === val
				);
				if (stepData)
					structuredStepsData = { ...structuredStepsData, [val]: stepData };
			});
			payload = { ...payload, structuredStepsData };
		}
	} catch (error) {
		console.log(error);
	}
	dispatch(closeLoader());
	dispatch({
		type: types.FETCH_AND_SET_VERIFICATION_DETAILS,
		payload,
	});
};

export const internalFetchAndSetJobDetails = jobId => async dispatch => {
	try {
		dispatch(openLoader());

		const resp = await getJobDetail(jobId);
		if (resp?.status === 200) {
			dispatch(selectedJob(resp?.data));
		}
	} catch (error) {
		console.log(error);
	}
	dispatch(closeLoader());
};

export const setAdminMembersConfig = (data = {}) => ({
	type: apiTypes.ADD_ADMIN_MEMBERS_CONFIG,
	data,
});

export const updateAdminMembersTableData = (data = {}) => ({
	type: apiTypes.ADD_ADMIN_MEMBERS_TABLE_DATA,
	data,
});
export const showComplimentaryCoinsMessage = (payload = false) => ({
	type: monetisationTypes.SHOW_COMPLIMENTARY_COINS_MESSAGE,
	payload,
});

export const duplicateJobFound = (payload = false) => ({
	type: types.DUPLICATE_JOB_FOUND,
	payload,
});

export const updateCallingLatestVerificationAPIFlag = (payload = false) => ({
	type: types.UPDATE_CALL_LATEST_VERIFICATION_API_FLAG,
	payload,
});

export const refetchActivationPlans = (payload = false) => ({
	type: monetisationTypes.REFETCH_ACTIVATION_PLANS,
	payload,
});
export const updateVerificationModalState = (data = false) => ({
	type: types.UPDATE_VERIFICATION_MODAL_OPEN_STATE,
	payload: data,
});

export const setVerificationStatusData = payload => ({
	type: types.SET_VERIFICATION_DETAILS,
	payload,
});

export const setAllowFieldJobOptionsForPostingJob = (data = true) => ({
	type: types.SET_JOBS_FIELD_JOB_ALLOW_OPTION,
	data,
});

export const updateSelectedActivationPlan = payload => ({
	type: monetisationTypes.UPDATE_SELECTED_ACTIVATION_PLAN,
	payload,
});

export const updateRefundDetails = (
	payload = {
		coins: 0,
		jobId: "",
	}
) => ({
	type: monetisationTypes.UPDATE_REFUND_DETAILS,
	payload,
});

export const updateShowApnaPromiseModal = (payload = false) => ({
	type: monetisationTypes.SHOW_APNA_PROMISE_MODAL,
	payload,
});

export const setExperienceTighteningConfig = (data = {}) => ({
	type: types.SET_EXPERIENCE_TIGHTENING_CONFIG,
	data,
});

export const setSelectedCategoryIdFromJobForm = (data = "") => ({
	type: types.SET_SELECTED_CATEGORY_ID_IN_JOB_POST_FORM,
	data,
});

export const updateFreeBannerJobs = (payload = 0) => ({
	type: monetisationTypes.FREE_BANNER_JOBS,
	payload,
});

export const showMissingFieldsPanel = (payload = false) => ({
	type: types.SHOW_MISSING_FIELDS_INFO_PANEL,
	payload,
});

export const saveMissingFields = (payload = []) => ({
	type: types.SAVE_JPF_MISSING_FIELDS,
	payload,
});

export const setIsJobPostFormError = (payload = false) => ({
	type: types.SET_IS_JPF_ERROR,
	payload,
});
export const updateExpiredFreeJobsList = (
	payload = {
		expiredFreeJobsList: [],
		totalJobs: 0,
		yLimitBreachedJobs: 0,
	}
) => ({
	type: monetisationTypes.EXPIRED_FREE_JOBS_LIST,
	payload,
});

export const updateInvalidRetryModal = (
	payload = {
		show: false,
		coins: 0,
		modalType: 0,
	}
) => ({
	type: monetisationTypes.INVALID_RETRY_PAYMENT_MODAL,
	payload,
});

export const isFirstPurchase = (payload = false) => ({
	type: monetisationTypes.IS_FIRST_PURCHASE,
	payload,
});

export const fetchTransactions = (payload = false) => ({
	type: monetisationTypes.FETCH_TRANSACTIONS,
	payload,
});

export const setAllowWFOOptionsForPostingJob = (data = true) => ({
	type: types.SET_JOBS_WFO_ALLOW_OPTION,
	data,
});
export const setIsFreeJobsDetailsFetched = (payload = false) => ({
	type: monetisationTypes.IS_FREE_JOB_DETAILS_FETCHED,
	payload,
});
export const updateMinBalanceForTransfer = (payload = 600) => ({
	type: monetisationTypes.MIN_BALANCE_FOR_TRANSFER,
	payload,
});

export const setJobLocationTypeForPostingJob = (wfo, wfh, fjo) => ({
	type: types.SET_JOB_ALLOW_OPTION,
	wfo,
	wfh,
	fjo,
});
export const updateJobLocationTypeForPostingJob = (key, payload) => ({
	type: types.UPDATE_JOB_ALLOW_OPTION,
	key,
	payload,
});

export const setEducationOptions = payload => ({
	type: types.SET_EDUCATION_OPTIONS,
	payload,
});
export const setEnglishOptions = payload => ({
	type: types.SET_ENGLISH_OPTIONS,
	payload,
});

export const setJobTags = (
	skillTags,
	assetTags,
	degreeTags,
	languageTags,
	salaryPerksTags
) => ({
	type: types.SET_JOB_TAGS,
	skillTags,
	assetTags,
	degreeTags,
	languageTags,
	salaryPerksTags,
});

export const updateJobTag = (key, payload) => ({
	type: types.UPDATE_JOB_TAG,
	key,
	payload,
});

export const setRecommendationType = payload => ({
	type: types.SET_RECOMMENDATION_TYPE,
	payload,
});

export const setCategoryDropDownList = payload => ({
	type: types.SET_CATEGORY_DROPDOWN_LIST,
	payload,
});

export const setTagsMaxCount = payload => ({
	type: types.SET_MAX_VALUES_FOR_TAGS,
	payload,
});
export const updateMaxValuesForTags = (key, payload) => ({
	type: types.UPDATE_MAX_VALUES_FOR_TAGS,
	key,
	payload,
});

export const setAllowedJobTitles = payload => ({
	type: types.SET_ALLOWED_JOB_TITLES,
	payload,
});

export const updateJobPostingModal = (
	payload = { show: false, modalType: JOB_POSTING_MODAL_TYPE.LOADER }
) => ({
	type: monetisationTypes.JOB_POSTING_MODAL,

	payload,
});
