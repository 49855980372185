import {
	UPDATE_RECENT_CANDIDATE_QUEUE,
	RESET_RECENT_CANDIDATE_QUEUE,
} from "../constants/types";
import { addToQueue } from "../utils/helper";

export const setRecentCandidateQueue = (updatedCandidates, jobId, tab, page) => {
	return {
		type: UPDATE_RECENT_CANDIDATE_QUEUE,
		payload: {
			updatedCandidates,
			jobId,
			tab,
			page,
		},
	};
};

export const updatedRecentCandidateQueue = (user, jobId, tab, page) => async (
	dispatch,
	getState
) => {
	const recentQueue = getState().teccRecentlyUpdatedQueue || {};
	const candidate = {
		...user,
		inQueue: true,
	};
	const updatedCandidates = addToQueue(recentQueue?.updatedCandidates || [], candidate);
	dispatch(setRecentCandidateQueue(updatedCandidates, jobId, tab, page));
};

export const resetRecentCandidateQueue = () => {
	return {
		type: RESET_RECENT_CANDIDATE_QUEUE,
	};
};
