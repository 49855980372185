import {
	CONNECTION_TABS,
	MODAL,
	CONNECTION_REMARK,
	TOAST_STATUS,
	DEFER_CONNECTION_UPDATE_DELAY,
} from "../constants";
import {
	POST_CALL_CONNECT_STEP_ONE,
	SHOW_CONNECTED_ON_CALL,
	MOVED_TO_REJECTED,
	CALL_FAILED_STEP_ONE,
	SHOW_CONNECTED_ON_WHATSAPP,
	SHOW_CONNECTED_ON_MOBILE_CALL,
	CLOSE_MODAL,
	UPDATE_TOAST,
	SHOW_TOAST,
	CLEAR_TOAST,
} from "../constants/types";
import { updateConnectionStatus, rejectCandidate } from "../utils/apiClients";
import {
	initiateDeferredConnectionUpdate,
	initDeferedToast,
} from "./update-defered-job-applications-action";
import { trackChangeEvents, trackPopupEvents } from "../utils/trackers";
import { getConnectionRemark } from "../utils/helper";

const {
	showCallConnectStepOne,
	showConnectedOnCall,
	rejectedCandidate,
	showCallFailedStepOne,
	connectedOnWhatsapp,
	showDidConnectOptions,
} = MODAL;

export const postCallConnectStepOne = (user, jobId, tab, page) => {
	return {
		type: POST_CALL_CONNECT_STEP_ONE,
		payload: {
			modal: showCallConnectStepOne,
			user,
			jobId,
			tab,
			page,
		},
	};
};

export const movedToConnected = user => {
	if (!user?.prev_connection_status) trackChangeEvents.connectionStatusChange();
	return {
		type: SHOW_CONNECTED_ON_CALL,
		payload: {
			modal: showConnectedOnCall,
			user,
		},
	};
};

export const moveToRejected = user => {
	if (!user?.prev_connection_status) trackChangeEvents.connectionStatusChange();
	return {
		type: MOVED_TO_REJECTED,
		payload: {
			modal: rejectedCandidate,
			user,
		},
	};
};

export const postCallFailedStepOne = (user, jobId, tab, page) => {
	return {
		type: CALL_FAILED_STEP_ONE,
		payload: {
			modal: showCallFailedStepOne,
			user,
			jobId,
			tab,
			page,
		},
	};
};

export const postWhatsappConnect = (user, jobId, tab, page) => {
	trackPopupEvents.whatsappConnect();
	if (!user?.prev_connection_status) trackChangeEvents.connectionStatusChange();
	return {
		type: SHOW_CONNECTED_ON_WHATSAPP,
		payload: {
			modal: connectedOnWhatsapp,
			user,
			jobId,
			tab,
			page,
		},
	};
};

export const postMobileCallInitiated = (user, jobId, tab, page) => {
	return {
		type: SHOW_CONNECTED_ON_MOBILE_CALL,
		payload: {
			modal: showDidConnectOptions,
			user,
			jobId,
			tab,
			page,
		},
	};
};

export const closeConnectionUpdateModal = () => {
	return { type: CLOSE_MODAL };
};

export const handleDisconnectedToast = (user, jobId, tab, page) => {
	return {
		type: UPDATE_TOAST,
		payload: {
			showToast: true,
			toastStatus: TOAST_STATUS.DISCONNECTED,
			user,
			jobId,
			tab,
			page,
		},
	};
};

export const handleCallFailedToast = (user, jobId, tab, page) => {
	return {
		type: UPDATE_TOAST,
		payload: {
			showToast: true,
			toastStatus: TOAST_STATUS.FAILED,
			user,
			jobId,
			tab,
			page,
		},
	};
};

export const handleCallSuccessToast = (user, jobId, tab, page) => {
	return {
		type: UPDATE_TOAST,
		payload: {
			showToast: true,
			toastStatus: TOAST_STATUS.CALL_SUCCESS,
			user,
			jobId,
			tab,
			page,
		},
	};
};

export const handleRejectedToast = (user, jobId, tab, page) => {
	if (!user?.prev_connection_status) trackChangeEvents.connectionStatusChange();
	return {
		type: UPDATE_TOAST,
		payload: {
			showToast: true,
			toastStatus: TOAST_STATUS.REJECTED,
			user,
			jobId,
			tab,
			page,
		},
	};
};

export const handleConnectedToast = (user, jobId, tab, page) => {
	return {
		type: UPDATE_TOAST,
		payload: {
			showToast: true,
			toastStatus: TOAST_STATUS.CONNECTED,
			user,
			jobId,
			tab,
			page,
		},
	};
};

export const handleNotConnectedToast = (user, jobId, tab, page) => {
	return {
		type: UPDATE_TOAST,
		payload: {
			showToast: true,
			toastStatus: TOAST_STATUS.NOT_CONNECTED,
			user,
			jobId,
			tab,
			page,
		},
	};
};

export const closeToast = () => {
	return {
		type: UPDATE_TOAST,
		payload: {
			showToast: false,
			toastStatus: null,
			user: null,
			jobId: null,
			tab: null,
			page: null,
		},
	};
};

export const handleRestoreToast = (user, jobId, tab, page) => dispatch => {
	if (user?.prev_connection_status === CONNECTION_TABS.CONNECTED) {
		const timerRef = setTimeout(() => {
			dispatch(handleConnectedToast(user, jobId, tab, page));
		}, DEFER_CONNECTION_UPDATE_DELAY);
		dispatch(initDeferedToast(user, jobId, timerRef));
	}
	if (user?.prev_connection_status === CONNECTION_TABS.NEW_CANDIDATES) {
		const timerRef = setTimeout(() => {
			dispatch(handleNotConnectedToast(user, jobId, tab, page));
		}, DEFER_CONNECTION_UPDATE_DELAY);
		dispatch(initDeferedToast(user, jobId, timerRef));
	}
};

export const updateCandidateTabs = (
	user,
	jobId,
	currentTab,
	page,
	moveToTab,
	updatedUser = {}
) => dispatch => {
	dispatch(
		initiateDeferredConnectionUpdate(
			user,
			jobId,
			currentTab,
			page,
			moveToTab,
			updatedUser
		)
	);
};

export const handleNumberShare = (user, jobId, tab, page) => async dispatch => {
	const response = await updateConnectionStatus(
		user?.application_id,
		CONNECTION_TABS.CONNECTED,
		getConnectionRemark(CONNECTION_REMARK.CALL)
	);
	if (response.status === 200) {
		dispatch(movedToConnected(user));
		dispatch(updateCandidateTabs(user, jobId, tab, page, CONNECTION_TABS.CONNECTED));
	}
};

export const handleConnectOnClose = (user, jobId, tab, page) => async dispatch => {
	const response = await updateConnectionStatus(
		user?.application_id,
		CONNECTION_TABS.CONNECTED,
		getConnectionRemark(CONNECTION_REMARK.CALL)
	);
	if (response.status === 200) {
		dispatch(updateCandidateTabs(user, jobId, tab, page, CONNECTION_TABS.CONNECTED));
		const timerRef = setTimeout(() => {
			dispatch(handleConnectedToast(user, jobId, tab, page));
		}, DEFER_CONNECTION_UPDATE_DELAY);
		dispatch(initDeferedToast(user, jobId, timerRef));
	}
};

export const handleRejectedStatusUpdate = (user, jobId, tab, page) => async dispatch => {
	const response = await rejectCandidate(
		user?.application_id,
		getConnectionRemark(CONNECTION_REMARK.REJECT)
	);
	if (response.status === 200) {
		dispatch(updateCandidateTabs(user, jobId, tab, page, CONNECTION_TABS.REJECTED));
		dispatch(moveToRejected(user, jobId, tab, page));
	}
};

export const handleWhatsappCandidate = (user, jobId, tab, page) => async (
	dispatch,
	getState
) => {
	const jobData = getState().jobData?.selectedJobDetails;
	const orgName = jobData?.organization?.name;
	const jobTitle = jobData?.title;
	const text = `Hi ${user?.full_name},\nI got your application for ${jobTitle} in our company - ${orgName} on Apna platform and I want to speak to you. Please send your CV on WhatsApp to me so I can go through it and call you for an interview. Thanks!`;
	const waLink =
		user?.json_data?.candidate_wa_deeplink || `http://wa.me/+91${user?.username}`;
	const url = encodeURI(`${waLink}?text=${text}`);

	// move to connected tab, show connected popup
	const response = await updateConnectionStatus(
		user?.application_id,
		CONNECTION_TABS.CONNECTED,
		getConnectionRemark(CONNECTION_REMARK.WHATSAPP)
	);
	if (response.status === 200) {
		dispatch(updateCandidateTabs(user, jobId, tab, page, CONNECTION_TABS.CONNECTED));
		dispatch(postWhatsappConnect(user, jobId, tab, page));
	}
	// redirect to whatsapp
	window.open(url, "_blank");
};

export const handleTrueEccNetworkError = () => ({
	type: SHOW_TOAST,
	payload: {
		toastStatus: TOAST_STATUS.NETWORK_ERROR,
	},
});

export const showToast = (status, message) => ({
	type: SHOW_TOAST,
	payload: {
		toastStatus: {
			value: status,
			message,
		},
	},
});

export const clearToast = () => ({
	type: CLEAR_TOAST,
});
