import React from "react";

function UsersIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={33}
			height={32}
			fill="none"
			viewBox="0 0 33 32"
		>
			<g fill="#4D3951" clipPath="url(#prefix__clip0)">
				<path d="M31.661 19.665l-4.989-2a1.326 1.326 0 01-.838-1.234V15.26a5.307 5.307 0 002.666-4.593V8.229a5.486 5.486 0 00-4.809-5.537 5.347 5.347 0 00-4.106 1.355A5.33 5.33 0 0017.833 8v2.667A5.313 5.313 0 0020.5 15.26v1.17a6.318 6.318 0 01-2.133 2.754c-.091.059-.179.124-.267.184l1.215.487a3.983 3.983 0 012.518 3.714v3.098A3.941 3.941 0 0121.588 28h9.579a1.332 1.332 0 001.333-1.333v-5.764a1.33 1.33 0 00-.839-1.238z" />
				<path d="M18.328 22.332l-4.99-2a1.343 1.343 0 01-.608-.489c-.15-.22-.23-.48-.23-.746v-.601a9.56 9.56 0 004.33-1.487 1.3 1.3 0 00.524-.892 1.302 1.302 0 00-.282-.996 7.984 7.984 0 01-1.905-4.404 5.173 5.173 0 00-2.534-4.666A5.392 5.392 0 005.241 7.97a5.395 5.395 0 00-.741 2.697 7.921 7.921 0 01-1.905 4.456 1.32 1.32 0 00-.113 1.494c.09.153.211.287.354.392a9.568 9.568 0 004.33 1.487v.601a1.332 1.332 0 01-.838 1.237l-4.99 2c-.246.1-.458.27-.608.49-.15.22-.23.48-.23.745v3.098A1.335 1.335 0 001.833 28h16a1.333 1.333 0 001.333-1.333v-3.098a1.337 1.337 0 00-.838-1.237z" />
			</g>
			<defs>
				<clipPath id="prefix__clip0">
					<path fill="#fff" d="M0 0h32v32H0z" transform="translate(.5)" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default UsersIcon;
