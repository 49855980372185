/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import { ApnaTimeLogo } from "../../assets/svg";
import { Container } from "../../styles/commonStyles";
import { mediaMaxWidth } from "../../utils/mediaQuery";
import Mixpanel from "../../utils/Mixpanel";
import homepageData from "../../cms/homepage-data";
import TopCompanies from "../Login/TopCompanies";
import PastHighlights from "../Login/PastHighlights";
import Text from "../../components/JobVerificationComponents/common/Text";
import PrimaryButton from "../../components/JobVerificationComponents/common/Button/PrimaryButton";
import CommonUtils from "../../utils/commonUtils";

const { homepage } = homepageData || {};

const SOURCE = "404 NotFound Page";
const READ_MORE_CTA_ID = "banner-read-more-link";

function NotFoundPage({ location }) {
	const history = useHistory();
	const token = CommonUtils.getItemFromLocalStorage("__token__");
	const getZonalLandingPageName = () => {
		if (location?.pathname === "/apnahire-east-and-central-india") {
			return "centralEastZone";
		}
		if (location?.pathname === "/apnahire-west-india") {
			return "westZone";
		}
		if (location?.pathname === "/apnahire-north-india") {
			return "northZone";
		}
		if (location?.pathname === "/apnahire-south-india") {
			return "southZone";
		}
		return null;
	};

	const zonalLandingPageName = getZonalLandingPageName();

	const handleBannerClick = e => {
		Mixpanel.track("Top banner click", {
			actionType: "Banner",
			section: "Top Banner",
			...(e.target.id === READ_MORE_CTA_ID && { cta: "Learn More" }), // Event bubbling
			bannerType: "New city launch banner",
			Source: SOURCE,
		});
	};

	useEffect(() => {
		Mixpanel.track("Landed on 404 Not Found Page", {
			Source: SOURCE,
			pathname: location?.pathname,
		});
	}, []);

	const goToDashboard = () => {
		if (token) {
			history.push("/");
		} else {
			history.push("/login");
		}
	};

	return (
		<Wrapper>
			<div>
				<Topbar hideOnMobile onClick={handleBannerClick}>
					<div id="banner-title">
						<div
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: homepage?.data?.attributes?.topBannerTitle || "",
							}}
						/>
						<a
							id={READ_MORE_CTA_ID}
							href={homepage?.data?.attributes?.topBannerBlogUrl}
							target="_blank"
							rel="noopener noreferrer"
						>
							Learn More
							<span id="arrow-right-icon" />
						</a>
					</div>
				</Topbar>
				<Navbar hideOnMobile>
					<NavItemsContainer>
						<ApnaTimeLogo />
					</NavItemsContainer>
				</Navbar>
			</div>

			<NotFoundBanner>404</NotFoundBanner>
			<ContainerBanner>
				<Text weight="bold" size="lg" color="#172B4D">
					Uh oh, we can&apos;t seem to find the page you&apos;re looking for.
				</Text>
				<CTAContainer>
					<PrimaryButton onClick={goToDashboard}>Go to dashboard</PrimaryButton>
				</CTAContainer>
			</ContainerBanner>
			<TopCompanies zonalLandingPageName={zonalLandingPageName} />
			<MobileContainer hideOnMobile>
				<PastHighlights />
			</MobileContainer>
		</Wrapper>
	);
}

export default withRouter(NotFoundPage);
const CTAContainer = styled.div`
	display: flex;
	gap: 24px;
`;

const ContainerBanner = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: center;

	a {
		color: #2bb792;
	}
`;
const NotFoundBanner = styled.div`
	font-size: 22vh;
	width: 100%;
	text-align: center;
	height: auto;
	color: #4d3951;

	${mediaMaxWidth.medium`
		font-size: 200px;
	`}
`;
const Wrapper = styled.div`
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;

	${mediaMaxWidth.medium`
		justify-content: space-around;
	`}
`;

const Topbar = styled.div`
	display: flex;
	background: #4d3951;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 48px;

	br {
		display: none;

		${mediaMaxWidth.small`
			display: block;
		`}
	}

	${mediaMaxWidth.medium`
		padding: 6px 20px;
		min-height: 54px;
		display: ${props => (props.hideOnMobile ? "none" : "flex")}
	`}

	div#banner-title {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		font-size: 16px;
		font-weight: 500;
		text-align: center;
		color: #eaedf2;
		line-height: 1.7;

		${mediaMaxWidth.medium`
			font-size: 10px;
		`}
	}

	a#banner-read-more-link {
		margin: 0 0 0 11px;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
		color: #2bb792;

		${mediaMaxWidth.medium`
			font-size: 10px;
			margin-left: 2px;
		`}

		#arrow-right-icon {
			flex-grow: 0;
			margin: 4px 0 3px 12px;
			object-fit: contain;

			${mediaMaxWidth.medium`
				margin-left: 0px;
			`}

			svg {
				width: 17px;
				height: 12px;

				${mediaMaxWidth.medium`
					width: 11;
  					height: 8px;
				`}
			}
		}
	}
`;

const Navbar = styled.nav`
	display: block;
	background: white;

	svg {
		filter: drop-shadow(0px 11px 31px rgba(0, 0, 0, 0.1));

		${mediaMaxWidth.medium`
            height: 48px;
            width: 48px;
        `}
	}

	${mediaMaxWidth.medium`
		display: ${props => (props.hideOnMobile ? "none" : "block")}
	`}
`;

const NavItemsContainer = styled(Container)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1200px;
	margin: auto;
	padding-top: 37px;
	padding-bottom: 0;

	${mediaMaxWidth.medium`
        padding-top: 18px;
        padding-bottom: 18px;
    `}
`;

const MobileContainer = styled.div`
	display: none;
	${mediaMaxWidth.medium`
		display: block;
		display: ${props => (props.hideOnMobile ? "none" : "block")}
	`}
`;
