// auth constants
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_RAVEN_TOKEN = "SET_RAVEN_TOKEN";

// jobs
export const FETCHED_JOBS = "FETCHED_JOBS";
export const FETCHED_SUGGESTED_LEADS = "FETCHED_SUGGESTED_LEADS";
export const SET_JOB_LEADS_MAPPING = "SET_JOB_LEADS_MAPPING";
export const UPDATE_JOB_LEADS_MAPPING = "UPDATE_JOB_LEADS_MAPPING";
export const SET_SELECTED_JOB_LEADS = "SET_SELECTED_JOB_LEADS";
export const FETCHED_LEAD_PROFILES = "FETCHED_LEAD_PROFILES";
export const SET_LOADING_PROFILES = "SET_LOADING_PROFILES";

// active chats
export const SET_ACTIVE_CHAT = "SET_ACTIVE_CHAT";

// channels
export const UPDATE_CHANNELS_LIST = "UPDATE_CHANNELS_LIST";
export const UPDATE_CHANNEL_UNREAD_COUNT = "UPDATE_CHANNEL_UNREAD_COUNT";
export const RESET_CHANNEL_UNREAD_COUNT = "RESET_CHANNEL_UNREAD_COUNT";
export const GET_A_CHANNEL = "GET_A_CHANNEL";
export const UPDATE_CHHANEL_DATA = "UPDATE_CHHANEL_DATA";
export const SET_RETRY_STATE = "SET_RETRY_STATE";

// number masking call
export const CANDIDATE_CALL_INITIATED = "CANDIDATE_CALL_INITIATED";
export const CANDIDATE_CALL_FAILED = "CANDIDATE_CALL_FAILED";
export const CANDIDATE_CALL_ENDED = "CANDIDATE_CALL_ENDED";
export const RESET_CANDIDATE_CALL = "RESET_CANDIDATE_CALL";
export const CANDIDATE_CALL_ID_UPDATED = "CANDIDATE_CALL_ID_UPDATED";

// ATS
// export const UPDATE_ATS_FUNNEL_STEP = "UPDATE_ATS_FUNNEL_STEP";

export const jobsActionTypes = {
	startJobsLoading: "jobs/startJobsLoading",
	stopJobsLoading: "jobs/stopJobsLoading",
	setJobs: "jobs/setJobs",
	updateJob: "jobs/updateJob",
	clearJobs: "jobs/clearJobs",
	addJobToJobList: "jobs/addJobToJobList",
	setJobsError: "jobs/setJobsError",
	clearJobsError: "jobs/clearJobsError",
	setJobCount: "jobs/setJobCount",
	setNoOfDaysFromLastJob: "jobs/setNoOfDaysFromLastJob",
	setIsNewUserOnBoarding: "job/setIsNewUserOnBoarding",
	setSelectedJob: "job/setSelectedJob",
	setJobApplicationsStatus: "job/setJobApplicationsStatus",
	setApplicationStatus: "job/setApplicationStatus",
	updateATSFunnelSteps: "job/updateATSFunnelSteps",
	addATSFunnelStep: "job/addATSFunnelStep",
	showCreatorRecruiterModal: "job/showCreatorRecruiterModal",
};

export const channelPostsActionTypes = {
	createChannel: "channelPosts/create",
	clearChannel: "channelPosts/clearChannel",
	clearAll: "channelPosts/clearAll",
	updateChannel: "channelPosts/updateChannel",
	addMoreToChannel: "channelPosts/addMoreToChannel",
	setErrorForChannel: "channelPosts/setErrorForChannel",
	startLoadingForChannel: "channelPosts/startLoading",
	stopLoadingForChannel: "channelPosts/stopLoading",
	addSingleMessageForChannel: "channelPosts/addSingleMessageForChannel",
};

export const channelActionTypes = {
	clearActiveChannel: "channel/clearActive",
	clearAllSelectedChannels: "channel/clearAllSelectedChannels",
	selectChannel: "channel/selectChannel",
	updateChannelList: "channel/updateChannelList",
	updateChannel: "channel/updateChannel",
	openChatWindow: "channel/openChatWindow",
	closeChatWindow: "channel/closeChatWindow",
};

export const jobUserChannelTypes = {
	clearJobUserChannelMapping: "jobUserChannel/clearMapping",
	setJobUserChannelMapping: "jobUserChannel/setMapping",
	bulkJobUserChannelMapping: "jobUserChannel/bulkJobUserChannelMapping",
};

export const userProfilesActionTypes = {
	setUserProfiles: "userProfile/setUserProfiles",
};

export const modalActionTypes = {
	showConfirmationModal: "modals/showConfirmationModal",
	hideConfirmationModal: "modals/hideConfirmationModal",
};

// socket events
export const SocketEvents = {
	USER_ADDED: "user_added",
	CHANNEL_CREATED: "channel_created",
	CHANNEL_UPDATED: "channel_updated",
	CHANNEL_DELETED: "channel_deleted",
	CHANNEL_VIEWED: "channel_viewed",
	POSTED: "posted",
	POST_UNREAD: "post_unread",
	DIRECT_ADDED: "direct_added",
	TYPING: "typing",
	STATUS_CHANGED: "status_change",
	HELLO: "hello",
	EMOJI_ADDED: "emoji_added",
	OPEN_DIALOG: "open_dialog",
	USER_ACTIVATION_STATUS_CHANGED: "user_activation_status_change",
	CHANNEL_RESTORED: "channel_restored",
};

export const atsFunnelTypes = {
	createFunnelHash: "atsFunnel/createFunnelHash",
	updateFunnelHash: "atsFunnel/updateFunnelHash",
};

export const applicantDeferedTypes = {
	startDeferedStatusUpdate: "applicantDefered/startDeferedStatusUpdate",
	clearDeferedStatusUpdate: "applicantDefered/clearDeferedStatusUpdate",
};

export const eccPreferenceActionTypes = {
	addCreatedById: "eccPreference/addCreatedById",
};
