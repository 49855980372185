import firebase from "firebase/app";
import "firebase/storage";
import "firebase/remote-config";
import config from "../config";
import { REMOTE_CONFIG_FETCH_INTERVAL } from "../utils/constants";

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: config.firebase.apiKey,
	authDomain: config.firebase.authDomain,
	databaseURL: config.firebase.databaseURL,
	projectId: config.firebase.projectId,
	storageBucket: config.firebase.storageBucket,
	messagingSenderId: config.firebase.messagingSenderId,
	appId: config.firebase.appId,
};

// Initialize Firebase

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { firebase, storage as default };

export const fireBaseStorage = firebase.storage().ref();
const remoteConfig = firebase.remoteConfig();
if (remoteConfig?.settings?.minimumFetchIntervalMillis) {
	remoteConfig.settings.minimumFetchIntervalMillis = REMOTE_CONFIG_FETCH_INTERVAL;
}

export { remoteConfig };
