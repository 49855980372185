/* eslint-disable radix */
import {
	UPDATE_CHANNELS_LIST,
	UPDATE_CHANNEL_UNREAD_COUNT,
	RESET_CHANNEL_UNREAD_COUNT,
	UPDATE_CHHANEL_DATA,
} from "../constants/types";
import { updateChannelInLS } from "../utils/helpers";

const initialState = {};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case UPDATE_CHANNELS_LIST:
			updateChannelInLS({ ...state, ...payload });
			return { ...state, ...payload };
		case UPDATE_CHANNEL_UNREAD_COUNT: {
			const updatedChannel = {
				[payload.id]: { ...state[payload.id], unread_count: payload },
			};
			updateChannelInLS({ ...state, ...updatedChannel });
			return { ...state, ...updatedChannel };
		}
		case UPDATE_CHHANEL_DATA: {
			const { channelId, data } = payload;
			const updatedChannel = {
				[channelId]: { ...state[channelId], ...data },
			};
			updateChannelInLS({ ...state, ...updatedChannel });
			return { ...state, ...updatedChannel };
		}
		case RESET_CHANNEL_UNREAD_COUNT: {
			const updatedChannel = {
				[payload.id]: { ...state[payload.id], unread_count: 0 },
			};
			updateChannelInLS({ ...state, ...updatedChannel });
			return { ...state, ...updatedChannel };
		}
		default:
			return state;
	}
};
