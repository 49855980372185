import { UPDATE_JOB_STALE_STATUS } from "../constants/types";

const initialState = {};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case UPDATE_JOB_STALE_STATUS: {
			return { ...state, [payload.jobId]: payload.data };
		}

		default:
			return state;
	}
};
