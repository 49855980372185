/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable import/no-cycle */
import dayjs from "dayjs";
import Bowser from "bowser";
import { LOCAL_OPENED_CHANNEL_LIST, LOCAL_CHANNELS, CALL_DETAIL } from "../ecc/constants";
import {
	TRUE_ECC_CALL_DETAIL,
	STALE_JOBS,
	TRUE_ECC_NUM_MASK_EDU,
	TECC_PREF_POPUP_SHOWN_FOR,
} from "../true-ecc/constants";

import {
	RELEASE_VERSION,
	MONTHS_SHORT_HAND,
	APPLICATION_STATUSES,
	AUTO_OPENED_FEEDBACK,
	CANDIDATES_PAGINATION_SIZE,
	VERIFICATION_STEPS,
	APPLICATION_LOCATION_VALUES_FOR_NON_WFH,
	APPLICATION_LOCATION_VALUES_FOR_WFH,
	PAY_TYPE,
	FREE_JOBS_BANNER,
	URL_PARAMS,
	SUPPORTED_BROWSERS,
	TAG_TYPES,
	DEVICE_IDENTIFIER,
	DEVICE_INFO,
	DEVICE_REQUEST_ID,
	SHIELD_ID,
} from "./constants";
import config from "../config";
import Mixpanel from "./Mixpanel";
import { openRespondChat } from "./respond";
import { logoutV1 } from "./apiClients";
import {
	BE_VALUES_FOR_LOCATION_TYPES,
	EXPERIENCE_LEVELS,
} from "../views/PostJobFormNew/constants";
import { TAB_AWARENESS_FLOW_SHOWN } from "../true-ecc/constants/types";

export const checkForValidEmail = email => {
	const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return emailRegex.test(email);
};

export const checkForValidPhoneNumber = num => {
	const pattern = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
	return pattern.test(num);
};

export const checkForValidGstin = gstin => {
	const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
	return gstinRegex.test(gstin);
};

/* eslint-disable consistent-return */
const CommonUtils = {
	setItemInLocalStorage: (key, value) => {
		if (key) {
			localStorage.setItem(key, value);
		}
	},
	getItemFromLocalStorage: key => {
		let data = null;
		try {
			if (key) {
				data = localStorage.getItem(key);
			}
			return data;
		} catch (error) {
			return data;
		}
	},
	clearLocalStorage: () => {
		const localStorageItems = [
			"__user__",
			"__token__",
			"__version__",
			"__filters__",
			"ravenToken",
			"first_chat_loaded",
			AUTO_OPENED_FEEDBACK,
			LOCAL_OPENED_CHANNEL_LIST,
			LOCAL_CHANNELS,
			CALL_DETAIL,
			TRUE_ECC_CALL_DETAIL,
			STALE_JOBS,
			TRUE_ECC_NUM_MASK_EDU,
			TECC_PREF_POPUP_SHOWN_FOR,
			FREE_JOBS_BANNER,
			TAB_AWARENESS_FLOW_SHOWN,
			DEVICE_IDENTIFIER,
			DEVICE_INFO,
			DEVICE_REQUEST_ID,
			SHIELD_ID,
		];
		localStorageItems.forEach(item => {
			localStorage.removeItem(item);
		});
	},
	isLatestVersion: () => {
		const version = CommonUtils.getItemFromLocalStorage("__version__");
		return version && RELEASE_VERSION === version;
	},
	updateReleaseVersion: () => {
		CommonUtils.setItemInLocalStorage("__version__", RELEASE_VERSION);
	},
	logout: () => {
		if (CommonUtils.getItemFromLocalStorage("__token__")) {
			logoutV1()
				.then(() => {})
				.finally(() => {
					CommonUtils.clearLocalStorage();
					if (window.location.pathname !== "/login") {
						window.location.replace("/login");
					}
				});
		}
	},
	formatAMPM: date => {
		let hours = date.getHours();
		let minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		hours %= 12;
		hours = hours || 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? `0${minutes}` : minutes;
		const strTime = `${hours}:${minutes} ${ampm}`;
		return strTime;
	},
	convertTimestampToDate: (timestamp, formatted, reverse, includeTime) => {
		const currentDate = new Date(timestamp);
		const date = currentDate.getDate();
		const month = currentDate.getMonth();
		const year = currentDate.getFullYear();

		const padding = n => {
			return n < 10 ? `0${n}` : n;
		};
		return reverse
			? `${year}-${
					formatted ? MONTHS_SHORT_HAND[month] : padding(month + 1)
			  }-${padding(date)}${
					includeTime ? ` ${CommonUtils.formatAMPM(currentDate)}` : ""
			  }`
			: `${padding(date)}-${
					formatted ? MONTHS_SHORT_HAND[month] : padding(month + 1)
			  }-${year}${includeTime ? ` ${CommonUtils.formatAMPM(currentDate)}` : ""}`;
	},
	formatDateToString: (date, dateFormat = "DD-MMM-YYYY") => {
		const currentDate = new Date(date);
		return dayjs(currentDate).format(dateFormat);
	},
	addDays: (days, oldDate) => {
		const date = oldDate ? new Date(oldDate) : new Date();
		date.setDate(date.getDate() + days);
		return date;
	},
	subtractDays: (days, oldDate) => {
		const date = oldDate ? new Date(oldDate) : new Date();
		date.setDate(date.getDate() - days);
		return date;
	},
	getKeyByValue: (object, value) => {
		return Object.keys(object).find(key => object[key] === value);
	},
	getAllKeysByValue: (object, value) => {
		const keys = [];
		Object.keys(object).forEach(item => {
			if (object[item] === value) {
				keys.push(item);
			}
		});
		return keys;
	},
	scrollToTop: id => {
		const element = document.getElementById(id);
		if (element) element.scrollTop = 0;
	},
	differenceInDays: (date, withZeroValue, csvDownload = false) => {
		const currentDateInISO = new Date().toISOString().split("T")[0];
		const currentDate = withZeroValue ? new Date(currentDateInISO) : new Date();
		const oldDate = new Date(date);
		const diffTime = withZeroValue
			? Math.abs(oldDate.getTime() - currentDate.getTime())
			: Math.abs(oldDate - currentDate);

		const diffDays = csvDownload
			? Math.floor(diffTime / (1000 * 60 * 60 * 24))
			: Math.ceil(diffTime / (1000 * 60 * 60 * 24));

		return diffDays;
	},
	getUrlParameter: (search, name) => {
		const newName = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
		const regex = new RegExp(`[\\?&]${newName}=([^&#]*)`);
		const results = regex.exec(search);
		return results === null ? "" : decodeURIComponent(results[1]);
	},
	generateCSVDataForAppliedAndMatchedCandidates: (
		appliedOrMatchedCandidatesData,
		isAppliedCandidatesData
	) => {
		const appliedData = Object.assign([], appliedOrMatchedCandidatesData);
		const newAppliedData = [];
		appliedData.map(data => {
			if (isAppliedCandidatesData) {
				newAppliedData.push({
					Name: data.full_name,
					"Phone Number": data.username,
					Area: data.area,
					Company: data.company,
					"Current Job": data.job_type,
					Education: data.edu,
					Experience: data.experience_in_years || "Fresher",
					// Gender: "",
					Status: APPLICATION_STATUSES[data.status],
					Feedback: data.feedback,
					"Applied On": new Date(data.last_updated).toLocaleString("en-IN", {
						hour12: true,
					}),
				});
			} else {
				newAppliedData.push({
					Name: data.full_name,
					"Phone Number": data.username,
					Area: data.area,
					Company: data.company,
					"Current Job": data.job_type,
					Education: data.edu,
					Experience: data.experience_in_years || "Fresher",
					// Gender: "",
					// Status: data.status,
					// Feedback: data.feedback,
					"Last Active On": new Date(data.last_active_on).toLocaleString(
						"en-IN",
						{
							hour12: true,
						}
					),
				});
			}
		});

		return newAppliedData;
	},

	generateBulkCSVDataForAppliedCandidates: appliedCandidatesData => {
		const appliedData = Object.assign([], appliedCandidatesData);
		const newAppliedData = [];
		appliedData.map(data => {
			const wfhStatus = data?.job_wfh_status;
			const isWillingToRelocate =
				wfhStatus === false &&
				data.job_city &&
				data.city &&
				data.job_city !== data.city;
			newAppliedData.push({
				"Job Applied For": data.job_title,
				"Job Id": data.job_id,
				"Job city": data.job_city,
				"Job Area": data.job_area,
				"Recruiter Communication Preference":
					data.recruiter_communication_preference,
				"Candidate Name": data.full_name,
				"Phone Number": data.username,
				Gender: data.gender,
				"Applied On": new Date(data?.lead_created_at).toLocaleString("en-IN", {
					hour12: true,
				}),
				"Candidate city": data.city,
				"Candidate Area": data.area,
				"Willing to relocate": isWillingToRelocate
					? "Willing to relocate"
					: "No relocation needed",
				Education: data.edu,
				Degree: data.degree,
				Experience: data.experience_in_years || "Fresher",

				"Current Job role": data.company_title,
				"Recruiter Notes": data.employer_feedback,
				"Connection Status": `${
					data.connection_status === "connected" ? "Reviewed" : "Review Pending"
				}`,
				"Hiring Status": data.hiring_state,
				"Resume, Skills etc": `${window.location.origin}/jobs/${data.job_id}/${data.application_id}/`,
				Source: "Apna",
			});
		});

		return newAppliedData;
	},

	/**
	 * Working days from Monday to Saturday and Working hours are from 8AM to 9PM
	 * @returns {number} Returns "true" if current date and time falls under the specified Working days and Hours.
	 */
	isWorkingDaysAndHours: () => {
		const date = new Date(); // returns current time
		const hours = date.getHours(); // returns the hour (from 0 to 23)
		const day = date.getDay(); // returns the day of the week (from 0 to 6). Sunday is 0, Monday is 1, and so on.
		const openingDays = [1, 2, 3, 4, 5, 6]; // Containing days from Monday to Saturday
		return openingDays.includes(day) && hours >= 8 && hours <= 21;
	},
	RemoveItemFromLocalStorage: key => {
		if (key) {
			const data = localStorage.removeItem(key);
			return data;
		}
	},
	isCategoryHasTags: categoryId => {
		const JOB_TYPES_WITH_TAGS = [1, 11, 37, 30, 2, 9];
		return JOB_TYPES_WITH_TAGS.find(val => val === categoryId);
	},
	getMaskedMobNumb: num => {
		return ` ${num.substring(0, 3)}****${num.substring(num.length - 4, num.length)} `;
	},
	checkForValidEmail,
	checkForValidPhoneNumber,
	checkForValidText: text => {
		const pattern = /^[a-zA-Z\s]*$/;
		return pattern.test(text);
	},
	checkForValidJobDescription: text => {
		const pattern = /[a-zA-Z]/;
		return pattern.test(text);
	},
};

export const parseQueryParams = params => {
	if (params?.includes("?")) {
		// remove ?
		const plainParams = params?.slice(1);
		const paramsArray = plainParams?.split("&");
		const result = paramsArray?.map(obj => {
			const keyVal = obj?.split("=");
			return { [keyVal?.[0]]: keyVal?.[1] };
		});
		return result;
	}
};

export const checkContainsCharacter = str => {
	const regex = /[a-zA-Z]/;
	return regex.test(str);
};

export const generateLocaleDate = stringifiedDate => {
	return dayjs(stringifiedDate).format("DD MMM YYYY");
};

export const generateLocaleTime = stringifiedDate => {
	return dayjs(stringifiedDate).format("h:mm:ss A");
};

export const getMissingFieldsForMixPanel = data => {
	const dataObj = { ...data };
	const arr = [];
	Object.entries(dataObj).forEach(([key, value]) => {
		if (!value) {
			arr.push(key);
		}
	});
	return arr;
};

export function decimalToRoman(number) {
	let roman = "";
	const romanNumList = {
		M: 1000,
		CM: 900,
		D: 500,
		CD: 400,
		C: 100,
		XC: 90,
		L: 50,
		XV: 40,
		X: 10,
		IX: 9,
		V: 5,
		IV: 4,
		I: 1,
	};
	let a;
	if (number < 1 || number > 3999) throw new Error("Enter a number between 1 and 3999");
	let tempNum = number;
	Object.keys(romanNumList).forEach(key => {
		a = Math.floor(tempNum / romanNumList[key]);
		if (a >= 0) {
			for (let i = 0; i < a; i += 1) {
				roman += key;
			}
		}
		tempNum %= romanNumList[key];
	});

	return roman;
}

export const retry = (fn, retriesLeft = 5, interval = 1000) => {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch(error => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						// reject('maximum retries exceeded');
						reject(error);
						return;
					}

					// Passing on "reject" is the important part
					retry(fn, retriesLeft - 1, interval).then(resolve, reject);
				}, interval);
			});
	});
};

export function debounce(func, timeout) {
	let timer;
	return (...args) => {
		const next = () => func(...args);
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(next, timeout > 0 ? timeout : 300);
	};
}

export function getUserDetails() {
	return JSON.parse(CommonUtils.getItemFromLocalStorage("__user__")) || {};
}

export const newIntercomMessage = msg => {
	openRespondChat();
	if (msg) {
		// window.Intercom("showNewMessage", msg);
	} else {
		// sessionStorage.removeItem("intercom.draft");
		// window.Intercom("showNewMessage");
	}
};

/**
 *
 * @param {Array<string>} acceptedDomains
 * @param {string} enteredEmail
 * @returns {boolean}
 */
export const isMatchingEmail = (acceptedDomains = [], enteredEmail = "") =>
	acceptedDomains.some(domain => enteredEmail.endsWith(`@${domain}`));

export const findMatchedData = (candidateData = [], jobRequirementData = []) => {
	let matchedPercentage = 0;
	const matchData = [];
	if (!jobRequirementData?.length) {
		return 1;
	}
	candidateData.forEach(candidateValue => {
		const dataFound = jobRequirementData.find(
			jobValue => jobValue?.id === candidateValue?.id
		);
		if (dataFound) {
			matchData.push(dataFound);
		}
	});
	matchedPercentage = matchData?.length / jobRequirementData?.length;
	return matchedPercentage;
};

export const getCandidateTrackingData = ({
	currentIndex = 0,
	currentPageNumber = 1,
	candidateData = {},
	noOfApplicants = 0,
	recommendedCandLength = 0,
}) => {
	const {
		assets = [],
		required_assets = [],
		skills = [],
		required_skills = [],
		last_applied_at,
		app_version = "",
		score = "",
		id = "",
		experience_in_years = "",
		exp = "",
		edu = "",
		last_active_on = "",
		username = "",
		full_name = "",
		show_number_clicked = "",
		company = "",
		current_salary = "",
		english = "",
		gender = "",
	} = candidateData;
	const candidatePosition =
		(currentPageNumber - 1) * CANDIDATES_PAGINATION_SIZE + (currentIndex + 1);
	const matchedSkills = findMatchedData(skills, required_skills);
	const matchedAssets = findMatchedData(assets, required_assets);
	let diffDays = 0;
	try {
		const todaysDate = new Date(dayjs().format("MM/DD/YYYY"));
		const lastAplliedDate = new Date(
			dayjs(last_applied_at || todaysDate).format("MM/DD/YYYY")
		);
		const diffTime = Math.abs(todaysDate - lastAplliedDate);
		diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	} catch (error) {
		console.log("error:", error);
	}
	return {
		"App Version": app_version,
		Position: candidatePosition,
		Score: score,
		"Number of skills required": required_skills?.length,
		"Number of assets required": required_assets?.length,
		"Job Skills required": required_skills,
		"Job assets required": required_assets,
		"Candidate Skills": skills,
		"Candidate Assets": assets,
		"Skills Matched Ratio": matchedSkills,
		"Assets Matched Ratio": matchedAssets,
		"Last Job Applied (days)": diffDays,
		"Candidate Experience In Years": experience_in_years,
		"Candidate Experience": exp,
		"Candidate Education": edu,
		"Candidate Last Active On": last_active_on,
		Number: username,
		"Candidate Name": full_name,
		"Candidate ID": id,
		"Show Number Clicked": show_number_clicked,
		"Candidate Company": company,
		"Candidate Current Salary": current_salary,
		"Applied Candidate Count": noOfApplicants,
		"Number of Suggested Candidates": recommendedCandLength,
		"Candidate English Requirements": english,
		"Candidate Gender": gender,
	};
};
export function getCurrentDateString(format = "YYYY-MM-DD") {
	const currentDate = new Date();
	return dayjs(currentDate).format(format);
}

export const getDocumentType = (documentPath = "") => {
	const splitTypes = documentPath.split(".");
	if (splitTypes.length === 1 || (splitTypes[0] === "" && splitTypes.length === 2)) {
		return "";
	}
	return splitTypes.pop();
};

/**
 *
 * @param {String[]} inp
 * @returns string
 */
export const makeString = inp => {
	if (!Array.isArray(inp) && !inp.length) return "";
	if (inp.length === 1) return inp[0];
	const last = inp.pop();
	return `${inp.join(", ")} and ${last}`;
};

/**
 * Use this function to download comma separated values as a CSV file.
 *
 * @param {string} filename
 * @param {string} rows
 */
export const downloadCsv = (filename, csvFile) => {
	const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, filename);
	} else {
		const link = document.createElement("a");
		if (link.download !== undefined) {
			// feature detection
			// Browsers that support HTML5 download attribute
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", filename);
			link.style.display = "none";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
};

/**
 * This function will convert and download a 2D array as a CSV file.
 * Warning: Intended to be used on frontend only.
 * Copied from stackoverflow and made a few changes according to our requirements.
 * Ref: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 *
 * @param {string} filename
 * @param {string[][]} rows
 */
export const exportToCsv = (filename, rows) => {
	const processRow = row => {
		let finalVal = "";
		for (let j = 0; j < row.length; j += 1) {
			let innerValue = row[j] === null ? "" : row[j].toString();
			if (row[j] instanceof Date) {
				innerValue = row[j].toLocaleString();
			}
			let result = innerValue.replace(/"/g, '""');
			if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`;
			if (j > 0) finalVal += ",";
			finalVal += result;
		}
		return `${finalVal}\n`;
	};

	let csvFile = "";
	for (let i = 0; i < rows.length; i += 1) {
		csvFile += processRow(rows[i]);
	}
	downloadCsv(filename, csvFile);
};

export const getCapitalizedWords = (stringToCapitalize = "") => {
	if (typeof stringToCapitalize === "string") {
		const wordsArray = stringToCapitalize?.split(" ");
		const refinedWords = [];
		wordsArray.forEach(word => {
			let trimmedWord = word;
			if (word?.trim) {
				trimmedWord = word.trim();
			}
			if (trimmedWord.length) {
				refinedWords.push(
					trimmedWord[0]?.toUpperCase() + trimmedWord?.substring(1)
				);
			}
		});
		return refinedWords.join(" ");
	}
	return "";
};

export const getPayJobTrackingData = ({ service = {} }) => {
	return {
		"Service Id": service?.service_id || "NA",
		"SKU Title": service?.title,
		"Valid for Days": service?.valid_for_days || "NA",
		"SKU Description": service?.description || "NA",
		Price: service?.price || "NA",
		"Coins Required": service?.price?.selling_price || "NA",
		"Target Leads": service?.target_leads || "NA",
	};
};

export const getRazorPayOptions = ({ paymentDetail, handlePaymentSuccess, userData }) => {
	const options = {
		key: config.razorPayKey,
		amount: paymentDetail?.grandTotal,
		currency: "INR",
		name: "Apna Time",
		description: "Apna Time Transaction",
		order_id: paymentDetail?.razorPayOrderId,
		handler(response) {
			handlePaymentSuccess(response);
		},
		prefill: {
			name: userData?.full_name,
			email: userData?.email,
			contact: userData?.phone_number,
		},
		theme: {
			color: "#3399cc",
		},
		retry: {
			enabled: false,
		},
	};
	return options;
};

export const convertFromMiltaryTimeToAMPM = time => {
	return `${time / 100 > 12 ? time / 100 - 12 : time / 100}:${
		time % 100 > 9 ? time % 100 : `${time % 100}0`
	} ${time / 100 > 12 ? "PM" : "AM"}`;
};

export const checkIfOnlyNumerical = str => {
	const regExp = new RegExp("^\\d+$");
	const isValid = regExp.test(str);
	return isValid;
};

/**
 * To be used with axios in onUploadProgess.
 * @param {any} progressEvent
 * @returns {number}
 */
export const getUploadProgress = progressEvent => {
	const totalLength = progressEvent.lengthComputable
		? progressEvent.total
		: progressEvent.target.getResponseHeader("content-length") ||
		  progressEvent.target.getResponseHeader("x-decompressed-content-length");
	if (totalLength !== null) {
		return Math.round((progressEvent.loaded * 100) / totalLength);
	}
	return 0;
};

export const extractCompanyVerificationData = tempData => {
	return (
		tempData?.verification_steps?.find(el =>
			el.step?.includes(VERIFICATION_STEPS.COMPANY_VERIFICATION)
		) || {}
	);
};

export const sendMixpanelCompanyVerificationEventsEvents = ({
	eventName,
	tempData = {},
	jobData = {},
	isInternal = false,
	verificationDetails = {},
}) => {
	const { id: userId } = getUserDetails();
	const temp = {
		"Job ID": jobData?.id,
		"User ID": userId,
		"Creator Org ID": jobData?.creator_organization?.id,
		"Org ID": jobData?.organization?.id,
		"Org Type":
			jobData?.organization?.domains?.length > 0
				? "Whitelisted Domain"
				: "Non Domain Whitelisted",
		"Hiring For":
			jobData?.organization?.id === jobData?.creator_organization?.id
				? "my company"
				: "my clients",
		"Creator Org Type": jobData?.creator_organization?.domains?.length
			? "Domain Whitelisted"
			: "Non Domain Whitelisted",
		"Page Type": isInternal ? "UR" : "EMPLOYER",
		"Is Reverification": Boolean(verificationDetails?.is_reverification),
	};
	Mixpanel.track(eventName, { ...temp, ...tempData });
};

export const getMappedVerificationData = tempVerificationStatusData => {
	let structuredStepsData = {};
	Object.values(VERIFICATION_STEPS).forEach(val => {
		const stepData = tempVerificationStatusData?.verification_steps?.find(
			el => el?.step === val
		);
		if (stepData) structuredStepsData = { ...structuredStepsData, [val]: stepData };
	});
	return structuredStepsData;
};
export const numberFormater = (num, format = "en-In") => {
	const formater = new Intl.NumberFormat(format);
	return formater.format(parseInt(num, 10));
};

export const getPreviousExpList = candidateData => {
	const tempArray = candidateData?.previous_experience || [];

	return tempArray?.join(", ");
};
export const calculatePlansDiscountPercentage = item => {
	let discountPercentage = 0;
	try {
		const discountAmount = item?.price?.mrp - item?.price?.selling_price;
		discountPercentage = Math.round((discountAmount * 100) / item?.price?.mrp);
		return discountPercentage;
	} catch (err) {
		console.log(err);
	}
	return discountPercentage;
};
export const checkForPromotionalURL = queryParamsList => {
	const promotionalCouponCode = queryParamsList.get("cc");
	const promotionalCheckoutAmount = queryParamsList.get("cv");
	const promotionalURLTeamName = queryParamsList.get("tn");

	const isPromotionalURL =
		promotionalCouponCode && promotionalCheckoutAmount && promotionalURLTeamName;
	return isPromotionalURL;
};

export const isNegative = number => {
	return number.match(/^-\d+$/);
};

export const getAddressString = ({
	completeAddress = "",
	areaName = "",
	cityName = "",
}) => {
	return `${completeAddress ? `${completeAddress},` : ""} ${
		areaName ? `${areaName},` : ""
	} ${cityName}`;
};

export const checkIfSame = (string1 = "", string2 = "") => {
	return string1 === string2;
};

export const getEmployerUserProperties = () => {
	const userData = getUserDetails();

	return {
		"Mobile Number": userData?.phone_number,
		Company: userData?.organization?.name,
		"Recruiter ID": userData?.id,
		"Recruiter Name": userData?.full_name,
		"Recruiter Email": userData?.email,
	};
};

export const getApplicationLocationOptions = (jobLocationCity = "") => {
	const tempOptions = [
		{
			label: "Within 10 KM",
			value: APPLICATION_LOCATION_VALUES_FOR_NON_WFH.WITHIN_10KM,
		},
		{
			label: "Within 25 KM",
			value: APPLICATION_LOCATION_VALUES_FOR_NON_WFH.WITHIN_25KM,
		},
		{
			label: `Entire ${jobLocationCity || "city"}`,
			value: APPLICATION_LOCATION_VALUES_FOR_NON_WFH.ENTIRE_CITY,
		},
	];

	return tempOptions;
};

// TODO: Can be accessed directly
export const salaryTypeOptions = () => {
	const tempOptions = [
		{
			label: "Fixed only",
			value: PAY_TYPE.FIXED_ONLY,
		},
		{
			label: "Fixed + Incentive",
			value: PAY_TYPE.FIXED_AND_INCENTIVE,
		},
		{
			label: "Incentive only",
			value: PAY_TYPE.INCENTIVE_ONLY,
		},
	];
	return tempOptions;
};

export const getAreaDescription = data => {
	if (data?.address_representation) return data?.address_representation;
	switch (data?.job_location_type) {
		case BE_VALUES_FOR_LOCATION_TYPES.WORK_FROM_HOME:
			return data?.applicant_location ===
				APPLICATION_LOCATION_VALUES_FOR_WFH.ENTIRE_CITY
				? `All Areas in ${data?.address?.area?.city?.name}`
				: "Anywhere in India";
		case BE_VALUES_FOR_LOCATION_TYPES.FIELD_JOB:
			return `All Areas in ${data?.address?.area?.city?.name}`;
		default:
			return data?.address?.area?.name || "";
	}
};

export const isHighlightRequired = (key, data = []) => {
	if (data?.includes(key)) return true;
	return false;
};

export const getExperienceType = (maxExp = 0, minExp = 0, isNewView = true) => {
	// Fresher - historically min 0 max 0, now min 0 max 1
	// Any - 0 to 31
	// Experienced - Min from input to 31
	let expType = "";
	if (!isNewView) {
		if (minExp === 0 && [0, 1].includes(maxExp)) {
			expType = EXPERIENCE_LEVELS.FRESHER;
		} else if (maxExp > 0) {
			expType = EXPERIENCE_LEVELS.EXPERIENCED;
		}
		return expType;
	}

	if (maxExp === 1) {
		expType = EXPERIENCE_LEVELS.FRESHER;
	} else if (maxExp > 1 && minExp === 0) {
		expType = EXPERIENCE_LEVELS.ANY;
	} else if (maxExp > 1) {
		expType = EXPERIENCE_LEVELS.EXPERIENCED;
	}
	return expType;
};

export const showNewExperienceView = ({
	experienceTighteningConfig = {},
	jobLocationCityId = "",
	jobLocationType = "",
	applicationLocation = "",
	selectedCategoryId = "",
}) => {
	const filteredCategoryCity = experienceTighteningConfig?.filtered_category_city;

	let showNexExp = false;

	// eslint-disable-next-line no-unused-expressions
	filteredCategoryCity?.forEach(each => {
		if (
			!each?.excluded_city_id?.includes(Number(jobLocationCityId)) &&
			each?.category_ids?.includes(Number(selectedCategoryId))
		) {
			showNexExp = true;
		}
		if (
			each?.category_ids?.includes(Number(selectedCategoryId)) &&
			jobLocationType === "wfh" &&
			applicationLocation === "ANYWHERE"
		) {
			showNexExp = true;
		}
	});

	return showNexExp;
};

export const getActivationPlanTitle = service =>
	service?.title?.toLowerCase().includes("large")
		? "LARGE"
		: service?.title?.toUpperCase();

export const getCityDescription = data => {
	switch (data?.job_location_type) {
		case BE_VALUES_FOR_LOCATION_TYPES.WORK_FROM_HOME:
		case BE_VALUES_FOR_LOCATION_TYPES.FIELD_JOB:
			return data?.address_representation;
		default:
			return data?.address?.area?.city?.name || "";
	}
};

export const getSalaryDetails = (
	minSalary,
	maxSalary,
	incentives,
	showRupeeSymbol = false
) => {
	return `${showRupeeSymbol ? "₹" : ""}${minSalary?.toLocaleString("en-IN")} - ${
		showRupeeSymbol ? "₹" : ""
	}${maxSalary?.toLocaleString("en-IN")} per month ( Fixed
	${incentives !== null && incentives ? "+ incentives )" : ")"}`;
};

export const removeFreeJobsLandingUTM = () => {
	// utm_custom_2 to track the landing page
	// from banner or whatsapp
	const utmParams = localStorage.getItem(URL_PARAMS);
	if (utmParams) {
		const urlUTMParams = JSON.parse(localStorage.getItem(URL_PARAMS));
		if (urlUTMParams?.utm_custom_2) {
			delete urlUTMParams.utm_custom_2;

			localStorage.setItem(
				URL_PARAMS,
				JSON.stringify({
					...urlUTMParams,
				})
			);
		}
	}
};

export const detectIfBrowserSupported = () => {
	const browser = Bowser.getParser(window.navigator.userAgent);
	const isValidBrowser = browser.satisfies(SUPPORTED_BROWSERS);
	return isValidBrowser;
};

export const checkForHolidayBanner = () => {
	const todayDate = new Date();

	// (YYYY, MM, DD, Hr, Min, Sec)
	const endDate = new Date(2022, 8, 1, 8, 0, 0); // September 1st 8am
	const startDate = new Date(2022, 7, 31); // August 31st 12am

	if (todayDate > startDate && todayDate < endDate) {
		return true;
	}
	return false;
};

export const isNewSalaryIncentive = ({
	cityId = "",
	categoryId = "",
	salaryStructureConfig = {},
}) => {
	// Example Firebase config:
	// emp_salary_incentive_category_city
	// {
	//   "11": [
	//     1,
	//     2,
	//     3
	//   ]
	// }
	// Key ~ City Id
	// Value Array: Has Category Ids, all string "all" if all category comes

	const allCityIds = Object.keys(salaryStructureConfig);
	let tempShowSalary = false;
	// eslint-disable-next-line no-restricted-syntax
	for (const eachCityId of allCityIds) {
		if (Number(eachCityId) === Number(cityId)) {
			if (Array.isArray(salaryStructureConfig[eachCityId])) {
				// eslint-disable-next-line no-loop-func
				salaryStructureConfig[eachCityId].forEach(catId => {
					if (Number(catId) === Number(categoryId)) {
						tempShowSalary = true;
					}
				});
			} else if (salaryStructureConfig[eachCityId] === "all") {
				tempShowSalary = true;
			}
		}
	}
	return tempShowSalary;
};
export const deleteKeysWithEmptyValueFromObject = tempObj => {
	const keys = Object.keys(tempObj);
	const copyObj = tempObj;
	// eslint-disable-next-line no-restricted-syntax
	for (const key of keys) {
		if ([null, undefined, ""].includes(tempObj[key])) {
			delete copyObj[key];
		}
	}
	return copyObj;
};

export const getSalaryString = ({
	minSalary = 0,
	maxSalary = 0,
	salaryType = "",
	incentive = 0,
}) => {
	switch (salaryType) {
		case PAY_TYPE.FIXED_ONLY:
			return `₹ ${numberFormater(minSalary)} - ₹ ${numberFormater(maxSalary)}`;
		case PAY_TYPE.INCENTIVE_ONLY:
			return `₹ ${numberFormater(incentive)} *`;
		case PAY_TYPE.FIXED_AND_INCENTIVE:
			return `₹ ${numberFormater(minSalary)} - ₹ ${numberFormater(
				Number(incentive) + Number(maxSalary)
			)} *`;
		default:
			return `₹ ${numberFormater(minSalary)} - ₹ ${numberFormater(maxSalary)}`;
	}
};

export const getSalaryPerkStringArray = ({
	salaryPerksOptions = [],
	selectedPerkIds = [],
}) => {
	return (
		selectedPerkIds
			?.filter?.(each => !!each)
			?.map(each => {
				const temp = salaryPerksOptions?.find(eachOpt => eachOpt?.id === each);
				return temp?.text;
			}) || []
	);
};

export const getSalaryFixedIncentiveString = (jobData = {}) => {
	let tempString = jobData?.salary_representation;

	if (jobData?.job_data?.salary_breakdown) {
		const salaryType = jobData?.job_data?.salary_breakdown?.salary_type;

		if (salaryType === PAY_TYPE.FIXED_AND_INCENTIVE) {
			tempString += ` ( Fixed:  ₹ ${jobData?.job_data?.salary_breakdown?.fixed?.min_salary?.toLocaleString(
				"en-IN"
			)} - ₹ ${jobData?.job_data?.salary_breakdown?.fixed?.max_salary?.toLocaleString(
				"en-IN"
			)}, Incentive: ₹ ${jobData?.job_data?.salary_breakdown?.incentive?.avg_incentive?.toLocaleString(
				"en-IN"
			)} ) `;
		}
		if (salaryType === PAY_TYPE.FIXED_ONLY) {
			tempString += ` ( Fixed: ₹ ${jobData?.job_data?.salary_breakdown?.fixed?.min_salary?.toLocaleString(
				"en-IN"
			)} - ₹ ${jobData?.job_data?.salary_breakdown?.fixed?.max_salary?.toLocaleString(
				"en-IN"
			)}, Incentive: 0) `;
		}
		if (salaryType === PAY_TYPE.INCENTIVE_ONLY) {
			tempString += ` ( Fixed: 0, Incentive: ₹ ${jobData?.job_data?.salary_breakdown?.incentive?.avg_incentive?.toLocaleString(
				"en-IN"
			)} ) `;
		}
	}

	return tempString;
};

export const getGenericPerks = (jobData = {}) => {
	let tempString = "NA";

	const tagList = jobData?.tag_list
		?.filter(each => each?.tag_type === TAG_TYPES.SALARY)
		?.map(each => each?.text);

	if (tagList?.length > 0) {
		tempString = tagList?.join(", ");
	}
	return tempString;
};

export const getNumberOfSalaryPerks = (jobData = {}) => {
	const tagList = jobData?.tag_list
		?.filter(each => each?.tag_type === TAG_TYPES.SALARY)
		?.map(each => each?.tag_text || each?.text);

	return tagList?.length + jobData?.job_data?.salary_breakdown?.other_perks ? 1 : 0;
};

export const checkIfNoDigitsAdded = str => {
	const regex = /[0-9]/;
	return !regex.test(str);
};

export const checkIfLessThanNDigit = (str, number) => {
	const temp = `${str}`; // to make sure its string
	return temp?.length < number;
};

export const isObjectEmpty = obj => {
	return Object.keys(obj).length === 0;
};
export default CommonUtils;
