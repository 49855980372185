/* eslint-disable import/prefer-default-export */
import * as Sentry from "@sentry/browser";
import CommonUtils from "../utils/commonUtils";

export const setTag = scope => {
	try {
		const userSession = JSON.parse(CommonUtils.getItemFromLocalStorage("__user__"));
		if (userSession) {
			scope.setTag("user_Id", userSession.id || "NA");
			scope.setTag("phone_number", userSession.phone_number || "NA");
			scope.setTag("is_internal_user", userSession.is_internal_user || "NA");
			scope.setUser({
				id: userSession.id || "NA",
				phone_number: userSession.phone_number,
				name: userSession.full_name,
				email: userSession.email,
				is_internal_user: userSession.is_internal_user,
			});
		}
	} catch (error) {
		Sentry.captureException(error);
	}
};
