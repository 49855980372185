import {
	SHOW_FORM,
	EDIT_FORM,
	DUPLICATE_JOB,
	SHOW_PROFILE_PAGE,
	SHOW_CONSULTANT_MODAL,
	SHOW_ONBOARDING_MODAL,
	SHOW_CHANGE_COMPANY_MODAL,
	SET_USER_DETAILS,
	UPDATE_COMPANY_DOC,
	EDIT_COMPANY_DOC,
	RESET_FORM,
	CLEAR_EDIT_FORM,
	SET_AGE_UNLEASH_STATUS,
	NEW_JOB_POSTED,
	UPDATE_POC_DOC,
	UPDATE_USER_DETAILS,
	SET_WORKSPACE,
	UPDATE_WORKSPACE,
	SET_IS_WORKSPACE_UPDATED,
	DUPLICATE_JOB_FOUND,
	UPDATE_CALL_LATEST_VERIFICATION_API_FLAG,
	UPDATE_VERIFICATION_MODAL_OPEN_STATE,
	SET_MAX_VALUES_FOR_TAGS,
	UPDATE_MAX_VALUES_FOR_TAGS,
	SET_JOB_ALLOW_OPTION,
	UPDATE_JOB_ALLOW_OPTION,
	SET_EDUCATION_OPTIONS,
	SET_ENGLISH_OPTIONS,
	SET_JOB_TAGS,
	UPDATE_JOB_TAG,
	SET_RECOMMENDATION_TYPE,
	SET_CATEGORY_DROPDOWN_LIST,
	SHOW_MISSING_FIELDS_INFO_PANEL,
	SAVE_JPF_MISSING_FIELDS,
	SET_IS_JPF_ERROR,
	SET_ALLOWED_JOB_TITLES,
} from "../constants/FormTypes";

import { SHOW_UPLOAD_DOC, HIDE_UPLOAD_DOC } from "../constants/ModalTypes";
import { sortEducationOptions } from "../utils";

const DEFAULT_POC_DOC = {
	type: null,
	poc_file: null,
	poc_file2: null,
	pocfile_name: "",
	pocfile2_name: "",
	pocFileObj: null,
	pocFileObj2: null,
	poc_verification_status: null,
	edit: false,
	diff: false,
};

const initialState = {
	showForm: false,
	resetForm: false,
	editFormData: {},
	duplicateJobFormData: {},
	isDuplicateJob: false,
	showProfilePage: false,
	showConsultantModal: false,
	showOnboardingModal: false,
	showCompanyChangeModal: false,
	userDetails: {},
	companyDoc: {
		formStates: {
			document_type: "",
			document_number: "",
			filePublicUrl: "",
			fileName: "",
			fileObject: null,
			fileObject2: null,
			docFile: null,
			docDetail: "",
			companyUrl: "",
			fileName2: "",
			verificationStatus: "pending",
			documentSource: "",
		},
		formErrors: {
			document_type: false,
			document_number: false,
			file: false,
			edit: false,
			diff: false,
		},
	},
	pocDoc: DEFAULT_POC_DOC,
	pocDocHr: DEFAULT_POC_DOC,
	isAgeSpecificJob: { isEnabled: false, value: [] },
	newJobPosted: true,
	workspace: {},
	isWorkspaceUpdated: false,
	duplicateJobFound: false,
	callLatestVerificationAPI: false,
	showCompanyVerificationModal: false,
	maxAssetTagsCount: 5,
	maxDegreeTagsCount: 4,
	maxSkillTagsCount: 4,
	maxLanguageTagsCount: 4,
	wfh: true,
	wfo: true,
	fjo: true,
	educationOptions: [],
	englishOptions: [],
	skillTags: [],
	assetTags: [],
	degreeTags: [],
	languageTags: [],
	salaryPerksTags: [],
	recommendationType: "",
	categoryList: [],
	isJobPostFormError: false,
	showMissingFieldsPanel: false,
	jobPostMissingFields: [],
	allowedJobTitles: [],
};

const forms = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_FORM:
			return {
				...state,
				showForm: action.value,
			};
		case EDIT_FORM:
			return {
				...state,
				showForm: action.values.showForm,
				editFormData: action.values.editFormData,
				callLatestVerificationAPI: true,
			};
		case CLEAR_EDIT_FORM:
			return {
				...state,
				editFormData: {},
			};
		case RESET_FORM:
			return {
				...state,
				resetForm: action.value,
			};
		case DUPLICATE_JOB:
			return {
				...state,
				showForm: action.values.showForm,
				isDuplicateJob: true,
				duplicateJobFormData: action.values.duplicateJobFormData,
			};

		case SHOW_PROFILE_PAGE:
			return {
				...state,
				showProfilePage: action.value,
			};
		case SHOW_CONSULTANT_MODAL:
			return {
				...state,
				showConsultantModal: action.value,
			};
		case SHOW_ONBOARDING_MODAL:
			return {
				...state,
				showOnboardingModal: action.value,
			};
		case SHOW_CHANGE_COMPANY_MODAL:
			return {
				...state,
				showCompanyChangeModal: action.value,
			};
		case SET_IS_WORKSPACE_UPDATED:
			return {
				...state,
				isWorkspaceUpdated: action.value,
			};
		case SET_USER_DETAILS:
			return {
				...state,
				userDetails: action.values,
			};
		case UPDATE_USER_DETAILS:
			return {
				...state,
				userDetails: {
					...state.userDetails,
					...action.values,
				},
			};
		case UPDATE_COMPANY_DOC:
			return {
				...state,
				companyDoc: {
					...state.companyDoc,
					formStates: {
						...state.companyDoc.formStates,
						...action.value.formStates,
					},
					formErrors: {
						...state.companyDoc.formErrors,
						...action.value.formErrors,
					},
				},
			};

		case EDIT_COMPANY_DOC:
			return {
				...state,
				companyDoc: {
					...state.companyDoc,
					formStates: {
						...state.companyDoc.formStates,
						edit: true,
					},
				},
			};
		case SHOW_UPLOAD_DOC:
			return {
				...state,
				companyDoc: {
					...state.companyDoc,
					formStates: {
						...state.companyDoc.formStates,
						edit: true,
					},
					formErrors: {
						...state.companyDoc.formErrors,
						document_type: false,
						document_number: false,
						file: false,
					},
				},
			};
		case HIDE_UPLOAD_DOC:
			return {
				...state,
				companyDoc: {
					...state.companyDoc,
					formStates: {
						...state.companyDoc.formStates,
						edit: false,
					},
				},
			};
		case SET_AGE_UNLEASH_STATUS:
			return {
				...state,
				isAgeSpecificJob: {
					isEnabled: action.data.isEnabled,
					value: action.data.categories,
				},
			};
		case NEW_JOB_POSTED:
			return {
				...state,
				newJobPosted: action.data,
			};
		case SET_WORKSPACE:
			return {
				...state,
				workspace: action.data,
			};
		case UPDATE_WORKSPACE: {
			return {
				...state,
				workspace: {
					...state.workspace,
					...action.data,
				},
			};
		}
		case UPDATE_POC_DOC:
			return {
				...state,
				...(action.isHr
					? {
							pocDocHr: {
								...state.pocDocHr,
								...action.value,
							},
					  }
					: {
							pocDoc: {
								...state.pocDoc,
								...action.value,
							},
					  }),
			};
		case DUPLICATE_JOB_FOUND:
			return { ...state, duplicateJobFound: action.payload };
		case UPDATE_CALL_LATEST_VERIFICATION_API_FLAG:
			return { ...state, callLatestVerificationAPI: action.payload };
		case UPDATE_VERIFICATION_MODAL_OPEN_STATE:
			return { ...state, showCompanyVerificationModal: action.payload };
		case SET_MAX_VALUES_FOR_TAGS:
			return {
				...state,
				maxAssetTagsCount: action.payload.maxAssetTagsCount,
				maxDegreeTagsCount: action.payload.maxDegreeTagsCount,
				maxSkillTagsCount: action.payload.maxSkillTagsCount,
			};
		case UPDATE_MAX_VALUES_FOR_TAGS:
			return {
				...state,
				[action.key]: action.payload,
			};
		case SET_JOB_ALLOW_OPTION:
			return {
				...state,
				wfo: action.wfo,
				wfh: action.wfh,
				fjo: action.fjo,
			};
		case UPDATE_JOB_ALLOW_OPTION:
			return {
				...state,
				[action.key]: action.payload,
			};
		case SET_EDUCATION_OPTIONS:
			return {
				...state,
				educationOptions: sortEducationOptions(action.payload),
			};
		case SET_ENGLISH_OPTIONS:
			return {
				...state,
				englishOptions: action.payload,
			};
		case SET_JOB_TAGS:
			return {
				...state,
				skillTags: action.skillTags,
				assetTags: action.assetTags,
				degreeTags: action.degreeTags,
				languageTags: action.languageTags,
				salaryPerksTags: action.salaryPerksTags,
			};
		case UPDATE_JOB_TAG:
			return {
				...state,
				[action.key]: action.payload,
			};
		case SET_RECOMMENDATION_TYPE:
			return {
				...state,
				recommendationType: action.payload,
			};
		case SET_CATEGORY_DROPDOWN_LIST:
			return {
				...state,
				categoryList: action.payload,
			};
		case SET_ALLOWED_JOB_TITLES:
			return {
				...state,
				allowedJobTitles: action.payload,
			};
		case SHOW_MISSING_FIELDS_INFO_PANEL:
			return { ...state, showMissingFieldsPanel: action.payload };
		case SAVE_JPF_MISSING_FIELDS:
			return { ...state, jobPostMissingFields: action.payload };
		case SET_IS_JPF_ERROR:
			return { ...state, isJobPostFormError: action.payload };

		default:
			return state;
	}
};

export default forms;
