import { SET_JOB_LEADS_MAPPING, UPDATE_JOB_LEADS_MAPPING } from "../constants/types";

const initialState = {};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_JOB_LEADS_MAPPING:
			return { ...state, ...payload };
		case UPDATE_JOB_LEADS_MAPPING:
			return { ...state, ...payload };
		default:
			return state;
	}
};
