export default {
	LOGOUT: "/api/employer/v1/logout/",
	LOGIN_V2: "/apna-auth/employer/login/", // TRAI changes
	OTP: "/api/v1/employer-info/get-otp/",
	OTP_V2: "/api/employer/v1/send-otp/",
	OTP_V3: "/api/userprofile/v1/otp/", // TRAI changes
	SQL_QUERY: "/api/v1/sql-query/",
	JOBS: "/api/employer/v1/jobs/",
	JOB_DATA: "/api/employer/v1/job/",
	JOB_DATA_V3: "/api/employer/v3/job/",
	BANNED_USER: "/api/employer/v1/is_banned/",
	JOBS_V2: "/api/employer/v2/jobs/",
	JOBS_V3: "/api/employer/v3/jobs/",
	JOBS_V4: "/api/employer/v4/jobs/",
	JOBS_V5: "/api/employer/v5/jobs/",
	JOBS_V6: "/api/employer/v6/jobs/",
	JOB_DATA_V2: "/api/employer/v2/jobs/",
	BANNED_USER_V2: "/api/employer/v2/is_banned/",
	JOB_TITLES: "/api/v3/job-titles/",
	NEW_JOB_CATEGORIES: "/api/v1/job-catg/",
	EDUCATION_LEVELS: "/api/v1/education-levels/",
	ORGANIZATION_NAME: "/api/v1/orgs/",
	VERIFIED_ORGANISATION: "/api/employer/v1/organization-search",
	ACCEPT_TERMS: "/api/employer/v1/terms/accept/",
	INTERNAL_USER_JOBS_LIST: "/api/internal/v1/jobs/",
	INTERNAL_USER_JOBS_LIST_V2: "/api/internal/v2/jobs/",
	INTERNAL_USER_JOBS_LIST_V3: "/api/internal/v3/jobs/",
	EMPLOYER_JOBS_LIST_V2: "/api/employer/v2/jobs/",
	USER_JOBS_LIST: "/api/employer/v3/jobs/",
	CITIES_LIST: "/api/location/v1/cities/",
	ASSESSMENT: "/api/assessments/v1/",
	JOB_POST_COUNT: "/api/internal/v1/jobs_count/",
	USER_PERSONA: "/api/employer/v2/persona/",
	USERS: "/api/employer/v1/users/",
	INTERNAL_USERS: "/api/internal/v1/users/",
	INTERNAL_USERS_V2: "/api/internal/v2/users/",
	EMPLOYER_TYPE: "/api/internal/v1/set-type/",
	INTERNAL_EMPLOYEE_COUNT: "/api/internal/v1/set-headcount/",
	GST_DETAILS: "/api/internal/v2/gst-details/",
	EMPLOYER_INVITE_TEXT: "/api/employer/v1/invite-text/",
	SEND_EMPLOYER_INVITE: "/api/employer/v1/invite-employers/",
	QC_JOB_LIST: "/api/assessments/v1/jobs-by-questions/",
	NEW_QC_JOB_LIST: "/api/internal/v1/jobs_qc_view/",
	JOB_APPLICATIONS: "/api/employer/v1/applications/",
	GET_AUTH_TOKEN: "/api/employer/auth_token/",
	INTERNAL_TAGS: "/api/internal/v1/job_tags/",
	ATS_FUNNEL_STEPS: "/api/employer/v1/ats-funnel/",
	UPDATE_JOB_ATS_TEMPLATE: "/api/employer/v1/ats-templates/",
	LANGUAGES_BY_CITY: "/api/location/v1/city-languages/",
	UPDATE_EMPLOYER_ECC_ACCESS: "/api/employer/v1/jobs/",
	EMPLOYER_HR_NUMBER_LIST: "/api/employer/v1/employer_hr_number_list/",
	EMPLOYER_HR_NUMBER_STATUS: "/api/employer/v1/employer_hr_number_status/",
	INTERNAL_VERIFY_PHONE_NUMBER: "/api/internal/v1/verify_phone_number/",
	SEND_PHONE_NUMBER_EVENT: "/api/employer/v1/update_application_status/",
	ECC_PREFERENCE_CONFIG: "/api/employer/v1/employer_preference/templates",
	EMPLOYER_JOBS_LOCATION: "/api/employer/v1/jobs_by_location/",
	JOB_ROLES_SUGGESTIONS: "/suggester/api/v2/suggestions",
	CREDIT_EXPIRY_OPTIONS: "/api/internal/v1/get-credits-delete-reason/",
	INITIATE_NUMBER_MASK_CALL_MOBILE: "/number-mask/api/v1/call/initiate",
	INITIATE_NUMBER_MASK_CALL_DESKTOP: "/number-mask/api/v1/call/trigger-call",
	NUMBER_MASK_CALL_STATUS: "/number-mask/api/v1/call/",
	GET_CANDIDATE_RESUME: "/api/employer/v1/resume/",
	ORGANIZATION_DOMAINS: "/api/employer/v1/organization-domains/",
	EMAIL_VERIFICATION_STATUS: "api/employer/v1/employer_hr_email_status/",
	COMPANY_PREFILL: "/api/employer/v1/organization-prefill/",
	SALARY_SUGGESTION_URL: "/api/employer/v1/salary-suggestion",
	BAN_DETAILS: "/api/employer/v1/ban-details/",
	REACTIVATION_REQUEST: "/api/employer/v1/reactivation-request/",
	ACCEPT_REACTIVATION_CONSENT: "/api/employer/v1/consent-accepted/",
	RECRUITERS_LIST: "/api/employer/v1/creator-recruiters/",
	USER_PROFILE: "/api/employer/v2/profile/",
	USER_WORKSPACE: "/api/employer/v1/workspace/",
	INTERNAL_USER_WORKSPACE: "/api/internal/v1/workspace/",
	TNC_AGREEMENT: "/api/employer/v1/check_agreement/",
	SIMILAR_JOBS: "/api/employer/v1/similar_jobs/",
	ACCOUNT_DETAILS: "/apna-account/v1/account/",
	VALIDATE_USER: "/api/userprofile/v1/user/validate-number/",
	PAYMENT_PENDING: "/api/employer/v1/jobs/premium-payment/info",
	AUTO_RENEWAL_CHANGE: "/auto-renew/change/",
	TRANSACTIONS: "/apna-account/v1/coin/transactions/",
	INTERNAL_TRANSACTIONS: "/apna-account/v1/internal/coin/transactions/",
	CHECK_COUPON_URL: "/apna-coupon/api/v1/redeem-coupon/",
	INIT_TRANSFER: "/apna-account/v1/coin/init-transfer/",
	VERIFY_TRANSFER: "/apna-account/v1/coin/verify-transfer/",
	DEBIT_COINS_UPDATE_JOB_STATUS: "/api/employer/v2/debit-and-post-job/",
	PACKAGES: "/product/packages",
	SERVICES: "/product/v3/services-filtered",
	SELECTED_SERVICE: "/product/service/",
	GENERATE_PAYMENT_ORDER: "/order/order",
	APNA_PRODUCTS_URL: "/product/product",
	ACC_OTP: "/apna-account/v1/coin/transfer/resend-otp/",
	STATES_URL: "/api/publicapis/v1/city-state-list/",
	CITIES_URL: "/api/publicapis/v1/city-state-list/",
	REPORT_RESUME_URL: "/api/employer/v1/report_resume/",
	UPDATE_TNC_AGREEMENT: "/api/employer/v1/sign_agreement/",
	TRIGGER_KALEYRA_CALL: "/api/employer/v1/%JOB_ID%/trigger-call/%EMPLOYER_ID%",
	ADD_CALL_FEEDBACK: "/api/internal/v1/jobs/%JOB_ID%/add_call_feedback",
	GET_CALLING_DETAILS: "/api/employer/v1/c2c_call/",
	GET_CALLING_COUNT: "/api/internal/v1/jobs/%JOB_ID%/call-counts",
	GET_EMPLOYER_FEEDBACK_OPTIONS: "/api/internal/v1/employer_feedback_process",
	ADD_EMPLOYER_FEEDBACK_COMMENTS: "/api/internal/v2/jobs/%JOB_ID%/comments/",
	GET_USER_COMPLAINT_HISTORY: "/api/internal/v1/jobs/%JOB_ID%/complaint_history/",
	GET_GST_DETAILS: "/invoice/api/v1/company-detail",
	POST_GST_DETAILS: "/invoice/api/v1/add-company-detail",
	POST_GST_IN_CHECKOUT: "/invoice/api/v1/add-gst-number",
	INVOICE_URL: "/invoice/api/v1/invoice/path/",
	ORDER_URL: "/order/order/",
	COMPANY_VERIFICATION_DETAILS: "/api/internal/v1/jobs/%JOB_ID%/verification_details/",
	PAID_PROMOTION_ELIGIBILITY_URL: "/api/employer/v1/paid-promotion-employer/check/",
	CHECK_MONETISED: "/api/employer/v1/check_monetised/",
	EXPIRY_FEEDBACK_STATUS: "/api/employer/v1/jobs/{jobId}/feedback/",
	GET_SUPPORT_CALL_JOBS_LIST: "/api/internal/v1/jobs_support_view/",
	GET_KALEYRA_CALL_HISTORY: "/api/internal/v1/%JOB_ID%/call-history",
	TRIGGER_SUPPORT_CALL_REQUEST:
		"/api/employer/v1/%JOB_ID%/request_support/%EMPLOYER_ID%",
	DELETEJOB: "/api/employer/v5/jobs/",
	BULK_CSV_DOWNLOAD: "/api/employer/v1/csv-download-info",
	VERIFY_COMPANY_CARD_DETAILS: "/%CARD%/company-details",
	REPORT_MEMBER_AND_CONSULTANCY: "/api/employer/v1/admin/report-%REPORTED%/",
	ADD_TEAM_MEMBER: "/api/employer/v1/admin/add-member/",
	GET_ADMIN_MEMBERS_CONFIG: "/api/employer/v1/admin/config/",
	GET_ADMIN_TEAM_MEMBERS: "/api/employer/v1/admin/recruiters/?organization_id=",
	DUPLICATE_JOB_CHECK: "/api/employer/v2/job/check-duplicate",
	CHECK_FIRST_PURCHASE: "/order/check-first-purchase/",
	COIN_REFUND_DETAILS: "/apna-account/v1/coin/refunded/uf/",
	FREE_LEADS_COUNT: "/rule-validation/v1/free-user-jobs/",
	JOB_POST_MISSING_FIELDS: "/api/employer/v1/jobs/%JOB_ID%/missing_fields/",
	VALIDATE_RETRY_PAYMENT: "order/validate-order-retry-payment/",
	GST_COMPANY_DETAILS: "invoice/api/v1/gst/detail/",
	EXPIRED_FREE_JOBS_LIST: "api/employer/v1/free-jobs-list",
	EXPRESS_CHECKOUT: "/order/check-express-checkout/",
	TITLE_CAT_OPTIONS: "api/employer/v1/title-cat-options",
	APNA_DB_ELIGIBILITY: "/cerebro/api/v1/eligible/",
	EMPLOYER_POST_JOB_PREFILL: "/api/employer/v4/prefill",
	ALLOWED_JOB_TITLES: "/api/employer/v1/%JOB_ID%/allowed-job-titles",
	CREATE_CUSTOM_PLAN: "/product/custom-plan",
	FRAUD_ANALYSIS_DATA: "/api/internal/v1/job/%JOB_ID%/tns-data/",
	VERIFY_USER: "/apna-verification/deviceinfo/",
	CHECK_FOR_MICRO_CITY_FLAG: "/api/employer/v1/micro-city-flag",
};
