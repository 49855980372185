/* eslint-disable consistent-return */
/* eslint-disable indent */
import styled, { css } from "styled-components";
import { mediaMaxWidth } from "../../utils/mediaQuery";

const buttonCssResets = css`
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	background: transparent;
	cursor: pointer;
	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;
	/* Normalize 'line-height'. Cannot be changed from 'normal' in Firefox 4+. */
	line-height: normal;
	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;
	/* Corrects inability to style clickable 'input' types in iOS */
	-webkit-appearance: none;
	/* Remove excess padding and border in Firefox 4+ */
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
`;

const buttonNecessaryCss = css`
	height: 48px;
	border-radius: 4px;
	padding: 0px 32px;
	font-weight: 500;
	text-align: center;
	min-width: 70px;
	transition: all 0.3s ease-out;
	position: relative;
`;

const buttonHoverState = prop => {
	/* Default is Primary */
	if (prop.link) {
		return css`
			background-color: transparent;
			border: none;
			text-decoration: underline;
			color: #2bb793;
			box-shadow: none;
		`;
	}
	if (prop.primary) {
		return css`
			background-color: #4d3951;
			color: #fff;
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.39);
		`;
	}
	if (prop.secondary && prop.danger) {
		return css`
			background-color: #ed767e;
			color: #fff;
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.39);
		`;
	}
	if (prop.secondary && prop.apnaCoins) {
		return css`
			background-color: #f4f2f6;
			color: #2bb793;
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.39);
		`;
	}
	if (prop.secondary) {
		return css`
			background-color: #fff;
			color: #2bb793;
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.39);
		`;
	}
	if (prop.danger) {
		return css`
			background-color: #ed767e;
			color: #fff;
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.39);
		`;
	}
	if (prop.coins) {
		return css`
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.39);
		`;
	}
	return css`
		background-color: #2bb793;
		color: #fff;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.39);
	`;
};
const buttonFocusState = css`
	outline: none;
`;

const buttonDisabledState = prop => {
	if (prop.secondary && prop.link && prop.disabled) {
		return css`
			background-color: #fff;
			color: #c4c6c8;
			border: 1px solid transparent;
			cursor: not-allowed;
			&:hover {
				box-shadow: none;
				text-decoration: none;
			}
		`;
	}
	if (prop.secondary && prop.disabled) {
		return css`
			background-color: #fff;
			color: #c4c6c8;
			border: 1px solid #bbbbbb;
			cursor: not-allowed;
			&:hover {
				box-shadow: none;
			}
		`;
	}
	if (prop.disabled) {
		return css`
			background-color: ${props => !props.disabledWithPrimaryBg && "#bbbbbb"};
			color: #fff;
			cursor: not-allowed;
			&:hover {
				box-shadow: none;
			}
		`;
	}
};

const buttonTextColor = prop => {
	if (prop.link) {
		return css`
			color: #2bb793;
		`;
	}
	if (prop.secondary && prop.danger) {
		return css`
			color: #ed767e;
		`;
	}
	if (prop.secondary) {
		return css`
			color: #2bb793;
		`;
	}
	if (prop.danger) {
		return css`
			color: #fff;
		`;
	}
	if (prop.coins) {
		return css`
			color: #ffd166;
		`;
	}
	return css`
		color: #fff;
	`;
};

const buttonBackgroundColor = prop => {
	if (prop.link) {
		return css`
			background-color: transparent;
			border: none;
		`;
	}
	if (prop.primary) {
		return css`
			border: none;
			background-color: #4d3951;
		`;
	}
	if (prop.secondary && prop.danger) {
		return css`
			border: 1px solid #ed767e;
			background-color: #fff;
		`;
	}
	if (prop.secondary && prop.apnaCoins) {
		return css`
			background-color: #f4f2f6;
			border: 1px solid #2bb793;
		`;
	}
	if (prop.secondary) {
		return css`
			background-color: #fff;
			border: 1px solid #2bb793;
		`;
	}
	if (prop.danger) {
		return css`
			background-color: #ed767e;
		`;
	}
	if (prop.coins) {
		return css`
			background-color: transparent;
			border: 1px solid #ffd166;
		`;
	}
	return css`
		background-color: #2bb793;
		border: 1px solid transparent;
	`;
};

const largeButton = prop => {
	if (prop.large) {
		return css`
			width: 100%;
		`;
	}
	if (prop.height) {
		return css`
			height: ${prop.height};
			width: auto;
		`;
	}
	return css`
		width: auto;
	`;
};

const buttonFont = prop => {
	if (prop.fontSize) {
		return css`
			font-size: ${prop.fontSize};
		`;
	}

	if (prop.lineHeight) {
		return css`
			line-height: ${prop.lineHeight};
		`;
	}
	return css`
		font-size: 16px;
	`;
};

const transcriptButton = prop => {
	if (prop.transcriptButton) {
		return css`
			height: 35px;
			font-weight: 500;
		`;
	}
};

const CustomCSSButton = props => {
	return css`
		padding: ${props.customPadding};
		border-radius: ${props.borderRadius};
	`;
};

const buttonText = prop => {
	if (prop.link && prop.apnaCoins) {
		return css`
			text-decoration: underline;
		`;
	}
	return css`
		text-decoration: none;
	`;
};

const Button = styled.button`
    ${buttonCssResets} /* To Remove all default css from the button */
    ${buttonNecessaryCss}
    ${buttonTextColor}
    ${buttonBackgroundColor}
    ${largeButton}
    ${buttonFont}
    ${transcriptButton}
	${CustomCSSButton}
	${buttonText}
    &:hover {
        ${buttonHoverState}
    }
    &:focus {
        ${buttonFocusState}
    }
    &:disabled {
        ${buttonDisabledState}
    }
`;

export const PrimaryCta = styled(Button)`
	padding: 8px 32px;
	height: auto;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	&:disabled {
		background-color: #2bb793;
	}

	${mediaMaxWidth.medium`
		min-width: 248px;
	`}
`;

export default Button;
