/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useRef } from "react";
import styled from "styled-components";
import { BinIcon, DocIcon, UploadIcon } from "../../assets/svg";
import Button from "../Buttons/Button";

const BYTES_IN_1_MB = 2 ** 20;

const DocUploader = ({
	fileName,
	infoText,
	uploadCtaText,
	supportedDocTypes = [],
	maxFileSizeInMB,
	onFileSizeError = () => null,
	onChange = () => null,
	error = false,
	onDelete = () => null,
}) => {
	const hiddenFileInputRef = useRef(null);

	const onCTAClick = () => {
		hiddenFileInputRef.current.click();
	};

	const handleDelete = () => {
		hiddenFileInputRef.current.value = "";
		onDelete();
	};

	const handleChange = e => {
		const [file] = e?.target?.files || [null];
		if (!file) {
			return;
		}
		const fileSizeInMB = file.size / BYTES_IN_1_MB;
		if (maxFileSizeInMB && fileSizeInMB > maxFileSizeInMB) {
			onFileSizeError(file, fileSizeInMB);
			return;
		}
		onChange(file);
	};

	return (
		<>
			{fileName ? (
				<Container2>
					<Section>
						<DocIcon />
						<SectionInfoContainer>
							<FileName>{fileName}</FileName>
							<InfoText2>{infoText}</InfoText2>
						</SectionInfoContainer>
					</Section>
					<UploadCta2 onClick={handleDelete}>
						<BinIcon />
					</UploadCta2>
				</Container2>
			) : (
				<>
					<Container1 hasError={!!error}>
						<InfoText>{infoText}</InfoText>
						<UploadCta1 onClick={onCTAClick}>
							<UploadIcon />
							&nbsp;&nbsp;{uploadCtaText}
						</UploadCta1>
					</Container1>
					{error && <ErrorText>{error}</ErrorText>}
				</>
			)}
			<input
				type="file"
				ref={hiddenFileInputRef}
				onChange={handleChange}
				{...(supportedDocTypes?.length && {
					accept: supportedDocTypes.map(d => `.${d}`).join(),
				})}
				style={{ display: "none" }}
			/>
		</>
	);
};

const Container1 = styled.div`
	padding: 20px;
	background: #f5f7fb;
	border: 1px solid ${({ hasError }) => (hasError ? "#CB2F4B" : "#dae0e5")};
	box-sizing: border-box;
	border-radius: 8px;
`;

const Container2 = styled.div`
	padding: 8px 16px;
	background: rgba(124, 219, 183, 0.1);
	border: 1px solid #2bb792;
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Section = styled.div`
	display: flex;
	align-items: center;
`;

const SectionInfoContainer = styled.div`
	padding: 0 14px;
`;

const FileName = styled.div`
	max-width: 160px;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: #444444;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const InfoText = styled.div`
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	color: #a5a5a5;
	margin-bottom: 16px;
`;

const InfoText2 = styled.div`
	font-weight: normal;
	font-size: 9px;
	line-height: 10px;
	color: #2bb792;
	margin-top: 2px;
`;

const UploadCta1 = styled(Button)`
	padding: 8px 32px;
	height: auto;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	border-radius: 2px;
	background: #4d3951;

	&:hover {
		background: #4d3951;
	}
`;

const UploadCta2 = styled.div`
	cursor: pointer;
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ffffff;
	border-radius: 100px;
`;

const ErrorText = styled.div`
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #cb2f4b;
	margin-top: 4px;
`;

export default DocUploader;
