import {
	UPDATE_CANDIDATE_APPLICATION,
	UPDATE_JOB_APPLICATIONS_LOADER,
	UPDATE_JOB_APPLICATIONS_LIST,
	RESET_JOB_APPLICATIONS,
	FETCHED_SUGGESTED_CANDIDATES,
	UPDATE_TAB_COUNT,
	FETCHED_APPLICANT_PROFILE,
	RESET_APPLICANT_PROFILE,
} from "../constants/types";
import { JOB_APPLICATIONS_PAGE_SIZE, CONNECTION_TABS } from "../constants";
import { fetchCandidates } from "../utils/apiClients";
import { getMatchedJobs, updateJobApplicationFeedback } from "../../utils/apiClients";
import { sendNotification } from "../../actions";
import { setRecentCandidateQueue } from "./recently-updated-candidates-action";

export const showCandidatesLoader = value => ({
	type: UPDATE_JOB_APPLICATIONS_LOADER,
	value,
});

export const updateApplicationCount = (tab, count) => {
	return {
		type: UPDATE_TAB_COUNT,
		payload: {
			tab,
			count,
		},
	};
};

export const fetchTabCandidates = (jobId, tab, pageCount = 1) => async dispatch => {
	try {
		const response = await fetchCandidates(jobId, tab, pageCount);
		if (response?.status === 200) {
			let candidateList = response.data?.results || [];
			if (tab !== CONNECTION_TABS.NEW_CANDIDATES) {
				candidateList = candidateList.filter(
					candidate => tab === candidate?.connection_status
				);
			}

			dispatch({
				type: UPDATE_JOB_APPLICATIONS_LIST,
				payload: {
					jobId,
					tab,
					candidates: candidateList,
					page: pageCount,
				},
			});
			dispatch(updateApplicationCount(tab, response?.data?.count));
		} else {
			throw response;
		}
	} catch (e) {
		console.log(e);
		dispatch(showCandidatesLoader(false));
	}
};

export const updateCandidateList = (user, jobId, currentTab, page) => (
	dispatch,
	getState
) => {
	const candidates = getState().jobApplications[jobId]?.[currentTab] || [];
	const selectedTabCount = getState().jobApplicationCount?.[currentTab] || 0;
	const totalPages = Math.ceil(selectedTabCount / JOB_APPLICATIONS_PAGE_SIZE);

	if (page === totalPages) {
		// move left
		if ((candidates?.length - 1) % JOB_APPLICATIONS_PAGE_SIZE === 0) {
			dispatch(fetchTabCandidates(jobId, currentTab, page - 1));
		}
		// same page
		else {
			const updatedCandidateList =
				candidates.filter(candidate => candidate?.id !== user?.id) || [];
			dispatch({
				type: UPDATE_JOB_APPLICATIONS_LIST,
				payload: {
					jobId,
					tab: currentTab,
					candidates: updatedCandidateList,
					page,
				},
			});
		}
	} else {
		// move right
		dispatch(fetchTabCandidates(jobId, currentTab, page));
	}
};

export const resetTabCandidates = (jobId, tab) => {
	return {
		type: RESET_JOB_APPLICATIONS,
		payload: {
			jobId,
			tab,
		},
	};
};

export const fetchSuggestedCandidates = jobId => async dispatch => {
	try {
		const response = await getMatchedJobs(jobId);
		if (response?.status === 200) {
			const candidates = response.data || [];
			dispatch({
				type: FETCHED_SUGGESTED_CANDIDATES,
				payload: { candidates },
			});
		} else {
			throw response;
		}
	} catch (e) {
		console.log(e);
	}
};

export const resetExtendedProfile = () => {
	return {
		type: RESET_APPLICANT_PROFILE,
	};
};

export const setExtendedProfile = (candidate, inQueue = false, redirectWA = false) => {
	return {
		type: FETCHED_APPLICANT_PROFILE,
		payload: { candidate, inQueue, open: true, redirectWA },
	};
};

export const updateCandidateApplication = (updatedCandidateList, jobId, tab) => {
	return {
		type: UPDATE_CANDIDATE_APPLICATION,
		payload: {
			jobId,
			tab,
			candidates: updatedCandidateList,
		},
	};
};

export const updateRecentCandidateQueue = (
	candidate,
	nextHiringState,
	jobId,
	tab,
	page
) => (dispatch, getState) => {
	const updatedLeadsQueue = getState().teccRecentlyUpdatedQueue || {};
	const recentActionList = updatedLeadsQueue?.updatedCandidates || [];
	const updatedQueue = recentActionList.map(c => {
		if (c?.id === candidate?.id) return { ...c, hiring_state: nextHiringState.state };
		return c;
	});
	dispatch(setRecentCandidateQueue(updatedQueue, jobId, tab, page));
};

export const updateCandidateApplicationsList = (
	candidate,
	nextHiringState,
	jobId,
	tab
) => (dispatch, getState) => {
	const candidateList = getState().jobApplications[jobId]?.[tab] || [];
	const updatedList = candidateList.map(c => {
		if (c?.id === candidate?.id) return { ...c, hiring_state: nextHiringState.state };
		return c;
	});
	dispatch(updateCandidateApplication(updatedList, jobId, tab));
};

export const updateCandidateHiringState = (
	candidate,
	nextHiringState,
	jobId,
	tab,
	page,
	isExtendedProfileAction
) => async dispatch => {
	try {
		const response = await updateJobApplicationFeedback({
			id: nextHiringState?.id,
			state: nextHiringState?.state,
			city_id: nextHiringState?.city,
		});
		if (response?.status === 200) {
			// update candidate detail in candidate list
			dispatch(
				updateCandidateApplicationsList(candidate, nextHiringState, jobId, tab)
			);
			// update candidate detail in recently updated queue
			dispatch(
				updateRecentCandidateQueue(candidate, nextHiringState, jobId, tab, page)
			);
			if (isExtendedProfileAction) {
				dispatch(
					setExtendedProfile({
						...candidate,
						hiring_state: nextHiringState.state,
					})
				);
			}
			dispatch(
				sendNotification({
					message_type: "success",
					message: "Profile updated successfully!",
				})
			);
		} else {
			dispatch(
				sendNotification({
					message_type: "error",
					message: "Network error. Try again.",
				})
			);
		}
	} catch (e) {
		console.log(e);
	}
};
