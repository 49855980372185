/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import httpClient from "axios";
import config from "../config";
import url from "../config/urls";
import CommonUtils from "./commonUtils";
import { JOB_APPLICANT_PAGE_SIZE } from "../constants";
import { COIN_PRODUCT } from "./constants";

const axios = httpClient.create({
	baseURL: config.baseUrl,
});
axios.interceptors.request.use(con => {
	const TOKEN = CommonUtils.getItemFromLocalStorage("__token__");
	if (TOKEN) con.headers.Authorization = `Token ${TOKEN}`;
	con.headers["Content-Type"] = "application/json;charset=UTF-8";
	return con;
});

// changing common header object to function for run time auth token change
export const getCommonHeader = () => {
	return undefined;
};

export const updateUrl = (endPoint, data = {}) => {
	if (!data || !Object.keys(data).length) return endPoint;

	return Object.keys(data).reduce((acc, curr) => {
		return acc.replace(`%${curr}%`, data[curr]);
	}, endPoint);
};

const loginV2 = data => {
	const URL = config.baseUrl + url.LOGIN_V2;
	return axios.post(URL, data);
};

const logoutV1 = () => {
	const URL = config.baseUrl + url.LOGOUT;
	return axios.post(URL, null);
};

const getOTP = params => {
	const URL = config.baseUrl + url.OTP;

	return Promise.resolve(axios.get(URL, { params }))
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getOTPV2 = params => {
	const URL = config.baseUrl + url.OTP_V2;

	return Promise.resolve(axios.get(URL, { params }))
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getOTPV3 = async params => {
	const URL = config.baseUrl + url.OTP_V3;

	return axios.post(URL, params);
};

const getJobs = () => {
	// const URL = `${config.baseUrl + url.JOBS + data}/`;
	const URL = `${config.baseUrl + url.JOBS_V6}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getPaginatedJobs = (queryParams = "") => {
	const URL = `${config.baseUrl + url.JOBS_V6}${queryParams}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp?.data || {};
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobApplications = data => {
	const URL = `${config.baseUrl + url.JOB_DATA + data}/applications/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobDetail = data => {
	const URL = `${config.baseUrl + url.JOBS_V6 + data}/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getMatchedJobs = data => {
	const URL = `${config.baseUrl + url.JOB_DATA + data}/suggestions/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const changeJobStatus = (jobId, status, remark) => {
	const URL = `${config.baseUrl + url.JOBS_V3 + jobId}/activate/`;
	const data = {
		status,
	};
	// eslint-disable-next-line no-unused-expressions
	remark && (data.comment = remark);
	return Promise.resolve(
		axios.put(URL, data, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const reactivateJob = (jobId, status, remark) => {
	const URL = `${config.baseUrl + url.JOBS_V3 + jobId}/activate/`;
	const data = {
		status,
	};
	// eslint-disable-next-line no-unused-expressions
	remark && (data.comment = remark);
	return Promise.resolve(
		axios.put(URL, data, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

export const getFeebackJob = jobId => {
	const URL = `${config.baseUrl + url.JOBS + jobId}/feedback/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

export const submitFeedbackJob = (jobId, data) => {
	const URL = `${config.baseUrl + url.JOBS + jobId}/feedback/`;
	return Promise.resolve(
		axios.post(URL, data, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getBlockedUser = jobId => {
	const URL = `${config.baseUrl + url.BANNED_USER + jobId}/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobTitles = params => {
	const URL = `${config.baseUrl + url.JOB_TITLES}?approved=true`;
	return axios.get(URL, {
		headers: getCommonHeader(),
		params,
	});
};

const getJobCategories = params => {
	let URL = config.baseUrl + url.NEW_JOB_CATEGORIES;
	if (params) URL += `?${params}`;
	return Promise.resolve(
		axios({
			method: "get",
			url: URL,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getFuzzyJobRoles = (params = "") => {
	const URL = `https://production.apna.co${url.JOB_ROLES_SUGGESTIONS}${params}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

const getEducationLevel = () => {
	const URL = config.baseUrl + url.EDUCATION_LEVELS;
	return Promise.resolve(
		axios({
			method: "get",
			url: URL,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getOrganizations = () => {
	const URL = config.baseUrl + url.ORGANIZATION_NAME;
	return Promise.resolve(
		axios({
			method: "get",
			url: URL,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const postJob = data => {
	const URL = config.baseUrl + url.JOBS_V6;
	return Promise.resolve(
		axios({
			method: "post",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const editJob = (id, data) => {
	const URL = `${config.baseUrl + url.JOBS_V6 + id}/`;
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const employerEditJob = (id, data) => editJob(id, data);

const acceptTerms = () => {
	const URL = config.baseUrl + url.ACCEPT_TERMS;
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const extendExpiry = (data, jobId) => {
	const URL = `${config.baseUrl + url.JOBS_V3 + jobId}/set-expiry/`;
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			headers: getCommonHeader(),
			data,
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getCityWiseAreas = (cityId, subCityId = "") => {
	const URL = `${config.baseUrl + url.CITIES_LIST + cityId}/areas/${
		subCityId ? `?sub_city_id=${subCityId}` : ""
	}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getCitiesList = () => {
	const URL = config.baseUrl + url.CITIES_LIST;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getQueryResult = data => {
	const URL = config.baseUrl + url.SQL_QUERY;
	return Promise.resolve(
		axios({
			method: "post",
			url: URL,
			data: {
				query: data,
			},
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobsPostedCountOfHRNumber = number => {
	const URL = `${config.baseUrl + url.JOB_POST_COUNT + number}/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateUserPersona = data => {
	const URL = config.baseUrl + url.USER_PERSONA;

	return Promise.resolve(
		axios.put(URL, data, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const uploadCompanyDocs = (data, userId) => {
	const URL = `${config.baseUrl + url.USERS + userId}/documents/`;
	return Promise.resolve(
		axios({
			method: "post",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateDocs = (data, userId, documentId) => {
	const URL = `${config.baseUrl + url.USERS + userId}/documents/${documentId}/`;
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getUserDocs = (userId, orgId = null) => {
	const URL = `${config.baseUrl + url.USERS + userId}/documents/`;
	return Promise.resolve(
		axios({
			method: "get",
			url: URL,
			headers: getCommonHeader(),
			params: {
				organization_id: orgId,
			},
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getEmployerInviteText = source => {
	const URL = `${config.baseUrl + url.EMPLOYER_INVITE_TEXT}?source=${source}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const sendEmployerInvite = phoneNums => {
	const URL = `${config.baseUrl + url.SEND_EMPLOYER_INVITE}?phones=${phoneNums}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateJobApplicationFeedback = data => {
	const URL = `${config.baseUrl + url.JOB_APPLICATIONS + data.id}/feedback/`;
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobAppliedCandidate = (id, pageNo, pageSize = JOB_APPLICANT_PAGE_SIZE) => {
	const URL = `${config.baseUrl +
		url.EMPLOYER_JOBS_LIST_V2 +
		id}/applications/?page=${pageNo}&page_size=${pageSize}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getAllQuestions = jobId => {
	const URL = `${config.baseUrl + url.ASSESSMENT}job/${jobId}/questions/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getAuthToken = data => {
	const URL = `${config.baseUrl + url.GET_AUTH_TOKEN}`;
	return Promise.resolve(
		axios({
			method: "post",
			url: URL,
			data,
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const sendShowPhoneNumberEvent = data => {
	const URL = `${config.baseUrl + url.SEND_PHONE_NUMBER_EVENT}`;
	return Promise.resolve(
		axios({
			method: "post",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getEmployerHrNumberList = () => {
	// sample url: {{baseUrl}}/api/employer/v1/employer_hr_number_list/
	const URL = `${config.baseUrl}${url.EMPLOYER_HR_NUMBER_LIST}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const resendHrNumVerificationLink = ({ hrNo, jobId }) => {
	const URL = `${config.baseUrl}${url.EMPLOYER_HR_NUMBER_STATUS}?hr_number=${hrNo}`;
	const data = {
		job_id: jobId,
	};
	return Promise.resolve(
		axios({
			method: "post",
			headers: getCommonHeader(),
			url: URL,
			data,
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getHrNumVerificationStatus = (hrNo, employerNo) => {
	const URL = `${config.baseUrl}${url.EMPLOYER_HR_NUMBER_STATUS}?hr_number=${hrNo}&employer_number=${employerNo}`;
	return Promise.resolve(
		axios({
			method: "get",
			headers: getCommonHeader(),
			url: URL,
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getATSFunnelSteps = () => {
	const URL = `${config.baseUrl + url.ATS_FUNNEL_STEPS}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateJobATSTemplate = (templateId, jobId, data) => {
	const URL = `${config.baseUrl +
		url.UPDATE_JOB_ATS_TEMPLATE +
		templateId}/?job_id=${jobId}`;
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateEmployerECCAccess = (jobId, data) => {
	const URL = `${config.baseUrl +
		url.UPDATE_EMPLOYER_ECC_ACCESS +
		jobId}/ats-ecc-employer/`;

	return Promise.resolve(
		axios({
			method: "post",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getEccPreferenceConfig = () => {
	const URL = `${config.baseUrl}${url.ECC_PREFERENCE_CONFIG}`;
	return axios
		.get(URL, {
			headers: getCommonHeader(),
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobsLocation = () => {
	const URL = `${config.baseUrl + url.EMPLOYER_JOBS_LOCATION}`;

	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp?.data || [];
		})
		.catch(err => {
			throw new Error(err);
		});
};

const fetchCandidateResumeLink = (params = "") => {
	const URL = `${url.GET_CANDIDATE_RESUME}${params}`;
	return axios
		.get(URL, {
			headers: getCommonHeader(),
		})
		.then(resp => {
			return resp?.data || [];
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateEccPreference = (jobId, data) => {
	const URL = `${config.baseUrl}${url.UPDATE_EMPLOYER_ECC_ACCESS}${jobId}/preference`;
	return axios({
		method: "put",
		url: URL,
		data,
		headers: getCommonHeader(),
	}).catch(err => {
		throw new Error(err);
	});
};

const getCreditExpiryOptions = () =>
	axios.get(url.CREDIT_EXPIRY_OPTIONS, {
		headers: getCommonHeader(),
	});

const initateNumberMaskCallMobile = (
	employerId,
	employerPhoneNumber,
	candidateId,
	candidatePhoneNumber,
	cityId,
	jobId
) => {
	const URL = url.INITIATE_NUMBER_MASK_CALL_MOBILE;
	const data = {
		source: "web",
		from: {
			type: "employer",
			phone: `+91${employerPhoneNumber}`,
			id: employerId,
		},
		to: {
			type: "candidate",
			phone: `+91${candidatePhoneNumber}`,
			id: candidateId,
		},
		city_id: cityId,
		job_id: String(jobId),
	};
	return axios.post(URL, data, { headers: getCommonHeader() });
};

const initateNumberMaskCallDesktop = (
	employerId,
	employerPhoneNumber,
	candidateId,
	candidatePhoneNumber,
	cityId,
	jobId
) => {
	const URL = url.INITIATE_NUMBER_MASK_CALL_DESKTOP;
	const data = {
		source: "web",
		from: {
			type: "employer",
			phone: `+91${employerPhoneNumber}`,
			id: employerId,
		},
		to: {
			type: "candidate",
			phone: `+91${candidatePhoneNumber}`,
			id: candidateId,
		},
		city_id: cityId,
		job_id: String(jobId),
	};
	return axios.post(URL, data, { headers: getCommonHeader() });
};

const numberMaskCallStatus = callId => {
	const URL = `${url.NUMBER_MASK_CALL_STATUS}${callId}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};
const getCompanyDomains = (id, isGetByJobId = false) =>
	axios.get(
		`${url.ORGANIZATION_DOMAINS}?${
			isGetByJobId ? "job_id" : "organization_id"
		}=${id}`,
		{
			headers: getCommonHeader(),
		}
	);

const sendEmailVerificationLink = (jobId, email) =>
	axios.post(
		`${url.JOB_DATA_V2}${jobId}/resend_email_link/`,
		{
			...(email && { hr_email: email }),
		},
		{
			headers: getCommonHeader(),
		}
	);

const uploadPocDoc = (userId, orgId, payload) => {
	const URL = `${url.USERS}${userId}/${orgId}/pocdoc/`;
	return axios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

const patchPocDoc = (userId, orgId, docId, payload) => {
	const URL = `${url.USERS}${userId}/${orgId}/pocdoc/${docId}/`;
	return axios.put(URL, payload, {
		headers: getCommonHeader(),
	});
};

const getPocDocs = (userId, orgId) => {
	const URL = `${url.USERS}${userId}/${orgId}/pocdoc/`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

const getEmailVerificationStatus = (creatorId, email) =>
	axios.get(url.EMAIL_VERIFICATION_STATUS, {
		headers: getCommonHeader(),
		params: {
			hr_email: email,
			created_by_id: creatorId,
		},
	});

const updatePocVerificationStatus = (jobId, status, isHr) => {
	const URL = `${url.INTERNAL_USERS_V2}${jobId}/verify_poc/`;
	return axios.put(
		URL,
		{ status },
		{
			headers: getCommonHeader(),
			params: {
				employer_to_verify: isHr ? "hr" : "creator",
			},
		}
	);
};

const getCompanyPrefillData = jobId => {
	let URL = url.COMPANY_PREFILL;
	if (jobId) {
		URL += `?job_id=${jobId}`;
	}
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};
const fetchSalarySuggestion = (params = "") => {
	const URL = `${url.SALARY_SUGGESTION_URL}${params}`;
	return axios
		.get(URL, {
			headers: getCommonHeader(),
		})
		.then(resp => {
			return resp?.data || [];
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getEmployerBanDetails = phoneNo =>
	axios.get(`${url.BAN_DETAILS}${phoneNo}/`, {
		headers: getCommonHeader(),
	});

const requestReactivation = payload =>
	axios.post(url.REACTIVATION_REQUEST, payload, { headers: getCommonHeader() });

const acceptReactivationConsent = () =>
	axios.post(url.ACCEPT_REACTIVATION_CONSENT, null, { headers: getCommonHeader() });

const getRecruitersList = () =>
	axios.get(`${url.RECRUITERS_LIST}`, {
		headers: getCommonHeader(),
	});

const getUserProfile = () =>
	axios.get(url.USER_PROFILE, {
		headers: getCommonHeader(),
	});

const getUserWorkspace = jobId => {
	const URL = url.USER_WORKSPACE + (jobId ? `?job_id=${jobId}` : "");
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};
const checkTncAgreement = () =>
	axios.get(`${url.TNC_AGREEMENT}`, {
		headers: getCommonHeader(),
	});

const updateTncAgreement = () => {
	const URL = url.UPDATE_TNC_AGREEMENT;
	return axios.put(
		URL,
		{},
		{
			headers: getCommonHeader(),
		}
	);
};

const getSimilarJobs = payload => {
	const URL = url.SIMILAR_JOBS;
	return axios.post(URL, payload, { headers: getCommonHeader() });
};

const accountDetails = () => {
	const userProfile = JSON.parse(CommonUtils.getItemFromLocalStorage("__user__"));
	const userId = userProfile && userProfile.id;
	const URL = `${url.ACCOUNT_DETAILS}${userId}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const createAccount = (notEligibleForFreeCoins = false) => {
	const userProfile = JSON.parse(CommonUtils.getItemFromLocalStorage("__user__"));
	const userId = userProfile && userProfile.id;
	const URL = url.ACCOUNT_DETAILS;

	const data = {
		user_id: `${userId}`,
		ignore_complementary_coins: notEligibleForFreeCoins,
	};

	return Promise.resolve(
		axios.post(URL, data, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const validatePhoneNumber = number => {
	const URL = `${url.VALIDATE_USER + number}/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const getPaymentPendingInfo = () => {
	const URL = `${url.PAYMENT_PENDING}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const autoRenewalChange = (jobId, renewStatus, serviceId) => {
	const URL = `${config.baseUrl + url.JOBS + jobId + url.AUTO_RENEWAL_CHANGE}`;

	const data = {
		auto_renew: renewStatus,
		service_id: serviceId,
	};

	return Promise.resolve(
		axios.put(URL, data, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const getTransactions = (accountId, pageNo = 1, itemsPerPage = 10, type) => {
	let URL = `${url.TRANSACTIONS +
		accountId}?page=${pageNo}&limit=${itemsPerPage}&sort_on=timestamp&sort_order=desc`;
	if (type !== "all") {
		URL += `&type=${type}`;
	}

	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
export const initiateTransfer = data => {
	const URL = url.INIT_TRANSFER;
	return axios.post(URL, data, {
		headers: getCommonHeader(),
	});
};

export const verifyTransfer = data => {
	const URL = url.VERIFY_TRANSFER;
	return axios.post(URL, data, {
		headers: getCommonHeader(),
	});
};
const debitCoinsAndUpdateJobStatus = (
	jobId,
	serviceId,
	status,
	cost,
	isFreeJob = false
) => {
	const URL = `${config.baseUrl + url.DEBIT_COINS_UPDATE_JOB_STATUS + jobId}/`;

	const data = {
		service_id: serviceId,
		status,
		cost,
		free_job: isFreeJob,
	};

	return Promise.resolve(
		axios.put(URL, data, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const deleteJob = (jobId = "") => {
	const URL = `${config.baseUrl}${url.DELETEJOB}${jobId}`;
	return axios
		.delete(URL, {
			headers: getCommonHeader(),
		})
		.then(response => response)
		.catch(error => {
			throw new Error(error);
		});
};

const checkCouponCode = (payload = {}) => {
	const URL = `${url.CHECK_COUPON_URL}`;
	return axios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

const getAllPackages = () => {
	const URL = `${config.baseUrl + url.PACKAGES}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
		params: {
			sort_on: "price.mrp",
			source: "emdash",
		},
	});
};

const getAllActivationPlans = (params = {}, payload = {}) =>
	axios.put(url.SERVICES, payload, {
		headers: getCommonHeader(),
		params,
	});

const checkForFirstPurchase = (userId = "") => {
	const URL = `${url.CHECK_FIRST_PURCHASE}${userId}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

const getSelectedActivationPlan = serviceId => {
	const URL = `${url.SELECTED_SERVICE}${serviceId}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

const initiateBuyCoins = (payload = {}) => {
	const URL = `${url.GENERATE_PAYMENT_ORDER}`;
	return axios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

const generatePaymentOrder = (payload = {}, orderId) => {
	const URL = `${url.GENERATE_PAYMENT_ORDER}/${orderId}/place`;
	return axios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

const confirmOrderPayment = (payload = {}, orderId) => {
	const URL = `${url.GENERATE_PAYMENT_ORDER}/${orderId}/validate`;
	return axios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

const getApnaProducts = () => {
	const URL = `${url.APNA_PRODUCTS_URL}?title=${COIN_PRODUCT}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

const resendTransactionOTP = params => {
	const URL = `${url.ACC_OTP}`;
	return Promise.resolve(axios.post(URL, params, { headers: getCommonHeader() }))
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const reportResume = data => {
	return axios.post(url.REPORT_RESUME_URL, data, { headers: getCommonHeader() });
};

const fetchStateDetails = () => {
	return axios.get(url.STATES_URL, {
		headers: getCommonHeader(),
	});
};

const fetchCitiesList = (queryParam = "") => {
	return axios.get(`${url.STATES_URL}${queryParam}`, {
		headers: getCommonHeader(),
	});
};

const getGstDetails = userId => {
	return axios.get(`${url.GET_GST_DETAILS}/${userId}`, {
		headers: getCommonHeader(),
	});
};

const postGstDetails = (payload, userId) => {
	return axios.post(`${url.POST_GST_DETAILS}/${userId}`, payload, {
		headers: getCommonHeader(),
	});
};

const getInvoice = orderId => {
	const URL = `${url.INVOICE_URL}${orderId}`;
	return axios.get(URL, { headers: getCommonHeader() });
};
const getOrderDetails = orderId => {
	const URL = `${url.ORDER_URL}${orderId}`;
	return axios.get(URL, { headers: getCommonHeader() });
};

const checkEligibleForPaidPromotion = () => {
	const URL = `${url.PAID_PROMOTION_ELIGIBILITY_URL}`;
	return axios.get(URL, { headers: getCommonHeader() });
};

const postVerifyCompanyVerificationEnteredCardDetails = ({ apiKey, payload = {} }) => {
	const URL = `${url.VERIFY_COMPANY_CARD_DETAILS.replace("%CARD%", apiKey)}`;
	return axios.post(URL, payload, { headers: getCommonHeader() });
};

const reportMembersAndConsultancy = ({ reported, payload }) => {
	return axios.post(
		url.REPORT_MEMBER_AND_CONSULTANCY.replace("%REPORTED%", reported),
		payload,
		{
			headers: getCommonHeader(),
		}
	);
};

const addTeamMember = payload => {
	return axios.post(url.ADD_TEAM_MEMBER, payload, {
		headers: getCommonHeader(),
	});
};

const getAdminMembersConfig = () => {
	const URL = `${url.GET_ADMIN_MEMBERS_CONFIG}`;
	return axios.get(URL, { headers: getCommonHeader() });
};

const getAdminTeamMembers = ({ ordId }) => {
	const URL = `${url.GET_ADMIN_TEAM_MEMBERS}${ordId}`;
	return axios.get(URL, { headers: getCommonHeader() });
};

const getAdminConsultanciesTeamMembers = ({ ordId, consultancyId }) => {
	const URL = `${url.GET_ADMIN_TEAM_MEMBERS}${ordId}&consultancy_id=${consultancyId}`;
	return axios.get(URL, { headers: getCommonHeader() });
};
const checkIfJobWillBePaidJob = (queryParams = "") => {
	const URL = `${url.CHECK_MONETISED}${queryParams}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
		queryParams,
	});
};
const checkForDuplicateJob = (params = {}) => {
	const URL = `${url.DUPLICATE_JOB_CHECK}`;

	return axios.get(URL, {
		headers: getCommonHeader(),
		params,
	});
};

const getExpiryFeedbackStatus = jobId => {
	const URL = url.EXPIRY_FEEDBACK_STATUS.replace("{jobId}", jobId);
	return axios.get(URL, { headers: getCommonHeader() });
};

const triggerSupportCallRequest = ({ jobId, employerId, payload = {} }) => {
	const URL = `${url.TRIGGER_SUPPORT_CALL_REQUEST.replace("%JOB_ID%", jobId).replace(
		"%EMPLOYER_ID%",
		employerId
	)}`;
	return axios.post(URL, payload, { headers: getCommonHeader() });
};

const getJobApplicationsList = remark => {
	const URL = `${url.JOB_APPLICATIONS}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
		params: {
			remark,
		},
	});
};

const getBulkCSVDownloadInfo = () =>
	axios.get(url.BULK_CSV_DOWNLOAD, {
		headers: getCommonHeader(),
	});

const getCoinRefundDetails = jobId => {
	const URL = `${url.COIN_REFUND_DETAILS}${jobId}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

export const getFreeLeadsCount = () =>
	axios.get(`${url.FREE_LEADS_COUNT}`, {
		headers: getCommonHeader(),
	});

export const getJobPostMissingFields = jobId => {
	const URL = `${url.JOB_POST_MISSING_FIELDS.replace("%JOB_ID%", jobId)}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};
export const validateRetryPayment = orderId =>
	axios.get(`${url.VALIDATE_RETRY_PAYMENT}${orderId}`, {
		headers: getCommonHeader(),
	});

export const getGSTCompanyDetails = payload =>
	axios.post(url.GST_COMPANY_DETAILS, payload, {
		headers: getCommonHeader(),
	});

export const postGstDetailsInCheckout = (payload, userId) => {
	return axios.post(`${url.POST_GST_IN_CHECKOUT}/${userId}/`, payload, {
		headers: getCommonHeader(),
	});
};

export const getExpiredFreeJobsList = () =>
	axios.get(url.EXPIRED_FREE_JOBS_LIST, {
		headers: getCommonHeader(),
	});

export const getExpressCheckout = () =>
	axios.get(url.EXPRESS_CHECKOUT, {
		headers: getCommonHeader(),
	});

export const getCategoryOptions = params => {
	const URL = url.TITLE_CAT_OPTIONS;

	return axios.get(URL, { headers: getCommonHeader(), params });
};
export const getApnaDatabaseEligibility = () =>
	axios.get(`${config.accountsUrl}${url.APNA_DB_ELIGIBILITY}`, {
		header: getCommonHeader(),
	});

export const getEmployerJobPostPrefillData = params => {
	const URL = url.EMPLOYER_POST_JOB_PREFILL;
	return axios.get(URL, {
		headers: getCommonHeader(),
		params,
	});
};
export const getAllowedJobTitles = jobId => {
	const URL = `${url.ALLOWED_JOB_TITLES.replace("%JOB_ID%", jobId)}?approved=true`;

	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};
export const createCustomPlan = payload =>
	axios.post(`${url.CREATE_CUSTOM_PLAN}`, payload, {
		headers: getCommonHeader(),
	});

/**
 * @typedef {object} ApnaVerifyPayload
 * @property {string} deviceId
 * @property {string} sessionId
 * @property {"shield" | "fingerprint"} vendor
 * @property {"employer"} source
 */

/**
 *
 * @param {ApnaVerifyPayload} payload
 * @return {Promise<{message: string}>}
 */
const sendDeviceInfo = payload => {
	return axios.post(url.VERIFY_USER, payload, {
		headers: getCommonHeader(),
	});
};

export const checkForMicroCityFlag = ({
	titleId = "",
	categoryId = "",
	orgId = "",
	jobLocationType = "",
}) => {
	const params = {
		title_id: titleId,
		category_id: categoryId,
		org_id: orgId,
		job_location_type: jobLocationType,
	};
	return axios.get(`${url.CHECK_FOR_MICRO_CITY_FLAG}`, {
		headers: getCommonHeader(),
		params,
	});
};
export {
	logoutV1,
	loginV2,
	getJobs,
	getOTP,
	getOTPV2,
	getOTPV3,
	getQueryResult,
	getJobApplications,
	getJobDetail,
	getMatchedJobs,
	reactivateJob,
	changeJobStatus,
	getBlockedUser,
	getJobTitles,
	getJobCategories,
	getEducationLevel,
	getOrganizations,
	postJob,
	editJob,
	acceptTerms,
	extendExpiry,
	getCityWiseAreas,
	getCitiesList,
	getJobsPostedCountOfHRNumber,
	updateUserPersona,
	uploadCompanyDocs,
	getUserDocs,
	getEmployerInviteText,
	sendEmployerInvite,
	updateDocs,
	updateJobApplicationFeedback,
	getJobAppliedCandidate,
	getAllQuestions,
	getAuthToken,
	getATSFunnelSteps,
	updateJobATSTemplate,
	updateEmployerECCAccess,
	getEmployerHrNumberList,
	resendHrNumVerificationLink,
	getHrNumVerificationStatus,
	sendShowPhoneNumberEvent,
	getEccPreferenceConfig,
	updateEccPreference,
	getJobsLocation,
	getPaginatedJobs,
	getCreditExpiryOptions,
	initateNumberMaskCallMobile,
	initateNumberMaskCallDesktop,
	numberMaskCallStatus,
	getCompanyDomains,
	sendEmailVerificationLink,
	getEmailVerificationStatus,
	uploadPocDoc,
	patchPocDoc,
	getPocDocs,
	updatePocVerificationStatus,
	getFuzzyJobRoles,
	fetchCandidateResumeLink,
	getCompanyPrefillData,
	fetchSalarySuggestion,
	getEmployerBanDetails,
	requestReactivation,
	acceptReactivationConsent,
	getRecruitersList,
	getUserProfile,
	getUserWorkspace,
	checkTncAgreement,
	updateTncAgreement,
	getSimilarJobs,
	accountDetails,
	validatePhoneNumber,
	getPaymentPendingInfo,
	autoRenewalChange,
	getTransactions,
	checkCouponCode,
	debitCoinsAndUpdateJobStatus,
	createAccount,
	getAllPackages,
	getAllActivationPlans,
	generatePaymentOrder,
	confirmOrderPayment,
	getApnaProducts,
	resendTransactionOTP,
	initiateBuyCoins,
	reportResume,
	fetchStateDetails,
	fetchCitiesList,
	getGstDetails,
	postGstDetails,
	getInvoice,
	getOrderDetails,
	checkEligibleForPaidPromotion,
	reportMembersAndConsultancy,
	addTeamMember,
	checkIfJobWillBePaidJob,
	getExpiryFeedbackStatus,
	triggerSupportCallRequest,
	deleteJob,
	getJobApplicationsList,
	getBulkCSVDownloadInfo,
	postVerifyCompanyVerificationEnteredCardDetails,
	getSelectedActivationPlan,
	checkForDuplicateJob,
	employerEditJob,
	getAdminMembersConfig,
	getAdminTeamMembers,
	getAdminConsultanciesTeamMembers,
	checkForFirstPurchase,
	getCoinRefundDetails,
	sendDeviceInfo,
};

export default axios;
