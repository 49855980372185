import { OPEN_NOTIFICATION, CLOSE_NOTIFICATION } from "../constants/NotificationTypes";

const initialState = {
	open: false,
	type: "",
	message: "",
	duration: 6000,
};

const notification = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_NOTIFICATION:
			return {
				...state,
				open: true,
				type: action.message_type,
				message: action.message,
				duration: action.duration || initialState.duration,
			};
		case CLOSE_NOTIFICATION:
			return initialState;
		default:
			return state;
	}
};

export default notification;
