import styled from "styled-components";
import media from "../utils/mediaQuery";

const Section = styled.section`
	background-color: ${props => props.bgColor || "transparent"};
`;
const Container = styled.div`
	padding-left: 15px;
	padding-right: 15px;
	margin: 0 auto;

	${media.extraSmall`
        max-width: 100%;
    `};
	${media.small`
        max-width: 540px;
    `};
	${media.medium`
        max-width: 720px;
    `};
	${media.large`
        max-width: 960px;
    `};
	${media.extraLarge`
        max-width: 1200px;
    `};
`;
const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Columns = styled.div`
	width: 100%;
`;

export { Section, Container, Row, Columns };
