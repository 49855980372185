import { ADD_CHANGE, CLEAR_FORM, BULK_ADD } from "../constants/FormTypes";

const initialState = {
	formOneData: {},
	formTwoData: {},
	formThreeData: {},
};

const formData = (state = initialState, action) => {
	switch (action.type) {
		case ADD_CHANGE:
			return {
				...state,
				[action.values.formNumber]: {
					...[action.values.formNumber],
					[action.values.fieldName]: action.values.fieldValue,
				},
			};
		case CLEAR_FORM:
			return {
				...state,
				formOneData: {},
				formTwoData: {},
				formThreeData: {},
			};
		case BULK_ADD:
			return {
				...state,
				[action.values.formNumber]: {
					...[action.values.formNumber],
					...action.values,
				},
			};
		default:
			return state;
	}
};

export default formData;
