/* eslint-disable camelcase */
import dayjs from "dayjs";
import Mixpanel from "../../utils/Mixpanel";

export const trackClickEvents = {
	// Tab click events
	notConnectedTab: data => Mixpanel.track("Not Connected Tab Click", data),
	connectedTab: data => Mixpanel.track("Connected Tab Click", data),
	rejectedTab: data => Mixpanel.track("Rejected Tab Click", data),
	suggestedTab: data => Mixpanel.track("Job Detail Suggested Tab Click", data),
	jobDetails: data => Mixpanel.track("Job Detail Details Tab Click", data),

	// action click events
	callAction: data => Mixpanel.track("Call Button Click", data),
	showNumberAction: data => Mixpanel.track("Show Number Button Click", data),
	whatsappAction: data => Mixpanel.track("WhatsApp Button click", data),
	rejectAction: data =>
		Mixpanel.track("Reject Button Click", {
			CTA: "Not Interested",
			...data,
		}),
	myNotesAction: data => Mixpanel.track("My Notes Button Click", data),
	shareNumberAction: data => Mixpanel.track("Share My Number", data),
	restoreAction: data => Mixpanel.track("Restore Button Click", data),

	// Empty view events
	emptyNotConnectedTab: data =>
		Mixpanel.track("Go to Suggested Section Button Click", data),
	emptyConnectedTab: data =>
		Mixpanel.track("Go to Not Connected Section Button Click", data),
};

export const trackChangeEvents = {
	hiringStatusChange: data => Mixpanel.track("Candidate status change", data),
	connectionStatusChange: () => Mixpanel.track("Connected Lead created"),
};

export const trackPopupEvents = {
	callConnectionSuccess: data => Mixpanel.track("Call Successful Popup Shown", data),
	callConnectionSuccessClose: data =>
		Mixpanel.track("Call Successful Popup Closed", data),
	callConnectionFailed: data => Mixpanel.track("Call Failed Popup Shown", data),
	callConnectionFailedClose: data => Mixpanel.track("Call Failed Popup Closed", data),

	whatsappConnect: data => Mixpanel.track("WhatsApp Successful Popup Shown", data),

	callCandidateFeedback: data => Mixpanel.track("Call Candidate Feedback Popup", data),
	callCandidateFeedbackYes: data =>
		Mixpanel.track("Call Candidate Feedback Yes Click", data),
	callCandidateFeedbackNo: data =>
		Mixpanel.track("Call Candidate Feedback No Click", data),
	candidateFeedbackClose: data =>
		Mixpanel.track("Call Candidate Feedback Closed", data),
};

export const trueEccPreferenceEvent = data =>
	Mixpanel.track("ECC Preference Selected", data);

export const eccPostRenewEvents = {
	eccPreferenceModalShown: data =>
		Mixpanel.track("Renewal Flow ECC Pref Modal Shown", data),
	eccPreferenceSubmited: data => Mixpanel.track("Renewal Flow ECC Pref Selected", data),
	eccBulkRejectModalShown: data => Mixpanel.track("ECC Bulk Reject Modal Shown", data),
	eccBulkRejectSubmitted: data =>
		Mixpanel.track("ECC Bulk Reject Modal Submitted", data),
};

const getDeviceType = () => {
	const ua = navigator.userAgent;
	if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
		return "tablet";
	}
	if (
		/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
			ua
		)
	) {
		return "mobile";
	}
	return "desktop";
};

export const getTabClickTrackerData = (jobData, candidateCount) => ({
	device: getDeviceType(),
	"Job ID": jobData?.id,
	"Category ID": jobData?.type?.job_type,
	"ECC Preference": jobData?.tecc_employer_preference_values,
	"Candidate Count": candidateCount,
	"Min Salary": jobData?.job_data?.min_salary,
	"Max Salary": jobData?.max_salary,
	"Is WFH": jobData?.is_wfh,
	"Lead Type": jobData?.created_by?.lead_type,
	Promoted: jobData?.is_promoted,
	"Is Stale": jobData?.is_fulfillment_staled,
	"Last Activated": jobData?.last_activated,
	Status: jobData?.status,
	"Number of skills required": jobData?.skills_required?.length || 0,
	"Number of assets required": jobData?.assets_required?.length || 0,
	"Job Skills required": jobData?.skills_required,
	"Job assets required": jobData?.assets_required,
	"Part Time": jobData?.is_part_time,
	Openings: jobData?.no_of_openings,
	Gender: jobData?.gender,
	Education: jobData?.education_level,
	"English Requirements": jobData?.job_data?.english,
	Experience: jobData?.experience_in_years,
	Shift: jobData?.shift,
	"Application From": jobData?.applicant_location,
	Area: jobData?.address?.area?.name,
	City: jobData?.address?.area?.city?.name,
	"Jobs Category": jobData?.category,
	"Expiry Date": jobData?.expiry,
});

export const getActionClickTrackerData = (
	jobData,
	candidateData,
	isExtendedProfileAction
) => {
	return {
		device: getDeviceType(),
		"Job ID": jobData?.id,
		"Category ID": jobData?.type?.job_type,
		"ECC Preference": jobData?.tecc_employer_preference_values,
		"Min Salary": jobData?.job_data?.min_salary,
		"Max Salary": jobData?.max_salary,
		"Is WFH": jobData?.is_wfh,
		"Lead Type": jobData?.created_by?.lead_type,
		Promoted: jobData?.is_promoted,
		"Is Stale": jobData?.is_fulfillment_staled,
		"Last Activated": jobData?.last_activated,
		Status: jobData?.status,
		"Number of skills required": jobData?.skills_required?.length || 0,
		"Number of assets required": jobData?.assets_required?.length || 0,
		"Job Skills required": jobData?.skills_required,
		"Job assets required": jobData?.assets_required,
		"Part Time": jobData?.is_part_time,
		Openings: jobData?.no_of_openings,
		Gender: jobData?.gender,
		Education: jobData?.education_level,
		"English Requirements": jobData?.job_data?.english,
		Experience: jobData?.experience_in_years,
		Shift: jobData?.shift,
		"Application From": jobData?.applicant_location,
		Area: jobData?.address?.area?.name,
		City: jobData?.address?.area?.city?.name,
		"Jobs Category": jobData?.category,
		"Expiry Date": jobData?.expiry,
		"Connection Status": candidateData?.connection_status,
		"Hiring Status": candidateData?.hiring_state,
		"Number Share Status": candidateData?.number_shared,
		"Candidate ID": candidateData?.id,
		"Candidate Name": candidateData?.full_name,
		...(isExtendedProfileAction && { Source: "Extended Profile Page" }),
		...(candidateData?.inQueue && { Section: "Recently Updated Card" }),
	};
};

export const getHiringStatusChangeData = (
	jobData,
	candidateData,
	currentStatus,
	isExtendedProfileAction
) => ({
	device: getDeviceType(),
	"Job ID": jobData?.id,
	"ECC Preference": jobData?.tecc_employer_preference_values,
	"Current Status": currentStatus,
	"Previous Status": candidateData?.hiring_state,
	"Candidate ID": candidateData?.id,
	"Candidate Name": candidateData?.full_name,
	Openings: jobData?.no_of_openings,
	Gender: jobData?.gender,
	Education: jobData?.education_level,
	"English Requirements": jobData?.job_data?.english,
	Experience: jobData?.experience_in_years,
	Shift: jobData?.shift,
	"Application From": jobData?.applicant_location,
	Area: jobData?.address?.area?.name,
	City: jobData?.address?.area?.city?.name,
	"Jobs Category": jobData?.category,
	"Expiry Date": jobData?.expiry,
	...(isExtendedProfileAction && { Source: "Extended Profile Page" }),
});

export const getTrueEccPreferenceEventData = ({ current, prev, source, userId }) => ({
	device: getDeviceType(),
	"Current ECC Preference Option": current,
	"Previous ECC Preference Option": prev,
	"User ID": userId,
	Source: source,
});

export const findMatchedData = (candidateData = [], jobRequirementData = []) => {
	let matchedPercentage = 0;
	const matchData = [];
	if (!jobRequirementData?.length) {
		return 1;
	}
	candidateData.forEach(candidateValue => {
		const dataFound = jobRequirementData.find(
			jobValue => jobValue?.id === candidateValue?.id
		);
		if (dataFound) {
			matchData.push(dataFound);
		}
	});
	matchedPercentage = matchData?.length / jobRequirementData?.length;
	return matchedPercentage;
};

export const getCandidateTrackingData = (
	jobData,
	candidateData,
	isExtendedProfileAction
) => {
	const {
		assets = [],
		required_assets = [],
		skills = [],
		required_skills = [],
		last_applied_at,
		app_version = "",
		score = "",
		id = "",
		experience_in_years = "",
		exp = "",
		edu = "",
		last_active_on = "",
		username = "",
		full_name = "",
		show_number_clicked = "",
		company = "",
		current_salary = "",
		english = "",
		gender = "",
	} = candidateData;
	const matchedSkills = findMatchedData(skills, required_skills);
	const matchedAssets = findMatchedData(assets, required_assets);
	let diffDays = 0;
	try {
		const todaysDate = new Date(dayjs().format("MM/DD/YYYY"));
		const lastAplliedDate = new Date(
			dayjs(last_applied_at || todaysDate).format("MM/DD/YYYY")
		);
		const diffTime = Math.abs(todaysDate - lastAplliedDate);
		diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	} catch (error) {
		console.log("error:", error);
	}
	return {
		"App Version": app_version,
		Score: score,
		"Number of skills required": required_skills?.length,
		"Number of assets required": required_assets?.length,
		"Job Skills required": required_skills,
		"Job assets required": required_assets,
		"Candidate Skills": skills,
		"Candidate Assets": assets,
		"Skills Matched Ratio": matchedSkills,
		"Assets Matched Ratio": matchedAssets,
		"Last Job Applied (days)": diffDays,
		"Candidate Experience In Years": experience_in_years,
		"Candidate Experience": exp,
		"Candidate Education": edu,
		"Candidate Last Active On": last_active_on,
		Number: username,
		"Candidate Name": full_name,
		"Candidate ID": id,
		"Show Number Clicked": show_number_clicked,
		"Candidate Company": company,
		"Candidate Current Salary": current_salary,
		"Candidate English Requirements": english,
		"Candidate Gender": gender,
		...getActionClickTrackerData(jobData, candidateData, isExtendedProfileAction),
	};
};
