/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import rootSaga from "../sagas";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [
	sagaMiddleware,
	thunk,
	// other middlewares here
];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	rootReducer,
	composeEnhancer(
		applyMiddleware(...middlewares)
		// other store enhancers if any
	)
);

// then run the saga
sagaMiddleware.run(rootSaga);

export default store;
